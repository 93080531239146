import multicall from 'utils/multicall';
import IDO_V2_ABI from 'config/abi/IDO_V2_ABI.json';
import BigNumber from 'bignumber.js';
import { BIG_TEN } from 'utils/bigNumber';

export const fetchInfoLands = async (lands) => {
  const calls = [
    {
      address: lands[0].idoContract,
      name: 'getCurrentRoundData',
    },
    {
      address: lands[0].idoContract,
      name: 'totalSold',
    },
    {
      address: lands[0].idoContract,
      name: 'getBurnedTokens',
    },
    {
      address: lands[0].idoContract,
      name: 'totalParticipants',
    },
    {
      address: lands[0].idoContract,
      name: 'getInputAmount',
      params: [new BigNumber(1).times(BIG_TEN.pow(lands[0].token.decimals)).toString()],
    },
    {
      address: lands[0].idoContract,
      name: 'getOutputAmount',
      params: [new BigNumber(1).times(BIG_TEN.pow(lands[0].token.decimals)).toString()],
    },
  ];

  const [rawCurrentRound, rawTotalSold, rawTotalDumped, rawTotalParticipants, rawSwapRate, rawSwapRateOutput] =
    await multicall(IDO_V2_ABI, calls, lands[0].chainId);

  const formatLands = lands.map((land) => ({
    ...land,
    currentRound: {
      round: new BigNumber(rawCurrentRound?.roundId?._hex).toNumber() + 1,
      startTime: new BigNumber(rawCurrentRound?.startTime?._hex).toNumber() * 1000,
      endTime: new BigNumber(rawCurrentRound?.endTime._hex).toNumber() * 1000,
      sold: new BigNumber(rawCurrentRound?.sold._hex).toJSON(),
      unsold: new BigNumber(rawCurrentRound?.unsold._hex).toJSON(),
    },
    totalParticipants: new BigNumber(rawTotalParticipants[0]._hex).toJSON(),
    totalSold: new BigNumber(rawTotalSold[0]._hex).toJSON(),
    totalBurn: new BigNumber(rawTotalDumped[0]._hex).toJSON(),
    pricePerToken: new BigNumber(rawSwapRate[0]._hex).toJSON(),
    pricePerTokenEstimate: new BigNumber(rawSwapRateOutput[0]._hex).toJSON(),
  }));

  return formatLands;
};
