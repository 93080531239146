import { useMemo, useState } from 'react';
import { formatAmount } from 'utils/formatInfoNumbers';
import { classNames } from 'utils/styling';
import LineChart from 'views/Analytics/components/InfoCharts/LineChart/LineChart';
import CandleChart from 'views/Analytics/components/InfoCharts/CandleChart/CandleChart';
import BarChart from 'views/Analytics/components/InfoCharts/BarChart/BarChart';
import dayjs from 'dayjs';
import Card from 'components/Card/Card';
import Skeleton from 'components/Skeleton/Skeleton';

const ChartView = {
  LIQUIDITY: 0,
  VOLUME: 1,
  PRICE: 2,
};

const ChartCard = ({ variant, chartData, tokenData, tokenPriceData }) => {
  const [view, setView] = useState(ChartView.VOLUME);
  const [hoverValue, setHoverValue] = useState();
  const [hoverDate, setHoverDate] = useState();

  const currentDate = new Date().toLocaleString('en', { month: 'short', year: 'numeric', day: 'numeric' });

  const formattedTvlData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: dayjs.unix(day.date).toDate(),
          value: day.liquidityUSD,
        };
      });
    }
    return [];
  }, [chartData]);

  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: dayjs.unix(day.date).toDate(),
          value: day.volumeUSD,
        };
      });
    }
    return [];
  }, [chartData]);

  const getLatestValueDisplay = () => {
    let valueToDisplay = null;
    if (hoverValue) {
      valueToDisplay = formatAmount(hoverValue);
    } else if (view === ChartView.VOLUME && formattedVolumeData.length > 0) {
      valueToDisplay = formatAmount(formattedVolumeData[formattedVolumeData.length - 1]?.value);
    } else if (view === ChartView.LIQUIDITY && formattedTvlData.length > 0) {
      valueToDisplay = formatAmount(formattedTvlData[formattedTvlData.length - 1]?.value);
    } else if (view === ChartView.PRICE && tokenData?.priceUSD) {
      valueToDisplay = formatAmount(tokenData.priceUSD);
    }

    return valueToDisplay ? <p>${valueToDisplay}</p> : <Skeleton height={36} width={100} />;
  };

  return (
    <Card>
      <div className="flex text-white bg-black1 m-3 rounded-3xl py-1 px-2 text-center">
        <div
          className={classNames(
            'flex-1 px-4 py-2 whitespace-nowrap cursor-pointer',
            view === ChartView.VOLUME && 'text-black bg-primary rounded-2xl',
          )}
          onClick={() => setView(ChartView.VOLUME)}
        >
          <p>Volume</p>
        </div>
        <div
          className={classNames(
            'flex-1 px-4 py-2 whitespace-nowrap cursor-pointer',
            view === ChartView.LIQUIDITY && 'text-black bg-primary rounded-2xl',
          )}
          onClick={() => setView(ChartView.LIQUIDITY)}
        >
          <p>Liquidity</p>
        </div>
        {variant === 'token' && (
          <div
            className={classNames(
              'flex-1 px-4 py-2 whitespace-nowrap cursor-pointer',
              view === ChartView.PRICE && 'text-black bg-primary rounded-2xl',
            )}
            onClick={() => setView(ChartView.PRICE)}
          >
            <p>Price</p>
          </div>
        )}
      </div>
      <div className="pb-5 px-5">
        <div className="flex flex-col  ">
          <span className="text-primary text-2xl font-bold">{getLatestValueDisplay()}</span>
          <p className="text-sm-md  ">{hoverDate || currentDate}</p>
        </div>

        <div className={classNames(variant === 'token' ? 'h-[250px]' : 'h-[335px]')}>
          {view === ChartView.LIQUIDITY ? (
            <LineChart data={formattedTvlData} setHoverValue={setHoverValue} setHoverDate={setHoverDate} />
          ) : view === ChartView.VOLUME ? (
            <BarChart data={formattedVolumeData} setHoverValue={setHoverValue} setHoverDate={setHoverDate} />
          ) : view === ChartView.PRICE ? (
            <CandleChart data={tokenPriceData} setValue={setHoverValue} setLabel={setHoverDate} />
          ) : null}
        </div>
      </div>
    </Card>
  );
};

export default ChartCard;
