import Dots from 'components/Loader/Dots';
import ModalApyCalculator from 'components/Modal/ModalApyCalculator';
import { useState } from 'react';
import { FiClipboard } from 'react-icons/fi';
import { formatNumber } from 'utils/formatBalance';
import { getPoolName } from 'utils/tokenHelpers';
import {formatAmount} from "../../../../../utils/formatInfoNumbers";

const AprCell = ({ pool }) => {
  const labelEarningToken = getPoolName(pool.isV2 ? pool.earningTokens : [pool.earningToken]);
  const [openRoi, setOpenRoi] = useState(false);

  const isShowModalApr = !pool.isV2 || pool.earningTokens.length < 2;

  if (pool.isFinished) return null;

  return (
    <div className="h-full ">
      {openRoi ? (
        <ModalApyCalculator
          open={openRoi}
          apr={pool?.apr}
          data={pool}
          earningTokenSymbol={labelEarningToken}
          tokenPrice={pool?.earningTokenPrice || pool?.earningTokensPrice?.[0]}
          onClose={() => setOpenRoi(!openRoi)}
        />
      ) : null}
      <p>APR</p>
      {pool.apr ? (
        <p
          onClick={() => {
            if (isShowModalApr) {
              setOpenRoi(true);
            }
          }}
          className="relative flex items-center cursor-pointer hover:opacity-50 font-bold text-primary"
        >
          <span>{`${pool.apr.yearlyAPR === '0' ? '???' : formatAmount(+pool.apr, {
            displayThreshold: 0.001,
          })}%`}</span>
          <FiClipboard className="ml-1 cursor-pointer" size={18} />
        </p>
      ) : (
        <Dots className="text-white" />
      )}
    </div>
  );
};

export default AprCell;
