import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import InputWithButton from 'components/NumericalInput/InputWithButton';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useApprove } from 'hooks/useApprove';
import useStake from 'hooks/useStake';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getTokenName } from 'utils/tokenHelpers';
import ModalWarningHarvestFee from 'components/Modal/ModalWarningHarvestFee';
import { ChainId } from 'ezcake-v2-sdk';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const Stake = ({ farm, userDataLoaded, masterChefAddress, onUpdateUserFarm }) => {
  const pid = farm.pid;
  const { account, chainId } = useActiveWeb3React();
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const [requestedApproval, setRequestedApproval] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleWarning = () => setOpen(!open);

  const { allowance, stakingTokenBalance, unlockTimestamp, stakedBalance } = farm.userData;
  const isApproved = account && allowance && allowance.isGreaterThan(0);

  const { onApprove } = useApprove(farm.stakingToken.address[chainId], masterChefAddress);
  const { onStake } = useStake(pid, masterChefAddress);

  const lpTokenName = getTokenName(farm.token0, farm.token1, farm.stakingToken);

  const usdValue = value && formatNumber(new BigNumber(value).times(farm.price || 0).toNumber());
  const usdBalance =
    stakingTokenBalance &&
    formatNumber(
      new BigNumber(getBalanceNumber(stakingTokenBalance, farm.stakingToken.decimals))
        .times(farm.price || 0)
        .toNumber(),
    );

  const handleTypeInput = (valueInput) => {
    setValue(valueInput);
  };

  const isInsufficientBalance = useMemo(() => {
    return new BigNumber(value).isGreaterThan(getFullDisplayBalance(stakingTokenBalance, farm.stakingToken.decimals));
  }, [value, stakingTokenBalance, farm.stakingToken.decimals]);

  const handleMaxInput = useCallback(() => {
    setValue(getFullDisplayBalance(stakingTokenBalance, farm.stakingToken.decimals));
  }, [farm.stakingToken.decimals, stakingTokenBalance]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (percent === 100) {
        handleMaxInput();
      } else {
        setValue(
          getFullDisplayBalance(
            new BigNumber(stakingTokenBalance).times(percent).dividedBy(100),
            farm.stakingToken.decimals,
            farm.stakingToken.decimals,
          ),
        );
      }
    },
    [farm.stakingToken.decimals, handleMaxInput, stakingTokenBalance],
  );

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove();
      showToastSuccess('Contract Enabled', `You can now stake in the ${lpTokenName} pool!`);
      onUpdateUserFarm();
      setRequestedApproval(false);
    } catch (e) {
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setRequestedApproval(false);
    }
  }, [lpTokenName, onApprove, onUpdateUserFarm]);

  const handleStake = useCallback(async () => {
    try {
      if (open) setOpen(false);
      setPendingTx(true);
      await onStake(value, farm.stakingToken.decimals);
      onUpdateUserFarm();
      showToastSuccess('Staked', `Your ${lpTokenName} funds have been staked in the pool!`);
      setValue('');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [farm.stakingToken.decimals, lpTokenName, onStake, onUpdateUserFarm, open, value]);

  const [q0User, q1User] = getLpBreakdown(
    stakingTokenBalance.div(BIG_TEN.pow(farm.stakingToken.decimals)).toNumber(),
    farm?.totalSupply,
    farm?.q0,
    farm?.q1,
  );

  return (
    <div>
      <ModalWarningHarvestFee
        open={open}
        toggleModal={toggleWarning}
        content="Deposit will auto-harvest your rewards. Harvest before 3 months lock will cost you 50% rewards and send to Stake and Lock Pool. Are you sure to continue?"
        onConfirm={handleStake}
      />

      <div>
        <div className="flex font-bold text-white">
          <p>Token Balance:</p>
          <div className="ml-2">
            <>
              {account ? (
                userDataLoaded ? (
                  getFullDisplayBalance(stakingTokenBalance, farm.stakingToken.decimals)
                ) : (
                  <Dots />
                )
              ) : (
                '???'
              )}{' '}
              {lpTokenName}
            </>
            {farm?.token1 && (
              <p>
                {stakingTokenBalance.gt(0) && (
                  <>
                    ({formatNumber(getBalanceNumber(q0User, farm.token0.decimals), 2, 4)} {farm.token0.symbol} +{' '}
                    {formatNumber(getBalanceNumber(q1User, farm.token1.decimals), 2, 4)} {farm.token1.symbol})
                  </>
                )}
              </p>
            )}
            <p className="text-sm mt-1">~{usdBalance || '0'} USD</p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex-1 text-white">
          <InputWithButton value={value} onUserInput={handleTypeInput} btnOnClick={handleMaxInput} />
          <p className="text-right text-sm mt-1">~{usdValue || '0'} USD</p>
          <PercentPicker
            isNumber={true}
            className="text-black max-w-sm mx-auto"
            onChangePercentInput={handlePercentInput}
          />
        </div>
        <div className="mt-2 text-black">
          {account ? (
            userDataLoaded ? (
              isApproved ? (
                <ButtonCustom
                  disabled={pendingTx || !new BigNumber(value).isGreaterThan(0) || isInsufficientBalance}
                  isLoading={pendingTx}
                  onClick={() => {
                    const hasHarvestFee = unlockTimestamp?.toNumber() * 1000 > Date.now();
                    if (hasHarvestFee && chainId === ChainId.HARMONY && stakedBalance.gt(0)) {
                      toggleWarning();
                    } else {
                      handleStake();
                    }
                  }}
                  className="w-full"
                >
                  {isInsufficientBalance ? `Insufficient balance` : pendingTx ? 'Pending Confirmation' : 'Stake'}
                </ButtonCustom>
              ) : (
                <ButtonCustom
                  className="w-full"
                  isLoading={requestedApproval}
                  disabled={requestedApproval}
                  onClick={handleApprove}
                >
                  Approve Contract
                </ButtonCustom>
              )
            ) : (
              <ButtonCustom className="w-full" isLoading>
                Loading <Dots />
              </ButtonCustom>
            )
          ) : (
            <UnlockButton  />
          )}
        </div>
      </div>
    </div>
  );
};

Stake.propTypes = {
  farm: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
  masterChefAddress: PropTypes.string.isRequired,
};

export default Stake;
