import BigNumber from 'bignumber.js';
import { classNames } from 'utils/styling';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import { FiExternalLink } from 'react-icons/fi';
import Dots from 'components/Loader/Dots';
import DoubleLogo from 'components/Logo/DoubleLogo';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useVaultApprove } from 'hooks/useApprove';
import useDeposit from 'hooks/useDeposit';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useModalWalletConnect } from 'store/modal/hooks';
import { fetchVaultUserDataAsync, fetchVaultV2UserDataAsync } from 'store/vaults/index';
import { getLinkGetTokenLP } from 'utils/explorer';
import { getFullDisplayBalance } from 'utils/formatBalance';
import { formatLogo } from 'utils/formatLogo';
import { getTokenNameVault } from 'utils/tokenHelpers';

const CardHeading = ({ vault, userDataLoaded }) => {
  const { onToggleConnectModal } = useModalWalletConnect();
  const lpTokenName = getTokenNameVault(vault?.token0, vault?.token1, vault?.stakingToken);
  const logo = formatLogo(vault?.token0, vault?.token1);
  const isLpToken = !!vault?.token1;
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch();
  const { userData } = vault;
  const [pendingTx, setPendingTx] = useState(false);
  const [requestedApproval, setRequestedApproval] = useState(false);
  const { stakingTokenBalance, allowance } = userData;
  const isApproved = account && allowance && allowance.isGreaterThan(0);

  const symbol = getTokenNameVault(vault?.token0, vault?.token1, vault?.stakingToken?.symbol);

  const { onApprove } = useVaultApprove(vault.contractAddress, vault.stakingToken.address[chainId]);

  const { onDeposit } = useDeposit(vault.contractAddress);

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove();
      showToastSuccess('Contract Enabled', `You can now stake in the ${symbol} vault!`);
      dispatch(fetchVaultV2UserDataAsync(account, chainId));
      dispatch(fetchVaultUserDataAsync(account, chainId));
      setRequestedApproval(false);
    } catch (e) {
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setRequestedApproval(false);
    }
  }, [account, chainId, dispatch, onApprove, symbol]);

  const handleDeposit = useCallback(async () => {
    try {
      setPendingTx(true);
      await onDeposit(
        getFullDisplayBalance(stakingTokenBalance, vault.stakingToken.decimals),
        vault.stakingToken.decimals,
      );
      dispatch(fetchVaultV2UserDataAsync(account, chainId));
      dispatch(fetchVaultUserDataAsync(account, chainId));
      showToastSuccess('Deposited', `Your ${symbol} funds have been deposited in the vault!`);
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [account, chainId, dispatch, onDeposit, stakingTokenBalance, symbol, vault.stakingToken.decimals]);

  const linkGetToken = getLinkGetTokenLP(chainId, vault?.token0, vault?.token1, vault?.stakingToken);

  return (
    <div>
      <div className="sm:flex justify-between space-x-4 -mb-5">
        <div className="flex-1 flex flex-col justify-center items-center -translate-y-6 transform">
          <div>
            <DoubleLogo
              className={classNames(vault?.token1 && '-translate-x-4 transform')}
              src0={logo.src0}
              src1={logo.src1}
              alt0={logo.alt0}
              alt1={logo.alt1}
              size={80}
            />
          </div>
          <div className="text-center text-white">
            <p className="font-bold mt-2 text-base sm:text-xl">{lpTokenName}</p>
            <p>{isLpToken ? 'LP Token' : 'Single Asset'}</p>
          </div>
        </div>
      </div>
      <div className="flex-1 flex space-x-2">
        <button className="font-bold bg-black text-white rounded-md p-4 w-full flex justify-center">
          {vault?.token1 ? (
            <span className="flex items-center justify-center cursor-pointer hover:underline whitespace-nowrap">
              {linkGetToken?.route ? (
                <Link
                  className="flex items-center justify-center cursor-pointer hover:underline"
                  to={linkGetToken?.route}
                  target="_blank"
                >
                  GET LP TOKENS
                  <FiExternalLink className="ml-1" size={16} />
                </Link>
              ) : (
                <a
                  rel="noreferrer"
                  className="flex justify-center items-center hover:underline"
                  target="_blank"
                  href={linkGetToken?.link}
                >
                  GET LP TOKENS <FiExternalLink className="ml-1" size={16} />
                </a>
              )}
            </span>
          ) : (
            <span className="flex items-center justify-center whitespace-nowrap">
              {linkGetToken?.route ? (
                <Link
                  className="flex items-center justify-center cursor-pointer hover:underline"
                  to={linkGetToken?.route}
                  target="_blank"
                >
                  GET TOKEN
                  <FiExternalLink className="ml-1" size={16} />
                </Link>
              ) : (
                <a
                  rel="noreferrer"
                  className="flex justify-center items-center hover:underline"
                  target="_blank"
                  href={linkGetToken?.link}
                >
                  GET TOKEN <FiExternalLink className="ml-1" size={16} />
                </a>
              )}
            </span>
          )}
        </button>
        {account ? (
          userDataLoaded ? (
            isApproved ? (
              <button
                onClick={handleDeposit}
                disabled={pendingTx || !new BigNumber(stakingTokenBalance).isGreaterThan(0)}
                className="font-bold bg-primary rounded-md p-4 w-full flex justify-center uppercase whitespace-nowrap"
              >
                <span className="flex items-center">
                  {pendingTx ? 'Pending' : 'Deposit All'} {pendingTx && <Dots />}
                </span>
              </button>
            ) : (
              <button
                onClick={handleApprove}
                disabled={requestedApproval}
                className="font-bold bg-primary rounded-md p-4 w-full flex justify-center uppercase whitespace-nowrap"
              >
                <span className="flex items-center">
                  {requestedApproval ? 'Pending' : 'Approve'}
                  {requestedApproval && <Dots />}
                </span>
              </button>
            )
          ) : (
            <button className="font-bold bg-primary rounded-md p-4 w-full flex justify-center uppercase">
              Loading <Dots />
            </button>
          )
        ) : (
          <button
            onClick={onToggleConnectModal}
            className="font-bold bg-primary rounded-md p-4 w-full flex justify-center whitespace-nowrap"
          >
            Connect wallet
          </button>
        )}
      </div>
    </div>
  );
};

export default CardHeading;
