import BigNumber from 'bignumber.js';
import ActionTool from 'components/ActionTool/ActionTool';
import Back from 'components/Back/Back';
import { TabContent } from 'components/Tabs/Tabs';
import TabsFinished from 'components/Tabs/TabsFinished';
import Value from 'components/Value/Value';
import { TABS, TYPE_VIEW } from 'constants/index';
import { sortListVault, sortTypeVault, PLATFORM } from 'constants/vaults';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useDebounce from 'hooks/useDebounce';
import useRefreshVault from 'hooks/useRefreshVault';
import { useBurnedDeadBalance } from 'hooks/useTokenBalance';
import { useMemo, useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { useFarmsOutside } from 'store/farmsOutside/hook';
import { usePrices } from 'store/prices/hook';
import { VAULT_VIEW_DEFAULT } from 'store/user/actions';
import { useUserStakedOnly, useUserViewMode } from 'store/user/hooks/index';
import { formatVault, formatVaultV2 } from 'store/vaults/helpers';
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getParameterCaseInsensitive } from 'utils/index';
import { getTokenName } from 'utils/tokenHelpers';
import { useVaults } from 'store/vaults/hook';
import { useFarmsNoAccount } from 'store/farms/hook';
import VaultsCard from 'views/Vaults/components/VaultsCard/VaultsCard';
import VaultsTable from 'views/Vaults/components/VaultsTable/VaultsTable';

const Vaults = () => {
  const { chainId } = useActiveWeb3React();
  const { vaults, userDataLoaded, vaultsV2, userDataLoadedV2 } = useVaults();
  const [tab, setTab] = useState(TABS.live);
  const [sortBy, setSortBy] = useState(sortTypeVault.hot);
  const [search, setSearch] = useState('');
  const { onRefresh } = useRefreshVault();

  const debouncedQuery = useDebounce(search, 300);

  const burnedDeadBalance = getBalanceNumber(useBurnedDeadBalance());

  const [viewMode, setViewMode] = useUserViewMode();
  const typeView = viewMode?.vaults || VAULT_VIEW_DEFAULT;

  const [stakedOnly, setStakedOnly] = useUserStakedOnly();
  const isStakedOnly = stakedOnly?.vaults;

  const prices = usePrices();
  const farms = useFarmsNoAccount();
  const farmsOutsideV2 = useFarmsOutside();

  const vaultsFormat = useMemo(
    () => vaults.map((vault) => formatVault(prices, farms, farmsOutsideV2, vault, chainId)),
    [vaults, prices, farms, farmsOutsideV2, chainId],
  );

  const vaultsV2Format = useMemo(
    () => vaultsV2.map((vault) => formatVaultV2(prices, farms, vault, chainId)),
    [vaultsV2, prices, farms, chainId],
  );

  const vaultsActive = useMemo(() => {
    if (tab === TABS.live) {
      return vaultsFormat.filter((pool) => !pool.isFinished);
    }

    return vaultsFormat.filter((pool) => pool.isFinished);
  }, [vaultsFormat, tab]);

  const vaultsV2Active = useMemo(() => {
    if (tab === TABS.live) {
      return vaultsV2Format.filter((pool) => !pool.isFinished);
    }

    return vaultsV2Format.filter((pool) => pool.isFinished);
  }, [vaultsV2Format, tab]);

  const vaultsSingleToken = useMemo(() => {
    const vaultV1Single = vaultsActive.filter((vault) => !vault.token1);
    const sortVaultDFLToTop = [...vaultsV2Active, ...vaultV1Single].sort((vaultA) => {
      if (vaultA.platform === PLATFORM.eCake) {
        return -1;
      }
      return 0;
    });

    const vaultDFL = sortVaultDFLToTop.filter((vault) => vault.platform === PLATFORM.eCake);

    const sortVaultApy = sortVaultDFLToTop
      .filter((vault) => vault.platform !== PLATFORM.eCake)
      .sort((vaultA, vaultB) => {
        return vaultB.apy.yearlyApy - vaultA.apy.yearlyApy;
      });

    return [...vaultDFL, ...sortVaultApy];
  }, [vaultsActive, vaultsV2Active]);

  const vaultsLpToken = useMemo(() => {
    const vaultLp = vaultsActive.filter((vault) => vault.token1);
    const vaultDFL = vaultLp.filter((vault) => vault.platform === PLATFORM.eCake);

    const sortVaultApy = vaultLp
      .filter((vault) => vault.platform !== PLATFORM.eCake)
      .sort((vaultA, vaultB) => {
        return vaultB.apy.yearlyApy - vaultA.apy.yearlyApy;
      });

    return [...vaultDFL, ...sortVaultApy];
  }, [vaultsActive]);

  const tvlAllVaults = useMemo(() => {
    const totalTvlVaultV1 = vaultsFormat.reduce((sum, vault) => {
      return (sum += vault.stakedTvl);
    }, 0);

    const totalTvlVaultV2 = vaultsV2Format.reduce((sum, vault) => {
      return (sum += vault.stakedTvl);
    }, 0);

    return totalTvlVaultV1 + totalTvlVaultV2;
  }, [vaultsFormat, vaultsV2Format]);

  const vaultsDisplay = useMemo(() => {
    if (isStakedOnly)
      return vaultsLpToken.filter((vault) => {
        const priceStakingToken = getParameterCaseInsensitive(prices, vault.stakingToken.address[chainId]);
        const usdHoldings = priceStakingToken
          ? new BigNumber(getFullDisplayBalance(vault.userData.stakedBalance, vault.stakingToken.decimals))
              .times(priceStakingToken)
              .toNumber()
          : 0;

        return usdHoldings && new BigNumber(usdHoldings).isGreaterThan(0.01);
      });
    return vaultsLpToken;
  }, [isStakedOnly, vaultsLpToken, prices, chainId]);

  const vaultsV2Display = useMemo(() => {
    if (isStakedOnly)
      return vaultsSingleToken.filter((vault) => {
        const priceStakingToken = getParameterCaseInsensitive(prices, vault.stakingToken.address[chainId]);
        const usdHoldings = priceStakingToken
          ? new BigNumber(getFullDisplayBalance(vault.userData.stakedBalance, vault.stakingToken.decimals))
              .times(priceStakingToken)
              .toNumber()
          : 0;

        return usdHoldings && new BigNumber(usdHoldings).isGreaterThan(0.01);
      });
    return vaultsSingleToken;
  }, [isStakedOnly, vaultsSingleToken, prices, chainId]);

  const filterDataV2BySearch = useMemo(() => {
    return vaultsV2Display.filter((vault) => {
      const nameDisplay = getTokenName(vault?.token0, vault?.token1, vault?.stakingToken);
      return nameDisplay.toLowerCase().includes(debouncedQuery.toLowerCase());
    });
  }, [debouncedQuery, vaultsV2Display]);

  const filterDataBySearch = useMemo(() => {
    return vaultsDisplay.filter((vault) => {
      const nameDisplay = getTokenName(vault?.token0, vault?.token1, vault?.stakingToken);
      return nameDisplay.toLowerCase().includes(debouncedQuery.toLowerCase());
    });
  }, [debouncedQuery, vaultsDisplay]);

  const vaultsV2Sort = useMemo(() => {
    if (sortBy === sortTypeVault.hot) return filterDataV2BySearch;
    return filterDataV2BySearch.sort((vaultA, vaultB) => {
      if (sortBy === sortTypeVault.apy) {
        return vaultB.apy.yearlyApy - vaultA.apy.yearlyApy;
      }
      if (sortBy === sortTypeVault.tvl) {
        return vaultB.stakedTvl - vaultA.stakedTvl;
      }
      if (sortBy === sortTypeVault.available) {
        return vaultB.userData.stakingTokenBalance.minus(vaultA.userData.stakingTokenBalance).toNumber();
      }
      if (sortBy === sortTypeVault.holdings) {
        const priceStakingTokenA = getParameterCaseInsensitive(prices, vaultA.stakingToken.address[chainId]);
        const priceStakingTokenB = getParameterCaseInsensitive(prices, vaultB.stakingToken.address[chainId]);
        const usdHoldingsA = priceStakingTokenA
          ? new BigNumber(getFullDisplayBalance(vaultA.userData.stakedBalance, vaultA.stakingToken.decimals))
              .times(priceStakingTokenA)
              .toNumber()
          : 0;
        const usdHoldingsB = priceStakingTokenB
          ? new BigNumber(getFullDisplayBalance(vaultB.userData.stakedBalance, vaultB.stakingToken.decimals))
              .times(priceStakingTokenB)
              .toNumber()
          : 0;

        return usdHoldingsB - usdHoldingsA;
      }
    });
  }, [chainId, filterDataV2BySearch, prices, sortBy]);

  const vaultsSort = useMemo(() => {
    if (sortBy === sortTypeVault.hot) return filterDataBySearch;
    return filterDataBySearch.sort((vaultA, vaultB) => {
      if (sortBy === sortTypeVault.apy) {
        return vaultB.apy.yearlyApy - vaultA.apy.yearlyApy;
      }
      if (sortBy === sortTypeVault.tvl) {
        return vaultB.stakedTvl - vaultA.stakedTvl;
      }
      if (sortBy === sortTypeVault.available) {
        return vaultB.userData.stakingTokenBalance.minus(vaultA.userData.stakingTokenBalance).toNumber();
      }
      if (sortBy === sortTypeVault.holdings) {
        const priceStakingTokenA = getParameterCaseInsensitive(prices, vaultA.lpTokenAddress);
        const priceStakingTokenB = getParameterCaseInsensitive(prices, vaultB.lpTokenAddress);
        const usdHoldingsA = priceStakingTokenA
          ? new BigNumber(getFullDisplayBalance(vaultA.userData.stakedBalance, vaultA.decimals))
              .times(priceStakingTokenA)
              .toNumber()
          : 0;
        const usdHoldingsB = priceStakingTokenB
          ? new BigNumber(getFullDisplayBalance(vaultB.userData.stakedBalance, vaultB.decimals))
              .times(priceStakingTokenB)
              .toNumber()
          : 0;

        return usdHoldingsB - usdHoldingsA;
      }
    });
  }, [filterDataBySearch, prices, sortBy]);

  const handleChangeTab = (value) => {
    if (value !== tab) {
      setTab(value);
    }
  };

  return (
    <>
      <Back />
      <div className="mt-5 flex justify-center flex-col items-center mb-5">
        <p className="text-white text-xl">
          {chainId === ChainId.ONUS ? 'Total mCake burned' : 'Total mCake bought back and burned by Vaults'}
        </p>
        <Value
          className="text-white text-xl"
          value={burnedDeadBalance}
          decimals={6}
          unit={<span className="ml-1">eCake</span>}
        />
        <div className="text-white  text-xl px-3 lg:px-0 mt-3">TVL in Vaults</div>
        {tvlAllVaults ? (
          <Value className="font-bold  text-white text-xl" value={tvlAllVaults} prefix="$" decimals={0} />
        ) : (
          <p className="text-white  text-center text-xl">...</p>
        )}
      </div>
      <div className="container max-w-screen-xl mx-auto mb-20 md:mb-72 pb-0">
        <ActionTool
          labelStaked="Hide small balances"
          isStakedOnly={isStakedOnly}
          setIsStakedOnly={() => setStakedOnly('vaults')}
          sortList={sortListVault}
          sortBy={sortBy}
          setSearch={setSearch}
          setSortBy={setSortBy}
          typeView={typeView}
          setTypeView={(view) => setViewMode('vaults', view)}
        />
        <div className="relative">
          <TabsFinished tab={tab} onChangeTab={handleChangeTab} />
          {chainId === ChainId.KAI && (
            <div
              onClick={onRefresh}
              className="absolute top-0 right-0 text-white flex items-center h-full cursor-pointer"
            >
              <FiRefreshCcw />
            </div>
          )}
        </div>
        <div className="relative z-20">
          <TabContent className="mt-4 px-4" activeTab={tab}>
            <div>
              {tab === TABS.finished && (
                <p className="mb-2 text-rose-700 text-xl">
                  These vaults are no longer distributing rewards. Please Withdraw your tokens.
                </p>
              )}
              {typeView === TYPE_VIEW.table ? (
                <VaultsTable
                  vaultsV2={vaultsV2Sort}
                  vaults={vaultsSort}
                  userDataLoaded={userDataLoaded}
                  userDataLoadedV2={userDataLoadedV2}
                />
              ) : (
                <VaultsCard
                  userDataLoadedV2={chainId === ChainId.KAI ? userDataLoadedV2 : userDataLoaded}
                  userDataLoaded={userDataLoaded}
                  vaults={vaultsSort}
                  vaultsV2={vaultsV2Sort}
                />
              )}
            </div>
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default Vaults;
