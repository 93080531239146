import { classNames } from 'utils/styling';
import { FiCheckCircle, FiCircle, FiExternalLink, FiXCircle } from 'react-icons/fi';
import { getExplorerLink } from 'utils/explorer';
import {useChainId} from "wagmi";

export default function Transaction({ tx }) {
    const chainId = useChainId()

  const summary = tx?.summary;
  const pending = !tx?.receipt;
  const success = !pending && tx && (tx.receipt?.status === "success" || typeof tx.receipt?.status === 'undefined');

  if (!chainId) return null;

  return (
    <div className="flex justify-between my-1">
      <a
        rel="noreferrer"
        target="_blank"
        className={classNames(
          'flex items-center hover-underline font-bold',
          pending ? 'text-primary' : success ? 'text-green-900' : 'text-red-500',
        )}
        href={getExplorerLink(chainId, tx.hash, 'transaction')}
      >
        {summary ?? tx.hash} <FiExternalLink className="ml-1" size={18} />
      </a>
      <div className={classNames(pending ? 'text-primary' : success ? 'text-green-900' : 'text-red-600')}>
        {pending ? <FiCircle /> : success ? <FiCheckCircle /> : <FiXCircle />}
      </div>
    </div>
  );
}
