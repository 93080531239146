// Multichain Explorer
import { SYMBOL_LP_PLATFORM } from 'constants/index';
import { ChainId, NativeCurrency, WETH } from 'ezcake-v2-sdk';
import tokens from '../constants/tokens';
import { PLATFORM } from 'constants/vaults';
import { DOMAIN } from "config";

const builders = {
  etherscan: (chainName, data, type) => {
    const prefix = `https://${chainName ? `${chainName}.` : ''}etherscan.io`;
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },
  bscscan: (chainName, data, type) => {
    const prefix = `https://${chainName ? `${chainName}.` : ''}bscscan.com`;
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },

  kaiscan: (chainName, data, type) => {
    const prefix = `https://explorer.kardiachain.io`;
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },

  matic: (chainName, data, type) => {
    const prefix = 'https://polygonscan.com';
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      case 'token':
        return `${prefix}/tokens/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },

  harmony: (chainName, data, type) => {
    const prefix = 'https://explorer.harmony.one';
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },

  harmonyTestnet: (chainName, data, type) => {
    const prefix = 'https://explorer.pops.one';
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },

  onus: (chainName, data, type) => {
    const prefix = 'https://explorer.onuschain.io';
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },

  onusTestnet: (chainName, data, type) => {
    const prefix = 'https://explorer-testnet.onuschain.io';
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },

  tomo: (chainName, data, type) => {
    const prefix = 'https://scan.tomochain.com';
    switch (type) {
      case 'transaction':
        return `${prefix}/tx/${data}`;
      case 'token':
        return `${prefix}/tokens/${data}`;
      default:
        return `${prefix}/${type}/${data}`;
    }
  },
};

const chains = {
  [ChainId.KAI]: {
    chainName: '',
    builder: builders.kaiscan,
  },
  [ChainId.MAINNET]: {
    chainName: '',
    builder: builders.etherscan,
  },
  [ChainId.BSC]: {
    chainName: '',
    builder: builders.bscscan,
  },
  [ChainId.BSC_TESTNET]: {
    chainName: 'testnet',
    builder: builders.bscscan,
  },
  [ChainId.HARMONY]: {
    chainName: '',
    builder: builders.harmony,
  },
  [ChainId.HARMONY_TESTNET]: {
    chainName: 'testnet',
    builder: builders.harmonyTestnet,
  },
  [ChainId.ONUS]: {
    chainName: '',
    builder: builders.onus,
  },
  [ChainId.ONUS_TESTNET]: {
    chainName: 'testnet',
    builder: builders.onusTestnet,
  },
};

export function getExplorerLink(chainId, data, type) {
  const chain = chains[chainId];
  return chain.builder(chain.chainName, data, type);
}

export const PLATFORM_LINK = {
  [PLATFORM.eCake]: `${DOMAIN}/#`,
  [PLATFORM.cake]: 'https://pancakeswap.finance',
};

export const PREFIX_LINK_LP = {
  [SYMBOL_LP_PLATFORM.eCake]: PLATFORM_LINK[PLATFORM.eCake],
  [SYMBOL_LP_PLATFORM.cake]: PLATFORM_LINK[PLATFORM.cake],
  [ChainId.ONUS]: PLATFORM_LINK[PLATFORM.eCake],
  [ChainId.BSC_TESTNET]: PLATFORM_LINK[PLATFORM.eCake],
  [ChainId.BSC]: PLATFORM_LINK[PLATFORM.cake],
};

export const getLinkGetTokenLP = (chainId, token0, token1, stakingToken) => {
  let prefix = token1 ? PREFIX_LINK_LP[stakingToken?.symbol] : PREFIX_LINK_LP[chainId];

  return {
    label: `Get ${token0?.symbol} Token`,
    link: token1 ? `${prefix}/add/${token0.address?.[chainId]}/${token1.address?.[chainId]}`  :`${prefix}/swap?outputCurrency=${token0.address?.[chainId]}`,
  };
};
