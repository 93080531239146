import Tooltip from 'components/Tooltip/Tooltip';
import { AVERAGE_BLOCK_TIME } from 'config/index';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { FiHelpCircle } from 'react-icons/fi';
const Fees = ({ pool }) => {
  const { chainId } = useActiveWeb3React();
  const feeTimeHours = (pool.blockPeriod * AVERAGE_BLOCK_TIME[chainId]) / 3600;

  return (
    <div className="flex items-center">
      <Tooltip
        tooltip={
          <>
            <p>Unstaking fee: {pool.fees.withdrawalFee}%</p>
            <p>
              Only applies within {feeTimeHours / 24} days of staking. Unstaking after {feeTimeHours / 24} days will not
              include a fee. Timer resets every time you stake in the pool.
            </p>
          </>
        }
      >
        <p>
          Fee: {pool?.fees?.withdrawalFee}% unstaking fee if withdrawn within {feeTimeHours}h{' '}
          <FiHelpCircle className="ml-1 inline-block align-text-bottom" size={16} />
        </p>
      </Tooltip>
    </div>
  );
};

export default Fees;
