import { CurrencyAmount, JSBI, NativeCurrency } from 'ezcake-v2-sdk';
import { MIN_BSC } from 'constants/swap';

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount) {
  if (!currencyAmount) return undefined;
  if (currencyAmount.currency === NativeCurrency[currencyAmount.currency.chainId]) {
    if (JSBI.greaterThan(currencyAmount.raw, MIN_BSC)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, MIN_BSC), currencyAmount.currency.chainId);
    }
    return CurrencyAmount.ether(JSBI.BigInt(0), currencyAmount.currency.chainId);
  }
  return currencyAmount;
}

export default maxAmountSpend;
