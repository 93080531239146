import BigNumber from 'bignumber.js';
import Card from 'components/Card/Card';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import Value from 'components/Value/Value';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useMasterChefContract } from 'hooks/useContract';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePrices } from 'store/prices/hook';
import { BIG_TEN } from 'utils/bigNumber';
import { harvestFarm } from 'utils/calls/farms';
import { getParameterCaseInsensitive } from 'utils/index';
import { getEarningsText } from 'views/Farms/helpers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const HarvestCard = ({
  userDataLoaded,
  farmsWithStakedBalance,
  earningToken,
  masterChefAddress,
  updateHarvestCallback,
  nameFarm,
}) => {
  const prices = usePrices();
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();
  const [pendingTx, setPendingTx] = useState(false);
  const earningTokenPrice = getParameterCaseInsensitive(prices, earningToken.address[chainId]) || 0;
  const masterChefContract = useMasterChefContract(masterChefAddress);

  const farmEarningsSum = useMemo(() => {
    const totalEarned = farmsWithStakedBalance.reduce((accum, farm) => {
      const earningNumber = new BigNumber(farm.userData.earnings);

      if (earningNumber.eq(0)) {
        return accum;
      }
      return accum + earningNumber.div(BIG_TEN.pow(earningToken.decimals)).toNumber();
    }, 0);

    return totalEarned;
  }, [earningToken.decimals, farmsWithStakedBalance]);

  const earningsUsd = new BigNumber(farmEarningsSum).multipliedBy(earningTokenPrice);

  const numTotalToCollect = farmsWithStakedBalance.length;
  const hasEarningPoolToCollect = numTotalToCollect > 0;

  const earningsText = getEarningsText(numTotalToCollect, hasEarningPoolToCollect, earningsUsd);
  const [preText, toCollectText] = earningsText.split(earningsUsd.isNaN() ? '0' : earningsUsd.toString());

  const harvestAllFarms = useCallback(async () => {
    setPendingTx(true);
    // eslint-disable-next-line no-restricted-syntax
    for (const farmWithBalance of farmsWithStakedBalance) {
      try {
        await harvestFarm(masterChefContract, farmWithBalance.pid);
        dispatch(updateHarvestCallback(account, farmWithBalance.pid, nameFarm));
        showToastSuccess(`Harvested!`, `Your ${earningToken.symbol} earnings have been sent to your wallet!`);
      } catch (error) {
        showToastError('Error', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      }
    }
    setPendingTx(false);
  }, [
    farmsWithStakedBalance,
    masterChefAddress,
    chainId,
    dispatch,
    updateHarvestCallback,
    account,
    nameFarm,
    earningToken.symbol,
    masterChefContract,
  ]);

  if (!account) return null;

  return (
    <Card className="mx-auto max-w-md p-3 mb-4">
      <div className="flex justify-between">
        <div className="text-white">
          {preText && <p>{preText}</p>}
          {userDataLoaded && earningsUsd && !earningsUsd.isNaN() ? (
            <Value
              decimals={earningsUsd.gt(0) ? 2 : 0}
              fontSize="24px"
              bold
              prefix={earningsUsd.gt(0) ? '~$' : '$'}
              lineHeight="1.1"
              value={earningsUsd.toNumber()}
            />
          ) : (
            <Dots>Loading</Dots>
          )}
          <p>{toCollectText}</p>
        </div>
        {numTotalToCollect <= 0 ? (
          <ButtonCustom disabled>Harvest all</ButtonCustom>
        ) : (
          <ButtonCustom
            className="font-bold"
            id="harvest-all"
            isLoading={pendingTx}
            disabled={pendingTx}
            onClick={harvestAllFarms}
          >
            {pendingTx ? 'Harvesting' : 'Harvest all'}
          </ButtonCustom>
        )}
      </div>
    </Card>
  );
};

export default HarvestCard;
