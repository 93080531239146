import { FiAlertCircle } from 'react-icons/fi';
import useCopyClipboard from 'hooks/useCopyClipboard';
import Tooltip from 'components/Tooltip/Tooltip';

export function SwapCallbackError({ error }) {
  const [isCopied, setCopied] = useCopyClipboard();

  return (
    <div className="flex items-center p-3 justify-center text-red-500">
      <div className="mr-1">
        <FiAlertCircle size={24} />
      </div>
      <div className="w-full break-words">
        {isCopied ? (
          <Tooltip tooltip="Copied!">
            <span className=" cursor-pointer">
              There was some error. Please try changing the parameters or{' '}
              <span className="underline">copy the error</span>
            </span>
          </Tooltip>
        ) : (
          <span className=" cursor-pointer" onClick={() => setCopied(error)}>
            There was some error. Please try changing the parameters or{' '}
            <span className="underline">copy the error</span>
          </span>
        )}
      </div>
    </div>
  );
}
