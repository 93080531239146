import { createSlice } from '@reduxjs/toolkit';
import {
  fetchBalanceHolders,
  fetchDragonUserBalance,
  fetchInvestmentBalances,
  fetchLandUserAllowances,
  fetchLandUserStakedBalances,
  fetchLandUserStakingTokenBalance,
  fetchLandUserTokenBalance,
  fetchBalanceIdo,
  fetchTotalCollectedIdo,
  fetchTotalInvestorsCount,
  fetchLandUserBuyTokenBalance,
  fetchLandUserAllowancesBuyToken,
  fetchLandV2UserTokenPaymentBalances,
  fetchLandV2UserTokenBalances,
} from 'store/lands/fetchLandUser';
import { getBalanceNumber } from 'utils/formatBalance';
import configLands, { configLandsV2 } from '../../constants/lands';
import { fetchInfoLands } from 'store/lands/fetchLands';

const initialState = {
  data: configLands,
  dataV2: configLandsV2,
  userDataLoaded: false,
  userDataLoadedV2: false,
};

export const landsSlice = createSlice({
  name: 'lands',
  initialState,
  reducers: {
    setLandsPublicData: (state, action) => {
      const liveLandsData = action.payload;
      state.data = liveLandsData;
    },
    setLandsUserData: (state, action) => {
      const userData = action.payload;
      state.data = (state.data || []).map((land) => {
        if (userData.idoId === land.idoId) {
          return { ...land, userData };
        }
        return land;
      });
      state.userDataLoaded = true;
    },
    updateLandUserData: (state, action) => {
      const { field, value, idoId } = action.payload;
      const index = state.data.findIndex((p) => p.idoId === idoId);

      if (index >= 0) {
        state.data[index] = {
          ...state.data[index],
          ...action.payload,
          userData: { ...state.data[index].userData, [field]: value },
        };
      }
    },
    setLandsV2PublicData: (state, action) => {
      state.dataV2 = state.dataV2.map((land) => {
        const liveData = action.payload.find((entry) => entry.idoId === land.idoId);
        return { ...land, ...liveData };
      });
    },
    setLandsV2UserData: (state, action) => {
      const { userData } = action.payload;
      state.dataV2 = state.dataV2.map((land) => {
        const userLandData = userData.find((entry) => entry.idoId === land.idoId);

        return { ...land, userData: userLandData };
      });
      state.userDataLoadedV2 = true;
    },
  },
});

// async action
export const fetchLandsPublicDataAsync = (lands) => async (dispatch) => {
  try {
    const distinctChainId = lands
      .map((land) => land.chainId)
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

    const _promise = distinctChainId.map(async (chainId) => {
      const promise = lands
        ?.filter((land) => land?.chainId === chainId)
        ?.map(async (land) => {
          if (land?.notComplete) return land;
          const promise = [
            fetchBalanceHolders(land, chainId),
            fetchBalanceIdo(land, chainId),
            fetchTotalCollectedIdo(land, chainId),
            fetchTotalInvestorsCount(land, chainId),
          ];

          const res = await Promise.all(promise);
          const balanceHolders = res?.[0];
          const idoBalances = res?.[1];
          const totalCollecteds = res?.[2]?.map(
            (item, i) => land?.options?.data?.[i]?.idoContract?.totalCollected || item,
          );
          const totalInvestors = res?.[3];
          let totalBalance = 0;
          let holders = 0;
          balanceHolders?.map((balanceHolder, i) => {
            const balance = getBalanceNumber(balanceHolder, land.stakingToken.decimals);
            holders +=
              land?.options?.data?.[i]?.poolContract?.stakingCount ||
              balance / land?.options?.data?.[i]?.poolContract?.stakingRequire;
            totalBalance += balance;
          });
          return {
            ...land,
            participants: {
              balanceHolders,
              totalBalance,
              holders,
              isLoaded: true,
            },
            idoBalances,
            totalCollecteds,
            totalInvestors,
          };
        });
      const dataLands = await Promise.all(promise);
      return dataLands;
    });
    const data = await Promise.all(_promise);
    const dataLandsMulChain = data.reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
    dispatch(setLandsPublicData(dataLandsMulChain || []));
  } catch (err) {}
};

export const fetchLandsV2PublicDataAsync = () => async (dispatch) => {
  const lands = await fetchInfoLands(configLandsV2);

  dispatch(setLandsV2PublicData(lands));
};

export const fetchLandDataAsync = (land, chainId) => async (dispatch) => {
  try {
    if (land?.notComplete) return;
    const promise = [
      fetchBalanceHolders(land, chainId),
      fetchBalanceIdo(land, chainId),
      fetchTotalCollectedIdo(land, chainId),
      fetchTotalInvestorsCount(land, chainId),
    ];

    const res = await Promise.all(promise);
    const balanceHolders = res?.[0];
    const idoBalances = res?.[1];
    const totalCollecteds = res?.[2]?.map((item, i) => land?.options?.data?.[i]?.idoContract?.totalCollected || item);
    const totalInvestors = res?.[3];
    let totalBalance = 0;
    let holders = 0;
    const options = land?.options?.data || [];
    balanceHolders?.map((balanceHolder, i) => {
      const balance = getBalanceNumber(balanceHolder, land.stakingToken.decimals);
      holders +=
        options?.[i]?.poolContract?.stakingCount || balance / land?.options?.data?.[i]?.poolContract?.stakingRequire;
      totalBalance += balance;
    });
    dispatch(
      updateLandUserData({
        ...land,
        participants: {
          balanceHolders,
          totalBalance,
          holders,
          isLoaded: true,
        },
        idoBalances,
        totalCollecteds,
        totalInvestors,
      }),
    );
  } catch (error) {}
};

export const fetchLandLinearDataAsync = (land, chainId) => async (dispatch) => {
  try {
    if (land?.notComplete) return;
    const promise = [
      fetchBalanceHolders(land, chainId),
      fetchBalanceIdo(land, chainId),
      fetchTotalCollectedIdo(land, chainId),
      fetchTotalInvestorsCount(land, chainId),
    ];

    const res = await Promise.all(promise);
    const balanceHolders = res?.[0];
    const idoBalances = res?.[1];
    const totalCollecteds = res?.[2]?.map((item, i) => land?.options?.data?.[i]?.idoContract?.totalCollected || item);
    const totalInvestors = res?.[3];
    let totalBalance = 0;
    let holders = 0;
    const options = land?.options?.data || [];
    balanceHolders?.map((balanceHolder, i) => {
      const balance = getBalanceNumber(balanceHolder, land.stakingToken.decimals);
      holders +=
        options?.[i]?.poolContract?.stakingCount || balance / land?.options?.data?.[i]?.poolContract?.stakingRequire;
      totalBalance += balance;
    });
    dispatch(
      updateLandUserData({
        ...land,
        participants: {
          balanceHolders,
          totalBalance,
          holders,
          isLoaded: true,
        },
        idoBalances,
        totalCollecteds,
        totalInvestors,
      }),
    );
  } catch (error) {}
};

export const fetchLandsV2UserDataAsync = (account) => async (dispatch) => {
  const promise = [
    fetchLandV2UserTokenPaymentBalances(account, configLandsV2),
    fetchLandV2UserTokenBalances(account, configLandsV2),
  ];

  const response = await Promise.all(promise);

  const paymentTokenBalances = response?.[0];
  const tokenBalances = response?.[1];

  const userData = configLandsV2.map((land, index) => ({
    idoId: land.idoId,
    paymentTokenBalance: paymentTokenBalances[index],
    tokenBalance: tokenBalances[index],
  }));

  dispatch(setLandsV2UserData({ userData }));
};

export const fetchLandUserDataAsync = (account, pool, chainId) => async (dispatch) => {
  if (!pool || pool?.notComplete) return;
  const promise = [
    // pool
    fetchLandUserAllowances(account, pool, chainId),
    fetchLandUserStakedBalances(account, pool, chainId),
    fetchLandUserStakingTokenBalance(account, pool, chainId),
    fetchLandUserTokenBalance(account, chainId),
    fetchDragonUserBalance(account, chainId),
    // ido
    fetchInvestmentBalances(account, pool, chainId),
  ];
  if (pool.useAltCoin) {
    promise[3] = fetchLandUserBuyTokenBalance(account, pool, chainId);
  }

  const response = await Promise.all(promise);
  dispatch(
    setLandsUserData({
      idoId: pool.idoId,
      allowances: response?.[0],
      stakedBalances: response?.[1],
      stakingTokenBalance: response?.[2],
      tokenBalance: response?.[3],
      dragonBalance: response?.[4],
      idoData: response?.[5],
      userDataLoaded: true,
    }),
  );
};

export const fetchLandLinearUserDataAsync = (account, pool, chainId) => async (dispatch) => {
  if (!pool || pool?.notComplete) return;
  const promise = [
    // pool
    fetchLandUserAllowances(account, pool, chainId),
    fetchLandUserStakedBalances(account, pool, chainId),
    fetchLandUserStakingTokenBalance(account, pool, chainId),
    fetchLandUserTokenBalance(account, chainId),
    fetchDragonUserBalance(account, chainId),
    // ido
    fetchInvestmentBalances(account, pool, chainId),
  ];
  if (pool.useAltCoin) {
    promise[3] = fetchLandUserBuyTokenBalance(account, pool, chainId);
    promise.push(fetchLandUserAllowancesBuyToken(account, pool, chainId));
  }

  const response = await Promise.all(promise);
  dispatch(
    setLandsUserData({
      idoId: pool.idoId,
      allowances: response?.[0],
      allowancesBuyToken: response?.[6],
      stakedBalances: response?.[1],
      stakingTokenBalance: response?.[2],
      tokenBalance: response?.[3],
      dragonBalance: response?.[4],
      idoData: response?.[5],
      userDataLoaded: true,
    }),
  );
};

export const updateUserAllowances = (account, pool) => async (dispatch) => {
  const allowance = await fetchLandUserAllowances(account, pool);
  dispatch(updateLandUserData({ idoId: pool.idoId, field: 'allowances', value: allowance }));
};

export const updateUserStakedBalance = (account, pool) => async (dispatch) => {
  const stakedBalances = await fetchLandUserStakedBalances(account, pool);
  dispatch(updateLandUserData({ idoId: pool.idoId, field: 'stakedBalances', value: stakedBalances }));
};

// Actions
export const { setLandsPublicData, setLandsUserData, updateLandUserData, setLandsV2PublicData, setLandsV2UserData } =
  landsSlice.actions;
export default landsSlice.reducer;
