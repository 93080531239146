import { ChainId } from 'ezcake-v2-sdk';
import { HashRouter, Routes, Route } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { ToastContainer } from 'react-toastify';
import Layout from 'components/Layout/Layout';

import { useFetchPublicData } from 'store/hooks';
import NotFound from 'views/NotFound';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import {
  ROUTE_BSC,
  ROUTE_BSC_TESTNET,
  ROUTE_HARMONY,
  ROUTE_KAI,
  ROUTE_ONUS,
  ROUTE_ONUS_TESTNET
} from './config/route';
import { usePollBlockNumber } from './store/block/hooks';
import {useChainId} from "wagmi";

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

// eslint-disable-next-line no-undef
const utc = require('dayjs/plugin/utc');
// eslint-disable-next-line no-undef
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(utc);
dayjs.extend(relativeTime);

const GlobalHooks = () => {
  useFetchPublicData();
  // useFetchVaultsPublicData();
  usePollBlockNumber();
  return null;
};

function App() {
  const chainId = useChainId()

  const RenderChainPage = useCallback(() => {
    switch (chainId) {
      case ChainId.BSC:
        return ROUTE_BSC;
      case ChainId.KAI:
        return ROUTE_KAI;
      case ChainId.HARMONY:
        return ROUTE_HARMONY;
      case ChainId.ONUS:
        return ROUTE_ONUS;
      case ChainId.ONUS_TESTNET:
        return ROUTE_ONUS_TESTNET;
        case ChainId.BSC_TESTNET:
        return ROUTE_BSC_TESTNET;
      default:
        return (
          <>
          </>
        );
    }
  }, [chainId]);

  return (
    <HashRouter>
      <GlobalHooks />
      <Layout>
        <Routes>
          {RenderChainPage()}
          <Route element={NotFound} />
        </Routes>
      </Layout>
      <ToastContainer newestOnTop />
    </HashRouter>
  );
}

export default App;
