import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import Logo from 'components/Logo/Logo';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { CASTLE_TAGS } from 'constants/index';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useSousHarvest } from 'hooks/useReward';
import { Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCastlesUserDataAsync, fetchCastlesUserDataAsyncV1 } from 'store/castles/index';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { formatListLogo } from 'utils/formatLogo';
import { getPoolName } from 'utils/tokenHelpers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {useCurrentBlock} from "../../../../../store/block/hooks";
import {getPoolBlockInfoClaim} from "../../../helpers";
import {useSousUnstake} from "../../../../../hooks/useUnstake";
import {useAccount, useChainId, useReadContract} from "wagmi";
import tokens from "../../../../../constants/tokens";
import {ERC20_ABI} from "../../../../../config/abi/erc20";
import {MCAKE_ADDRESS} from "../../../../../constants/contracts";

const Harvest = ({ pool, userDataLoaded }) => {
  const dispatch = useDispatch();
  const earningTokens = pool.earningToken ? [pool.earningToken] : pool.earningTokens;
  const isFees = !!pool?.fees?.withdrawalFee;
  const chainId = useChainId();
  const {address} = useAccount();
  const [pendingTx, setPendingTx] = useState(false);
  const logos = formatListLogo(earningTokens);
  const isIfo = pool.tags.includes(CASTLE_TAGS.ifo);
  const currentBlock = useCurrentBlock();
  const {
    shouldShowBlockCountdown,
  } = getPoolBlockInfoClaim(pool.claimBlock, pool.isFinished, currentBlock);

  const {data: mCakeBalance} = useReadContract({
    abi: ERC20_ABI,
    address: MCAKE_ADDRESS[chainId],
    functionName: 'balanceOf',
    args: [address],
    refetchInterval: 10000,
  })

  const formatmCakeBalance = getBalanceNumber(mCakeBalance, tokens.mCake.decimals);

  const earningTokensPrice = pool.earningTokenPrice ? [pool.earningTokenPrice] : pool.earningTokensPrice

  const { onReward } = useSousHarvest(pool.contractAddress, isFees);

  const { onUnstake } = useSousUnstake(pool.contractAddress);

  const tokensEarningLabel = getPoolName(earningTokens);

  const { earnings, stakedBalance } = pool.userData;

  const usdTokensEarning = earnings?.map?.((earning, index) => {
    return earning
      ? new BigNumber(getFullDisplayBalance(earning, earningTokens[index].decimals))
          .times(earningTokensPrice?.[index] || 0)
          .toNumber()
      : 0;
  });

  // const handleReward = useCallback(async () => {
  //   try {
  //     setPendingTx(true);
  //     await onReward();
  //     // dispatch(fetchCastlesUserDataAsync(account, chainId));
  //     dispatch(fetchCastlesUserDataAsyncV1(account, chainId))
  //     showToastSuccess('Harvested', `Your ${tokensEarningLabel} earnings have been sent to your wallet!`);
  //     setPendingTx(false);
  //   } catch (e) {
  //     setPendingTx(false);
  //     showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
  //     console.error(e);
  //   }
  // }, [account, chainId, dispatch, onReward, tokensEarningLabel]);

  const handleReward = useCallback(async () => {
    try {
      setPendingTx(true);
      pool.claimBlock ? await onUnstake(getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals), pool.stakingToken.decimals) : await onReward();
      // dispatch(fetchCastlesUserDataAsync(account, chainId));
      dispatch(fetchCastlesUserDataAsyncV1(address, chainId))
      showToastSuccess('Harvested', `Your ${tokensEarningLabel} earnings have been sent to your wallet!`);
      setPendingTx(false);
    } catch (e) {
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      console.error(e);
    }
  }, [onUnstake, stakedBalance, pool.stakingToken.decimals, dispatch, address, chainId, tokensEarningLabel]);

  const totalRewards = new BigNumber(stakedBalance).div(0.100181494773760107).times(100000000);
  const usdTotalRewards = new BigNumber(getFullDisplayBalance(totalRewards, earningTokens[0].decimals)).times(
    pool.earningTokensPrice?.[0] || 0,
  );

  return (
    <div>
      <div>
        <div className="text-center flex space-x-2">
          {isIfo ? (
            <>
              <div>
                <div className="flex justify-center">
                  <Logo size={40} src={logos.srcs[0]} alt={logos.alts[0]} />
                </div>
                <Value
                  className="text-primary font-bold text-xl"
                  decimals={0}
                  value={getBalanceNumber(address ? totalRewards : 0)}
                />
              </div>
              {pool.earningTokensPrice ? (
                <Value
                  className="text-white text-sm"
                  prefix="~"
                  value={address ? usdTotalRewards : 0}
                  decimals={3}
                  unit="USD"
                />
              ) : (
                <span className="text-primary">~???USD</span>
              )}
            </>
          ) : (
            earningTokens.map((earningToken, index) => (
              <div key={index}>
                <Tooltip
                  tooltip={
                    <div className="whitespace-nowrap">
                      <p>{earningToken.symbol} Price</p>
                      <p>{formatNumber(earningTokensPrice?.[index] ?? 0, 2, 4)}$</p>
                    </div>
                  }
                >
                  <div className="flex justify-center">
                    <Logo size={40} src={logos.srcs[index]} alt={logos.alts[index]} />
                  </div>
                </Tooltip>
                <Value
                  className="text-primary font-bold text-xl"
                  decimals={6}
                  value={getBalanceNumber(address ? earnings?.[index] : 0, earningToken.decimals)}
                />
                {earningTokensPrice ? (
                  <Value
                    className="text-white text-sm"
                    prefix="~"
                    value={address ? usdTokensEarning?.[index] : 0}
                    decimals={3}
                    unit="USD"
                  />
                ) : (
                  <span className="text-primary">~???USD</span>
                )}
              </div>
            ))
          )}
        </div>
        <div>
          <p className="text-white font-bold text-xl">Pending Rewards</p>
          {address ? (
            userDataLoaded ? (
              <ButtonCustom
                disabled={pendingTx || earnings?.[0]?.eq(new BigNumber(0)) || shouldShowBlockCountdown || formatmCakeBalance < 1}
                isLoading={pendingTx}
                onClick={handleReward}
              >
                {pendingTx ? `Collecting ${tokensEarningLabel}` : pool.claimBlock ? 'Harvest&Unstake' : 'Harvest'}
              </ButtonCustom>
            ) : (
              <ButtonCustom>
                <Dots>Loading</Dots>
              </ButtonCustom>
            )
          ) : (
            <ButtonCustom disabled>Harvest</ButtonCustom>
          )}
        </div>
        {
          formatmCakeBalance < 1 ? <p class="text-white text-sm">*You need to hold at least 1 {tokens.mCake.symbol}</p> : null
        }
      </div>
    </div>
  );
};

export default Harvest;
