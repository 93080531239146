import DoubleLogo from 'components/Logo/DoubleLogo';
import { formatLogo } from 'utils/formatLogo';
import { getTokenNameVault } from 'utils/tokenHelpers';
import PropTypes from 'prop-types';
import { classNames } from 'utils/styling';

const AssetsCell = ({ vault, className }) => {
  const nameDisplay = getTokenNameVault(vault?.token0, vault?.token1, vault?.stakingToken);
  const logo = formatLogo(vault.token0, vault.token1);

  return (
    <div className="flex items-center">
      <div className="mr-4">
        <DoubleLogo right="-right-6" src0={logo.src0} src1={logo.src1} alt0={logo.alt0} alt1={logo.alt1} size={40} />
      </div>
      <div className="ml-4 cursor-pointer">
        <div className={classNames('text-white', className)}>{nameDisplay}</div>
      </div>
    </div>
  );
};

AssetsCell.propTypes = {
  vault: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default AssetsCell;
