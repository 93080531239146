import { classNames } from 'utils/styling';
import { FiMail } from 'react-icons/fi';
import React from 'react';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
const InputSubscribe = React.forwardRef(({ className, classNameInput, ...rest }, ref) => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex">
        <div className={classNames(className, 'border border-black6 rounded-l-lg p-1 px-2 flex items-center')}>
          <FiMail size={18} className="mr-2 text-black6" />
          <input
            ref={ref}
            id="email"
            name="email"
            {...rest}
            className={classNames('bg-transparent placeholder-secondary-text max-w-32 text-sm', classNameInput)}
          />
        </div>
        <ButtonCustom className="rounded-l-none font-bold text-sm-md">Subscribe</ButtonCustom>
      </div>
    </form>
  );
});

export default InputSubscribe;
