import QuestionHelper from 'components/QuestionHelper/index';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import IconShowChart from 'views/Swap/components/Chart/IconShowChart';
import Settings from 'views/Swap/components/Settings/index';
import Transactions from 'views/Swap/components/Transactions/index';
import { classNames } from "utils/styling";

const AppHeader = ({ title, subtitle, helper, backTo, noConfig = false, isChart = false }) => {
  return (
    <div className="flex justify-between items-center text-white mb-4">
      {backTo && (
        <Link to={backTo} className="flex-1">
          <FiArrowLeft className="mr-2" />
        </Link>
      )}
      <div className={classNames("flex-1", backTo ? noConfig ? 'text-right' : 'text-center' :'text-left')}>
        <h2 className="text-2xl font-bold text-primary">{title}</h2>
          {
              subtitle && (
                  <p className="text-sm-md text-secondary-text font-semibold">{subtitle}</p>
              )
          }
      </div>
        {!noConfig && (
            <div className="flex justify-end gap-2 flex-1">
          <Settings />
          {isChart && <IconShowChart />}
          <Transactions />
          {helper && <QuestionHelper text={helper} size={24} />}
        </div>
      )}
    </div>
  );
};

export default AppHeader;
