import { createSlice } from '@reduxjs/toolkit';
import { ChainId } from 'ezcake-v2-sdk';

const initialState = {
  data: {
    [ChainId.KAI]: null,
    [ChainId.BSC]: null,
    [ChainId.BSC_TESTNET]: null,
    [ChainId.HARMONY]: null,
  },
};

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setPricesData: (state, action) => {
      const { prices, chainId } = action.payload;

      state.data[chainId] = {
        ...state.data?.[chainId],
        ...prices,
      };
    },
  },
});

// Actions
export const { setPricesData } = pricesSlice.actions;
export default pricesSlice.reducer;
