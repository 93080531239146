import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import ModalWarningHarvestFee from 'components/Modal/ModalWarningHarvestFee';
import Value from 'components/Value/Value';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useReward from 'hooks/useReward';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFarmUserDataAsync } from 'store/farms/index';
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import HarvestFeeCountdownRow from 'views/Farms/components/HarvestFeeCountdown';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const HarvestAction = ({
  earnings,
  earningTokenPrice,
  pid,
  masterChefAddress,
  userDataLoaded,
  earningTokenSymbol,
  isFinished,
  unlockTimestamp,
  stakedBalance,
}) => {
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();
  const [pendingTx, setPendingTx] = useState(false);
  const { onReward } = useReward(pid, masterChefAddress);
  const [open, setOpen] = useState(false);

  const toggleWarning = () => setOpen(!open);

  const usdTokenEarning = earnings
    ? new BigNumber(getFullDisplayBalance(earnings)).times(earningTokenPrice || 0).toNumber()
    : 0;

  const handleReward = useCallback(async () => {
    try {
      if (open) setOpen(false);
      setPendingTx(true);
      await onReward();
      showToastSuccess('Harvested', `Your ${earningTokenSymbol} earnings have been sent to your wallet!`);
      dispatch(fetchFarmUserDataAsync(account, chainId));
      setPendingTx(false);
    } catch (e) {
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      console.error(e);
    }
  }, [account, chainId, dispatch, earningTokenSymbol, onReward, open]);

  return (
    <>
      <ModalWarningHarvestFee
        open={open}
        toggleModal={toggleWarning}
        onConfirm={handleReward}
        content="Harvest before 3 months lock will cost you 50% rewards and send to Stake and Lock Pool. Are you sure to continue?"
      />
      <div className="flex justify-between items-center">
        <div>
          <Value className="text-primary font-bold text-xl" value={account ? getBalanceNumber(earnings) : 0} />
          <Value
            prefix="~"
            className="text-primary text-sm"
            value={account ? usdTokenEarning : 0}
            decimals={2}
            unit="USD"
          />
        </div>
        {!isFinished &&
          (account ? (
            userDataLoaded ? (
              <ButtonCustom
                disabled={pendingTx || earnings.eq(new BigNumber(0))}
                isLoading={pendingTx}
                onClick={() => {
                  const hasHarvestFee = unlockTimestamp?.toNumber() * 1000 > Date.now();
                  if (hasHarvestFee && chainId === ChainId.HARMONY) {
                    toggleWarning();
                  } else {
                    handleReward();
                  }
                }}
              >
                {pendingTx ? `Collecting ${earningTokenSymbol}` : 'Harvest'}
              </ButtonCustom>
            ) : (
              <ButtonCustom>
                <Dots>Loading</Dots>
              </ButtonCustom>
            )
          ) : (
            <ButtonCustom disabled>Harvest</ButtonCustom>
          ))}
      </div>
      {chainId === ChainId.HARMONY && stakedBalance.gt(0) && (
        <HarvestFeeCountdownRow unlockTimestamp={unlockTimestamp} />
      )}
    </>
  );
};

export default HarvestAction;
