import { CASTLE_TAGS } from 'constants/index';
import tokens from 'constants/tokens';
import { ChainId } from 'ezcake-v2-sdk';

const castlesConfig = {
  [ChainId.KAI]: [],
  [ChainId.BSC]: [
    {
      sousId: 0,
      stakingToken: tokens.vndcV2,
      token0: tokens.vndcV2,
      earningToken: tokens.mCake,
      startBlock: '42959554',
      endBlock: '43823554',
      tokenPerBlock: '578703700000000',
      contractAddress: '0x3E5e8cA400ED4DafF2024c05AF6A254c5CD38c81',
      harvest: true,
      isFinished: false,
      tags: [CASTLE_TAGS.exclusive],
      isPartner: false,
    },
    {
      sousId: 1,
      stakingToken: tokens.wbnb,
      token0: tokens.wbnb,
      earningToken: tokens.mCake,
      startBlock: '42959554',
      endBlock: '43823554',
      tokenPerBlock: '578703700000000',
      contractAddress: '0x860FAB119cdB3c1D6E2B226a72e7B7D3432Bf25f',
      harvest: true,
      isFinished: false,
      tags: [CASTLE_TAGS.exclusive],
      isPartner: false,
    },
    {
      sousId: 2,
      stakingToken: tokens.cake,
      token0: tokens.cake,
      earningToken: tokens.mCake,
      startBlock: '42959554',
      endBlock: '43823554',
      tokenPerBlock: '578703700000000',
      contractAddress: '0x6f25B2dE1406d15C424D5C0E524F752E277a41Cf',
      harvest: true,
      isFinished: false,
      tags: [CASTLE_TAGS.exclusive],
      isPartner: false,
    },
  ],
  [ChainId.HARMONY]: [],
  [ChainId.HARMONY_TESTNET]: [],
  [ChainId.ONUS]: [],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [
    {
      sousId: 0,
      stakingToken: tokens.usdtBscTest,
      token0: tokens.usdtBscTest,
      earningToken: tokens.mCake,
      startBlock: '44295026',
      endBlock: '42904047',
      tokenPerBlock: '100000000000000000',
      contractAddress: '0x640ac9AAC31d89b81ce7475AaB5948B4BC86baF1',
      harvest: true,
      isFinished: false,
      tags: [CASTLE_TAGS.exclusive],
      isPartner: false,
    },
  ],
};
export const castlesV2Config = {
  [ChainId.KAI]: [],
  // [ChainId.BSC]: [
  //   {
  //     sousId: 0,
  //     stakingToken: tokens.dragon,
  //     token0: tokens.dragon,
  //     earningTokens: [tokens.hero],
  //     startBlock: 10708020,
  //     endBlock: 11572020,
  //     stakingBlock: 10679220,
  //     stakingEndBlock: 11572020,
  //     unStakingBlock: 10679220,
  //     tokensPerBlock: ['248015046290000000'],
  //     contractAddress: '0xfed7d3243fd42fedc0aBaD84C48F3dD29b5A658d',
  //     isFinished: true,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //     blockPeriod: 86400,
  //     fees: {
  //       withdrawalFee: 1,
  //     },
  //   },
  //   {
  //     sousId: 1,
  //     stakingToken: {
  //       ...tokens.cakeLp,
  //       address: {
  //         [ChainId.BSC]: '0x4e65c29271D581E7B31db6F67400c1443db4a2f0',
  //       },
  //     },
  //     token0: tokens.lpdi,
  //     token1: tokens.wbnb,
  //     earningTokens: [tokens.wbnb],
  //     startBlock: 11109590,
  //     endBlock: 11541590,
  //     stakingBlock: 11080973,
  //     stakingEndBlock: 11541590,
  //     unStakingBlock: 11080973,
  //     tokensPerBlock: ['34722222222222'],
  //     contractAddress: '0xe8280e6d38FaEAfDBc85a31F0b8FFF6A90E90155',
  //     isFinished: true,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //   },
  //   {
  //     sousId: 2,
  //     stakingToken: tokens.dfl1,
  //     token0: tokens.dfl1,
  //     earningTokens: [tokens.rasta],
  //     startBlock: 11335967,
  //     endBlock: 11767967,
  //     stakingBlock: 11306441,
  //     stakingEndBlock: 11767967,
  //     unStakingBlock: 11306441,
  //     tokensPerBlock: ['91666666666666666'],
  //     contractAddress: '0xF3826d46B090e464Fa24695525E1B0D0aCa8eE9a',
  //     isFinished: true,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //   },
  //   {
  //     sousId: 3,
  //     stakingToken: {
  //       ...tokens.cakeLp,
  //       address: {
  //         [ChainId.BSC]: '0x4e65c29271D581E7B31db6F67400c1443db4a2f0',
  //       },
  //     },
  //     token0: tokens.lpdi,
  //     token1: tokens.wbnb,
  //     earningTokens: [tokens.mCake],
  //     startBlock: 11595930,
  //     endBlock: 12459930,
  //     stakingBlock: 11595890,
  //     stakingEndBlock: 12459930,
  //     unStakingBlock: 11595890,
  //     tokensPerBlock: ['675347222222222222'],
  //     contractAddress: '0x1b59f9d41810970AEDa96B611464527F7a577C65',
  //     isFinished: true,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //   },
  //   {
  //     sousId: 4,
  //     stakingToken: tokens.mCake,
  //     token0: tokens.mCake,
  //     earningTokens: [tokens.lpdi],
  //     startBlock: 11595930,
  //     endBlock: 12459930,
  //     stakingBlock: 11595890,
  //     stakingEndBlock: 12459930,
  //     unStakingBlock: 11595890,
  //     tokensPerBlock: ['71180555555555555'],
  //     contractAddress: '0x216A20500c74C90D0Be0f0c8D89880b47Dd1E49C',
  //     isFinished: true,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //   },
  //   {
  //     sousId: 5,
  //     stakingToken: tokens.dragon,
  //     token0: tokens.dragon,
  //     earningTokens: [tokens.cake],
  //     startBlock: 11595930,
  //     endBlock: 12459930,
  //     stakingBlock: 11595890,
  //     stakingEndBlock: 12459930,
  //     unStakingBlock: 11595890,
  //     tokensPerBlock: ['180555555555555'],
  //     contractAddress: '0x44CB7da6619f276aC96ed1B5E2778678d72666Ea',
  //     isFinished: true,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //     blockPeriod: 201600,
  //     fees: {
  //       withdrawalFee: 1,
  //     },
  //   },
  //   {
  //     sousId: 6,
  //     stakingToken: tokens.lpdi3,
  //     token0: tokens.lpdi3,
  //     earningTokens: [tokens.cake, tokens.mCake],
  //     startBlock: 11936511,
  //     endBlock: 14528511,
  //     stakingBlock: 11912725,
  //     stakingEndBlock: 14528511,
  //     unStakingBlock: 11912725,
  //     tokensPerBlock: ['29023610000000', '106098379630000000'],
  //     contractAddress: '0x4524F666c5c2097df928b86A0361255f5448F0b3',
  //     isFinished: true,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //   },
  // ],
  [ChainId.BSC]: [],
  [ChainId.HARMONY]: [],
  [ChainId.HARMONY_TESTNET]: [],
  // [ChainId.ONUS]: [
  //   {
  //     sousId: 0,
  //     stakingToken: tokens.mCake,
  //     token0: tokens.mCake,
  //     earningTokens: [tokens.wonus],
  //     startBlock: 2501282,
  //     endBlock: 5093282,
  //     stakingBlock: 2480000,
  //     stakingEndBlock: 5093282,
  //     unStakingBlock: 5093282,
  //     tokensPerBlock: ['385802460000000'],
  //     contractAddress: '0x56E6bD5C89A5cFd9d43355ed3Bca5f20aEe469DB',
  //     isFinished: false,
  //     tags: [CASTLE_TAGS.exclusive],
  //     isV2: true,
  //     sortId: 1,
  //     totalReward: [1000],
  //   },
  // ],
  [ChainId.ONUS]: [],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [],
  [ChainId.BSC]: [],
};
export default castlesConfig;
