import {useAppKit} from "@reown/appkit/react";
import ButtonCustom from "../ButtonCustom/ButtonCustom";

export default function Web3Connect() {
  const { open } = useAppKit()

  function handleOpen(){
    try{
      open();
    } catch(_e){
      open();
    }
  }

  return <ButtonCustom size="sm" className="font-bold text-sm-md" onClick={handleOpen}>Connect</ButtonCustom>

  // return error ? (
  //   <div
  //     className={classNames(
  //       'flex items-center justify-center px-1 h-[36px] font-bold text-sm-md text-white border rounded-lg bg-opacity-80 border-red-500 bg-red-500 hover:bg-opacity-100',
  //       error instanceof UnsupportedChainIdError && 'cursor-pointer',
  //     )}
  //     onClick={onToggleConnectModal}
  //   >
  //     {error instanceof UnsupportedChainIdError ? 'Wrong network' : 'Error'}
  //   </div>
  // ) : (
  //   <ButtonCustom size="sm" className="font-bold text-sm-md" onClick={onToggleConnectModal}>Connect</ButtonCustom>
  // );
}
