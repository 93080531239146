import InfoNav from './components/InfoNav/InfoNav';
import { MatchBreakpointsProvider } from 'contexts/MatchBreakpoints/Provider';
import 'styles/info.scss';
import {useEffect, useState} from "react";
import {useChainId} from "wagmi";

export const AnalyticsPageLayout = ({ children }) => {
    const chainId = useChainId()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (chainId) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 1);
        }
    }, [chainId]);

    if (isLoading) {
        return (
            <div className="container mx-auto info text-white px-6">
                <InfoNav />
            </div>
        );
    }

  return (
    <MatchBreakpointsProvider>
      <div className="container mx-auto info text-white px-6">
        <InfoNav />
        {children}
      </div>
    </MatchBreakpointsProvider>
  );
};
