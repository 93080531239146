import Loader from 'components/Loader/Loader';
import PropTypes from 'prop-types';
import ProjectCardV2 from 'views/Lands/components/ProjectCardV2';

const ListProjectsSpecial = ({ title, data, userDataLoadedV2 }) => {
  return (
    <div>
      <h2 className="text-center text-primary text-5xl mt-4 mb-8 font-bold ">{title}</h2>
      {Array.isArray(data) ? (
        <>
          {data.length > 0 ? (
            <div className=" max-w-3xl mx-auto">
              {data.map((item, index) => (
                <ProjectCardV2 key={index} pool={item} index={index} userDataLoaded={userDataLoadedV2} />
              ))}
            </div>
          ) : (
            <p className="text-white text-xl text-center my-5">No data</p>
          )}
        </>
      ) : (
        <Loader className="border-t-4 h-20 w-20 mx-auto mt-12 mb-8" />
      )}
    </div>
  );
};

ListProjectsSpecial.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ListProjectsSpecial;
