import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useDispatch, useSelector } from 'react-redux';
import useRefresh from 'hooks/useRefresh';
import { useEffect } from 'react';

import {
  fetchVaultsTotalStakedDataAsync,
  fetchVaultsV2PublicDataAsync,
  fetchVaultUserDataAsync,
  fetchVaultV2UserDataAsync,
} from './index';
import { transformVault, transformVaultV2 } from './helpers';

const fetchDataWithChain = {
  [ChainId.KAI]: async (dispatch, chainId) => {
    dispatch(fetchVaultsTotalStakedDataAsync(chainId));
    dispatch(fetchVaultsV2PublicDataAsync(chainId));
  },
  [ChainId.BSC]: async (dispatch, chainId) => {
    dispatch(fetchVaultsTotalStakedDataAsync(chainId));
  },
  [ChainId.HARMONY]: async () => {},
  [ChainId.ONUS]: async (dispatch, chainId) => {
    dispatch(fetchVaultsTotalStakedDataAsync(chainId));
  },
  [ChainId.ONUS_TESTNET]: async () => {},
  [ChainId.BSC_TESTNET]: async () => {},
};

export const useFetchVaultsPublicData = () => {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    if (chainId) {
      fetchDataWithChain[chainId](dispatch, chainId);
    }
  }, [dispatch, slowRefresh, account, chainId]);
};

export const useVaults = () => {
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();

  useEffect(() => {
    if (chainId && account) {
      dispatch(fetchVaultUserDataAsync(account, chainId));
      if (chainId === ChainId.KAI) {
        dispatch(fetchVaultV2UserDataAsync(account, chainId));
      }
    }
  }, [account, chainId, dispatch, fastRefresh]);

  const { vaults, userDataLoaded, vaultsV2, userDataLoadedV2 } = useSelector((state) => ({
    vaults: state.vaults.data[chainId],
    vaultsV2: state.vaults.dataV2[chainId],
    userDataLoaded: state.vaults.userDataLoaded,
    userDataLoadedV2: state.vaults.userDataLoadedV2,
  }));

  return {
    vaults: vaults.map(transformVault),
    userDataLoaded,
    vaultsV2: vaultsV2.map(transformVaultV2),
    userDataLoadedV2,
  };
};

export const useVaultsNoAccount = () => {
  const { chainId } = useActiveWeb3React();
  const { vaults, vaultsV2 } = useSelector((state) => ({
    vaults: state.vaults.data[chainId],
    vaultsV2: state.vaults.dataV2[chainId],
  }));

  return { vaults: vaults.map(transformVault), vaultsV2: vaultsV2.map(transformVaultV2) };
};
