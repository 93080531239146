import { useState, useMemo, useCallback, Fragment } from 'react';
import { formatAmount } from 'utils/formatInfoNumbers';
import Percent from 'views/Analytics/components/Percent/Percent';
import useMatchBreakpoints from 'contexts/MatchBreakpoints/useMatchBreakpoints';
import { Link } from 'react-router-dom';
import Skeleton from 'components/Skeleton/Skeleton';
import { CurrencyLogo } from 'views/Analytics/components/CurrencyLogo/CurrencyLogo';
import Pagination from 'views/Analytics/components/Pagination/Pagination';
import { ITEMS_PER_INFO_TABLE_PAGE } from 'constants/analytics';
import { useSWRTokens } from 'hooks/swr/analytics/tokens';
import { useWatchlistTokens } from 'store/user/hooks';
import { isAddress } from 'utils';

const TableLoader = () => {
  const loadingRow = (
    <div className="responsiveGridToken">
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
    </div>
  );

  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  );
};

const DataRow = ({ tokenData, index }) => {
  const { isXs, isSm } = useMatchBreakpoints();
  return (
    <Link to={`/analytics/tokens/${tokenData.address}`}>
      <div className="responsiveGridToken my-4 hover:opacity-80">
        <div>
          <p>{index + 1}</p>
        </div>
        <div className="flex items-center">
          <CurrencyLogo currency={tokenData} />
          {(isXs || isSm) && <p className="ml-3">{tokenData.symbol}</p>}
          {!isXs && !isSm && (
            <div className="flex items-center ml-3">
              <p>{tokenData.name}</p>
              <p className="ml-1">({tokenData.symbol})</p>
            </div>
          )}
        </div>
        <p>${formatAmount(tokenData.priceUSD, { notation: 'standard' })}</p>
        <div>
          <Percent value={tokenData.priceUSDChange} fontWeight={400} />
        </div>
        <p>
          $
          {formatAmount(tokenData.volumeUSD, {
            displayThreshold: 0.001,
          })}
        </p>
        <p>
          $
          {formatAmount(tokenData.liquidityUSD, {
            displayThreshold: 0.001,
          })}
        </p>
      </div>
    </Link>
  );
};

const SORT_FIELD = {
  name: 'name',
  volumeUSD: 'volume24h',
  liquidityUSD: 'liquidity',
  priceUSD: 'derivedUSD',
  priceUSDChange: 'priceChange',
  priceUSDChangeWeek: 'priceUSDChangeWeek',
};

const TokenTable = ({ isShowWatchList }) => {
  const [sortField, setSortField] = useState(SORT_FIELD.liquidityUSD);
  const [sortDirection, setSortDirection] = useState(true);
  const [savedTokens] = useWatchlistTokens();

  // pagination
  const [page, setPage] = useState(1);

  const { maxPage, tokenDatas } = useSWRTokens({
    page,
    sort: sortField,
    sortDir: sortDirection ? 'desc' : 'asc',
    ...(isShowWatchList && { listToken: savedTokens.map(isAddress).join(',') }),
  });

  const sortedTokens = useMemo(() => {
    return tokenDatas ? tokenDatas : [];
  }, [tokenDatas]);

  const handleSort = useCallback(
    (newField) => {
      setSortField(newField);
      setSortDirection(sortField !== newField ? true : !sortDirection);
    },
    [sortDirection, sortField],
  );

  const arrow = useCallback(
    (field) => {
      const directionArrow = !sortDirection ? '↑' : '↓';
      return sortField === field ? directionArrow : '';
    },
    [sortDirection, sortField],
  );

  if (!tokenDatas) {
    return <div />;
  }

  return (
    <div className="tableWrapper shadow-lg">
      <div className="responsiveGridToken mb-5 mt-3">
        <p className="font-bold text-primary text-sm">#</p>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.name)}
        >
          Name {arrow(SORT_FIELD.name)}
        </div>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.priceUSD)}
        >
          Price {arrow(SORT_FIELD.priceUSD)}
        </div>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.priceUSDChange)}
        >
          Price Change {arrow(SORT_FIELD.priceUSDChange)}
        </div>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.volumeUSD)}
        >
          Volume 24H {arrow(SORT_FIELD.volumeUSD)}
        </div>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.liquidityUSD)}
        >
          Liquidity {arrow(SORT_FIELD.liquidityUSD)}
        </div>
      </div>

      <div className="bg-black1 h-0.5 w-full my-2" />
      {sortedTokens.length > 0 ? (
        <>
          {sortedTokens.map((data, i) => {
            if (data) {
              return (
                <Fragment key={data.address}>
                  <DataRow index={(page - 1) * ITEMS_PER_INFO_TABLE_PAGE + i} tokenData={data} />
                  <div className="bg-black1 h-0.5 w-full my-2" />
                </Fragment>
              );
            }
            return null;
          })}
          <Pagination page={page} maxPage={maxPage} setPage={setPage} />
        </>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </div>
  );
};

export default TokenTable;
