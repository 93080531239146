import { classNames } from 'utils/styling';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { useAppKit } from '@reown/appkit/react'

const UnlockButton = ({ className, fullWidth = true }) => {
    const { open } = useAppKit()

    function handleOpen(){
        try{
            open();
        } catch(_e){
            open();
        }
    }

    return <ButtonCustom className={classNames(className, fullWidth && 'w-full')} onClick={handleOpen}>
        Connect wallet
    </ButtonCustom>
};

export default UnlockButton;
