import { classNames } from 'utils/styling';
import CurrencyLogo from './CurrencyLogo';

export default function DoubleCurrencyLogo({ currency0, currency1, size = '32px' }) {
  return (
    <div className={classNames('flex items-center')}>
      {currency0 && <CurrencyLogo size={size} className="z-20 relative" currency={currency0} />}
      {currency1 && <CurrencyLogo size={size} className="-ml-2 z-10 relative" currency={currency1} />}
    </div>
  );
}
