/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import { formatAmount } from 'utils/formatInfoNumbers';
import Percent from 'views/Analytics/components/Percent/Percent';
import SaveIcon from 'views/Analytics/components/SaveIcon/SaveIcon';
import PoolTable from 'views/Analytics/components/InfoTables/PoolsTable';
import TransactionTable from 'views/Analytics/components/InfoTables/TransactionsTable';
import { useWatchlistTokens } from 'store/user/hooks';
import ChartCard from 'views/Analytics/components/InfoCharts/ChartCard/ChartCard';
import { Link, useParams } from 'react-router-dom';
import useMatchBreakpoints from 'contexts/MatchBreakpoints/useMatchBreakpoints';
import Card from 'components/Card/Card';
import { shortenAddress } from 'utils';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { getExplorerLink } from 'utils/explorer';
import Loader from 'components/Loader/Loader';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { CurrencyLogo } from 'views/Analytics/components/CurrencyLogo/CurrencyLogo';
import { useSWRTokenChartData, useSWRTokenData, useSWRTokenPriceChartData } from 'hooks/swr/analytics/tokens';
import { FiExternalLink } from 'react-icons/fi';
import { classNames } from 'utils/styling';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {useChainId} from "wagmi";

const TokenPage = () => {
  const chainId = useChainId()
  const { address: routeAddress } = useParams();

  const { isXs, isSm } = useMatchBreakpoints();

  // In case somebody pastes checksummed address into url (since GraphQL expects lowercase address)
  const address = routeAddress;

  const { tokenData } = useSWRTokenData(address);
  const { chartData } = useSWRTokenChartData(address);
  const { priceData } = useSWRTokenPriceChartData(address);

  const adjustedPriceData = useMemo(() => {
    // Include latest available price
    if (priceData && tokenData && priceData.length > 0) {
      return [
        ...priceData,
        {
          time: new Date().getTime() / 1000,
          open: priceData[priceData.length - 1].close,
          close: tokenData?.priceUSD,
          high: tokenData?.priceUSD,
          low: priceData[priceData.length - 1].close,
        },
      ];
    }
    return undefined;
  }, [priceData, tokenData]);

  const [watchlistTokens, addWatchlistToken] = useWatchlistTokens();

  return (
    <div>
      {tokenData ? (
        !tokenData.exists ? (
          <Card>
            <div className="p-5">
              <div>
                No pool has been created with this token yet. Create one
                <Link className="underline" style={{ display: 'inline', marginLeft: '6px' }} to={`/add/${address}`}>
                  here.
                </Link>
              </div>
            </div>
          </Card>
        ) : (
          <>
            {/* Stuff on top */}
            <div className="flex justify-between mb-5">
              <Breadcrumbs>
                <Link to="/analytics">
                  <span color="primary">Analytics</span>
                </Link>
                <Link to="/analytics/tokens">
                  <span color="primary">Tokens</span>
                </Link>
                <div className="flex items-center">
                  <span className="mr-1">{tokenData.symbol}</span>
                  <span>{`(${shortenAddress(address)})`}</span>
                </div>
              </Breadcrumbs>
              <div className="flex justify-end items-center">
                <ExternalLink
                  className="mr-1"
                  color="primary"
                  href={getExplorerLink(chainId, address, 'address')}
                  endIcon={<FiExternalLink size={20} className="ml-1" />}
                >
                  View on Explorer
                </ExternalLink>
                <SaveIcon
                  className="cursor-pointer"
                  fill={watchlistTokens.includes(address)}
                  onClick={() => addWatchlistToken(address)}
                />
              </div>
            </div>
            <div className="flex justify-between flex-wrap">
              <div className="flex ">
                <CurrencyLogo size="42px" currency={tokenData} />
                <div className="ml-3">
                  <div className="flex items-center leading-7">
                    <p
                      className={classNames(' font-bold', isXs || isSm ? 'text-2xl' : 'text-4xl')}
                      id="info-token-name-title"
                    >
                      {tokenData.name}
                    </p>
                    <p className={classNames('ml-2 text-gray-500', isXs || isSm ? 'text-sm-md' : 'text-xl')}>
                      ({tokenData.symbol})
                    </p>
                  </div>
                  <div className="flex items-start mt-2">
                    <p className=" font-bold text-2xl mr-2">
                      ${formatAmount(tokenData.priceUSD, { notation: 'standard' })}
                    </p>
                    <Percent value={tokenData.priceUSDChange} fontWeight={600} />
                  </div>
                </div>
              </div>
              <div className="flex">
                <Link to={`/add/${address}`}>
                  <ButtonCustom className="mr-2 " color="secondary">
                    Add Liquidity
                  </ButtonCustom>
                </Link>
                <Link to={`/swap?inputCurrency=${address}`}>
                  <ButtonCustom>Trade</ButtonCustom>
                </Link>
              </div>
            </div>

            {/* data on the right side of chart */}
            <div className="contentLayout">
              <Card>
                <div className="p-5">
                  <p className="uppercase font-bold text-primary text-sm-md">Liquidity</p>
                  <p className="font-bold text-2xl">
                    $
                    {formatAmount(tokenData.liquidityUSD, {
                      displayThreshold: 0.001,
                    })}
                  </p>
                  <Percent value={tokenData.liquidityUSDChange} />

                  <p className="mt-5 uppercase font-bold text-primary text-sm-md">Volume 24H</p>
                  <p className="font-bold text-2xl">
                    $
                    {formatAmount(tokenData.volumeUSD, {
                      displayThreshold: 0.001,
                    })}
                  </p>
                  <Percent value={tokenData.volumeUSDChange} />

                  <p className="mt-5 uppercase font-bold text-primary text-sm-md">Volume 7D</p>
                  <p className="font-bold text-2xl">
                    $
                    {formatAmount(tokenData.volumeUSDWeek, {
                      displayThreshold: 0.001,
                    })}
                  </p>

                  <p className="mt-5 uppercase font-bold text-primary text-sm-md">Transactions 24H</p>
                  <p className="font-bold text-2xl">{formatAmount(tokenData.txCount, { isInteger: true })}</p>
                </div>
              </Card>
              {/* charts card */}
              <ChartCard
                variant="token"
                chartData={chartData}
                tokenData={tokenData}
                tokenPriceData={adjustedPriceData}
              />
            </div>

            {/* pools and transaction tables */}
            <h2 className="mt-10 mb-5 font-bold text-2xl">Pools</h2>

            <PoolTable address={address} />

            <h2 className="mt-10 mb-5 font-bold text-2xl">Transactions</h2>

            <TransactionTable tokenAddress={address} />
          </>
        )
      ) : (
        <div className="flex justify-center">
          <Loader className="border-t-4 h-20 w-20 mx-auto" />
        </div>
      )}
    </div>
  );
};

export default TokenPage;
