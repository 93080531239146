import BigNumber from 'bignumber.js';
import ActionTool from 'components/ActionTool/ActionTool';
import Back from 'components/Back/Back';
import TabsFinished from 'components/Tabs/TabsFinished';
import { FARMS_VIEW_DEFAULT, sortListFarms, sortTypeFarms } from 'constants/farms';
import { CASTLE_TAGS, TABS, TYPE_VIEW } from 'constants/index';
import useDebounce from 'hooks/useDebounce';
import { useMemo, useState } from 'react';
import { useCastles } from 'store/castles/hook';
import { useUserStakedOnly, useUserViewMode } from 'store/user/hooks/index';
import ContainerPage from 'components/Container/ContainerV2';
import CastlesCard from 'views/Castles/components/CastlesCard/CastlesCard';
import CastlesTable from 'views/Castles/components/CastlesTable/CastlesTable';
import HarvestCard from 'views/Castles/components/CastlesCard/HarvestCard';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const Castles = () => {
  const { pools, userDataLoaded } = useCastles();
  const [tab, setTab] = useState(TABS.live);
  const [sortBy, setSortBy] = useState(sortTypeFarms.hot);
  const [search, setSearch] = useState('');
  const debouncedQuery = useDebounce(search, 300);

  const [stakedOnly, setStakedOnly] = useUserStakedOnly();
  const isStakedOnly = stakedOnly?.pools;

  const [viewMode, setViewMode] = useUserViewMode();
  const typeView = viewMode?.pools || FARMS_VIEW_DEFAULT;

  const handleChangeTab = (value) => {
    if (value !== tab) {
      setTab(value);
    }
  };

  const poolsActive = useMemo(() => {
    if (tab === TABS.live) {
      return pools
        .filter((pool) => !pool.isHide)
        .filter((pool) => !pool.isFinished)
        .sort((poolA) => {
          if (poolA.sortId) {
            return -1;
          }
          return 1;
        });
    }

    return pools
      .filter((pool) => !pool.isHide)
      .filter((pool) => pool.isFinished)
      .sort((pool) => (pool.isV2 ? -1 : 0));
  }, [pools, tab]);

  const poolsWithStakedBalance = useMemo(() => {
    return pools
      .filter((pool) => !pool.isHide)
      .filter((pool) => !pool.isFinished)
      .filter((pool) => !pool.tags.includes(CASTLE_TAGS.ifo))
      .filter((farm) => new BigNumber(farm.userData.stakedBalance).isGreaterThan(0));
  }, [pools]);

  const poolsDisplay = useMemo(() => {
    if (isStakedOnly) return poolsActive.filter((pool) => new BigNumber(pool.userData.stakedBalance).isGreaterThan(0));

    return poolsActive;
  }, [poolsActive, isStakedOnly]);

  const filterDataBySearch = useMemo(() => {
    return poolsDisplay.filter((pool) => {
      const nameDisplay = pool.stakingToken.symbol;
      return nameDisplay.toLowerCase().includes(debouncedQuery.toLowerCase());
    });
  }, [debouncedQuery, poolsDisplay]);

  const poolsSort = useMemo(() => {
    const farms = [...filterDataBySearch];

    if (sortBy === sortTypeFarms.hot) return farms;
    return farms.sort((poolA, poolB) => {
      if (sortBy === sortTypeFarms.apr) {
        return poolB?.apr - poolA?.apr;
      }
      if (sortBy === sortTypeFarms.tvl) {
        return poolB.stakedTvl - poolA.stakedTvl;
      }
    });
  }, [filterDataBySearch, sortBy]);

  return (
    <>
      <Back />
      <div>
        <ContainerPage>
          <div className="container mx-auto max-w-6xl my-10">
            <ActionTool
              isStakedOnly={isStakedOnly}
              setIsStakedOnly={() => setStakedOnly('pools')}
              sortList={sortListFarms}
              sortBy={sortBy}
              setSearch={setSearch}
              setSortBy={setSortBy}
              typeView={typeView}
              setTypeView={(view) => setViewMode('pools', view)}
            />
            {/*<HarvestCard userDataLoaded={userDataLoaded} poolsWithStakedBalance={poolsWithStakedBalance} />*/}
            <div className="relative z-20">
              <TabsFinished tab={tab} onChangeTab={handleChangeTab} />
              <div className="mt-4">
                {tab === TABS.finished && (
                  <p className="mb-2 text-rose-700 text-xl">
                    These pools are no longer distributing rewards. Please unstake your tokens.
                  </p>
                )}
                {typeView === TYPE_VIEW.grid ? (
                  <CastlesCard pools={poolsSort} userDataLoaded={userDataLoaded} />
                ) : (
                  <CastlesTable pools={poolsSort} userDataLoaded={userDataLoaded} />
                )}
              </div>
            </div>
            {/*<ButtonCustom*/}
            {/*  className="mx-auto relative z-20 my-12"*/}
            {/*  onClick={() =>*/}
            {/*    window.open(*/}
            {/*      '',*/}
            {/*      '_blank',*/}
            {/*    )*/}
            {/*  }*/}
            {/*>*/}
            {/*  Apply for listing*/}
            {/*</ButtonCustom>*/}
          </div>
        </ContainerPage>
      </div>
    </>
  );
};

export default Castles;
