import { classNames } from 'utils/styling';
import Dots from 'components/Loader/Dots';
import ModalApyCalculator from 'components/Modal/ModalApyCalculator';
import { useMemo, useState } from 'react';
import { FiChevronUp, FiClipboard } from 'react-icons/fi';
import { formatNumber } from 'utils/formatBalance';
import { tokenEarnedPerThousandDaily } from 'utils/index';

const AprCell = ({ farm, earningTokenSymbol, farms }) => {
  const [openRoi, setOpenRoi] = useState(false);

  const farmNotBoosted = useMemo(() => {
    return farms.find((farmCurrent) => farmCurrent.pid === farm.pidFarmBoosted);
  }, [farm.pidFarmBoosted, farms]);

  const apr = farm?.apr;

  const stakedTvl = farm?.stakedTvl || 0;

  if (farm.isFinished) return null;

  return (
    <div className="h-full">
      {openRoi ? (
        <ModalApyCalculator
          earningTokenSymbol={earningTokenSymbol}
          open={openRoi}
          tokenPrice={farm.earningTokenPrice}
          data={farm}
          tokenEarnedPerThousandDaily={tokenEarnedPerThousandDaily(stakedTvl, apr)}
          isFarm
          onClose={() => setOpenRoi(!openRoi)}
        />
      ) : null}
      <p>APR</p>
      {apr ? (
        <p
          onClick={() => setOpenRoi(true)}
          className={classNames(
            'relative flex items-center cursor-pointer hover:opacity-50 font-bold text-primary',
            farm.isBoosted && 'mt-4 -ml-6',
          )}
        >
          {farm.isBoosted && <FiChevronUp />}
          {`${apr.yearlyAPR === '0' ? '???' : formatNumber(+apr.yearlyAPR)}%`}
          <FiClipboard className="ml-1 cursor-pointer" size={18} />
          {farm.isBoosted && (
            <span className="absolute bottom-5 ml-6 left-0 line-through text-white">
              {formatNumber(+farmNotBoosted?.apr?.yearlyAPR || 0)}%
            </span>
          )}
        </p>
      ) : (
        <Dots className="text-white" />
      )}
    </div>
  );
};

export default AprCell;
