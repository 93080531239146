import PropTypes from 'prop-types';
import DetailsSection from 'views/Farms/components/FarmsCard/DetailsSection';
import Harvest from 'views/Farms/components/FarmsTable/ActionPanel/Harvest';
import Stake from 'views/Farms/components/FarmsTable/ActionPanel/Stake';
import Unstake from 'views/Farms/components/FarmsTable/ActionPanel/Unstake';

const ActionPanel = ({ farm, userDataLoaded, masterChefAddress, earningToken, onUpdateUserFarm }) => {
  return (
    <div className="px-2 py-1 sm:px-6 sm:py-4">
      <div className="block lg:flex lg:flex-nowrap flex-wrap lg:space-x-2 space-y-2 lg:space-y-0">
        <div className="h-full">
          <div className="rounded-2xl border border-black2 bg-black1 p-3">
            <DetailsSection
              farm={farm}
              token1={farm.token1}
              token0={farm.token0}
              lpAddress={farm.lpAddress}
              stakingToken={farm?.stakingToken}
              earningToken={earningToken}
              isTable={true}
            />
          </div>
          <div className="rounded-2xl border border-black2 bg-black1 p-3 mt-2">
            <Harvest
              masterChefAddress={masterChefAddress}
              farm={farm}
              userDataLoaded={userDataLoaded}
              earningToken={earningToken}
              onUpdateUserFarm={onUpdateUserFarm}
            />
          </div>
        </div>
        {/*<div className="flex-1">*/}
        {/*  <div className="rounded-2xl border border-black2 bg-black1 dark:bg-black p-3">*/}
        {/*    <Harvest*/}
        {/*        masterChefAddress={masterChefAddress}*/}
        {/*        farm={farm}*/}
        {/*        userDataLoaded={userDataLoaded}*/}
        {/*        earningToken={earningToken}*/}
        {/*        onUpdateUserFarm={onUpdateUserFarm}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {!farm.isFinished && (
          <div className="flex-1">
            <div className="rounded-2xl border border-black2 bg-black1  p-3 h-full">
              <Stake
                userDataLoaded={userDataLoaded}
                masterChefAddress={masterChefAddress}
                farm={farm}
                onUpdateUserFarm={onUpdateUserFarm}
              />
            </div>
          </div>
        )}
        <div className="flex-1">
          <div className="rounded-2xl border border-black2 bg-black1  p-3 h-full">
            <Unstake
              userDataLoaded={userDataLoaded}
              masterChefAddress={masterChefAddress}
              farm={farm}
              earningToken={earningToken}
              onUpdateUserFarm={onUpdateUserFarm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ActionPanel.propTypes = {
  farm: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
  masterChefAddress: PropTypes.string.isRequired,
  earningToken: PropTypes.object.isRequired,
};

export default ActionPanel;
