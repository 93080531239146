import { useMemo } from 'react';
import TokenTable from 'views/Analytics/components/InfoTables/TokensTable';
import PoolTable from 'views/Analytics/components/InfoTables/PoolsTable';
import HoverableChart from '../components/InfoCharts/HoverableChart';
import LineChart from 'views/Analytics/components/InfoCharts/LineChart/LineChart';
import BarChart from 'views/Analytics/components/InfoCharts/BarChart/BarChart';
import Card from 'components/Card/Card';
import TransactionTable from 'views/Analytics/components/InfoTables/TransactionsTable';
import { useSWRProtocolChartData, useSWRProtocolData } from 'hooks/swr/analytics/protocol';
import TopTokenMovers from 'views/Analytics/components/TopTokenMovers/TopTokenMovers';

const Overview = () => {
  const { chartData } = useSWRProtocolChartData();
  const { protocolData } = useSWRProtocolData();

  const currentDate = useMemo(
    () => new Date().toLocaleString('en', { month: 'short', year: 'numeric', day: 'numeric' }),
    [],
  );

  return (
    <>
      <h1 className="mb-5 text-primary text-4xl font-bold">EasyCake Info & Analytics</h1>
      <div className="chartCardsContainer">
        <Card>
          <HoverableChart
            chartData={chartData}
            protocolData={protocolData}
            currentDate={currentDate}
            valueProperty="liquidityUSD"
            title="Liquidity"
            ChartComponent={LineChart}
          />
        </Card>
        <Card>
          <HoverableChart
            chartData={chartData}
            protocolData={protocolData}
            currentDate={currentDate}
            valueProperty="volumeUSD"
            title="Volume 24H"
            ChartComponent={BarChart}
          />
        </Card>
      </div>
      <TopTokenMovers />
      <h2 className="mt-10 mb-5 font-bold text-2xl">Top Tokens</h2>
      <TokenTable />
      <h2 className="mt-10 mb-5 font-bold text-2xl">Top Pools</h2>
      <PoolTable />
      <h2 className="mt-10 mb-5 font-bold text-2xl">Transactions</h2>
      <TransactionTable />
    </>
  );
};

export default Overview;
