import BigNumber from 'bignumber.js';
import { BIG_ZERO } from 'utils/bigNumber';

export const transformUserDataV2 = (userData) => {
  return {
    tokenBalance: userData ? new BigNumber(userData.tokenBalance) : BIG_ZERO,
    paymentTokenBalance: userData ? new BigNumber(userData.paymentTokenBalance) : BIG_ZERO,
  };
};

export const transformLandsV2 = (pool) => {
  const { totalSold, totalBurn, currentRound, pricePerToken, pricePerTokenEstimate, userData, ...rest } = pool;

  return {
    ...rest,
    pricePerToken: pricePerToken ? new BigNumber(pricePerToken) : BIG_ZERO,
    pricePerTokenEstimate: pricePerTokenEstimate ? new BigNumber(pricePerTokenEstimate) : BIG_ZERO,
    totalSold: totalSold ? new BigNumber(totalSold) : BIG_ZERO,
    totalBurn: totalBurn ? new BigNumber(totalBurn) : BIG_ZERO,
    currentRound: {
      ...currentRound,
      sold: currentRound?.sold ? new BigNumber(currentRound.sold) : BIG_ZERO,
      unsold: currentRound?.unsold ? new BigNumber(currentRound.unsold) : BIG_ZERO,
    },
    nextRound: {
      startTime: currentRound?.endTime,
      endTime: currentRound?.endTime + 86400,
    },
    userData: transformUserDataV2(userData),
  };
};
