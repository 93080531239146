import React, { useState } from 'react';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import Modal from 'components/Modal/Modal';
import ModalTitle from 'components/Modal/ModalTitle';
import Input from 'components/Input/Input';
import ModalFooter from 'components/Modal/ModalFooter';
import { showToastError } from 'components/CustomToast/CustomToast';
import { isAddress } from 'utils';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const ModalSendNFT = ({ open, toggle, nft, onConfirm }) => {
  const { account } = useActiveWeb3React();
  const [address, setAddress] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const isValid = address ? isAddress(address) : false;
  const isOwner = account && nft?.owner && account === nft?.owner;

  const handleChange = (evt) => {
    setAddress(evt.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (isOwner && address && isValid) {
        setPendingTx(true);
        await onConfirm(address);
        setPendingTx(false);
        toggle();
      }
    } catch (error) {
      console.log(error);
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
    }
  };

  return (
    <Modal open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Send NFT</ModalTitle>
      <div className="mt-2 mx-2">
        <p className="my-1">Type or paste wallet address</p>
        <Input className={address && !isValid ? 'border-red-600' : ''} value={address} onChange={handleChange} />
        {address && !isValid ? <p className="text-red-600 text-sm-md">Invalid address</p> : null}
      </div>

      <p className="text-orange85 m-5 mt-4 p-2 text-sm-md">
        <strong>Note:</strong> Please ensure that you have an exact wallet address. If not, we are not responsible for
        any mistakes!
      </p>
      <ModalFooter className="mt-4">
        <div className="flex justify-center w-full">
          <ButtonCustom
            disabled={!isOwner || !address || !isValid || pendingTx}
            isLoading={pendingTx}
            onClick={handleSubmit}
          >
            Confirm
          </ButtonCustom>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalSendNFT;
