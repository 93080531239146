import ModalApyCalculatorVault from 'components/Modal/ModalApyCalculatorVault';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FiClipboard } from 'react-icons/fi';
import { classNames } from 'utils/styling';
import Dots from 'components/Loader/Dots';
import { formatNumber } from 'utils/formatBalance';

const ApyCell = ({ vault, className }) => {
  const [openRoi, setOpenRoi] = useState(false);

  const handleOpenModalApy = () => setOpenRoi((prevState) => !prevState);

  return (
    <>
      {openRoi && <ModalApyCalculatorVault onClose={handleOpenModalApy} open={openRoi} apy={vault.apy} data={vault} />}
      {vault.apy.yearlyApy ? (
        <p
          className={classNames('flex', className)}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenModalApy();
          }}
        >
          {vault.apy.yearlyApy > 100000 ? '>99,999' : formatNumber(vault.apy.yearlyApy, 2, 2)}%
          <span className="flex items-center">
            <FiClipboard className="ml-1" size={18} />
          </span>
        </p>
      ) : (
        <Dots className="font-bold sm:text-base" />
      )}
    </>
  );
};

ApyCell.propTypes = {
  vault: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default ApyCell;
