const Percent = ({ value, ...rest }) => {
  if (!value || Number.isNaN(value)) {
    return <p {...rest}>-</p>;
  }

  const isNegative = value < 0;

  return (
    <p {...rest} className={isNegative ? 'text-failure' : 'text-success'}>
      {isNegative ? '↓' : '↑'}
      {Math.abs(value).toFixed(2)}%
    </p>
  );
};

export default Percent;
