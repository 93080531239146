import { InjectedConnector } from '@web3-react/injected-connector';
import { ChainId } from 'ezcake-v2-sdk';
import { NetworkConnector } from './NetworkConnector';
import { BscConnector } from '@binance-chain/bsc-connector';

export const RPC = {
  [ChainId.BSC]: 'https://bsc-dataseed.binance.org/',
  [ChainId.KAI]: 'https://rpc.kardiachain.io',
  [ChainId.BSC_TESTNET]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  [ChainId.HARMONY]: 'https://api.s0.t.hmny.io',
  [ChainId.HARMONY_TESTNET]: 'https://api.s0.b.hmny.io',
  [ChainId.ONUS]: 'https://rpc.onuschain.io/',
  [ChainId.ONUS_TESTNET]: 'https://rpc-testnet.onuschain.io/',
};

export const DEFAULT_NETWORK = ChainId.BSC;

export const network = new NetworkConnector({
  defaultChainId: DEFAULT_NETWORK,
  urls: RPC,
});

export const NETWORK_SUPPORTED_CHAINIDS = [ChainId.BSC, ChainId.ONUS
  // , ChainId.BSC_TESTNET
];

export const injected = new InjectedConnector({
  supportedChainIds: [
    // ChainId.KAI, // kai
    ChainId.ONUS,
    ChainId.BSC, // binance smart chain
    // ChainId.BSC_TESTNET, // binance smart chain testnet
    // ChainId.HARMONY, // HARMONY
    // ChainId.ONUS_TESTNET,
    // 1666700000, // HARMONY TESTNET
  ],
});

// mainnet only
// export const walletconnect = new WalletConnectConnector({
//   rpc: RPC,
//   bridge: 'https://bridge.walletconnect.org',
//   qrcode: true,
//   pollingInterval: 15000,
// });

// binance only
export const binance = new BscConnector({ supportedChainIds: [ChainId.BSC, ChainId.ONUS] });
// export const binance = new BscConnector({ supportedChainIds: [97] }) // testnet

export const SUPPORTED_NETWORKS = {
  [ChainId.MAINNET]: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3'],
    blockExplorerUrls: ['https://etherscan.com'],
  },
  [ChainId.TOMO]: {
    chainId: '0x58',
    chainName: 'TomoChain',
    nativeCurrency: {
      name: 'TomoChain',
      symbol: 'TOMO',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.tomochain.com'],
    blockExplorerUrls: ['https://scan.tomochain.com'],
  },
  [ChainId.BSC]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  [ChainId.BSC_TESTNET]: {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'tBNB',
      symbol: 'tBNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  [ChainId.KAI]: {
    chainId: '0x18',
    chainName: 'KardiaChain',
    nativeCurrency: {
      name: 'KardiaChain Token',
      symbol: 'KAI',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.kardiachain.io'],
    blockExplorerUrls: ['https://explorer.kardiachain.io'],
  },
  [ChainId.MATIC]: {
    chainId: '0x89',
    chainName: 'Matic',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com'], // ['https://matic-mainnet.chainstacklabs.com/'],
    blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com'],
  },
  [ChainId.FANTOM]: {
    chainId: '0xfa',
    chainName: 'Fantom',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    blockExplorerUrls: ['https://ftmscan.com'],
  },
  [ChainId.HECO]: {
    chainId: '0x80',
    chainName: 'Heco',
    nativeCurrency: {
      name: 'Heco Token',
      symbol: 'HT',
      decimals: 18,
    },
    rpcUrls: ['https://http-mainnet.hecochain.com'],
    blockExplorerUrls: ['https://hecoinfo.com'],
  },
  [ChainId.XDAI]: {
    chainId: '0x64',
    chainName: 'xDai',
    nativeCurrency: {
      name: 'xDai Token',
      symbol: 'xDai',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.xdaichain.com'],
    blockExplorerUrls: ['https://blockscout.com/poa/xdai'],
  },
  [ChainId.HARMONY]: {
    chainId: '0x63564C40',
    chainName: 'Harmony',
    nativeCurrency: {
      name: 'One Token',
      symbol: 'ONE',
      decimals: 18,
    },
    rpcUrls: ['https://api.s0.t.hmny.io'],
    blockExplorerUrls: ['https://explorer.harmony.one/'],
  },
  [ChainId.HARMONY_TESTNET]: {
    chainId: '0x6357D2E0',
    chainName: 'Harmony Testnet',
    nativeCurrency: {
      name: 'One Token',
      symbol: 'ONE',
      decimals: 18,
    },
    rpcUrls: [
      'https://api.s0.b.hmny.io',
      'https://api.s1.b.hmny.io',
      'https://api.s2.b.hmny.io',
      'https://api.s3.b.hmny.io',
    ],
    blockExplorerUrls: ['https://explorer.pops.one/'],
  },
  [ChainId.AVALANCHE]: {
    chainId: '0xA86A',
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'Avalanche Token',
      symbol: 'AVAX',
      decimals: 18,
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network'],
  },
  [ChainId.OKEX]: {
    chainId: '0x42',
    chainName: 'OKEx',
    nativeCurrency: {
      name: 'OKEx Token',
      symbol: 'OKT',
      decimals: 18,
    },
    rpcUrls: ['https://exchainrpc.okex.org'],
    blockExplorerUrls: ['https://www.oklink.com/okexchain'],
  },
  [ChainId.ARBITRUM]: {
    chainId: '0xA4B1',
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://mainnet-arb-explorer.netlify.app'],
  },
  [ChainId.CELO]: {
    chainId: '0xA4EC',
    chainName: 'Celo',
    nativeCurrency: {
      name: 'Celo',
      symbol: 'CELO',
      decimals: 18,
    },
    rpcUrls: ['https://forno.celo.org'],
    blockExplorerUrls: ['https://explorer.celo.org'],
  },
  [ChainId.PALM]: {
    chainId: '0x2A15C308D',
    chainName: 'Palm',
    nativeCurrency: {
      name: 'Palm',
      symbol: 'PALM',
      decimals: 18,
    },
    rpcUrls: ['https://palm-mainnet.infura.io/v3/da5fbfafcca14b109e2665290681e267'],
    blockExplorerUrls: ['https://explorer.palm.io'],
  },
  [ChainId.ONUS]: {
    chainId: '0x7B7',
    chainName: 'ONUS Chain',
    nativeCurrency: {
      name: 'ONUS',
      symbol: 'ONUS',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.onuschain.io/'],
    blockExplorerUrls: ['https://explorer.onuschain.io/'],
  },
  [ChainId.ONUS_TESTNET]: {
    chainId: '0x799',
    chainName: 'ONUS Chain Testnet',
    nativeCurrency: {
      name: 'ONUS',
      symbol: 'ONUS',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-testnet.onuschain.io/'],
    blockExplorerUrls: ['https://explorer-testnet.onuschain.io/'],
  },
};
