import BigNumber from 'bignumber.js';
import { UINT256_MAX } from 'config';
import { BIG_TEN } from 'utils/bigNumber';

export const approveFarm = async (tokenContract, masterChefAddress) => {
  const tx = await tokenContract.approve(masterChefAddress, UINT256_MAX);
  await tx.wait();
  return tx.hash;
};

export const stakeFarm = async (masterChefContract, pid, amount, decimals = 18) => {
  const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString();
  const tx = await masterChefContract.deposit(pid, value);
  await tx.wait();
  return tx.hash;
};

export const unstakeFarm = async (masterChefContract, pid, amount, decimals = 18) => {
  const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString();
  const tx = await masterChefContract.withdraw(pid, value);
  await tx.wait();
  return tx.hash;
};

export const harvestFarm = async (masterChefContract, pid) => {
  const tx = await masterChefContract.deposit(pid, '0');
  await tx.wait();
  return tx.hash;
};

export const harvestFarmWithdraw = async (masterChefContract, pid) => {
  const tx = await masterChefContract.withdraw(pid, '0');
  await tx.wait();
  return tx.hash;
};
