import { classNames } from 'utils/styling';
import { useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';

const BAD_SRCS = {};

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
const LogoSwap = ({ srcs, size = '32px', alt, className, ...rest }) => {
  const [, refresh] = useState(0);

  const src = srcs.find((s) => !BAD_SRCS[s]);

  if (src) {
    return (
      <div
        className={classNames('rounded-full shadow-md p-1 bg-white', className)}
        style={{
          width: size,
          height: size,
        }}
      >
        <img
          className="rounded-full w-full h-full"
          alt={alt}
          src={src}
          onError={() => {
            if (src) BAD_SRCS[src] = true;
            refresh((i) => i + 1);
          }}
        />
      </div>
    );
  }

  return (
    <FiHelpCircle
      size={size}
      color="black"
      className={classNames(className, ' rounded-50 bg-white shadow-md p-1')}
      {...rest}
    />
  );
};

export default LogoSwap;
