import { MASTERCHEF_ADDRESS, MASTERCHEF_ADDRESS_CAKE, MASTERCHEF_ADDRESS_MIA } from 'constants/contracts';
import tokens from 'constants/tokens';
import { ChainId } from 'ezcake-v2-sdk';
export const sortTypeVault = {
  hot: 'hot',
  apy: 'apy',
  tvl: 'tvl',
  available: 'available',
  holdings: 'holdings',
};

export const TYPE_STRATEGY = {
  stratX4: 'StratX4',
  strat100X4: 'Strat100X4',
  stratX2: 'StratX2',
  strat100Back: 'Strat100',
  dragon: 'DRAGON',
};

export const sortListVault = [
  {
    name: sortTypeVault.hot,
    label: 'Hot',
  },
  {
    name: sortTypeVault.apy,
    label: 'Apy',
  },
  {
    name: sortTypeVault.tvl,
    label: 'Tvl',
  },
  {
    name: sortTypeVault.available,
    label: 'Available',
  },
  {
    name: sortTypeVault.holdings,
    label: 'Holdings',
  },
];

export const PLATFORM = {
  eCake: 'Easy Cake',
  cake: 'Cakeswap',
};

const vaultsConfig = {
  [ChainId.KAI]:[],
  [ChainId.BSC]: [
    {
      vid: 11,
      contractAddress: '0xbCA839079D00599E88425f17e6eD1bbafC5336a6',
      contractStratAddress: '0xDa7D9c45655fa9C9E39B7245dF94b1a9145a0751',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: tokens.dragon,
      token0: tokens.dragon,
      farmPid: 1,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 0,
      contractAddress: '0xe654976F27a9b00F4a1CE298C286D5cfaDBAc69c',
      contractStratAddress: '0xB63aBc71498F29986AF4361ef1827fA57b4b86F3',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x08f638cfed28CC60A7F4A9F0DE36e10FfD70Afe0',
        },
      },
      token0: tokens.mCake,
      token1: tokens.wbnb,
      farmPid: 0,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 10,
      contractAddress: '0x6D7f28e79aEb0427eAEFD5443280A10861Cf94e0',
      contractStratAddress: '0xA7Ef3Ecd06E83F56355C0584A57cDDcbF4d32dD5',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.wingsLp,
        address: {
          [ChainId.BSC]: '0x362923F64cF94c4462De0d059Dd0997954Ce8E31',
        },
      },
      token0: tokens.mCake,
      token1: tokens.wbnb,
      farmPid: 9,
      fees: 3,
      platform: PLATFORM.eCake,
      isFinished: true,
    },
    {
      vid: 9,
      contractAddress: '0xF28626AdAeF484729a22315a85408DaA5317844B',
      contractStratAddress: '0xd10B774bcd012273cE3eFb693819fF62dBb82444',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.wingsLp,
        address: {
          [ChainId.BSC]: '0xAF781E78119A97652dDffA5CBaAeCEedfd987886',
        },
      },
      token0: tokens.mCake,
      token1: tokens.wings,
      farmPid: 10,
      fees: 3,
      platform: PLATFORM.eCake,
      isFinished: true,
    },
    {
      vid: 1,
      contractAddress: '0x91065a37400000dD78D465502a40AF29B7373489',
      contractStratAddress: '0x3337542E6d1cEF98A32E7B1Ff599a1f00a5dB848',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x39499083418DD7865D86AA6cD613e7864CC46aC6',
        },
      },
      token0: tokens.mCake,
      token1: tokens.busd,
      farmPid: 4,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 2,
      contractAddress: '0xE815cF2F793c00f21E95B33f613445b54bdb48Fc',
      contractStratAddress: '0xbfdf6943787830D8b2262f3401618e3A3Ee14Fe3',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x8802F9feb07B04697fAc08911f7092435fF03AC3',
        },
      },
      token0: tokens.mCake,
      token1: tokens.btcb,
      farmPid: 7,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 3,
      contractAddress: '0x7e6ce5EcBEfe4782864DD8b44c7E2DDef4AE6745',
      contractStratAddress: '0xBF2932D320E168D60F0339314Ad59F382b4AA1c4',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x6A0FF4aA4746B7240d1a06c479fdF4f9bE1be809',
        },
      },
      token0: tokens.mCake,
      token1: tokens.eth,
      farmPid: 8,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 4,
      contractAddress: '0xb286CB728Ae6015cd92bBe335821A0aE865e1829',
      contractStratAddress: '0x8eE756Ef5474B4e622129C6BcCb30602e0a67724',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x4af1D859fD604ee7D590e178Bd0aB712A62B193D',
        },
      },
      token0: tokens.mCake,
      token1: tokens.kaiBNB,
      farmPid: 6,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 5,
      contractAddress: '0x54bA1db4Afe00b1D061137498ABe29E9fBEed3ff',
      contractStratAddress: '0xa0b84FF4C77FF107F59e80d51Fffe91A117A100e',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x06cdD84AF418e8a71C12C79978Fa61EB7C143920',
        },
      },
      token0: tokens.kaiBNB,
      token1: tokens.wbnb,
      farmPid: 2,
      fees: 3,
      platform: PLATFORM.eCake,
      isFinished: true,
    },
    {
      vid: 6,
      contractAddress: '0x645603acA5819E754B99130019e28642dC128D02',
      contractStratAddress: '0xc31d07C9f89d959b57bc73Dcf6e63bB275821aff',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x93716E4bB61fbbf7E7b30ED40e6DEc32a343e769',
        },
      },
      token0: tokens.ldr,
      token1: tokens.wbnb,
      farmPid: 11,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 7,
      contractAddress: '0x3576A3c7842A6962e68E33ABF99D7786D07BA627',
      contractStratAddress: '0xEEA219B79c7Af9cf5b60595cdbcaeBff5701Ad80',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x4e65c29271D581E7B31db6F67400c1443db4a2f0',
        },
      },
      token0: tokens.lpdi,
      token1: tokens.wbnb,
      farmPid: 3,
      fees: 3,
      platform: PLATFORM.eCake,
      isFinished: true,
    },
    {
      vid: 8,
      contractAddress: '0x8b4eCC805c2E7C7232fbC6AEF10BC4b5488f1822',
      contractStratAddress: '0x65b262b61341444A939fccA090aB96f2c385Ed5C',
      contractMasterChefAddress: MASTERCHEF_ADDRESS[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0xd2935b58Cc2f93334B83EdE34EA48D106F9E7587',
        },
      },
      token0: tokens.mCake,
      token1: tokens.dpet,
      farmPid: 5,
      fees: 3,
      platform: PLATFORM.eCake,
    },
    {
      vid: 12,
      contractAddress: '0x6c2c36553Edf2735DeFbCB115d5D14E3f43936C7',
      contractStratAddress: '0x8Fa73634Ac9d5e65533FE8F2C6a1566D1AD67685',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_CAKE[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
        },
      },
      token0: tokens.busdc,
      token1: tokens.busdt,
      farmPid: 423,
      fees: 3,
      platform: PLATFORM.cake,
      pendingRewardsMethod: 'pendingCake',
      isFinished: true,
    },
    {
      vid: 13,
      contractAddress: '0xfe26F90c27DA32de5b2AA3047028E7624Ac6e555',
      contractStratAddress: '0xaBF856803d4bBbe944Ce2c03A1bea71e3638010e',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_CAKE[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
        },
      },
      token0: tokens.busdt,
      token1: tokens.busd,
      farmPid: 258,
      fees: 3,
      platform: PLATFORM.cake,
      pendingRewardsMethod: 'pendingCake',
      isFinished: true,
    },
    {
      vid: 14,
      contractAddress: '0x3447255E8A29805Bbf00d283e31d4Ac4039718f0',
      contractStratAddress: '0xb725ccAA1FA8605670114648Ed34699747F1661F',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_CAKE[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
        },
      },
      token0: tokens.busdc,
      token1: tokens.busd,
      farmPid: 283,
      fees: 3,
      platform: PLATFORM.cake,
      pendingRewardsMethod: 'pendingCake',
      isFinished: true,
    },
    {
      vid: 15,
      contractAddress: '0x5b24B88E3a8e14D927dFF04a4eE5e4d1dbD995Ce',
      contractStratAddress: '0x058c81e9c6c5334Da9B2a23a62D3a31DcFDc94F0',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_CAKE[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
        },
      },
      token0: tokens.bdai,
      token1: tokens.busd,
      farmPid: 282,
      fees: 3,
      platform: PLATFORM.cake,
      pendingRewardsMethod: 'pendingCake',
      isFinished: true,
    },
    {
      vid: 16,
      contractAddress: '0x66049D017D4079f49055e1ED8Ebe3FC4A7320725',
      contractStratAddress: '0xa30BbbD6d3Ec55048b15Fbe4f90d107c18e8038f',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_CAKE[ChainId.BSC],
      stakingToken: {
        ...tokens.cakeLp,
        address: {
          [ChainId.BSC]: '0x05faf555522Fa3F93959F86B41A3808666093210',
        },
      },
      token0: tokens.ust,
      token1: tokens.busd,
      farmPid: 293,
      fees: 3,
      platform: PLATFORM.cake,
      pendingRewardsMethod: 'pendingCake',
      isFinished: true,
    },
  ],
  [ChainId.HARMONY]: [],
  [ChainId.HARMONY_TESTNET]: [],
  [ChainId.ONUS]: [
    {
      vid: 0,
      contractAddress: '0x3A39971aaCF9A85E3783760E308403c737cF848a',
      contractStratAddress: '0x899d4315D9BDa65ADf38fB7160E64B5b44Aa1936',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0xADe7762EF1A20B36fc935c7553f7BdeB80680a55',
        },
      },
      token0: tokens.wonus,
      token1: tokens.mia,
      rewardToken: tokens.mia,
      farmPid: 0,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 1,
      contractAddress: '0x6bdeB64E1552f80d41cA9E1956291F91548f9403',
      contractStratAddress: '0x3b10BfFf5DbC1582cdADE8269f3541D1138ab14F',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0xDE74Dd9Da2b38F92cCCE5f6bf46f3D34b21ce79C',
        },
      },
      token0: tokens.mia,
      token1: tokens.busd,
      rewardToken: tokens.mia,
      farmPid: 1,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 2,
      contractAddress: '0x7FF248cdBe36891e6F0004Abc6457A344d607826',
      contractStratAddress: '0x5508BE97311805d83Eb6F2ac909Fc4369fA0d44b',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0x6ed76CD41960159938BEE34FfdE6Aba47A25E5cA',
        },
      },
      token0: tokens.mia,
      token1: tokens.vndc,
      rewardToken: tokens.mia,
      farmPid: 3,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 3,
      contractAddress: '0x6C1A5d941d8e84c1314E8520021A3C7Fed32Ee7B',
      contractStratAddress: '0x6c50750526030a594e196205B61E69c2403A7976',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0x64C9D16BF9BbAB164677894c432e40EA5E068f69',
        },
      },
      token0: tokens.mia,
      token1: tokens.usdtOnus,
      rewardToken: tokens.mia,
      farmPid: 4,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 4,
      contractAddress: '0x1713a763e99183dCefBE5195bf26E41a55583f02',
      contractStratAddress: '0x7435d8c5Bb78A098dB0f039d67c34515033f1040',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0x4b99fc04de32Adc6302b318520aebF3Ed96b9b90',
        },
      },
      token0: tokens.usdo,
      token1: tokens.mia,
      rewardToken: tokens.mia,
      farmPid: 8,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 5,
      contractAddress: '0x59ab7E2861D401cECD15F845FF79229BA4Ed23B7',
      contractStratAddress: '0x0E02694Ab0F79dc0d56A65EbB5C5C89D52BA3529',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0xa1b23C01fC911cAc3795c2170dac0f13585e1823',
        },
      },
      token0: tokens.btc,
      token1: tokens.vndc,
      rewardToken: tokens.mia,
      farmPid: 9,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 6,
      contractAddress: '0x1986562F0Cb171efcA1438e7197aDDD031f84489',
      contractStratAddress: '0x624191727CD7EeB54e43cC9879cbF04Dab5672f5',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0x738F874f7f7002b020D62B5A975507D264402670',
        },
      },
      token0: tokens.eth,
      token1: tokens.vndc,
      rewardToken: tokens.mia,
      farmPid: 10,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 7,
      contractAddress: '0x54d46b2f63Ac664845Cdb8F6291ffF9FB6647d36',
      contractStratAddress: '0xC0C8A5B2aC066b95Baaf3228e7924D18F32A81d6',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0x979CD82E93DB1458c6d8c862ea7aD6dC27486C41',
        },
      },
      token0: tokens.bnbKai,
      token1: tokens.vndc,
      rewardToken: tokens.mia,
      farmPid: 11,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 8,
      contractAddress: '0xa96D452c0A6669f01Aa90f6F0c62F7F4DE81f3EE',
      contractStratAddress: '0xB87CEEc97a4a0bEa69347e463B9be53De2e3CCE4',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0x5e995e666f21FC3aA3D5757304bb8Bd8bAA96774',
        },
      },
      token0: tokens.vndc,
      token1: tokens.usdtOnus,
      rewardToken: tokens.mia,
      farmPid: 5,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 9,
      contractAddress: '0xdea2e30Ba17db859535E0284987eB38FD4eF17BC',
      contractStratAddress: '0xBcE16b4F08ba9C419A5e3551D324bE0C8D94191B',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0xDC108A0021fA96f8A4BF5971F45bC5EA4F065296',
        },
      },
      token0: tokens.usdo,
      token1: tokens.vndc,
      rewardToken: tokens.mia,
      farmPid: 7,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 10,
      contractAddress: '0xFAd0Bab4Af12E5Bfb4e56797469B20FACB5B5076',
      contractStratAddress: '0xa0b501317fD3d8c47543efa6a5C5d7183cA5EBA3',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0xeb1DC5110505D71347e168D48A2490f010794393',
        },
      },
      token0: tokens.vndc,
      token1: tokens.rice,
      rewardToken: tokens.mia,
      farmPid: 12,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
    {
      vid: 11,
      contractAddress: '0x85dCaBAE8a7D89FAb0Bd530843a03c126Ec7F554',
      contractStratAddress: '0x8a7ebAe1398E17Df8EEc8176845DE682133e854F',
      contractMasterChefAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      stakingToken: {
        ...tokens.miaLP,
        address: {
          [ChainId.ONUS]: '0x9a380A25D2250807aee3c7087aAD2A5b9b31a00E',
        },
      },
      token0: tokens.wonus,
      token1: tokens.vndc,
      rewardToken: tokens.mia,
      farmPid: 6,
      fees: 1.5,
      feesWithdraw: 0.5,
      platform: PLATFORM.mia,
      pendingRewardsMethod: 'pendingToken',
    },
  ],
  [ChainId.ONUS_TESTNET]: [],
};
export const vaultsV2Config = {
  [ChainId.KAI]: [
    {
      vid: 10,
      contractAddress: '0x2Cff3D83494311268DD7600b49F0eA532befEDaB',
      strategyContractAddress: '0x72a60F1d17aa51d4AC0dD11f132B2948216780AA',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.nami,
      token0: tokens.nami,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into NAMI , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
      isFinished: true,
    },
    {
      vid: 11,
      contractAddress: '0xb5F029B94C60b3Fa59E713D2E61F730fE4f9FB24',
      strategyContractAddress: '0x01b662F1274cD8e64B432446DD3F74B1b02BB352',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.ltd,
      token0: tokens.ltd,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into LTD , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
    },
    {
      vid: 12,
      contractAddress: '0x062D0992926DDD316e6855bC35755Bac9f64c7c6',
      strategyContractAddress: '0xa05476bB8B007B5cF5Ad59C129B02D03Ae7cCfca',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.lpdi,
      token0: tokens.lpdi,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into LPDI , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
    },
    {
      vid: 7,
      contractAddress: '0x2a1FA42AF3f8323b9A92a8528f01cF2E0D4E4A33',
      strategyContractAddress: '0xBcEC4670A1D064D03dE7795FEd289cba68056D69',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.kusd,
      token0: tokens.kusd,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into KUSD-T , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
      isFinished: true,
    },
    {
      vid: 8,
      contractAddress: '0x44B1bBc999E63e40341049b7a83521f67A2F3730',
      strategyContractAddress: '0x2c4a3834d8aE498a67627d752d36Ec1928eB7Ba4',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.vndc,
      token0: tokens.vndc,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into VNDC , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
    },
    {
      vid: 9,
      contractAddress: '0xDad1aa941E0C1b3A08a2f68a419154f998a5DBB1',
      strategyContractAddress: '0xf6649165d2cc8501a4d47EA73A24da589974B1aD',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.vndt,
      token0: tokens.vndt,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into VNDT , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
    },
    {
      vid: 13,
      contractAddress: '0x5700261141bB0a2dD88FFdf55C45A187D7314866',
      strategyContractAddress: '0x13708aC2F787dFb89Efd9671b792e0841904f329',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.chat,
      token0: tokens.chat,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into CHAT , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
    },
    {
      vid: 14,
      contractAddress: '0x04b928e0E3B485A80eCc6F15D9B1ce6D534D3bb5',
      strategyContractAddress: '0x76169e51Fb13687d2d236Dcf2727e88fd87272Ff',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.chat,
      token0: tokens.chat,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Sell half rewards into CHAT , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
      isFinished: true,
    },
    {
      vid: 6,
      contractAddress: '0xd0Ff1cAA3135A2529A7110BD3056266C84c93227',
      strategyContractAddress: '0xAD84eDb13EA7bBc019A4861C6E8e895CB6Cf4a3B',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.wkai,
      token0: tokens.wkai,
      fees: 1.5,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.dragon,
      docs: '3. Sell half rewards into KUSD-T , use the other half of DFL to add DFL-KUSD-T LP to put in the highest APY Vault of DFL-KUSD-T LP',
    },
    {
      vid: 5,
      contractAddress: '0x8D32Bb508a4c803C859d7a42D8e71AF904cc2761',
      strategyContractAddress: '0x046C1a67D0103aCC5f66705067e9821a82aC0480',
      contractVaultStakedAddress: '0x181CaFef6277870d082D484dB0C1b27c3B1366e4',
      stakingToken: tokens.dragon,
      token0: tokens.dragon,
      fees: 1,
      platform: PLATFORM.eCake,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docsExpand:
        'This vault automatically performs a multi-step transaction for you:\n' +
        '\n' +
        '1. Make a deposit into DRAGON farm of Defily.\n' +
        '2. Auto-harvest rewards every 5 minutes or whenever someone deposit/withdraw.\n' +
        '3. Cut rewards into 2 half\n' +
        '4. Make a deposit of the first half back into DRAGON farm of Defily to compound.\n' +
        '5. Pipe the second half into DFL-KUSD-T LP token and make a deposit into DFL-KUSD-T farm of Defily\n' +
        '6. When you withdraw, the vault converts everything you have back into DRAGON token.',
    },
    {
      vid: 3,
      contractAddress: '0x91AAd8D2cd0b73b2C951c70648A82459BA6877c4',
      strategyContractAddress: '0x2d82E6867Ad01ecEE6f91e2b4FF3c76f139279DB',
      contractVaultStakedAddress: '0xF85Ce3b95AD4F183ceD89F4302A691d152270497',
      stakingToken: tokens.wkai,
      token0: tokens.wkai,
      fees: 1.5,
      platform: PLATFORM.beco,
      typeStrategy: TYPE_STRATEGY.strat100X4,
      isFinished: true,
    },
    {
      vid: 0,
      contractAddress: '0xFFF8c502D5818d3A74E2C36A0A6525f0cA1171a3',
      strategyContractAddress: '0xF3Ea1de61b7f86372E3568dAe6a18Ba4a2C43436',
      contractVaultStakedAddress: '0xF85Ce3b95AD4F183ceD89F4302A691d152270497',
      stakingToken: tokens.wkai,
      token0: tokens.wkai,
      fees: 1.5,
      logo: '/tokens/becoswap.png',
      platform: PLATFORM.beco,
      typeStrategy: TYPE_STRATEGY.stratX4,
      isFinished: true,
    },
    {
      vid: 1,
      contractAddress: '0xb8f89078Be2b1cc0e0F04254887839cEF05Ad56d',
      strategyContractAddress: '0xC484B41B05D9A9Ce3814BBd24F7870E0f0C2F062',
      contractVaultStakedAddress: '0xF85Ce3b95AD4F183ceD89F4302A691d152270497',
      stakingToken: tokens.sen,
      token0: tokens.sen,
      fees: 1.5,
      platform: PLATFORM.beco,
      typeStrategy: TYPE_STRATEGY.stratX4,
      isFinished: true,
    },
    {
      vid: 2,
      contractAddress: '0x6a533bccd0d30E97029B2DbDdFE6681eFf14a076',
      strategyContractAddress: '0x0DE659e54E3E8FcCEEDc5dd1f45a932aFC166160',
      contractVaultStakedAddress: '0xF85Ce3b95AD4F183ceD89F4302A691d152270497',
      stakingToken: tokens.dpet,
      token0: tokens.dpet,
      fees: 1.5,
      platform: PLATFORM.beco,
      typeStrategy: TYPE_STRATEGY.stratX4,
      isFinished: true,
    },
    {
      vid: 4,
      contractAddress: '0x13EDDC8a228b1707Aa23eB5cE2C2682EfFC120a3',
      strategyContractAddress: '0x9885eaA68fd165224825D89F6561723adD6A0cda',
      contractVaultStakedAddress: '0xF85Ce3b95AD4F183ceD89F4302A691d152270497',
      stakingToken: tokens.beco,
      token0: tokens.beco,
      fees: 1.5,
      platform: PLATFORM.beco,
      typeStrategy: TYPE_STRATEGY.stratX4,
      docs: '3. Use half rewards as BECO to compound back, other half will be sold another half into WKAI to add BECO-KAI LP to deposit into BECO-KAI Vault',
      isFinished: true,
    },
  ],
  [ChainId.BSC]: [],
  [ChainId.HARMONY]: [],
  [ChainId.HARMONY_TESTNET]: [],
  [ChainId.ONUS]: [],
  [ChainId.ONUS_TESTNET]: [],
};
export default vaultsConfig;
