import { createSlice } from '@reduxjs/toolkit';
import castlesConfig, { castlesV2Config } from 'constants/castles';
import { ChainId } from 'ezcake-v2-sdk';
import { fetchPoolsLimits, fetchPoolsStakingLimits, fetchPoolsTotalStaking } from 'store/castles/fetchPools';
import {
  fetchPoolUser,
  fetchPoolUserAllowances,
  fetchPoolUserEarnings, fetchPoolUserEarningsV1,
  fetchPoolUserStakedBalances, fetchPoolUserStakedBalancesV1,
  fetchPoolUserTokenBalances,
  fetchPoolV2User,
} from 'store/castles/fetchPoolsUser';
import { BIG_ZERO } from 'utils/bigNumber';

const initUserData = {
  allowance: '0',
  tokenBalance: '0',
  stakedBalance: '0',
  earnings: '0',
  earningsTokenBalance: '0',
};

const initialState = {
  data: {
    [ChainId.BSC]: [...castlesConfig[ChainId.BSC]],
    [ChainId.ONUS]: [...castlesConfig[ChainId.ONUS], ...castlesV2Config[ChainId.ONUS]],
    [ChainId.BSC_TESTNET]: castlesConfig[ChainId.BSC_TESTNET],
  },
  userDataLoaded: {
    [ChainId.BSC]: false,
    [ChainId.ONUS]: false,
    [ChainId.BSC_TESTNET]: false,
  },
  userData: {},
};

export const castlesSlice = createSlice({
  name: 'castles',
  initialState,
  reducers: {
    setCastlesPublicData: (state, action) => {
      const { livePoolsData, chainId } = action.payload;
      state.data[chainId] = state.data[chainId].map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.sousId === pool.sousId);
        return { ...pool, ...livePoolData };
      });
    },
    setPoolsUserData: (state, action) => {
      const { userData, chainId } = action.payload;
      state.data[chainId] = state.data[chainId].map((pool) => {
        const userFarmData = userData.find((entry) => entry.sousId === pool.sousId);
        return { ...pool, userData: userFarmData };
      });
      state.userDataLoaded[chainId] = true;
    },
    setUserData: (state, action) => {
      state.userData[action.payload.sousId] = {
        ...state.userData[action.payload.sousId],
        userDataLoaded: true,
        ...action.payload,
      };
    },
  },
});

// async action

export const fetchCastlesPublicDataAsync = (currentBlock, chainId) => async (dispatch) => {
  const allPools = [...castlesConfig[chainId], ...castlesV2Config[chainId]];
  const totalStakings = await fetchPoolsTotalStaking(allPools, chainId);

  const liveData = allPools.map((pool) => {
    const totalStaking = totalStakings.find((entry) => entry.sousId === pool.sousId);
    const isPoolEndBlockExceeded = currentBlock > 0 && currentBlock > Number(pool.endBlock);
    const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded;

    return {
      ...totalStaking,
      isFinished: isPoolFinished,
    };
  });

  dispatch(setCastlesPublicData({ livePoolsData: liveData, chainId }));
};

export const fetchCastlesStakingLimitsAsync = (chainId) => async (dispatch, getState) => {
  try {
    const poolsWithStakingLimit = getState()
      .castles.data[chainId].filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
      .map((pool) => pool.sousId);

    const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit, chainId);
    const poolLimits = await fetchPoolsLimits(chainId);

    const stakingLimitData = [...castlesConfig[chainId], ...castlesV2Config[chainId]].map((pool) => {
      if (poolsWithStakingLimit.includes(pool.sousId)) {
        return { sousId: pool.sousId };
      }
      const stakingLimit = stakingLimits[pool.sousId] || BIG_ZERO;
      const poolLimit = poolLimits[pool.sousId] || BIG_ZERO;
      return {
        sousId: pool.sousId,
        stakingLimit: stakingLimit.toJSON(),
        poolLimit: poolLimit.toJSON(),
      };
    });

    dispatch(setCastlesPublicData({ livePoolsData: stakingLimitData, chainId }));
  } catch (e) {
    console.log(e);
  }
};

export const fetchCastlesUserDataAsync = (account, chainId) => async (dispatch) => {
  try {
    const promise = [
      fetchPoolUserAllowances(account, castlesV2Config[chainId], chainId),
      fetchPoolUserTokenBalances(account, castlesV2Config[chainId], chainId),
      fetchPoolUserStakedBalances(account, castlesV2Config[chainId], chainId),
      fetchPoolUserEarnings(account, castlesV2Config[chainId], chainId),
    ];
    const response = await Promise.all(promise);

    const allowances = response?.[0];
    const stakingTokenBalances = response?.[1];
    const balances = response?.[2];
    const earnings = response?.[3];

    const userData = castlesV2Config[chainId].map((vault, index) => {
      const balance = balances.find((balance) => balance.sousId === vault.sousId);

      return {
        sousId: vault.sousId,
        allowance: allowances[index],
        stakingTokenBalance: stakingTokenBalances[index],
        stakedBalance: balance?.stakedBalance,
        lastStakingBlock: balance?.lastStakingBlock,
        earnings: earnings[index],
      };
    });

    dispatch(setPoolsUserData({ userData, chainId }));
  } catch (e) {
    console.log(e);
  }
};

export const fetchCastlesUserDataAsyncV1 = (account, chainId) => async (dispatch) => {
  try {
    const promise = [
      fetchPoolUserAllowances(account, castlesConfig[chainId], chainId),
      fetchPoolUserTokenBalances(account, castlesConfig[chainId], chainId),
      fetchPoolUserStakedBalancesV1(account, castlesConfig[chainId], chainId),
      fetchPoolUserEarningsV1(account, castlesConfig[chainId], chainId),
    ];
    const response = await Promise.all(promise);

    const allowances = response?.[0];
    const stakingTokenBalances = response?.[1];
    const balances = response?.[2];
    const earnings = response?.[3];

    const userData = castlesConfig[chainId].map((vault, index) => {
      const balance = balances.find((balance) => balance.sousId === vault.sousId);

      return {
        sousId: vault.sousId,
        allowance: allowances[index],
        stakingTokenBalance: stakingTokenBalances[index],
        stakedBalance: balance?.stakedBalance,
        earnings: earnings[index],
      };
    });

    dispatch(setPoolsUserData({ userData, chainId }));
  } catch (e) {
    console.log(e);
  }
};


export const fetchCastleUserDataAsync = (account, pool, chainId) => async (dispatch) => {
  try {
    let res = {};
    if (pool.isV2) {
      res = await fetchPoolV2User(account, pool, chainId);
    } else {
      res = await fetchPoolUser(account, pool, chainId);
    }

    const userData = castlesConfig[chainId].map((vault, index) => {
      return {
        sousId: vault.sousId,
        ...res,
        earnings: [res.earnings],
        allowance: [res.allowance],
        stakingTokenBalance: [res.stakingTokenBalance],
        stakedBalance: [res.stakedBalance],
      };
    });

    dispatch(
        setPoolsUserData({
          userData, chainId
      }),
    );
  } catch (e) {
    dispatch(
      setUserData({
        sousId: pool.sousId,
        ...initUserData,
      }),
    );
  }
};

// Actions
export const { setCastlesPublicData, setUserData, setPoolsUserData } = castlesSlice.actions;
export default castlesSlice.reducer;
