import { createSlice } from '@reduxjs/toolkit';
import statisticApi from '../../api/statisticApi';

const initialState = {
  statistic: undefined,
};

const statisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    setStatisticData: (state, action) => {
      state.statistic = action.payload;
    },
  },
});

export const fetchStatisticDataAsync = () => async (dispatch) => {
  try {
    const data = await statisticApi.getStatistic();
    dispatch(setStatisticData(data));
  } catch (error) {
    console.log(error);
  }
};

export const { setStatisticData } = statisticSlice.actions;

export default statisticSlice.reducer;
