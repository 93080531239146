import Tooltip from 'components/Tooltip/Tooltip';
import { FiHelpCircle } from 'react-icons/fi';
import {classNames} from "../../utils/styling";
const QuestionHelper = ({ text, iconClassName, ...props }) => {
  return (
    <Tooltip tooltip={text} {...props}>
      <FiHelpCircle className={classNames("ml-1", iconClassName)} size={props.size || 16} />
    </Tooltip>
  );
};

export default QuestionHelper;
