import BigNumber from 'bignumber.js';
import Dots from 'components/Loader/Dots';
import Tooltip from 'components/Tooltip/Tooltip';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getTokenName } from 'utils/tokenHelpers';
import {useAccount} from "wagmi";

const Stats = ({ pool, userDataLoaded }) => {
  const { address } = useAccount();

  const { stakedBalance, stakingTokenBalance } = pool.userData;

  const usdStakingBalance =
    stakingTokenBalance &&
    formatNumber(
      new BigNumber(getBalanceNumber(stakingTokenBalance, pool.stakingToken.decimals))
        .times(pool.stakingTokenPrice || 0)
        .toNumber(),
    );

  const usdStakedBalance =
    stakedBalance &&
    formatNumber(
      new BigNumber(getBalanceNumber(stakedBalance, pool.stakingToken.decimals))
        .times(pool.stakingTokenPrice || 0)
        .toNumber(),
    );

  const stakingNameDisplay = getTokenName(pool.token0, pool?.token1, pool.stakingToken);

  const maxCharacters = 15;

  return (
    <div>
      <div className="flex">
        <div className="font-bold text-white flex-1">
          <p>Token Balance:</p>
          <div className="flex">
            {address ? (
              userDataLoaded ? (
                <>
                  {getFullDisplayBalance(stakingTokenBalance, pool.stakingToken.decimals).length > maxCharacters ? (
                    <Tooltip
                      tooltip={
                        <>
                          {getFullDisplayBalance(stakingTokenBalance, pool.stakingToken.decimals)}
                          <span className="ml-1">{stakingNameDisplay}</span>
                        </>
                      }
                    >
                      {' ' +
                        getFullDisplayBalance(stakingTokenBalance, pool.stakingToken.decimals).slice(
                          0,
                          maxCharacters - 1,
                        ) +
                        '...'}
                    </Tooltip>
                  ) : (
                    getFullDisplayBalance(stakingTokenBalance, pool.stakingToken.decimals)
                  )}
                </>
              ) : (
                <Dots />
              )
            ) : (
              '???'
            )}{' '}
            <span className="ml-1">{stakingNameDisplay}</span>
          </div>
          <p className="text-sm mt-1">~{usdStakingBalance || '0'} USD</p>
        </div>
        <div className="font-bold text-white flex-1">
          <p>Your Stake:</p>
          <div className="flex">
            {address ? (
              userDataLoaded ? (
                <>
                  {getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals).length > maxCharacters ? (
                    <Tooltip
                      tooltip={
                        <>
                          {getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals)}
                          <span className="ml-1">{stakingNameDisplay}</span>
                        </>
                      }
                    >
                      {' ' +
                        getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals).slice(0, maxCharacters - 1) +
                        '...'}
                    </Tooltip>
                  ) : (
                    getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals)
                  )}
                </>
              ) : (
                <Dots />
              )
            ) : (
              '???'
            )}{' '}
            <span className="ml-1">{stakingNameDisplay}</span>
          </div>
          <p className="text-sm mt-1">~{usdStakedBalance || '0'} USD</p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
