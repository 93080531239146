import { useLocation, useNavigate } from 'react-router-dom';
import Search from 'views/Analytics/components/InfoSearch/InfoSearch';
import { classNames } from 'utils/styling';

const InfoNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const isInfo = pathname === '/analytics';
  const isPools = pathname === '/analytics/pairs';
  const isTokens = pathname === '/analytics/tokens';

  return (
    <div className="flex justify-between flex-wrap py-5">
      <div className="my-2">
        <div className="flex justify-center mx-auto text-white bg-black1 max-w-min	rounded-3xl py-1 px-2">
          <div
            className={classNames(
              'px-4 py-2 whitespace-nowrap',
              isInfo ? 'text-black bg-primary rounded-2xl' : 'cursor-pointer',
            )}
            onClick={() => navigate('/analytics')}
          >
            Overview
          </div>
          <div
            className={classNames(
              'px-4 py-2 whitespace-nowrap',
              isPools ? 'text-black bg-primary rounded-2xl' : 'cursor-pointer',
            )}
            onClick={() => navigate('/analytics/pairs')}
          >
            Pairs
          </div>
          <div
            className={classNames(
              'px-4 py-2 whitespace-nowrap',
              isTokens ? 'text-black bg-primary rounded-2xl' : 'cursor-pointer',
            )}
            onClick={() => navigate('/analytics/tokens')}
          >
            Tokens
          </div>
        </div>
      </div>
      <div className="my-2">
        <Search />
      </div>
    </div>
  );
};

export default InfoNav;
