import { getQueryFromObject, isAddress } from 'utils';

export const analyticsUrlApi = {
  getProtocolChartData: '/factories/data',
  getProtocolData: '/factories',
  getAllTransactions: (query) => `/transactions/all${getQueryFromObject(query)}`,
  getMintTransactions: (query) => `/transactions/mints${getQueryFromObject(query)}`,
  getBurnTransactions: (query) => `/transactions/burns${getQueryFromObject(query)}`,
  getSwapTransactions: (query) => `/transactions/swaps${getQueryFromObject(query)}`,
  getPairs: (query) => `/pairs${getQueryFromObject(query)}`,
  getPairChartData: (address) => `/pairs/${isAddress(address)}/data`,
  getPairChartPriceData: (query) => `/pairs/swap-chart${getQueryFromObject(query)}`,
  getPairData: (address) => `/pairs/${isAddress(address)}`,
  getTokens: (query) => `/tokens${getQueryFromObject(query)}`,
  getTokenChartData: (address) => `/tokens/${isAddress(address)}/data`,
  getTokenPriceChartData: (address) => `/tokens/${isAddress(address)}/hour`,
  getTokenData: (address) => `/tokens/${isAddress(address)}`,
};
