import PropTypes from 'prop-types';
import { classNames } from 'utils/styling';

const ContainerPage = ({ children, className }) => {
  return (
    <div className={classNames('container mx-auto px-3 mb-60 md:mb-96 lg:mb-96 lg:pb-60', className)}>{children}</div>
  );
};

ContainerPage.propTypes = {
  children: PropTypes.node,
};

export default ContainerPage;
