import BigNumber from 'bignumber.js';
import { UINT256_MAX } from 'config';
import { BIG_TEN } from 'utils/bigNumber';

export const approveVaultWithEthers = async (tokenContract, contractAddress) => {
  const tx = await tokenContract.approve(contractAddress, UINT256_MAX);
  await tx.wait();
  return tx.hash;
};

export const stakeVault = async (vaultContract, amount, decimals = 18) => {
  const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString();
  const tx = await vaultContract.deposit(value);
  await tx.wait();
  return tx.hash;
};

export const stakeVaultV2 = async (vaultContract, amount, decimals = 18) => {
  const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString();
  const tx = await vaultContract.deposit(value, true);
  await tx.wait();
  return tx.hash;
};

export const unstakeVault = async (vaultContract, amount, decimals = 18) => {
  const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString();
  const tx = await vaultContract.withdraw(value);
  await tx.wait();
  return tx.hash;
};

export const unstakeVaultV2 = async (vaultContract, amount, decimals = 18) => {
  const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString();
  const tx = await vaultContract.withdraw(value, true);
  await tx.wait();
  return tx.hash;
};

export const compoundVault = async (vaultContract) => {
  const tx = await vaultContract.executeStrategy();
  await tx.wait();
  return tx.hash;
};

export const refresh = async (refreshContract) => {
  const tx = await refreshContract.refresh();
  await tx.wait();
  return tx.hash;
};
