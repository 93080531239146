import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalFooter from 'components/Modal/ModalFooter';
import { showToastError } from 'components/CustomToast/CustomToast';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const ModalConfirm = ({ open, toggle, onSubmit, isChest, nft }) => {
  const [pendingTx, setPendingTx] = useState(false);

  const handleSubmit = async () => {
    try {
      setPendingTx(true);
      await onSubmit();
      setPendingTx(false);
    } catch (error) {
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
    }
  };

  return (
    <Modal open={open} onClose={toggle}>
      <ModalTitle onClose={toggle}>Warning</ModalTitle>
      <div className="p-4 text-center">
        <p className="mb-1">Are you sure to burn this NFT? You can not undo this action.</p>
        <p>
          You will get {isChest ? nft?.lootChestValue : nft?.amountBurnReceive}{' '}
          {isChest ? nft?.tokenBurnChest?.symbol : nft?.tokenBurn?.symbol} token and can use it to farm DFL on Defily
          Farm.
        </p>
      </div>
      <ModalFooter>
        <div className="flex justify-center w-full">
          <ButtonCustom disabled={pendingTx} isLoading={pendingTx} onClick={handleSubmit}>
            Confirm
          </ButtonCustom>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;
