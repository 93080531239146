import BigNumber from 'bignumber.js';
import ActionTool from 'components/ActionTool/ActionTool';
import Back from 'components/Back/Back';
import ContainerPages from 'components/Container/ContainerV2';
import TabsFinished from 'components/Tabs/TabsFinished';
import { FARMS_VIEW_DEFAULT, sortListFarms, sortTypeFarms } from 'constants/farms';
import { TABS, TYPE_VIEW } from 'constants/index';
import tokens from 'constants/tokens';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useDebounce from 'hooks/useDebounce';
import { useMemo, useState } from 'react';
import { isArchivedPid } from 'store/farms/helpers';
import { useFarms } from 'store/farms/hook';
import { updateUserEarningsBalance } from 'store/farms/index';
import { useUserStakedOnly, useUserViewMode } from 'store/user/hooks/index';
import { getTokenName } from 'utils/tokenHelpers';
import FarmsCard from 'views/Farms/components/FarmsCard/FarmsCard';
import HarvestCard from 'views/Farms/components/FarmsCard/HarvestCard';
import { MASTERCHEF_ADDRESS } from 'constants/contracts';
import FarmsTable from 'views/Farms/components/FarmsTable/FarmsTable';

const Farms = () => {
  const { chainId } = useActiveWeb3React();
  const { farms, userDataLoaded } = useFarms();
  const [tab, setTab] = useState(TABS.live);
  const activeFarms = useMemo(() => farms.filter((farm) => !isArchivedPid(farm.pid, chainId)), [chainId, farms]);
  const [sortBy, setSortBy] = useState(sortTypeFarms.hot);
  const [search, setSearch] = useState('');
  const debouncedQuery = useDebounce(search, 300);

  const [viewMode, setViewMode] = useUserViewMode();
  const typeView = viewMode?.farms || FARMS_VIEW_DEFAULT;

  const farmsActive = useMemo(() => {
    if (tab === TABS.live) {
      return activeFarms.filter((pool) => !pool.isFinished);
    }

    return activeFarms.filter((pool) => pool.isFinished);
  }, [activeFarms, tab]);

  const handleChangeTab = (value) => {
    if (value !== tab) {
      setTab(value);
    }
  };

  const [stakedOnly, setStakedOnly] = useUserStakedOnly();

  const isStakedOnly = stakedOnly?.farms;

  const farmsWithStakedBalance = useMemo(() => {
    return farmsActive.filter((farm) => new BigNumber(farm.userData.stakedBalance).isGreaterThan(0));
  }, [farmsActive]);

  const farmsDisplay = useMemo(() => {
    if (isStakedOnly) return farmsWithStakedBalance;

    return farmsActive;
  }, [farmsActive, farmsWithStakedBalance, isStakedOnly]);

  const filterDataBySearch = useMemo(() => {
    return farmsDisplay.filter((farm) => {
      const nameDisplay = getTokenName(farm.token0, farm.token1, farm.stakingToken);
      return nameDisplay.toLowerCase().includes(debouncedQuery.toLowerCase());
    });
  }, [debouncedQuery, farmsDisplay]);

  const farmsSort = useMemo(() => {
    const farms = [...filterDataBySearch];

    if (sortBy === sortTypeFarms.hot) return farms.sort((farmA, farmB) => farmB.mul - farmA.mul);
    return farms.sort((farmA, farmB) => {
      if (sortBy === sortTypeFarms.apr) {
        return farmB?.apr?.yearlyAPR - farmA?.apr?.yearlyAPR;
      }
      if (sortBy === sortTypeFarms.tvl) {
        return farmB.stakedTvl - farmA.stakedTvl;
      }
    });
  }, [filterDataBySearch, sortBy]);

  return (
    <>
      <Back />
      {/*<Banner bg="url(/images/banner-earn.png)">*/}
      {/*  <HeaderDetail title="Select Your Fav Farms On Defily">*/}
      {/*    <p className="text-white text-xl">Earn tokens by staking LP tokens</p>*/}
      {/*  </HeaderDetail>*/}
      {/*</Banner>*/}
      {/*{chainId === ChainId.ONUS && <StartCountdown />}*/}
      <ContainerPages>
        <div className="container mx-auto max-w-6xl mt-10">
          <ActionTool
            className="mb-4"
            isStakedOnly={isStakedOnly}
            setIsStakedOnly={() => setStakedOnly('farms')}
            sortList={sortListFarms}
            sortBy={sortBy}
            setSearch={setSearch}
            setSortBy={setSortBy}
            typeView={typeView}
            setTypeView={(view) => setViewMode('farms', view)}
          />
          <HarvestCard
            masterChefAddress={MASTERCHEF_ADDRESS[chainId]}
            farmsWithStakedBalance={farmsWithStakedBalance}
            earningToken={tokens.mCake}
            earningTokenPrice={activeFarms[0]?.earningTokenPrice || 0}
            userDataLoaded={userDataLoaded}
            updateHarvestCallback={updateUserEarningsBalance}
          />
          {[ChainId.BSC, ChainId.KAI].includes(chainId) && <TabsFinished tab={tab} onChangeTab={handleChangeTab} />}
          {tab === TABS.finished && (
            <p className="mb-2 text-rose-700 text-xl">
              These farms are no longer distributing rewards. Please Withdraw your tokens.
            </p>
          )}
          {typeView === TYPE_VIEW.grid ? (
            <FarmsCard farms={farmsSort} userDataLoaded={userDataLoaded} />
          ) : (
            <FarmsTable farms={farmsSort} userDataLoaded={userDataLoaded} />
          )}
        </div>
      </ContainerPages>
    </>
  );
};

export default Farms;
