import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import PropTypes from 'prop-types';
import { usePrices } from 'store/prices/hook';
import { getParameterCaseInsensitive } from 'utils/index';
import { getTokenName } from 'utils/tokenHelpers';
import Deposit from 'views/Vaults/components/VaultsTable/Table/ActionPanel/Deposit';
import ManualCompoundCard from 'views/Vaults/components/VaultsTable/Table/ActionPanel/ManualCompoundCard';
import Withdraw from 'views/Vaults/components/VaultsTable/Table/ActionPanel/Withdraw';

const ActionPanel = ({ vault, userDataLoaded }) => {
  const { chainId } = useActiveWeb3React();
  const symbol = getTokenName(vault?.token0, vault?.token1, vault.stakingToken);
  const prices = usePrices();
  const priceStakingToken = getParameterCaseInsensitive(prices, vault.stakingToken.address[chainId]);
  const { userData } = vault;

  return (
    <div className="px-2 py-1 sm:px-6 sm:py-4">
      <div className="block lg:flex flex-wrap sm:space-x-4 space-y-2 sm:space-y-0">
        {!vault.isFinished && (
          <div className="flex-1">
            <Deposit
              symbol={symbol}
              priceStakingToken={priceStakingToken}
              decimals={vault.stakingToken.decimals}
              userData={userData}
              vault={vault}
              userDataLoaded={userDataLoaded}
            />
          </div>
        )}
        <div className="flex-1">
          <Withdraw
            symbol={symbol}
            priceStakingToken={priceStakingToken}
            decimals={vault.stakingToken.decimals}
            userData={userData}
            vault={vault}
            userDataLoaded={userDataLoaded}
          />
        </div>
        <div className="flex-1">
          <ManualCompoundCard vault={vault} userDataLoaded={userDataLoaded} />
        </div>
      </div>
      <div className="text-white p-3">
        {chainId === ChainId.KAI ? (
          <>
            <p>What does this Vault do?</p>
            <p>Average 30 minutes or faster, the Vault:</p>
            <ul>
              <li>1. Auto-harvest rewards</li>
              <li>2. Charge {vault.fees}% buyback fee on harvest rewards to buyback DFL and burn</li>
              <li>3. {vault.docs || 'Auto-compound back into your staked value'}</li>
              <li>4. Every deposit, withdraw of stakers will also compound for the Vault</li>
              <li>5. You will receive Defily Dungeons Token (DDT) as receipt for your staking</li>
            </ul>
          </>
        ) : (
          <>
            <p>What does this Vault do?</p>
            <p>Average 30 minutes or faster, the Vault:</p>
            <ul>
              <li>1. Auto-harvest rewards</li>
              <li>
                2. Charge {vault.fees}% on harvest rewards to buyback DFL and burn, {vault.feesWithdraw || 1}% on
                rewards to pay for the strategy developer
              </li>
              <li>3. Auto-compound back into your staked value</li>
              {chainId !== ChainId.ONUS && (
                <li>4. Stakers will receive DeFily Dungeons Token (DDT) as receipt for your staking</li>
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

ActionPanel.propTypes = {
  vault: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
};

export default ActionPanel;
