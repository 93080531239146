import BigNumber from 'bignumber.js';
import Dots from 'components/Loader/Dots';
import Value from 'components/Value/Value';
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import {useAccount} from "wagmi";

const YourStakedCell = ({ pool, userDataLoaded }) => {
  const { address } = useAccount();
  const { stakedBalance } = pool.userData;

  const usdTokenStaking = stakedBalance
    ? new BigNumber(getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals))
        .times(pool.stakingTokenPrice || 0)
        .toNumber()
    : 0;

  return (
    <div>
      <p>Your Stake</p>
      {address ? (
        userDataLoaded ? (
          <>
            <Value
              className="font-bold"
              value={address ? getBalanceNumber(stakedBalance, pool.stakingToken.decimals) : 0}
            />
            <Value prefix="~" className="text-sm" value={address ? usdTokenStaking : 0} decimals={2} unit="USD" />
          </>
        ) : (
          <Dots className="font-bold" />
        )
      ) : (
        <>
          <p className="font-bold">???</p>
          <Value prefix="~" className="text-sm" value={0} decimals={0} unit="USD" />
        </>
      )}
    </div>
  );
};

export default YourStakedCell;
