import BigNumber from 'bignumber.js';
import { BIG_TEN } from '../bigNumber';

export const mintNFT = async (contract, amount, price, decimals, account, isMainCoin) => {
  const params = isMainCoin ? { value: new BigNumber(amount * price).times(BIG_TEN.pow(decimals)).toString() } : {};
  const tx = await contract.claim(new BigNumber(amount).toString(), params);
  await tx.wait();
  return tx.hash;
};

export const claimTokenNFT = async (contract, id) => {
  const tx = await contract.burn(id.toString());
  await tx.wait();
  return tx.hash;
};

export const sendNFT = async (contract, toAddress, id, account) => {
  const tx = await contract.transferFrom(account, toAddress, id);
  await tx.wait();
  return tx.hash;
};
