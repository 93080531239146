import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import InputWithButton from 'components/NumericalInput/InputWithButton';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useWithdraw from 'hooks/useWithdraw';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchVaultUserDataAsync, fetchVaultV2UserDataAsync } from 'store/vaults/index';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const Withdraw = ({ decimals, symbol, priceStakingToken, userData, vault, userDataLoaded }) => {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const { stakedBalance } = userData;
  const { onWithdraw } = useWithdraw(vault.contractAddress);

  const usdValue = value && formatNumber(new BigNumber(value).times(priceStakingToken || 0).toNumber());
  const usdBalance =
    stakedBalance &&
    formatNumber(new BigNumber(getBalanceNumber(stakedBalance, decimals)).times(priceStakingToken || 0).toNumber());

  const handleTypeInput = (valueInput) => {
    setValue(valueInput);
  };

  const isInsufficientBalance = useMemo(() => {
    return new BigNumber(value).isGreaterThan(getFullDisplayBalance(stakedBalance, decimals));
  }, [value, stakedBalance, decimals]);

  const handleMaxInput = useCallback(() => {
    setValue(getFullDisplayBalance(stakedBalance, decimals));
  }, [decimals, stakedBalance]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (percent === 100) {
        handleMaxInput();
      } else {
        setValue(getFullDisplayBalance(new BigNumber(stakedBalance).times(percent).dividedBy(100), decimals, decimals));
      }
    },
    [decimals, handleMaxInput, stakedBalance],
  );

  const handleWithdraw = useCallback(async () => {
    try {
      setPendingTx(true);
      await onWithdraw(value, decimals);
      dispatch(fetchVaultV2UserDataAsync(account, chainId));
      dispatch(fetchVaultUserDataAsync(account, chainId));
      showToastSuccess('Withdrawal Successful!');
      setValue('');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [account, decimals, dispatch, onWithdraw, value, chainId]);

  const [q0User, q1User] = getLpBreakdown(
    stakedBalance.div(BIG_TEN.pow(vault.stakingToken.decimals)).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  return (
    <div>
      <div>
        <div className="flex font-bold">
          <p>Your balance:</p>
          <div className="ml-1">
            <p>
              {account ? (
                userDataLoaded ? (
                  getFullDisplayBalance(stakedBalance, decimals)
                ) : (
                  <Dots className="inline-block" />
                )
              ) : (
                '???'
              )}{' '}
              {symbol}
            </p>
            {vault?.token1 && vault.farmStaked && (
              <p>
                {stakedBalance.gt(0) && (
                  <>
                    ({formatNumber(getBalanceNumber(q0User, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                    {vault.farmStaked.token0.symbol} +{' '}
                    {formatNumber(getBalanceNumber(q1User, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                    {vault.farmStaked.token1.symbol})
                  </>
                )}
              </p>
            )}
            <p className="text-sm mt-1">~{usdBalance || '0'} USD</p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex-1">
          <InputWithButton value={value} onUserInput={handleTypeInput} btnOnClick={handleMaxInput} />
          <p className="text-right text-sm mt-1">~{usdValue || '0'} USD</p>
          <PercentPicker isNumber={true} className="text-black" onChangePercentInput={handlePercentInput} />
        </div>
        <div className="mt-2 text-black">
          {account ? (
            userDataLoaded ? (
              <ButtonCustom
                className="w-full"
                disabled={pendingTx || !new BigNumber(value).isGreaterThan(0) || isInsufficientBalance}
                isLoading={pendingTx}
                onClick={handleWithdraw}
              >
                {isInsufficientBalance ? `Insufficient balance` : pendingTx ? 'Pending Confirmation' : 'Withdraw'}
              </ButtonCustom>
            ) : (
              <ButtonCustom className="w-full" isLoading>
                Loading <Dots />
              </ButtonCustom>
            )
          ) : (
            <UnlockButton />
          )}
        </div>
      </div>
    </div>
  );
};

Withdraw.propTypes = {
  symbol: PropTypes.string.isRequired,
  decimals: PropTypes.number,
  priceStakingToken: PropTypes.number,
  userData: PropTypes.object.isRequired,
  vault: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
};

export default Withdraw;
