import { createSlice } from '@reduxjs/toolkit';
import stakeConfig from 'constants/stake';
import { ChainId } from 'ezcake-v2-sdk';
import fetchDataLockPool from 'store/stake/fetchDataLockPool';
import fetchLockPoolDataUser from 'store/stake/fetchDataUser';

const initialState = {
  data: stakeConfig,
  userDataLoaded: {
    [ChainId.KAI]: false,
    [ChainId.BSC]: false,
    [ChainId.HARMONY]: false,
  },
};

export const stakeSlice = createSlice({
  name: 'stake',
  initialState,
  reducers: {
    setData: (state, action) => {
      const { liveData, chainId } = action.payload;
      state.data[chainId] = { ...state.data[chainId], ...liveData };
    },
    setUserData: (state, action) => {
      const { userData, chainId } = action.payload;
      state.data[chainId] = { ...state.data[chainId], userData: userData };
      state.userDataLoaded[chainId] = true;
    },
    updateUserData: (state, action) => {
      const { field, value, chainId } = action.payload;
      state.data[chainId] = {
        ...state.data[chainId],
        userData: { ...state.data[chainId].userData, [field]: value },
      };
    },
  },
});

// async action

export const fetchLockPoolDataAsync = (chainId) => async (dispatch) => {
  try {
    const liveData = await fetchDataLockPool(stakeConfig[chainId], chainId);
    dispatch(
      setData({
        liveData,
        chainId,
      }),
    );
  } catch (error) {
    console.log(error);
  }
};

export const fetchLockPoolUserDataAsync = (account, chainId) => async (dispatch) => {
  try {
    const userData = await fetchLockPoolDataUser(stakeConfig[chainId], account, chainId);

    dispatch(
      setUserData({
        userData: userData,
        chainId,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

// Actions
export const { setData, setUserData, updateUserData } = stakeSlice.actions;
export default stakeSlice.reducer;
