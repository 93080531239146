import { JSBI, Percent } from 'ezcake-v2-sdk';
import { classNames } from 'utils/styling';

const PercentPicker = ({ onChangePercentInput, className, isNumber = false, isDisabled }) => {
  return (
    <div className={classNames(className, 'w-full')}>
      <div className="flex mt-2 space-x-2 text-sm-md justify-end">
        <button
          disabled={isDisabled}
          className={`${isDisabled ? 'bg-black8 text-gray-1' : 'bg-primary text-black1'} rounded px-2`}
          onClick={() => onChangePercentInput(isNumber ? 25 : new Percent(JSBI.BigInt(2500), JSBI.BigInt(10000)))}
        >
          25%
        </button>
        <button
          disabled={isDisabled}
          className={`${isDisabled ? 'bg-black8 text-gray-1' : 'bg-primary text-black1'} rounded px-2`}
          onClick={() => onChangePercentInput(isNumber ? 50 : new Percent(JSBI.BigInt(5000), JSBI.BigInt(10000)))}
        >
          50%
        </button>
        <button
          disabled={isDisabled}
          className={`${isDisabled ? 'bg-black8 text-gray-1' : 'bg-primary text-black1'} rounded px-2`}
          onClick={() => onChangePercentInput(isNumber ? 75 : new Percent(JSBI.BigInt(7500), JSBI.BigInt(10000)))}
        >
          75%
        </button>
        <button
          disabled={isDisabled}
          className={`${isDisabled ? 'bg-black8 text-gray-1' : 'bg-primary text-black1'} rounded px-2`}
          onClick={() => onChangePercentInput(isNumber ? 100 : new Percent('100'))}
        >
          100%
        </button>
      </div>
    </div>
  );
};

export default PercentPicker;
