import Dots from 'components/Loader/Dots';
import DoubleLogo from 'components/Logo/DoubleLogo';
import ModalApyCalculatorVault from 'components/Modal/ModalApyCalculatorVault';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { useState } from 'react';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber } from 'utils/formatBalance';
import { PLATFORM_LINK } from 'utils/explorer';

const CardApy = ({ vault }) => {
  const [openRoi, setOpenRoi] = useState(false);

  const handleOpenModalApy = () => setOpenRoi((prevState) => !prevState);

  const [q0Vault, q1Vault] = getLpBreakdown(
    vault?.totalStaked.div(BIG_TEN.pow(vault.stakingToken.decimals)).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  return (
    <div className="bg-black1 rounded-2xl py-3 px-3 sm:px-5 flex flex-wrap justify-between">
      <div className="text-center sm:text-left text-white">
        <p>Farm Platform</p>
        <Tooltip
          tooltip={
            <div className="whitespace-nowrap">
              <p>{vault.platform}</p>
            </div>
          }
        >
          <a className="cursor-pointer" href={PLATFORM_LINK[vault.platform]} target="_blank" rel="noreferrer">
            <DoubleLogo size={40} src0={`/platform/${vault.platform.toLowerCase()}.png`} alt0={vault.platform} />
          </a>
        </Tooltip>
      </div>
      <div className="text-right">
        {openRoi && (
          <ModalApyCalculatorVault onClose={handleOpenModalApy} open={openRoi} apy={vault.apy} data={vault} />
        )}
        <p className="text-white">Vault Assets</p>
        {vault.stakedTvl ? (
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap text-left">
                <p>Total Deposits</p>
                <p>
                  {formatNumber(getBalanceNumber(vault?.totalStaked, vault.stakingToken.decimals))}{' '}
                  {vault.stakingToken.symbol}
                </p>
                {vault?.token1 && vault?.farmStaked && (
                  <p>
                    {vault?.totalStaked.gt(0) && (
                      <>
                        {formatNumber(getBalanceNumber(q0Vault, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                        {vault.farmStaked.token0.symbol} +{' '}
                        {formatNumber(getBalanceNumber(q1Vault, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                        {vault.farmStaked.token1.symbol}
                      </>
                    )}
                  </p>
                )}
              </div>
            }
          >
            <Value className="font-bold text-xl text-primary" prefix="$" value={vault.stakedTvl} decimals={0} />
          </Tooltip>
        ) : (
          <Dots className="font-bold text-xl text-primary" />
        )}
      </div>
    </div>
  );
};

export default CardApy;
