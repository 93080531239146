import LogoSwap from 'components/LogoSwap/LogoSwap';
import { Token } from 'ezcake-v2-sdk';
import { useMemo } from 'react';
import { WrappedTokenInfo } from 'hooks/Tokens';

export default function CurrencyLogo({ currency, size = '22px', style, className }) {
  const srcs = useMemo(() => {
    if (currency?.tokenInfo?.logoURI) {
      return [currency?.tokenInfo?.logoURI];
    }
    return [`/tokens/${currency?.symbol?.toLowerCase()}.png`];
  }, [currency]);

  return (
    <LogoSwap size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} className={className} />
  );
}
