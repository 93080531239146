import React, { useEffect, useState } from 'react';
import Back from 'components/Back/Back';
import Card from 'components/Card/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { useDragon, useFetchDetailNFT, useFetchNFTsPublicData, useNFT } from '../../store/NFTs/hook';
import Loader from 'components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { fetchDetailNFTAsync, setDragon } from 'store/NFTs';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useClaimNFT, useSendNFT } from 'hooks/useMint';
import { showToastSuccess } from 'components/CustomToast/CustomToast';
import ModalConfirm from './components/ModalConfirm';
import ModalSendNFT from './components/ModalSendNFT';
import { ICON_CHAIN_URL } from 'constants';
import { ethers } from 'ethers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const DetailNFT = () => {
  useFetchNFTsPublicData();
  useFetchDetailNFT();
  // useFetchNFTData()
  const { chainId } = useActiveWeb3React();
  const { slug, id } = useParams();
  const nft = useNFT(slug, chainId);
  const navigate = useNavigate();
  const dragon = useDragon();
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const { onClaim } = useClaimNFT(nft?.poolAddress);
  const { onSend } = useSendNFT(nft?.poolAddress);
  const [pendingTxClaim, setPendingTxClaim] = useState(false);
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [pendingTxSend, setPendingTxSend] = useState(false);
  const [openModalSend, setOpenModalSend] = useState(false);
  const handleClaim = async () => {
    try {
      setPendingTxClaim(true);
      await onClaim(dragon?.token_id);
      await dispatch(fetchDetailNFTAsync(nft, id));
      showToastSuccess('Success', 'Claim token successfully!');
      setOpenModalWarning(false);
      setPendingTxClaim(false);
      navigate(-1);
    } catch (error) {
      setPendingTxClaim(false);
      throw error;
    }
  };

  const handleSend = async (address) => {
    try {
      const addressSend = ethers.utils.getAddress(address);
      setPendingTxSend(true);
      await onSend(addressSend, dragon?.token_id);
      await dispatch(fetchDetailNFTAsync(nft, id));
      showToastSuccess('Success', 'Send NFT successfully!');
      setOpenModalSend(false);
      setPendingTxSend(false);
    } catch (error) {
      setPendingTxSend(false);
      throw error;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setDragon(undefined));
    };
  }, []);
  useEffect(() => {
    setOpenModalWarning(false);
  }, [account, dragon?.token_id]);

  return (
    <>
      <Back />
      <div className="p-4 sm:p-6 mb-20" style={{ minHeight: '70vh' }}>
        <div className="container max-w-screen-xl mx-auto">
          <div>
            <div className="flex justify-center mt-10">
              <div>
                {typeof dragon === 'undefined' ? (
                  <div className="mt-5">
                    <Loader className="border-t-4 h-20 w-20 mx-auto" />
                  </div>
                ) : dragon ? (
                  <Card
                    className="overflow-hidden box-shadow-nft-card max-w-sm w-full"
                    style={{
                      minHeight: 100,
                    }}
                  >
                    <div className="relative overflow-hidden">
                      <img
                        src={
                          Number(dragon?.token_id) && Number(dragon?.token_id) % nft?.lootChestNumber === 0
                            ? '/images/loot-chest.jpg'
                            : dragon?.image
                        }
                      />
                      <div className="absolute right-2 top-2">
                        <img
                          src={ICON_CHAIN_URL[nft?.chainId]}
                          style={{
                            width: 30,
                          }}
                          className="mr-2"
                        />
                      </div>
                    </div>
                    <div className="py-4">
                      <p className="text-white text-center text-lg">#{dragon?.token_id}</p>
                      {dragon?.description ? <p className="text-white text-center">{dragon?.description}</p> : null}
                      {dragon?.attributes?.map((item, index) => (
                        <h3 className="text-white text-center" key={index}>
                          <span className="capitalize">{item?.trait_type}</span>:{' '}
                          <span className="capitalize">{item?.value}</span>
                        </h3>
                      ))}
                      {account && account === dragon?.owner ? (
                        <div className="flex justify-center mt-3">
                          {nft?.tokenBurn ||
                          (Number(dragon?.token_id) && Number(dragon?.token_id) % nft?.lootChestNumber === 0) ? (
                            <ButtonCustom
                              className="mx-2"
                              disabled={pendingTxClaim || pendingTxSend}
                              isLoading={pendingTxClaim}
                              onClick={() => setOpenModalWarning(true)}
                            >
                              {Number(dragon?.token_id) && Number(dragon?.token_id) % nft?.lootChestNumber === 0
                                ? 'Claim'
                                : 'Burn'}
                            </ButtonCustom>
                          ) : null}

                          <ButtonCustom
                            className="mx-2"
                            disabled={pendingTxSend || pendingTxClaim}
                            isLoading={pendingTxSend}
                            onClick={() => setOpenModalSend(true)}
                          >
                            Send
                          </ButtonCustom>
                        </div>
                      ) : null}
                    </div>
                  </Card>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModalWarning ? (
        <ModalConfirm
          open={openModalWarning}
          nft={nft}
          isChest={Number(dragon?.token_id) && Number(dragon?.token_id) % nft?.lootChestNumber === 0}
          toggle={() => setOpenModalWarning(false)}
          onSubmit={handleClaim}
        />
      ) : null}
      {openModalSend ? (
        <ModalSendNFT open={openModalSend} nft={dragon} toggle={() => setOpenModalSend(false)} onConfirm={handleSend} />
      ) : null}
    </>
  );
};

export default DetailNFT;
