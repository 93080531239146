import Tooltip from 'components/Tooltip/Tooltip';
import PropTypes from 'prop-types';

const FeesCell = ({ vault }) => {
  const { fees } = vault;

  return (
    <>
      <Tooltip
        tooltip={
          <div>
            <p>
              {fees}% of harvest rewards will be used to buyback DFL and burn to
              0x000000000000000000000000000000000000dEaD
            </p>
          </div>
        }
      >
        <div>
          <p>{fees}%</p>
        </div>
      </Tooltip>
      <Tooltip tooltip={<>0.09% Withdrawal Fee on holdings will be sent back to Vault</>}>
        <div>
          <p>0.09%</p>
        </div>
      </Tooltip>
    </>
  );
};

FeesCell.propTypes = {
  vault: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default FeesCell;
