import { showToastTx } from 'components/CustomToast/CustomToast';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useIDOLaunchpadContract, useIDOLinearContract, useVaultContract } from 'hooks/useContract';
import { useCallback } from 'react';
import { stakeVault, stakeVaultV2 } from 'utils/calls/vaults';
import { investAltCoin, investMainCoin } from 'utils/calls/IDOs';
import { ChainId } from 'ezcake-v2-sdk';

const useDeposit = (vaultAddress) => {
  const { chainId } = useActiveWeb3React();
  const vaultContract = useVaultContract(vaultAddress);

  const handleDeposit = useCallback(
    async (amount, decimals) => {
      const txHash =
        chainId === ChainId.ONUS
          ? await stakeVaultV2(vaultContract, amount, decimals)
          : await stakeVault(vaultContract, amount, decimals);

      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, vaultContract],
  );

  return { onDeposit: handleDeposit };
};

export const useInvestIdo = (idoAddress) => {
  const { chainId } = useActiveWeb3React();
  const contract = useIDOLaunchpadContract(idoAddress);
  const contractLinear = useIDOLinearContract(idoAddress);
  const handleInvest = useCallback(
    async (amount, decimals, isMainCoin = true) => {
      let txHash;
      if (isMainCoin) {
        txHash = await investMainCoin(contract, amount, decimals);
      } else {
        txHash = await investAltCoin(contractLinear, amount, decimals);
      }
      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, contract, contractLinear],
  );

  return { onInvest: handleInvest };
};

export default useDeposit;
