import { MCAKE_ADDRESS } from 'constants/contracts';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { usePrices } from 'store/prices/hook';
import { getParameterCaseInsensitive } from 'utils/index';
import tokens from 'constants/tokens';

export const useDflPrice = () => {
  const { chainId } = useActiveWeb3React();
  const prices = usePrices();

  return getParameterCaseInsensitive(prices, MCAKE_ADDRESS[chainId]);
};

export const usePriceByTokenAddress = (address) => {
  const prices = usePrices();
  return getParameterCaseInsensitive(prices, address);
};

export const useKaiPrice = () => {
  const prices = usePrices();
  const { chainId } = useActiveWeb3React();

  return getParameterCaseInsensitive(prices, tokens.wkai.address[chainId]);
};
