import { useCallback, useMemo, useState } from 'react';
import { useAllTokens, useIsLoadedTokenDefault } from 'hooks/Tokens';
import { Token } from 'ezcake-v2-sdk';

const useWarningImport = (currencyIn, currencyOut) => {
  const [dismissTokenWarning, setDismissTokenWarning] = useState(false);

  const isLoadedTokenDefault = useIsLoadedTokenDefault();

  const urlLoadedTokens = useMemo(
    () => [currencyIn, currencyOut]?.filter((c) => c instanceof Token) ?? [],
    [currencyIn, currencyOut],
  );

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens();

  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token) => {
      return !(token.address in defaultTokens);
    });

  const isShowModalImportToken = isLoadedTokenDefault && importTokensNotInDefault.length > 0 && !dismissTokenWarning;

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  return { importTokensNotInDefault, isShowModalImportToken, onDismissTokenWarning: handleDismissTokenWarning };
};

export default useWarningImport;
