import Logo from 'components/Logo/Logo';
import Tooltip from 'components/Tooltip/Tooltip';
import { formatNumber } from 'utils/formatBalance';

const RewardCell = ({ earningToken, farm }) => {
  return (
    <div>
      <p>Reward</p>
      <Tooltip
        tooltip={
          <div className="whitespace-nowrap">
            <p>{earningToken.symbol} Price</p>
            <p>{formatNumber(farm?.earningTokenPrice ?? 0, 2, 4)}$</p>
          </div>
        }
      >
        <div>
          <Logo size={30} src={`/tokens/${earningToken.symbol.toLowerCase()}.png`} alt="token logo" />
        </div>
      </Tooltip>
    </div>
  );
};

export default RewardCell;
