import Tooltip from 'components/Tooltip/Tooltip';
import { AVERAGE_BLOCK_TIME } from 'config/index';
import { CASTLE_TAGS } from 'constants/index';
import { useMemo } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { FiClock } from 'react-icons/fi';
import { formatNumber } from 'utils/formatBalance';
import { getPoolBlockInfo } from 'views/Castles/helpers';
import { useCurrentBlock } from 'store/block/hooks';
import {useChainId} from "wagmi";

const CountdownCell = ({ pool }) => {
  const chainId = useChainId();
  const isIfo = pool.tags.includes(CASTLE_TAGS.ifo);

  const currentBlock = useCurrentBlock();
  const {
    shouldShowBlockCountdown,
    blocksUntilStart,
    blocksRemaining,
    hasPoolStarted,
    blocksToDisplay,
    durationDisplay,
  } = getPoolBlockInfo(pool, currentBlock, chainId);

  const timer = useMemo(() => {
    return blocksToDisplay * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksToDisplay]);

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null;

    return (
      <>
        {days > 0 ? `${zeroPad(days)}d-` : ''}
        {zeroPad(hours)}h-{zeroPad(minutes)}m-{zeroPad(seconds)}s
      </>
    );
  };

  if (pool.isFinished) return null;

  return (
    <>
      {shouldShowBlockCountdown && (
        <Tooltip
          tooltip={
            <div className="whitespace-nowrap">
              <p>~{formatNumber(blocksToDisplay, 0, 0)} Blocks</p>
            </div>
          }
        >
          <div>
            <p>
              {hasPoolStarted
                ? `${isIfo ? 'Distribution ends in' : 'Rewards end in'}`
                : `${isIfo ? 'Distribution starts in' : 'Rewards start in'}`}
            </p>
            <div className="flex items-center">
              {(blocksRemaining || blocksUntilStart) && currentBlock ? (
                <Countdown zeroPadTime={2} date={timer} renderer={renderCountdown} />
              ) : (
                '...'
              )}
              <FiClock className="ml-1" size={18} />
            </div>
          </div>
        </Tooltip>
      )}
      <div className="flex items-center mb-1 text-white">
        <p>{isIfo ? 'Distribution Period:' : 'Duration:'}</p>
        <p className="ml-1">{durationDisplay ? durationDisplay : '...'} days</p>
      </div>
    </>
  );
};

export default CountdownCell;
