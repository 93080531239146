import React from 'react';
import { SUPPORTED_NETWORKS } from 'connectors';
import { SUPPORTED_WALLETS } from 'constants';
import { showToastError } from 'components/CustomToast/CustomToast';
import { useWeb3React } from '@web3-react/core';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const SwitchNetworkButton = ({ chainId }) => {
  const { activate } = useWeb3React();

  const handleChangeNetwork = async () => {
    try {
      const params = SUPPORTED_NETWORKS[chainId];

      const provider = window.ethereum;
      if (provider) {
        await provider?.request({
          method: 'wallet_addEthereumChain',
          params: [params],
        });

        await activate(SUPPORTED_WALLETS.METAMASK.connector);
      }
    } catch (e) {
      showToastError('Failed to switch networks');
    }
  };

  return (
    <ButtonCustom onClick={handleChangeNetwork} className="w-full">
      Switch network
    </ButtonCustom>
  );
};

export default SwitchNetworkButton;
