import BigNumber from 'bignumber.js';
import erc20 from 'config/abi/erc20.json';
import UNIV2PairAbi from 'config/abi/lpToken.json';
import { DEFAULT_TOKEN_DECIMAL } from 'config/index';
import { ChainId } from 'ezcake-v2-sdk';
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber';
import { getParameterCaseInsensitive } from 'utils/index';
import multicall from 'utils/multicall';
import tokens from 'constants/tokens';

export const transformUserData = (userData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    earnings: userData ? new BigNumber(userData.earnings) : BIG_ZERO,
    unlockTimestamp: userData ? new BigNumber(userData?.unlockTimestamp || 0) : BIG_ZERO,
  };
};

export async function getStoredToken(type, tokenAddress, masterChefAddress, chainId) {
  switch (type) {
    case 'uniswap':
      return await getPool(UNIV2PairAbi, tokenAddress, masterChefAddress, chainId);
    case 'erc20':
      return await getErc20(erc20, tokenAddress, masterChefAddress, chainId);
  }
}

export async function getPool(abi, poolAddress, masterChefAddress, chainId) {
  const calls = [
    {
      address: poolAddress,
      name: 'getReserves',
    },
    {
      address: poolAddress,
      name: 'totalSupply',
    },
    {
      address: poolAddress,
      name: 'balanceOf',
      params: [masterChefAddress],
    },
    {
      address: poolAddress,
      name: 'decimals',
    },
  ];

  const [reserves, totalSupply, staked] = await multicall(abi, calls, chainId);

  return {
    q0: reserves._reserve0._hex,
    q1: reserves._reserve1._hex,
    totalSupply: new BigNumber(totalSupply).div(DEFAULT_TOKEN_DECIMAL).toJSON(),
    staked: new BigNumber(staked).div(DEFAULT_TOKEN_DECIMAL).toJSON(),
  };
}

export async function getErc20(abi, tokenAddress, masterChefAddress, chainId) {
  const calls = [
    {
      address: tokenAddress,
      name: 'totalSupply',
    },
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [masterChefAddress],
    },
    {
      address: tokenAddress,
      name: 'decimals',
    },
  ];

  const [totalSupply, staked, [decimals]] = await multicall(abi, calls, chainId);

  return {
    decimals,
    address: tokenAddress,
    totalSupply: new BigNumber(totalSupply).div(BIG_TEN.pow(decimals)).toJSON(),
    staked: new BigNumber(staked).div(BIG_TEN.pow(decimals)).toJSON(),
    tokens: [tokenAddress],
  };
}

export async function getToken(tokenAddress, masterChefAddress, chainId) {
  let type = window.localStorage.getItem(tokenAddress);

  if (tokenAddress.toLowerCase() === tokens.wkai.address[chainId]) {
    type = 'erc20';
  }

  if (type) return getStoredToken(type, tokenAddress, masterChefAddress, chainId);

  try {
    const uniPool = await getPool(UNIV2PairAbi, tokenAddress, masterChefAddress, chainId);
    window.localStorage.setItem(tokenAddress, 'uniswap');
    return uniPool;
  } catch (e) {
    console.log(e);
  }

  try {
    const erc20tok = await getErc20(erc20, tokenAddress, masterChefAddress, chainId);
    window.localStorage.setItem(tokenAddress, 'erc20');
    return erc20tok;
  } catch (e) {
    console.log(e);
  }
}

const getUniPrices = (prices, pool, chainId) => {
  var t0 = pool.token0;
  var p0 = getParameterCaseInsensitive(prices, pool.token0.address[chainId]);
  var t1 = pool.token1;
  var p1 = getParameterCaseInsensitive(prices, pool.token1.address[chainId]);

  if (p0 == null && p1 == null) {
    console.log(`Missing prices for tokens ${pool.token0} and ${pool.token1}.`);
    return undefined;
  }
  if (t0?.decimals == null) {
    console.log(`Missing information for token ${pool.token0}.`);
    return undefined;
  }
  if (t1?.decimals == null) {
    console.log(`Missing information for token ${pool.token1}.`);
    return undefined;
  }

  var q0 = pool.q0 / 10 ** t0.decimals;
  var q1 = pool.q1 / 10 ** t1.decimals;
  if (p0 == null) {
    p0 = (q1 * p1) / q0;
    if (p0) {
      prices[pool.token0.address[chainId]] = p0;
    }
  }
  if (p1 == null) {
    p1 = (q0 * p0) / q1;
    if (p1) {
      prices[pool.token1.address[chainId]] = p1;
    }
  }
  var tvl = q0 * p0 + q1 * p1;
  var price = tvl / pool.totalSupply;
  if (price) {
    prices[pool.lpAddress[chainId]] = price;
  }
  var stakedTvl = pool.staked * price;

  return {
    t0: t0,
    p0: p0,
    q0: q0,
    t1: t1,
    p1: p1,
    q1: q1,
    price: price,
    tvl: tvl,
    stakedTvl: stakedTvl,
  };
};

const getErc20Prices = (prices, pool, chainId) => {
  var price = getParameterCaseInsensitive(prices, pool.lpAddress[chainId]);
  var tvl = (pool.totalSupply * price) / 10 ** pool.decimals;
  var stakedTvl = pool.staked * price;

  return {
    stakedTvl: stakedTvl,
    price: price,
    stakeTokenTicker: pool.symbol,
    tvl: tvl,
  };
};

export function getPoolPrices(prices, pool, chainId) {
  if (pool.token1) return getUniPrices(prices, pool, chainId);
  return getErc20Prices(prices, pool, chainId);
}

const ARCHIVED_FARMS_PID = {
  [ChainId.KAI]: [2, 1000, 1001],
  [ChainId.BSC]: [],
  [ChainId.HARMONY]: [],
  [ChainId.HARMONY_TESTNET]: [],
  [ChainId.ONUS]: [],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [],
};

export const isArchivedPid = (pid, chainId) => ARCHIVED_FARMS_PID[chainId].includes(pid);
