import { classNames } from 'utils/styling';
import { FiSearch } from 'react-icons/fi';
import React from 'react';
const InputSearch = React.forwardRef(({ className, classNameInput, ...rest }, ref) => {
  return (
    <div className={classNames(className, 'border-primary border-2 rounded-xl p-2 flex items-center')}>
      <FiSearch size={24} className="text-primary mr-2" />
      <input ref={ref} {...rest} className={classNames('bg-transparent text-primary placeholder-gray-1', classNameInput)} />
    </div>
  );
});

export default InputSearch;
