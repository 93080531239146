import { ChainId } from 'ezcake-v2-sdk';
import {
  FiBox,
  FiBriefcase,
  FiDollarSign,
  FiInbox,
  FiLayers,
  FiMoon,
  FiPlus,
  FiSun,
} from 'react-icons/fi';
import { TiArrowSync } from 'react-icons/ti';

export const listNewMenu = {
  [ChainId.KAI]: [
    {
      title: 'Dashboard',
      route: '/dashboard',
    },
    {
      title: 'Invest',
      activeRoute: ['/vaults', '/ido', '/pools'],
      child: [
        {
          title: 'Auto Farm',
          desc: 'Auto Farm from different yield farming opportunities',
          route: '/vaults',
          icon: <FiDollarSign />,
        },
        {
          title: 'IDO Launchpad',
          desc: 'Invest in the next big crypto projects',
          route: '/ido',
          icon: <FiLayers />,
        },
        {
          title: 'Pools Aggregators',
          desc: 'Find and compare yield farming opportunities',
          route: '/pools',
          icon: <FiInbox />,
        },
      ],
    },
    {
      title: 'Trade',
      activeRoute: ['/swap', '/liquidity', '/add', '/remove', '/find', '/zap', '/pipe'],
      child: [
        {
          title: 'Swap',
          desc: 'Trade tokens in an instant',
          route: '/swap',
          icon: <TiArrowSync />,
        },
        {
          title: 'Liquidity',
          desc: 'Add liquidity to earn from fees',
          route: '/liquidity',
          icon: <FiPlus />,
        },
      ],
    },
    {
      title: 'NFTs',
      activeRoute: ['/NFTs'],
      child: [
        {
          title: 'Projects',
          desc: '',
          route: '/nfts',
          icon: <FiLayers />,
          inside: true,
        },
        {
          title: 'My NFTs',
          desc: '',
          route: '/my-nfts',
          icon: <FiBriefcase />,
          auth: true,
        },
      ],
    },
    {
      title: 'Bridge',
      route: '/bridge',
    },
  ],
  [ChainId.BSC]: [
    {
      title: 'Earn',
      route: '/',
    },
    // {
    //   title: 'Launchpool',
    //   route: '/launchpool',
    // },
    // {
    //   title: 'Launchpad',
    //   route: '/launchpad',
    // },
    {
      title: 'Swap',
      link: 'https://pancakeswap.finance/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xd77b2d571256ce5fb3365bfff3c5859d1ef40f0a&chain=bsc',
    },
    {
      title: 'Liquidity',
      link: 'https://pancakeswap.finance/add/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c/0xd77b2d571256ce5fb3365bfff3c5859d1ef40f0a/10000?chain=bsc&minPrice=156.967395000000010441&maxPrice=627.869580000000041764',
    },
    {
      title: 'Analytics',
      link: 'https://pancakeswap.finance/info/v3/tokens/0xd77b2d571256ce5fb3365bfff3c5859d1ef40f0a',
    },
  ],
  [ChainId.HARMONY]: [],
  [ChainId.ONUS]: [
    {
      title: 'Trade',
      activeRoute: ['/swap', '/liquidity', '/add', '/remove', '/find'],
      child: [
        {
          title: 'Swap',
          desc: 'Trade tokens in an instant',
          route: '/swap',
          icon: <TiArrowSync />,
        },
        {
          title: 'Liquidity',
          desc: 'Add liquidity to earn from fees',
          route: '/liquidity',
          icon: <FiPlus />,
        },
      ],
    },
    {
      title: 'Analytics',
      route: '/analytics',
    },
  ],
  [ChainId.ONUS_TESTNET]: [
    {
      title: 'Trade',
      activeRoute: ['/swap', '/liquidity', '/add', '/remove', '/find', '/zap', '/pipe'],
      child: [
        {
          title: 'Swap',
          desc: 'Trade tokens in an instant',
          route: '/swap',
          icon: <FiSun />,
        },
        {
          title: 'Liquidity',
          desc: 'Add liquidity to earn from fees',
          route: '/liquidity',
          icon: <FiMoon />,
        },
      ],
    },
  ],
  [ChainId.BSC_TESTNET]: [
    {
      title: 'Earn',
      activeRoute: ['/farms', '/castles'],
      child: [
        {
          title: 'EasyCake Farms',
          desc: 'Yield farming to earn mCake Token',
          route: '/farms',
          icon: <FiSun />,
        },
        {
          title: 'Initial Farm Offerings',
          desc: 'LockPool crypto assets to earn more tokens',
          route: '/pools',
          icon: <FiBox />,
        },
      ],
    },
    {
      title: 'Trade',
      activeRoute: ['/swap', '/liquidity', '/add', '/remove', '/find'],
      child: [
        {
          title: 'Swap',
          desc: 'Trade tokens in an instant',
          route: '/swap',
          icon: <TiArrowSync />,
        },
        {
          title: 'Liquidity',
          desc: 'Add liquidity to earn from fees',
          route: '/liquidity',
          icon: <FiPlus />,
        },
      ],
    },
    {
      title: 'Analytics',
      route: '/analytics',
    },
  ],
};
