import Card from 'components/Card/Card';
import useRefresh from 'hooks/useRefresh';
import React, { useMemo } from 'react';
import SocialLinks from './SocialLinks';
import Countdown, { zeroPad } from 'react-countdown';
import Progress from 'components/Progress/Progress';
import Badge from './Badge';
import PropTypes from 'prop-types';
import Value from '../../../components/Value/Value';
import { ICON_CHAIN_URL } from '../../../constants';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { NETWORK_LABEL } from '../../../constants/networks';
import YourAccount from 'views/Lands/components/YourAccount';
import { getBalanceNumber } from 'utils/formatBalance';
import BigNumber from 'bignumber.js';
import { registerToken } from 'utils/wallet';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useCheckTimeRound from 'hooks/useCheckTimeRound';
import QuestionHelper from 'components/QuestionHelper';
import { STATUS_TIME, STATUS_TIME_LABEL } from 'constants/lands';

const ProjectCardV2 = ({ pool, userDataLoaded }) => {
  const { chainId } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();
  const timer = useMemo(() => {
    if (pool.startTime > Date.now()) {
      return {
        status: STATUS_TIME.open,
        time: pool.startTime,
      };
    }

    if (pool.startTime < Date.now() && pool.endTime > Date.now()) {
      return {
        status: STATUS_TIME.ongoing,
        time: pool.endTime,
      };
    }

    if (pool.endTime < Date.now()) {
      return {
        status: STATUS_TIME.end,
      };
    }
  }, [pool, fastRefresh]);

  const timerRound = useMemo(() => {
    if (pool.currentRound.startTime > Date.now()) {
      return {
        status: STATUS_TIME.open,
        time: pool.currentRound.startTime,
      };
    }

    const unsoldFormat = getBalanceNumber(pool?.currentRound?.unsold, pool?.token?.decimals);

    if (
      pool.currentRound.startTime < Date.now() &&
      pool.currentRound.endTime > Date.now() &&
      pool?.currentRound?.round < pool.totalRound &&
      unsoldFormat < 0.1
    ) {
      return {
        status: STATUS_TIME.nextRound,
        time: pool.currentRound.endTime,
      };
    }

    if (pool.currentRound.startTime < Date.now() && pool.currentRound.endTime > Date.now()) {
      return {
        status: STATUS_TIME.ongoing,
        time: pool.currentRound.endTime,
      };
    }

    if (pool.currentRound.endTime < Date.now() || (pool.currentRound === pool.totalRound && unsoldFormat < 0.1)) {
      return {
        status: STATUS_TIME.end,
      };
    }
  }, [pool, fastRefresh]);

  useCheckTimeRound(timer.time);

  const rendererStartIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="flex mt-2 justify-center sm:justify-start">
          <Badge className="!text-sm-md flex justify-start items-center text-red-600 bg-red-opacity-25">
            <div className="rounded-50 p-1 mr-1 bg-red-600 mr-2" />
            Close
          </Badge>
        </div>
      );
    }

    return (
      <div className="flex mt-2 justify-center sm:justify-start">
        <Badge className="flex justify-start items-center !text-sm-md">
          <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
          {STATUS_TIME_LABEL[timer?.status]} {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </Badge>
      </div>
    );
  };

  const rendererRoundStartIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="flex mt-2 justify-center sm:justify-start">
          <Badge className="!text-sm-md flex justify-start items-center text-red-600 bg-red-opacity-25">
            <div className="rounded-50 p-1 mr-1 bg-red-600 mr-2" />
            Close
          </Badge>
        </div>
      );
    }

    return (
      <div className="flex mt-2 justify-center sm:justify-start">
        <Badge className="flex justify-start items-center !text-sm-md">
          <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
          {STATUS_TIME_LABEL[timerRound?.status]} {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m{' '}
          {zeroPad(seconds)}s
        </Badge>
      </div>
    );
  };

  const totalProgress = useMemo(() => {
    const total = getBalanceNumber(new BigNumber(pool.totalSold).plus(pool.totalBurn), pool.token.decimals);
    return total;
  }, [pool]);

  return (
    <div className="col-span-2 w-96 max-w-full sm:w-full mx-auto relative z-20 cursor-pointer border-2 border-yellow-800 hover:border-primary rounded-xl">
      <Card className="p-5 h-full flex flex-col justify-between">
        <div>
          <figure className="block sm:flex items-center">
            <div className="rounded-50 bg-black1 flex justify-center items-center w-20 h-20 my-6 mx-auto sm:mx-6 sm:ml-1.5">
              <img
                alt="logo"
                src={pool?.img}
                style={{
                  maxWidth: '3.5rem',
                  maxHeight: '3.5rem',
                }}
                className="w-14 cursor-pointer"
              />
            </div>
            <figcaption className="flex-1 ">
              <div className="flex items-center">
                <h3 className="text-primary text-xl font-bold text-center sm:text-left">{pool.name}</h3>
                {chainId === pool.chainId && (
                  <img
                    onClick={() => {
                      registerToken(pool.token.address, pool.token.symbol, pool.token.decimals);
                    }}
                    className="ml-1"
                    width="24"
                    src="/images/wallets/metamask.png"
                  />
                )}
              </div>
              <SocialLinks
                data={pool?.socialLink || []}
                className="relative z20 flex mt-1.5 justify-center sm:justify-start"
              />
              {timer ? (
                <>
                  {[STATUS_TIME.open, STATUS_TIME.ongoing].includes(timer?.status) ? (
                    <Countdown zeroPadTime={2} date={timer.time} renderer={rendererStartIn} />
                  ) : (
                    <div className="flex mt-2 justify-center sm:justify-start">
                      <Badge className="flex justify-start items-center !text-sm-md">
                        <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
                        {timer.status === STATUS_TIME.opening ? 'Open' : 'End'}
                      </Badge>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex mt-2 justify-center sm:justify-start">
                  <Badge className="flex justify-start items-center !text-sm-md">
                    <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
                    Opens in TBA
                  </Badge>
                </div>
              )}
              <div className="mt-1 flex text-center justify-center sm:justify-start">
                <div className="flex">
                  <Tooltip tooltip={`${NETWORK_LABEL[pool?.chainId]} Chain`}>
                    <img
                      src={ICON_CHAIN_URL[pool?.chainId]}
                      style={{
                        width: 25,
                        borderRadius: '50%',
                      }}
                      className="mr-2"
                    />
                  </Tooltip>

                  <div>
                    <Badge>{pool?.tokenPayment.symbol}</Badge>
                  </div>
                </div>
              </div>
            </figcaption>
          </figure>
          <p className="text-white text-md my-2 content-intro-project">{pool?.content}</p>
        </div>
        <div className="mb-3">
          <div>
            <p className="text-sm-md text-white font-bold text-2xl  mb-1">Current round: {pool?.currentRound?.round}</p>
            <div>
              <>
                {[STATUS_TIME.open, STATUS_TIME.ongoing, STATUS_TIME.nextRound].includes(timerRound?.status) ? (
                  <Countdown zeroPadTime={2} date={timerRound.time} renderer={rendererRoundStartIn} />
                ) : (
                  <div className="flex mt-2 justify-center sm:justify-start">
                    <Badge className="flex justify-start items-center !text-sm-md">
                      <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
                      {timerRound?.status === STATUS_TIME.opening ? 'Open' : 'End'}
                    </Badge>
                  </div>
                )}
              </>
            </div>
          </div>
          <div>
            <div className="flex flex-wrap flex-4 mt-3">
              <div className="w-full lg:w-2/4">
                <p className="text-sm-md text-white mb-1">Swap rate (estimate)</p>
                <div className="font-bold text-primary text-base">
                  <Value
                    className="inline"
                    value={getBalanceNumber(pool.pricePerToken, pool.token.decimals)}
                    decimals={3}
                    unit={` ${pool.tokenPayment.symbol} per ${pool.token.symbol}`}
                  />
                </div>
              </div>
              <div className="w-1/2 lg:w-1/4">
                <p className="text-sm-md text-white text-left lg:text-center mb-1">Sold</p>
                <div className="font-bold text-primary text-base text-left lg:text-center">
                  <Value
                    className="inline"
                    value={getBalanceNumber(pool?.currentRound?.sold, pool?.token?.decimals)}
                    decimals={3}
                    unit={` ${pool.token.symbol}`}
                  />
                </div>
              </div>
              <div className="w-1/2 lg:w-1/4">
                <p className="text-sm-md text-white text-right mb-1">Remaining</p>
                <div className="font-bold text-primary text-base text-right">
                  <Value
                    className="inline"
                    value={getBalanceNumber(pool?.currentRound?.unsold, pool?.token?.decimals)}
                    decimals={3}
                    unit={` ${pool.token.symbol}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <p className="text-sm-md text-white mb-1">Progress</p>
          <Progress percent={(totalProgress / pool.totalSale) * 100} />
          <div className="flex justify-between mt-0.5">
            <span className="text-white text-sm-md font-normal">
              <Value className="inline" value={(totalProgress / pool.totalSale) * 100} decimals={2} />%
            </span>
            <span className="text-white text-sm-md font-normal">
              <Value className="inline" value={totalProgress} decimals={2} /> /{' '}
              <Value className="inline" value={pool.totalSale} decimals={2} /> {pool?.token?.symbol}
            </span>
          </div>
        </div>
        <div className="mt-3">
          <div className="flex flex-wrap flex-4 ">
            <div className="w-1/2 lg:w-1/4">
              <p className="text-sm-md text-white mb-1">Total round</p>
              <div className="font-bold text-primary text-base">
                <p>{pool.totalRound}</p>
              </div>
            </div>
            <div className="w-1/2 lg:w-1/4">
              <p className="text-sm-md text-white mb-1 lg:text-center text-right">Token per round</p>
              <div className="font-bold text-primary text-base lg:text-center text-right">
                <Value className="inline" value={pool.tokenPerRound} decimals={0} unit={` ${pool?.token?.symbol}`} />
              </div>
            </div>
            <div className="w-1/2 lg:w-1/4">
              <p className="text-sm-md text-white text-left lg:text-center mb-1">Total sold</p>
              <div className="font-bold text-primary text-base text-left lg:text-center">
                <Value
                  className="inline"
                  value={getBalanceNumber(pool.totalSold, pool?.token?.decimals)}
                  decimals={2}
                  unit={` ${pool.token.symbol}`}
                />
              </div>
            </div>
            <div className="w-1/2 lg:w-1/4">
              <div className="text-sm-md text-white  mb-1 flex items-center justify-end">
                Total burn
                <QuestionHelper text={`Unsold ${pool.token.symbol} will be burned after each round ended`} />
              </div>
              <div className="font-bold text-primary text-base text-right">
                <Value
                  className="inline"
                  value={getBalanceNumber(pool.totalBurn, pool?.token?.decimals)}
                  decimals={2}
                  unit={` ${pool.token.symbol}`}
                />
              </div>
            </div>
          </div>
        </div>
        <YourAccount userDataLoaded={userDataLoaded} pool={pool} />
      </Card>
    </div>
  );
};

ProjectCardV2.propTypes = {
  pool: PropTypes.object.isRequired,
};

export default ProjectCardV2;
