import Modal from 'components/Modal/Modal';
import ModalTitle from 'components/Modal/ModalTitle';
import PropTypes from 'prop-types';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const ModalWarning = ({ open, toggleModal, content }) => {
  return (
    <Modal open={open} onClose={toggleModal}>
      <ModalTitle onClose={toggleModal}>Warning</ModalTitle>
      <div className="text-center">
        <p>{content}</p>
      </div>
      <ButtonCustom className="mx-auto mt-4" onClick={() => toggleModal()}>
        Close
      </ButtonCustom>
    </Modal>
  );
};

ModalWarning.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  content: PropTypes.any,
};

export default ModalWarning;
