import Value from 'components/Value/Value';
import { CASTLE_TAGS } from 'constants/index';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { getLinkGetTokenLP } from 'utils/explorer';
import Limit from 'views/Castles/components/CastlesCard/Limit';
import Countdown from 'views/Castles/components/CastlesTable/ActionPanel/Detail/Countdown';
import Fees from 'views/Castles/components/CastlesTable/ActionPanel/Detail/Fees';
import {useChainId} from "wagmi";

const Detail = ({ pool }) => {
    const chainId = useChainId();

  const earningTokens = pool.earningToken ? [pool.earningToken] : pool.earningTokens;
  const { stakingLimit, poolLimit, stakingToken } = pool;
  const stakedTvl = pool.stakedTvl;
  const linkGetToken = getLinkGetTokenLP(chainId, pool?.token0, pool?.token1, stakingToken);

    const isIfo = pool.tags.includes(CASTLE_TAGS.ifo);

  return (
    <div className="text-white mt-2">
      <div className="flex items-center text-white mb-1">
        <p>TVL:</p>
        <Value className="font-bold ml-1" prefix="$" value={stakedTvl ? +stakedTvl : 0} decimals={0} />
      </div>
      {pool?.totalReward?.length > 0 ? (
        <div className="flex items-center text-white mb-1">
          <p>Total Reward:</p>
          <div>
            {pool.totalReward.map((value, index) => (
              <p className="font-bold ml-1">
                {value} {pool?.earningTokens?.[index]?.symbol}
              </p>
            ))}
          </div>
        </div>
      ) : null}

      <Limit
        isIfo={isIfo}
        stakingToken={stakingToken}
        poolLimit={poolLimit}
        stakingLimit={stakingLimit}
        stakingTokenPrice={pool.stakingTokenPrice}
      />
      <Countdown pool={pool} />
      {pool.fees && <Fees pool={pool} />}
      <div className="border-t-2 mt-2 pt-2">
        {earningTokens.length > 1 ? (
          earningTokens.map((earningToken, index) => (
            <a
              rel="noreferrer"
              key={index}
              className="flex items-center cursor-pointer hover:underline"
              href={earningToken.projectLink}
              target="_blank"
            >
              View Project Site ({earningToken.symbol})
              <FiExternalLink className="ml-1" size={16} />
            </a>
          ))
        ) : (
          <a
            rel="noreferrer"
            className="flex items-center cursor-pointer hover:underline"
            href={earningTokens[0].projectLink}
            target="_blank"
          >
            View Project Site
            <FiExternalLink className="ml-1" size={16} />
          </a>
        )}
        {linkGetToken ? (
          <>
            {linkGetToken?.route ? (
              <Link
                className="flex items-center cursor-pointer hover:underline"
                to={linkGetToken?.route}
                target="_blank"
              >
                {linkGetToken?.label}
                <FiExternalLink className="ml-1" size={16} />
              </Link>
            ) : (
              <a
                rel="noreferrer"
                className="flex items-center hover:underline"
                target="_blank"
                href={linkGetToken?.link}
              >
                {linkGetToken?.label} <FiExternalLink className="ml-1" size={16} />
              </a>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Detail;
