import { NavItem, TabContent, TabPane, Tabs } from 'components/Tabs/Tabs';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { usePrices } from 'store/prices/hook';
import { getVaultStaked } from 'store/vaults/helpers';
import { getParameterCaseInsensitive } from 'utils/index';
import { getTokenName } from 'utils/tokenHelpers';
import { TABS_VAULT_CARD } from 'views/Vaults/components/VaultsCard/VaultCard';
import WithdrawV2 from 'views/Vaults/components/VaultsCard/YourAccount/WithdrawV2';
import Deposit from 'views/Vaults/components/VaultsCard/YourAccount/Deposit';
import ManualCompound from 'views/Vaults/components/VaultsCard/YourAccount/ManualCompound';
import Withdraw from 'views/Vaults/components/VaultsCard/YourAccount/Withdraw';

const YourAccount = ({ vault, userDataLoaded, tab, onChangeTab, open, toggleOpen }) => {
  const { chainId } = useActiveWeb3React();
  const symbol = getTokenName(vault?.token0, vault?.token1, vault.stakingToken);
  const prices = usePrices();
  const priceStakingToken = getParameterCaseInsensitive(prices, vault.stakingToken.address[chainId]);
  const { userData } = vault;

  const vaultStaked = getVaultStaked(vault, chainId);
  const symbolInVaultStaked = getTokenName(vaultStaked?.token0, vaultStaked?.token1, vaultStaked?.stakingToken);
  const priceStakingTokenInVaultStaked = getParameterCaseInsensitive(
    prices,
    vaultStaked?.stakingToken?.address[chainId],
  );

  return (
    <div className="mt-4 text-white">
      <p onClick={toggleOpen} className="font-bold flex items-center justify-center cursor-pointer">
        Your Account {open ? <FiChevronUp /> : <FiChevronDown />}
      </p>
      {open && (
        <div>
          <div>
            <Tabs className="w-full justify-between">
              <NavItem active={tab === TABS_VAULT_CARD.deposit} onClick={() => onChangeTab(TABS_VAULT_CARD.deposit)}>
                {TABS_VAULT_CARD.deposit}
              </NavItem>
              <NavItem active={tab === TABS_VAULT_CARD.withdraw} onClick={() => onChangeTab(TABS_VAULT_CARD.withdraw)}>
                {TABS_VAULT_CARD.withdraw}
              </NavItem>
              <NavItem
                isLast={true}
                active={tab === TABS_VAULT_CARD.compound}
                onClick={() => onChangeTab(TABS_VAULT_CARD.compound)}
              >
                {chainId === ChainId.KAI ? 'Detail' : TABS_VAULT_CARD.compound}
              </NavItem>
            </Tabs>
          </div>
          <TabContent className="mt-4" activeTab={tab}>
            <TabPane tabId={TABS_VAULT_CARD.deposit}>
              <div className="rounded-2xl  bg-black1  p-3 mt-4">
                <Deposit
                  symbol={symbol}
                  priceStakingToken={priceStakingToken}
                  decimals={vault.stakingToken.decimals}
                  userData={userData}
                  vault={vault}
                  userDataLoaded={userDataLoaded}
                />
              </div>
            </TabPane>
            <TabPane tabId={TABS_VAULT_CARD.withdraw}>
              <div className="rounded-2xl  bg-black1  p-3 mt-4">
                {vault?.contractVaultStakedAddress ? (
                  <WithdrawV2
                    decimalsTokenStrategyStaking={vaultStaked.stakingToken.decimals}
                    symbol={symbol}
                    symbolInVaultStaked={symbolInVaultStaked}
                    priceStakingToken={priceStakingToken}
                    priceStakingTokenInVaultStaked={priceStakingTokenInVaultStaked}
                    decimals={vault.stakingToken.decimals}
                    userData={userData}
                    vault={vault}
                    userDataLoaded={userDataLoaded}
                  />
                ) : (
                  <Withdraw
                    symbol={symbol}
                    priceStakingToken={priceStakingToken}
                    decimals={vault.stakingToken.decimals}
                    userData={userData}
                    vault={vault}
                    userDataLoaded={userDataLoaded}
                  />
                )}
              </div>
            </TabPane>
            <TabPane tabId={TABS_VAULT_CARD.compound}>
              <div className="rounded-2xl  bg-black1  p-3 mt-4">
                <ManualCompound vault={vault} userDataLoaded={userDataLoaded} />
              </div>
            </TabPane>
          </TabContent>
        </div>
      )}
    </div>
  );
};

export default YourAccount;
