import BigNumber from 'bignumber.js';
import { classNames } from 'utils/styling';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import InputWithButton from 'components/NumericalInput/InputWithButton';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useVaultApprove } from 'hooks/useApprove';
import useDeposit from 'hooks/useDeposit';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchVaultUserDataAsync, fetchVaultV2UserDataAsync } from 'store/vaults/index';
import { BIG_TEN } from 'utils/bigNumber';
import { getLinkGetTokenLP } from 'utils/explorer';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import UnlockButton from 'components/UnlockButton/UnlockButton';

const Deposit = ({ decimals, symbol, priceStakingToken, userData, vault, userDataLoaded, isV2 }) => {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const [requestedApproval, setRequestedApproval] = useState(false);
  const { stakingTokenBalance, allowance } = userData;
  const isApproved = account && allowance && allowance.isGreaterThan(0);

  const { onApprove } = useVaultApprove(vault.contractAddress, vault.stakingToken.address[chainId]);

  const { onDeposit } = useDeposit(vault.contractAddress);

  const usdValue = value && formatNumber(new BigNumber(value).times(priceStakingToken || 0).toNumber());
  const usdBalance =
    stakingTokenBalance &&
    formatNumber(
      new BigNumber(getBalanceNumber(stakingTokenBalance, decimals)).times(priceStakingToken || 0).toNumber(),
    );

  const handleTypeInput = (valueInput) => {
    setValue(valueInput);
  };

  const isInsufficientBalance = useMemo(() => {
    return new BigNumber(value).isGreaterThan(getFullDisplayBalance(stakingTokenBalance, decimals));
  }, [value, stakingTokenBalance, decimals]);

  const handleMaxInput = useCallback(() => {
    setValue(getFullDisplayBalance(stakingTokenBalance, decimals));
  }, [decimals, stakingTokenBalance]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (percent === 100) {
        handleMaxInput();
      } else {
        setValue(
          getFullDisplayBalance(new BigNumber(stakingTokenBalance).times(percent).dividedBy(100), decimals, decimals),
        );
      }
    },
    [decimals, handleMaxInput, stakingTokenBalance],
  );

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove();
      showToastSuccess('Contract Enabled', `You can now stake in the ${symbol} vault!`);
      dispatch(fetchVaultV2UserDataAsync(account, chainId));
      dispatch(fetchVaultUserDataAsync(account, chainId));
      setRequestedApproval(false);
    } catch (e) {
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setRequestedApproval(false);
    }
  }, [account, chainId, dispatch, onApprove, symbol]);

  const handleDeposit = useCallback(async () => {
    try {
      setPendingTx(true);
      await onDeposit(value, decimals);
      dispatch(fetchVaultV2UserDataAsync(account, chainId));
      dispatch(fetchVaultUserDataAsync(account, chainId));
      showToastSuccess('Deposited', `Your ${symbol} funds have been deposited in the vault!`);
      setValue('');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [account, decimals, dispatch, onDeposit, symbol, value, chainId]);

  const linkGetToken = getLinkGetTokenLP(chainId, vault.token0, vault.token1, vault.stakingToken);

  const [q0User, q1User] = getLpBreakdown(
    stakingTokenBalance.div(BIG_TEN.pow(vault.stakingToken.decimals)).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  return (
    <div className="rounded-2xl border border-black2 bg-black1 p-3 mt-4">
      <div className="text-white flex mb-2">
        <p>Available:</p>
        <div className={classNames('ml-2', isV2 && 'mb-6')}>
          <p>
            {account ? getFullDisplayBalance(stakingTokenBalance, decimals) : 0} {symbol}
          </p>
          {vault.token1 && vault.farmStaked && (
            <p>
              {stakingTokenBalance.gt(0) && (
                <>
                  ({formatNumber(getBalanceNumber(q0User, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                  {vault.farmStaked.token0.symbol} +{' '}
                  {formatNumber(getBalanceNumber(q1User, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                  {vault.farmStaked.token1.symbol})
                </>
              )}
            </p>
          )}
          <p className="text-sm mt-1">~{usdBalance || '0'} USD</p>
        </div>
      </div>
      <InputWithButton className="text-white" value={value} onUserInput={handleTypeInput} btnOnClick={handleMaxInput} />
      <p className="text-sm text-right text-white mt-1">~{usdValue || '0'} USD</p>
      <PercentPicker
        isNumber={true}
        className="text-black max-w-sm mx-auto"
        onChangePercentInput={handlePercentInput}
      />
      <div className="flex justify-between mt-2">
        <div className="text-white mx-auto mb-2">
          {linkGetToken ? (
            <>
              {linkGetToken?.route ? (
                <Link
                  className="flex items-center justify-center cursor-pointer hover:underline"
                  to={linkGetToken?.route}
                  target="_blank"
                >
                  {linkGetToken?.label}
                  <FiExternalLink className="ml-1" size={16} />
                </Link>
              ) : (
                <a
                  rel="noreferrer"
                  className="flex justify-center items-center hover:underline"
                  target="_blank"
                  href={linkGetToken?.link}
                >
                  {linkGetToken?.label} <FiExternalLink className="ml-1" size={16} />
                </a>
              )}
            </>
          ) : null}
        </div>
      </div>
      {account ? (
        userDataLoaded ? (
          isApproved ? (
            <ButtonCustom
              className="w-full"
              disabled={pendingTx || !new BigNumber(value).isGreaterThan(0) || isInsufficientBalance}
              isLoading={pendingTx}
              onClick={handleDeposit}
            >
              {isInsufficientBalance
                ? `Insufficient ${symbol} balance`
                : pendingTx
                ? 'Pending Confirmation'
                : 'Deposit'}
            </ButtonCustom>
          ) : (
            <ButtonCustom
              className="w-full"
              isLoading={requestedApproval}
              disabled={requestedApproval}
              onClick={handleApprove}
            >
              Approve Contract
            </ButtonCustom>
          )
        ) : (
          <ButtonCustom className="w-full" isLoading>
            Loading <Dots />
          </ButtonCustom>
        )
      ) : (
        <UnlockButton />
      )}
    </div>
  );
};

Deposit.propTypes = {
  symbol: PropTypes.string.isRequired,
  decimals: PropTypes.number,
  priceStakingToken: PropTypes.number,
  userData: PropTypes.object.isRequired,
  vault: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
  isV2: PropTypes.bool,
};

export default Deposit;
