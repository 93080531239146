import { useWatchlistTokens } from 'store/user/hooks';
import TokenTable from 'views/Analytics/components/InfoTables/TokensTable';
import TopTokenMovers from 'views/Analytics/components/TopTokenMovers/TopTokenMovers';
import Card from 'components/Card/Card';

const TokensOverview = () => {
  const [savedTokens] = useWatchlistTokens();

  return (
    <>
      <h1 className="mb-5 text-primary text-4xl font-bold">Your Watchlist</h1>
      {savedTokens.length > 0 ? (
        <TokenTable isShowWatchList />
      ) : (
        <Card className="p-5">
          <p>Saved tokens will appear here</p>
        </Card>
      )}
      <TopTokenMovers />
      <h2 className="mt-10 mb-5 font-bold text-2xl">All Tokens</h2>
      <TokenTable />
    </>
  );
};

export default TokensOverview;
