import { classNames } from 'utils/styling';
import PropTypes from 'prop-types';
import { Tooltip as MuiTooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const Tooltip = ({ position = 'bottom', children, tooltip, className, style }) => {
  return (
    <MuiTooltip html={tooltip} position={position} style={style} className={classNames(className)}>
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  tooltip: PropTypes.node,
};

export default Tooltip;
