import React from 'react';
import { classNames } from 'utils/styling';
import PropTypes from 'prop-types';

const Table = ({ className, containerClass, containerStyle, children, ...rest }) => {
  return (
    <div style={containerStyle} className={classNames('overflow-x-auto rounded bg-black1', containerClass)}>
      <table {...rest} className={classNames('custom-table-base text-white w-full', className)}>
        {children}
      </table>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  containerClass: PropTypes.string,
  children: PropTypes.any,
};

export default Table;
