import { classNames } from 'utils/styling';
import Card from 'components/Card/Card';
import useLastTruthy from 'hooks/useLast';
import { AdvancedSwapDetails } from './AdvancedSwapDetails';
import { Transition } from '@headlessui/react';

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }) {
  const lastTrade = useLastTruthy(trade);

  return (
    <Transition
      show={!!trade}
      unmount={false}
      enter="transition ease duration-300 transform"
      enterFrom="opacity-0 -translate-y-12"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease duration-200 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-12"
    >
      <Card className={classNames('max-w-md mx-auto mt-4 px-3 py-4 rounded-2xl bg-blue1 ', trade ? 'block' : 'hidden')}>
        <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
      </Card>
    </Transition>
  );
}
