import BigNumber from 'bignumber.js';
import { MCAKE_ADDRESS } from 'constants/contracts';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useRefresh from 'hooks/useRefresh';
import { useEffect, useState } from 'react';
import { BIG_ZERO } from 'utils/bigNumber';
import { getERC20Contract } from 'utils/contractHelpers';

import multicall from 'utils/multicall';

import ercAbi from 'config/abi/erc20.json';

const useTokenBalance = (tokenAddress) => {
  const [balance, setBalance] = useState(BIG_ZERO);
  const { chainId, account } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    async function fetchTokenBalance() {
      try {
        const contract = getERC20Contract(tokenAddress, chainId);
        const res = await contract.balanceOf(account);
        setBalance(new BigNumber(res));
      } catch (e) {
        console.log(e);
      }
    }

    if (account && tokenAddress && chainId) {
      fetchTokenBalance();
    }
  }, [account, chainId, fastRefresh, tokenAddress]);

  return balance;
};

export const useBurnedDeadBalance = () => {
  const { fastRefresh } = useRefresh();
  const [balance, setBalance] = useState(BIG_ZERO);
  const { chainId } = useActiveWeb3React();

  useEffect(() => {
    async function fetchTokenBalance() {
      try {
        const calls = [
          {
            address: MCAKE_ADDRESS[chainId],
            name: 'balanceOf',
            params: ['0x000000000000000000000000000000000000dEaD'],
          },
        ];


        const raw = await multicall(ercAbi, calls, chainId);

        const total = raw.reduce((sum, acc) => {
          return sum.plus(new BigNumber(acc[0]._hex));
        }, BIG_ZERO);

        setBalance(total);
      } catch (e) {}
    }

    if (chainId) {
      fetchTokenBalance();
    }
  }, [chainId, fastRefresh]);

  return balance;
};

export default useTokenBalance;
