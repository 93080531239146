import React, { useEffect, useMemo, useRef, useState } from 'react';
import { classNames } from 'utils/styling';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { CurrencyLogo, DoubleCurrencyLogo } from 'views/Analytics/components/CurrencyLogo/CurrencyLogo';
import SaveIcon from 'views/Analytics/components/SaveIcon/SaveIcon';
import { isAddress } from 'utils';
import { formatAmount } from 'utils/formatInfoNumbers';
import { useNavigate } from 'react-router-dom';
import useMatchBreakpoints from 'contexts/MatchBreakpoints/useMatchBreakpoints';
import { useWatchlistPools, useWatchlistTokens } from 'store/user/hooks';
import { useSWRSearchPairsInfinite } from 'hooks/swr/analytics/pairs';
import { useSWRSearchTokensInfinite } from 'hooks/swr/analytics/tokens';
import { MINIMUM_SEARCH_CHARACTERS } from 'constants/analytics';
import Dots from 'components/Loader/Dots';

const ContentSearch = ({ inputRef, debouncedSearchTerm, setShowMenu, showMenu }) => {
  const navigate = useNavigate();
  const { isXs, isSm, isMd } = useMatchBreakpoints();

  const menuRef = useRef(null);
  // watchlist
  const [savedTokens, addSavedToken] = useWatchlistTokens();
  const [savedPools, addSavedPool] = useWatchlistPools();
  const [showWatchlist, setShowWatchlist] = useState(false);

  const {
    poolDatas,
    size: sizePools,
    setSize: setPoolsSize,
    isLoadingInitialData: tokensLoading,
    total: totalPools,
    isLoadingMore: isLoadingMorePools,
  } = useSWRSearchPairsInfinite({
    rowPerPage: 5,
    ...(showWatchlist && !debouncedSearchTerm ? {} : { textSearch: debouncedSearchTerm }),
    ...(showWatchlist && { listPair: savedPools.map(isAddress).join(',') }),
  });

  const {
    tokenDatas,
    size: sizeTokens,
    setSize: setTokensSize,
    isLoadingInitialData: poolsLoading,
    total: totalTokens,
    isLoadingMore: isLoadingMoreTokens,
  } = useSWRSearchTokensInfinite({
    rowPerPage: 5,
    ...(showWatchlist && !debouncedSearchTerm ? {} : { textSearch: debouncedSearchTerm }),
    ...(showWatchlist && { listToken: savedTokens.map(isAddress).join(',') }),
  });

  useEffect(() => {
    setPoolsSize(1);
    setTokensSize(1);
  }, [debouncedSearchTerm, showWatchlist]);

  const handleOutsideClick = (e) => {
    const menuClick = menuRef.current && (e.target.contains(menuRef.current) || menuRef.current.contains(e.target));
    const inputClick = inputRef.current && (e.target.contains(menuRef.current) || inputRef.current.contains(e.target));

    if (!menuClick && !inputClick) {
      setPoolsSize(1);
      setTokensSize(1);
      setShowMenu(false);
    }
  };

  useEffect(() => {
    // if (showMenu) {
    //   document.querySelector('body').style.overflow = 'hidden';
    // } else {
    //   document.querySelector('body').style.overflow = 'visible';
    // }
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showMenu]);

  const handleItemClick = (to) => {
    setShowMenu(false);
    setPoolsSize(1);
    setTokensSize(1);
    navigate(to);
  };

  // filter on view
  const tokensForList = useMemo(() => {
    if (showWatchlist) {
      return tokenDatas && savedTokens.length > 0 ? tokenDatas : [];
    }
    return tokenDatas && debouncedSearchTerm ? tokenDatas : [];
  }, [showWatchlist, tokenDatas, debouncedSearchTerm, savedTokens.length]);

  const poolForList = useMemo(() => {
    if (showWatchlist) {
      return poolDatas && savedPools.length > 0 ? poolDatas : [];
    }
    return poolDatas && debouncedSearchTerm ? poolDatas : [];
  }, [showWatchlist, poolDatas, debouncedSearchTerm, savedPools.length]);

  const contentUnderTokenList = () => {
    const isLoading = tokensLoading || isLoadingMoreTokens;
    const noTokensFound =
      tokensForList.length === 0 && !isLoading && debouncedSearchTerm.length >= MINIMUM_SEARCH_CHARACTERS;
    const noWatchlistTokens = tokensForList.length === 0 && !isLoading;
    const showMessage = showWatchlist ? noWatchlistTokens : noTokensFound;
    const noTokensMessage = showWatchlist ? 'Saved tokens will appear here' : 'No results';
    return (
      <>
        {isLoading && <Dots>Loading</Dots>}
        {showMessage && <p>{noTokensMessage}</p>}
        {!showWatchlist && debouncedSearchTerm.length < MINIMUM_SEARCH_CHARACTERS && <p>Search pools or tokens</p>}
      </>
    );
  };

  const contentUnderPoolList = () => {
    const isLoading = poolsLoading || isLoadingMorePools;
    const noPoolsFound =
      poolForList.length === 0 && !isLoading && debouncedSearchTerm.length >= MINIMUM_SEARCH_CHARACTERS;
    const noWatchlistPools = poolForList.length === 0 && !isLoading;
    const showMessage = showWatchlist ? noWatchlistPools : noPoolsFound;
    const noPoolsMessage = showWatchlist ? 'Saved tokens will appear here' : 'No results';
    return (
      <>
        {isLoading && <Dots>Loading</Dots>}
        {showMessage && <p>{noPoolsMessage}</p>}
        {!showWatchlist && debouncedSearchTerm.length < MINIMUM_SEARCH_CHARACTERS && <p>Search pools or tokens</p>}
      </>
    );
  };

  return (
    <div
      ref={menuRef}
      className={classNames(
        'block flex-col text-white absolute bg-black1 top-[50px] overflow-auto right-0 w-full rounded-2xl mt-2 z-50 md:w-[800px] p-5 lg:max-h-[600px] md:max-h-[600px] max-h-[400px]',
        showMenu ? 'block' : 'hidden',
      )}
    >
      <div className="flex mb-3">
        <ButtonCustom size="xs" variant={showWatchlist ? 'outlined' : 'filled'} onClick={() => setShowWatchlist(false)}>
          Search
        </ButtonCustom>
        <ButtonCustom
          variant={showWatchlist ? 'filled' : 'outlined'}
          className="ml-3"
          size="xs"
          onClick={() => setShowWatchlist(true)}
        >
          Watchlist
        </ButtonCustom>
      </div>

      <div className="responsiveGrid">
        <p className="text-primary">Tokens</p>
        {!isXs && !isSm && !isMd && <p className="text-right">Price</p>}
        {!isXs && !isSm && !isMd && <p className="text-right">Volume 24H</p>}
        {!isXs && !isSm && !isMd && <p className="text-right">Liquidity</p>}
      </div>
      {tokensForList.map((token, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div
            className="hover:opacity-70 cursor-pointer"
            onClick={() => handleItemClick(`/analytics/tokens/${token.address}`)}
            key={i}
          >
            <div className="responsiveGrid">
              <div className="flex items-center">
                <CurrencyLogo currency={token} />
                <div className="ml-1" style={{ whiteSpace: 'nowrap' }}>
                  <p>{`${token.name} (${token.symbol})`}</p>
                </div>
                <SaveIcon
                  id="watchlist-icon"
                  style={{ marginLeft: '8px' }}
                  fill={savedTokens.includes(isAddress(token.address))}
                  onClick={(e) => {
                    e.stopPropagation();
                    addSavedToken(token.address);
                  }}
                />
              </div>
              {!isXs && !isSm && !isMd && <p className="text-right">${formatAmount(token.priceUSD)}</p>}
              {!isXs && !isSm && !isMd && <p className="text-right">${formatAmount(token.volumeUSD)}</p>}
              {!isXs && !isSm && !isMd && <p className="text-right">${formatAmount(token.liquidityUSD)}</p>}
            </div>
          </div>
        );
      })}
      {contentUnderTokenList()}
      {tokensForList.length > 0 && (
        <p
          onClick={() => {
            setPoolsSize(sizeTokens + 1);
          }}
          className={classNames(
            'cursor-pointer hover:text-primary',
            totalTokens <= tokensForList.length ? 'hidden' : 'block',
          )}
        >
          See more...
        </p>
      )}

      <div className="my-3 h-0.5 bg-primary" />
      <div className="responsiveGrid">
        <p className="text-primary">Pools</p>
        {!isXs && !isSm && !isMd && <p className="text-right">Volume 24H</p>}
        {!isXs && !isSm && !isMd && <p className="text-right">Volume 7D</p>}
        {!isXs && !isSm && !isMd && <p className="text-right">Liquidity</p>}
      </div>
      {poolForList.map((p, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div
            className="hover:opacity-70 cursor-pointer"
            onClick={() => handleItemClick(`/analytics/pairs/${p.address}`)}
            key={i}
          >
            <div className="responsiveGrid">
              <div className="flex items-center">
                <DoubleCurrencyLogo currency0={p.token0} currency1={p.token1} />
                <div className="ml-1" style={{ whiteSpace: 'nowrap' }}>
                  <p>{`${p.token0.symbol} / ${p.token1.symbol}`}</p>
                </div>
                <SaveIcon
                  id="watchlist-icon"
                  style={{ marginLeft: '10px' }}
                  fill={savedPools.includes(isAddress(p.address))}
                  onClick={(e) => {
                    e.stopPropagation();
                    addSavedPool(p.address);
                  }}
                />
              </div>
              {!isXs && !isSm && !isMd && <p className="text-right">${formatAmount(p.volumeUSD)}</p>}
              {!isXs && !isSm && !isMd && <p className="text-right">${formatAmount(p.volumeUSDWeek)}</p>}
              {!isXs && !isSm && !isMd && <p className="text-right">${formatAmount(p.liquidityUSD)}</p>}
            </div>
          </div>
        );
      })}
      {contentUnderPoolList()}
      {poolForList.length > 0 && (
        <p
          onClick={() => {
            setPoolsSize(sizePools + 1);
          }}
          className={classNames(
            'cursor-pointer hover:text-primary',
            totalPools <= poolForList.length ? 'hidden' : 'block',
          )}
        >
          See more...
        </p>
      )}
    </div>
  );
};

export default ContentSearch;
