import AppHeader from 'components/AppHeader/AppHeader';
import Card from 'components/Card/Card';
import Dots from 'components/Loader/Dots';
import { useMemo } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link, NavLink } from 'react-router-dom';
import FullPositionCard from 'components/PositionCard/PositionCard';
import { useTokenBalancesWithLoadingIndicator } from 'hooks/wallet';
import { usePairs } from 'hooks/usePairs';
import { toV2LiquidityToken, useTrackedTokenPairs } from 'store/user/hooks';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { useModalWalletConnect } from 'store/modal/hooks';
import { classNames } from 'utils/styling';
import {useAccount} from "wagmi";

export default function Liquidity() {
  const { address } = useAccount();
  const { onToggleConnectModal } = useModalWalletConnect();
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs],
  );

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens],
  );

  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    address ?? undefined,
    liquidityTokens,
  );

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0'),
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances],
  );

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens));

  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair) => Boolean(v2Pair));

  const renderBody = () => {
    if (!address) {
      return (
        <div className="mt-16 text-center">
          <p className="text-white mb-4 font-bold text-2xl">Your liquidity</p>
          {/* <UnlockButton className="my-2 mx-auto" fullWidth={false} /> */}
          <ButtonCustom className={classNames('w-full !h-20 !bg-black1 text-white')} onClick={onToggleConnectModal}>
            Connect to a wallet to view your liquidity.
          </ButtonCustom>
        </div>
      );
    }
    if (v2IsLoading) {
      return <Dots className="text-center text-white mb-4 mt-2">Loading</Dots>;
    }
    if (allV2PairsWithLiquidity?.length > 0) {
      return allV2PairsWithLiquidity.map((v2Pair, index) => (
        <FullPositionCard
          key={v2Pair.liquidityToken.address}
          pair={v2Pair}
          mb={index < allV2PairsWithLiquidity.length - 1 ? '16px' : 0}
        />
      ));
    }
    return <p className="text-center text-white mt-2">No liquidity found.</p>;
  };

  return (
    <>
      <Card className="max-w-3xl mx-auto p-5 border border-yellow-800">
        <AppHeader title="Liquidity" subtitle="Add liquidity to receive LP tokens" />
        <Link to="/add">
          <ButtonCustom size="lg" className="w-full flex items-center mt-8 font-bold text-lg">
            <FiPlus /> Add Liquidity
          </ButtonCustom>
        </Link>
        {renderBody()}
        {address && !v2IsLoading && (
          <div className="bg-black1 text-white text-center py-4 my-4 rounded-lg">
            <p>Don't see a pool you joined?</p>
            <NavLink className="text-primary hover:underline" to="/find">
              Find other LP tokens
            </NavLink>
          </div>
        )}
      </Card>
    </>
  );
}
