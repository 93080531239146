import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const ToggleShowCell = ({ row }) => {
  return <div className="text-primary flex justify-end">{row.isExpanded ? <FiChevronUp /> : <FiChevronDown />}</div>;
};

ToggleShowCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export default ToggleShowCell;
