import useActiveWeb3React from './useActiveWeb3React';
import { useCallback } from 'react';
import { useMintDragonContract } from './useContract';
import { claimTokenNFT, mintNFT, sendNFT } from '../utils/calls/mint';
import { showToastTx } from '../components/CustomToast/CustomToast';

export const useMintDragon = (poolAddress) => {
  const { account, chainId } = useActiveWeb3React();
  const contract = useMintDragonContract(poolAddress);

  const handleMint = useCallback(
    async (amount, price, decimals, isMainCoin) => {
      const txHash = await mintNFT(contract, amount, price, decimals, account, isMainCoin);
      showToastTx(txHash, chainId);
      return txHash;
    },
    [account, contract, chainId],
  );

  return { onMint: handleMint };
};

export const useClaimNFT = (poolAddress) => {
  const { chainId } = useActiveWeb3React();
  const contract = useMintDragonContract(poolAddress);

  const handleClaim = useCallback(
    async (id) => {
      const txHash = await claimTokenNFT(contract, id);

      showToastTx(txHash, chainId);
      return txHash;
    },
    [contract, chainId],
  );

  return { onClaim: handleClaim };
};

export const useSendNFT = (poolAddress) => {
  const { account, chainId } = useActiveWeb3React();
  const contract = useMintDragonContract(poolAddress);

  const handleSend = useCallback(
    async (toAddress, id) => {
      const txHash = await sendNFT(contract, toAddress, id, account);

      showToastTx(txHash, chainId);
      return txHash;
    },
    [account, contract, chainId],
  );

  return { onSend: handleSend };
};
