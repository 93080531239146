import axios from 'axios';
import BigNumber from 'bignumber.js';
import tokens from 'constants/tokens';
import { ChainId } from 'ezcake-v2-sdk';
import pricesApi from "../api/pricesApi";

const TOKENS_BSC = [
  { id: 'BNB', symbol: 'BNB', contract: tokens.wbnb.address[ChainId.BSC] },
  { id: 'CAKE', symbol: tokens.cake.symbol, contract: tokens.cake.address[ChainId.BSC] },
  { id: 'MCAKE', symbol: tokens.cake.symbol, contract: tokens.mCake.address[ChainId.BSC] },
  { id: 'VNDC', symbol: tokens.cake.symbol, contract: tokens.vndcV2.address[ChainId.BSC] },
  // { id: 'pancakeswap-token', symbol: tokens.cake.symbol, contract: tokens.cake.address[ChainId.BSC] },
];

// const TOKENS_BSC = [
//   { id: `56:${tokens.wbnb.address[ChainId.BSC]}`, symbol: 'BNB', contract: tokens.wbnb.address[ChainId.BSC] },
//   { id: `56:${tokens.cake.address[ChainId.BSC]}`, symbol: tokens.cake.symbol, contract: tokens.cake.address[ChainId.BSC] },
//   { id: `56:${tokens.mCake.address[ChainId.BSC]}`, symbol: tokens.mCake.symbol, contract: tokens.mCake.address[ChainId.BSC] },
//   { id: `56:${tokens.vndcV2.address[ChainId.BSC]}`, symbol: tokens.vndcV2.symbol, contract: tokens.vndcV2.address[ChainId.BSC] },
// ];

// const TOKENS_BSC_CMC = [
//   { id: '33361', symbol: tokens.mCake.symbol, contract: tokens.mCake.address[ChainId.BSC] },
// ];

const TOKENS_KAI = [];

const TOKENS_HARMONY = [];
const TOKENS_ONUS = [];

const TOKENS_HARMONY_TESTNET = [];

const TOKENS_BSC_TESTNET = [
  { id: 'BNB', symbol: 'BNB', contract: tokens.wbnb.address[ChainId.BSC_TESTNET] },
  { id: 'CAKE', symbol: tokens.cake.symbol, contract: tokens.cake.address[ChainId.BSC_TESTNET] },
  { id: 'MCAKE', symbol: tokens.cake.symbol, contract: tokens.mCake.address[ChainId.BSC_TESTNET] },
  { id: 'VNDC', symbol: tokens.cake.symbol, contract: tokens.vndcV2.address[ChainId.BSC_TESTNET] },
];

const TOKENS = {
  [ChainId.KAI]: TOKENS_KAI,
  [ChainId.BSC]: TOKENS_BSC,
  [ChainId.HARMONY]: TOKENS_HARMONY,
  [ChainId.HARMONY_TESTNET]: TOKENS_HARMONY_TESTNET,
  [ChainId.ONUS]: TOKENS_ONUS,
  [ChainId.BSC_TESTNET]: TOKENS_BSC_TESTNET,
};

const chunk = (arr, n) => (arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : []);

const lookUpPrices = async function (id_array) {
  const prices = {};
  for (const id_chunk of chunk(id_array, 50)) {
    let ids = id_chunk.join('%2C');
    let res = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=' + ids + '&vs_currencies=usd');

    for (const [key, v] of Object.entries(res.data)) {
      if (v.usd) prices[key] = v.usd;
    }
  }
  return prices;
};


const lookUpPricesCMC = async function (id_array) {
  const prices = {};
  for (const id_chunk of chunk(id_array, 50)) {
    let ids = id_chunk.join('%2C');
    let res = await axios.get(`https://wallet-api.pancakeswap.com/v1/prices/list/${ids}`);
    for (const [key, v] of Object.entries(res.data)) {
      const keyFormat = key.split(':')[1];
      prices[keyFormat] = v;
    }
  }
  return prices;
};


// const callApiGetPrice = async function (id_array) {
//   const prices = {};
//   for (const id_chunk of chunk(id_array, 50)) {
//     let ids = id_chunk.join('%2C');
//     let res = await pricesApi.getPrices()
//     for (const [key, v] of Object.entries(res.data)) {
//       const keyFormat = key.split(':')[1];
//       prices[keyFormat] = v;
//     }
//   }
//   return prices;
// };

export async function getPrices(chainId) {
  const prices = await pricesApi.getAllPrices()
  const pricesFormat = {};
  for (const bt of TOKENS[chainId]) if (prices[bt.id]) pricesFormat[bt.contract] = prices[bt.id];
  return {
    ...pricesFormat,
    [tokens.busdt.address[ChainId.BSC]]: 1,
    [tokens.busdc.address[ChainId.BSC]]: 1,
    [tokens.usdtBscTest.address[ChainId.BSC_TESTNET]]: 1,
    // [tokens.mCake.address[ChainId.BSC]]: priceMCake,
    // [tokens.vndcV2.address[ChainId.BSC]]: 0.0000398715,
  };
}

export const calculatorPriceLp = (pair, totalSupply, tokenPrice0, tokenPrice1) => {
  if (!pair || !totalSupply) return undefined;

  const q0 = new BigNumber(pair.reserve0.toExact()).toNumber();
  const q1 = new BigNumber(pair.reserve1.toExact()).toNumber();
  const tvl = q0 * tokenPrice0 + q1 * tokenPrice1;
  const price = tvl / totalSupply.toExact();
  return price;
};
