import React from 'react';
import Loader from 'components/Loader/Loader';
import { classNames } from 'utils/styling';

const DIMENSIONS = {
  xs: 'px-2 h-[28px] !border',
  sm: 'px-3 h-[36px]',
  md: 'px-4 h-[40px]',
  lg: 'px-6 h-[52px]',
    custom:''
};

const SIZE = {
  xs: 'text-sm rounded-full',
  sm: 'text-sm',
  md: '',
  lg: 'text-2xl',
    custom:''
};

const SIZE_LOADER = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '24px',
};

const FILLED = {
  default: 'disabled:pointer-events-none disabled:opacity-40 rounded',
  primary: 'bg-primary  hover:bg-primary/80 !text-black',
  danger: 'bg-red-600 border-red-600 hover:bg-red-600/80 text-white',
  secondary: 'bg-black border-gray-1 border text-white hover:bg-gray-1',
  blue: 'bg-blue1 border-blue1 hover:bg-blue1/80 text-white',
  white: 'bg-white border-white text-black hover:bg-gray-1 hover:text-white',
  black: 'bg-black border-black text-white hover:bg-black/80',
  red: 'bg-red-500 border-red-500 text-white hover:bg-red-500/80',
  pink: 'bg-pink',
  purple: 'bg-purple',
  gray: 'bg-gray-1 hover:bg-gray-1/80 text-white',
    custom:''
};

const OUTLINED = {
  default: 'border disabled:pointer-events-none disabled:opacity-40 rounded',
  primary: 'text-primary border-primary hover:bg-primary hover:text-black',
  blue: 'border-blue hover:text-black hover:bg-blue text-blue focus:bg-blue/30',
  white: 'border-white-4 text-white hover:border-primary hover:text-primary',
  black: 'border-black text-black hover:border-primary hover:text-primary',
  red: ' bg-red hover:bg-red active:bg-red text-red focus:bg-red',
  pink: ' bg-pink hover:bg-pink active:bg-pink text-pink focus:bg-pink',
  purple: ' bg-purple hover:bg-purple active:bg-purple text-purple focus:bg-purple',
  gray: 'border-gray-1 hover:bg-gray-1 active:bg-gray-1 focus:bg-gray-1',
    custom:''
};

const EMPTY = {
  default:
    'bg-transparent hover:brightness-[90%] focus:brightness-[90%] active:brightness-[80%] disabled:pointer-events-none disabled:opacity-40',
  blue: 'text-blue',
  red: 'text-red',
  pink: 'text-pink',
  purple: 'text-purple',
  gray: 'text-gray',
    custom:''
};

const VARIANT = {
  outlined: OUTLINED,
  filled: FILLED,
  empty: EMPTY,
};

const ButtonCustom = React.forwardRef(
  (
    {
      children,
      className = '',
      color = 'primary',
      size = 'md',
      variant = 'filled',
      startIcon = undefined,
      endIcon = undefined,
      fullWidth = false,
      isLoading,
      disabled,
      rounded,
      ...rest
    },
    ref,
  ) => {
    return (
      <button
        {...rest}
        ref={ref}
        disabled={disabled || isLoading}
        className={classNames(
          VARIANT?.[variant]?.['default'],
          VARIANT?.[variant]?.[color],
          SIZE[size],
          variant !== 'empty' ? DIMENSIONS[size] : '',
          fullWidth ? 'w-full' : '',
          'flex items-center justify-center gap-1 transition-colors',
          rounded && '!rounded-full',
          className,
        )}
      >
        {startIcon && startIcon}
        {children}
        {endIcon && endIcon}
        {isLoading && <Loader color="current" size={SIZE_LOADER[size]} />}
      </button>
    );
  },
);

export default ButtonCustom;
