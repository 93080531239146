import { useMemo, useRef, useEffect } from 'react';
import { formatAmount } from 'utils/formatInfoNumbers';
import Percent from 'views/Analytics/components/Percent/Percent';
import Card from 'components/Card/Card';
import { Link } from 'react-router-dom';
import { CurrencyLogo } from 'views/Analytics/components/CurrencyLogo/CurrencyLogo';
import { useSWRTokens } from 'hooks/swr/analytics/tokens';

const DataCard = ({ tokenData }) => {
  return (
    <Link to={`/analytics/tokens/${tokenData.address}`}>
      <div className="cardWrapper">
        <div className="topMoverCard">
          <div className="flex">
            <div
              style={{
                width: '32px',
                height: '32px',
              }}
            >
              {/* wrapped in a box because of alignment issues between img and svg */}
              <CurrencyLogo currency={tokenData} size="32px" />
            </div>
            <div className="ml-3">
              <p>{tokenData.symbol}</p>
              <div className="flex items-center">
                <p className="text-sm-md mr-3">${formatAmount(tokenData.priceUSD)}</p>
                <Percent fontSize="14px" value={tokenData.priceUSDChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const TopTokenMovers = () => {
  const { tokenDatas: allTokens } = useSWRTokens();

  const topPriceIncrease = useMemo(() => {
    return allTokens ? Object.values(allTokens)
      .filter((token) => token?.priceUSDChange > 0)
      .sort(({ data: a }, { data: b }) => {
        // eslint-disable-next-line no-nested-ternary
        return a && b ? (Math.abs(a?.priceUSDChange) > Math.abs(b?.priceUSDChange) ? -1 : 1) : -1;
      })
      .slice(0, Math.min(20, Object.values(allTokens).length))
    : [];
  }, [allTokens]);

  const increaseRef = useRef(null);
  const moveLeftRef = useRef(true);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (increaseRef.current) {
        if (increaseRef.current.scrollLeft === increaseRef.current.scrollWidth - increaseRef.current.clientWidth) {
          moveLeftRef.current = false;
        } else if (increaseRef.current.scrollLeft === 0) {
          moveLeftRef.current = true;
        }
        increaseRef.current.scrollTo(
          moveLeftRef.current ? increaseRef.current.scrollLeft + 1 : increaseRef.current.scrollLeft - 1,
          0,
        );
      }
    }, 30);

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  if (topPriceIncrease.length === 0) {
    return null;
  }

  return (
    <Card className="h-full mt-10 pt-5">
      <h3 className="ml-5 ">Top Movers</h3>
      <div className="scrollableRow" ref={increaseRef}>
        {topPriceIncrease?.map?.((data) => (
          <DataCard key={`top-card-token-${data?.address}`} tokenData={data} />
        ))}
      </div>
    </Card>
  );
};

export default TopTokenMovers;
