import React from 'react';
import { useFetchNFTsPublicData, useFetchUserDataNFT, useNFT } from 'store/NFTs/hook';
import { useParams } from 'react-router-dom';
import Back from 'components/Back/Back';
import Loader from 'components/Loader/Loader';
import Detail from './components/Detail';
import WrongNetWork from '../Land/components/WrongNetWork';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';

const NFTPage = () => {
  useFetchNFTsPublicData();
  useFetchUserDataNFT();
  const { chainId } = useActiveWeb3React();
  const { id } = useParams();
  const nft = useNFT(id, chainId);

  if (!nft) {
    return (
      <>
        <Back />
        <div className="container mx-auto p-3 h-96 mt-16">
          <Loader className="border-t-4 h-20 w-20 mx-auto" />
        </div>
      </>
    );
  }

  if (nft?.chainId !== undefined && nft.chainId !== chainId) {
    return <WrongNetWork supportChain={nft?.chainId} type="NFT" />;
  }

  return (
    <>
      <Back />
      <div className="px-4 sm:px-6">
        <div className="container max-w-screen-xl mx-auto">
          <Detail nft={nft} />
        </div>
      </div>
    </>
  );
};

export default NFTPage;
