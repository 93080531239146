import Card from 'components/Card/Card';
import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import Progress from 'components/Progress/Progress';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Value from 'components/Value/Value';
import Badge from '../../Lands/components/Badge';
import Tooltip from 'components/Tooltip/Tooltip';
import { NETWORK_LABEL } from 'constants/networks';
import { ICON_CHAIN_URL } from 'constants/index';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const ProjectCard = ({ nft }) => {
  const navigate = useNavigate();

  const rendererStartIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="flex mt-2 justify-center sm:justify-start">
          <Badge className="flex justify-start items-center bg-primary">Opening</Badge>
        </div>
      );
    }

    return (
      <div className="flex mt-2 justify-center sm:justify-start">
        <Badge className="flex justify-start items-center">
          <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
          Open in {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </Badge>
      </div>
    );
  };

  const navigateToDetail = () => {
    if (!nft?.notComplete) {
      navigate(`/nft/${nft?.slug || nft.d}`);
    }
  };

  return (
    <div
      onClick={navigateToDetail}
      className="col-span-2 w-96 max-w-full sm:w-full mx-auto relative z-20 cursor-pointer border-2 border-yellow-800 hover:border-primary rounded-xl"
    >
      <Card className="p-5 h-full flex flex-col justify-between">
        <div>
          <figure className="block sm:flex items-center">
            <div className="rounded-50 overflow-hidden bg-black1 flex justify-center items-center w-20 h-20 my-6 mx-auto sm:mx-6 sm:ml-1.5">
              <img alt="logo" src={nft?.img} className="w-auto h-full cursor-pointer" />
            </div>
            <figcaption className="flex-1">
              <h3 className="text-primary text-xl font-bold text-center sm:text-left">{nft.name}</h3>
              <div className="block sm:flex justify-between items-center">
                <div>
                  {nft?.openTime ? (
                    <Countdown zeroPadTime={2} date={nft?.openTime} renderer={rendererStartIn} />
                  ) : (
                    <div className="flex mt-2 justify-center sm:justify-start">
                      <Badge className="flex justify-start items-center">
                        <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
                        Opens in TBA
                      </Badge>
                    </div>
                  )}
                  <div className="mt-1 flex text-center justify-center sm:justify-start">
                    <div className="flex">
                      <Tooltip tooltip={`${NETWORK_LABEL[nft?.chainId]} Chain`}>
                        <img
                          src={ICON_CHAIN_URL[nft?.chainId]}
                          style={{
                            width: 25,
                          }}
                          className="mr-2"
                        />
                      </Tooltip>

                      <div>
                        <Badge>{nft?.buyToken.symbol}</Badge>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex sm:hidden md:flex flex justify-center mt-2 sm:mt-0">
                  <div>
                    <ButtonCustom className="sm:px-7" onClick={navigateToDetail}>
                      Mint
                    </ButtonCustom>
                  </div>
                </div>
              </div>
            </figcaption>
          </figure>
          <p className="text-white text-md my-2 content-intro-project">{nft?.content}</p>
        </div>
        <div>
          <div className="flex flex-wrap flex-4 mt-7">
            <div className="w-1/3 flex">
              <div>
                <p className="text-sm-md text-white text-center mb-1">Total NFT</p>
                <div className="font-bold text-primary text-base text-center">
                  {nft?.maxMint ? <Value className="inline" value={nft?.maxMint} decimals={0} /> : 'TBA'}
                </div>
              </div>
            </div>
            <div className="w-1/3">
              {nft?.tokenBurn ? (
                <>
                  <p className="text-sm-md text-white text-center mb-1">NFT Burned</p>
                  <div className="font-bold text-primary text-base text-center">
                    {typeof nft?.NFTBurned === 'number' ? (
                      <Value className="inline" value={nft?.NFTBurned} decimals={0} />
                    ) : (
                      '--'
                    )}
                  </div>
                </>
              ) : null}
            </div>
            <div className="w-1/3">
              <p className="text-sm-md text-white text-center mb-1">Price</p>
              <div className="font-bold text-primary text-base text-center">
                {nft?.price?.value ? (
                  <Value
                    className="inline"
                    value={nft?.price?.value}
                    unit={` ${nft?.buyToken?.symbol}`}
                    decimals={nft?.price?.decimals}
                  />
                ) : (
                  'TBA'
                )}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <p className="text-sm-md text-white mb-1">Progress</p>
            <Progress percent={nft?.maxMint ? (nft?.minted * 100) / nft?.maxMint : 0} />
            <div className="flex justify-between mt-0.5">
              <span className="text-white text-sm-md font-normal">
                {nft?.maxMint && typeof nft?.minted === 'number' ? (
                  <Value
                    className="inline"
                    value={nft?.maxMint ? (nft?.minted * 100) / nft?.maxMint : 0}
                    decimals={2}
                  />
                ) : (
                  '0.00'
                )}{' '}
                %
              </span>
              <span className="text-white text-sm-md font-normal">
                {nft?.maxMint && typeof nft?.minted === 'number' ? (
                  <>
                    <Value className="inline" value={nft?.minted || 0} decimals={0} /> /{' '}
                    <Value className="inline" value={nft?.maxMint} decimals={0} />
                  </>
                ) : (
                  '0/TBA'
                )}
              </span>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

ProjectCard.propTypes = {
  nft: PropTypes.object.isRequired,
};

export default ProjectCard;
