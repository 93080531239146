import { Dialog } from '@headlessui/react';
import PropTypes from 'prop-types';
import {classNames} from "../../utils/styling";

const ModalTitle = ({ children, onClose, className, leftIcon }) => {
  return (
    <Dialog.Title as="h3" className={classNames("text-left flex items-start justify-between gap-2 leading-6 text-white bg-black1 text-2xl font-bold p-3", className)}>
      {leftIcon ? leftIcon : null}
      {children}
      <button  onClick={onClose} className="shrink-0">
        <img src="/icon/close.svg" alt="close" width={24} height={24} />
      </button>
    </Dialog.Title>
  );
};

ModalTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default ModalTitle;
