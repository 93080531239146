import PropTypes from 'prop-types';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';

const ToggleShowCell = ({ row }) => {
  return (
    <div>
      <span className="text-primary font-bold hidden sm:block">{row.isExpanded ? 'HIDE' : 'SHOW'}</span>
      <span className="text-primary font-bold block sm:hidden">
        {row.isExpanded ? <FiChevronUp /> : <FiChevronDown />}
      </span>
    </div>
  );
};

ToggleShowCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export default ToggleShowCell;
