import { MCAKE_ADDRESS, } from 'constants/contracts';
import { SYMBOL_LP_PLATFORM } from 'constants/index';
import { ChainId, Token } from 'ezcake-v2-sdk';
import { DOMAIN } from "config";

const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
    decimals: 18,
  },
  wbnb: {
    symbol: 'WBNB',
    address: {
      [ChainId.BSC]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  mCake: {
    symbol: 'mCake',
    address: {
      [ChainId.BSC]: MCAKE_ADDRESS[ChainId.BSC],
      [ChainId.BSC_TESTNET]: MCAKE_ADDRESS[ChainId.BSC_TESTNET],
      [ChainId.KAI]: MCAKE_ADDRESS[ChainId.KAI],
      [ChainId.HARMONY]: MCAKE_ADDRESS[ChainId.HARMONY],
      [ChainId.ONUS]: MCAKE_ADDRESS[ChainId.ONUS],
      [ChainId.ONUS_TESTNET]: MCAKE_ADDRESS[ChainId.ONUS_TESTNET],
    },
    decimals: 18,
    projectLink: DOMAIN,
  },
  usdtBscTest: {
    symbol: 'USDT',
    address: {
      [ChainId.BSC_TESTNET]: '0x0FE843f83A9808aFf8838C52A6740E90dab2c036',
    },
    decimals: 18,
    projectLink: '',
  },
  usdtOnus: {
    symbol: 'USDT',
    address: {
      [ChainId.ONUS]: '0xff276c6bca1F66Fd54a8915e830735D6ab0C7B09',
    },
    decimals: 18,
    projectLink: '',
  },
  busdc: {
    symbol: 'USDC',
    address: {
      [ChainId.BSC]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    decimals: 18,
    projectLink: '',
  },
  busdt: {
    symbol: 'USDT',
    address: {
      [ChainId.BSC]: '0x55d398326f99059ff775485246999027b3197955',
    },
    decimals: 18,
    projectLink: '',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      [ChainId.BSC]: '0x55d398326f99059fF775485246999027B3197955',
    },
    decimals: 18,
    projectLink: '',
  },
  vndc: {
    symbol: 'VNDC',
    address: {
      [ChainId.KAI]: '0xeFF34B63f55200a9D635B8ABBBFCC719b4977864',
      [ChainId.ONUS]: '0xC1D3A18C32c42D5c033C2d4bfc151dB8fd2c9D81',
    },
    decimals: 0,
    projectLink: 'https://vndc.io/',
  },
  vndcV2: {
    symbol: 'VNDC',
    address: {
      [ChainId.KAI]: '0xA276DC434A366C62ef010585b3885D0C85154389',
      [ChainId.ONUS]: '0xA276DC434A366C62ef010585b3885D0C85154389',
      [ChainId.BSC]: '0xA276DC434A366C62ef010585b3885D0C85154389',
      [ChainId.BSC_TESTNET]: '0xA276DC434A366C62ef010585b3885D0C85154389',
    },
    decimals: 0,
    projectLink: 'https://vndc.io/',
  },
  eth: {
    symbol: 'ETH',
    address: {
      [ChainId.KAI]: '0x1540020a94aA8bc189aA97639Da213a4ca49d9a7',
      [ChainId.BSC]: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      [ChainId.ONUS]: '0xB4373ebB073A4DcbA47e567d075a9583Fa3C763e',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      [ChainId.BSC]: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      [ChainId.KAI]: '0x3444273AFdf9E00Fd0491C8a97738acA3ebB2A93',
      [ChainId.ONUS]: '0xdfB5E8a4AC08E46258A12AbE737bba5D8c452508',
    },
    decimals: 18,
    projectLink: '',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      [ChainId.BSC]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      [ChainId.BSC_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    },
    decimals: 18,
    projectLink: '',
  },
  onus: {
    symbol: 'ONUS',
    address: {
      [ChainId.BSC]: '0x1851ccD370C444ff494d7505e6103959bCE9F9d9',
      [ChainId.BSC_TESTNET]: '0x1851ccD370C444ff494d7505e6103959bCE9F9d9',
    },
    decimals: 18,
    projectLink: '',
  },
  btc: {
    symbol: 'BTC',
    address: {
      [ChainId.ONUS]: '0x935765Ad27a1af00f73097c998A9fb721D2d7790',
    },
    decimals: 18,
    projectLink: '',
  },
  cake: {
    symbol: 'Cake',
    address: {
      [ChainId.BSC]: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  cakeLp: {
    symbol: SYMBOL_LP_PLATFORM.cake,
    decimals: 18,
  },
  dflLp: {
    symbol: SYMBOL_LP_PLATFORM.eCake,
    decimals: 18,
  },
  eCakeLp: {
    symbol: SYMBOL_LP_PLATFORM.eCake,
    decimals: 18,
  }
};

export const MCAKE = {
  [ChainId.KAI]: new Token(
      ChainId.KAI,
      tokens.mCake.address[ChainId.KAI],
      tokens.mCake.decimals,
      tokens.mCake.symbol,
      'mCake',
  ),
  [ChainId.BSC]: new Token(
      ChainId.BSC,
      tokens.mCake.address[ChainId.BSC],
      tokens.mCake.decimals,
      tokens.mCake.symbol,
      'mCake',
  ),
  [ChainId.BSC_TESTNET]: new Token(
      ChainId.BSC_TESTNET,
      tokens.mCake.address[ChainId.BSC_TESTNET],
      tokens.mCake.decimals,
      tokens.mCake.symbol,
      'mCake',
  ),
  [ChainId.HARMONY]: new Token(
      ChainId.HARMONY,
      tokens.mCake.address[ChainId.HARMONY],
      tokens.mCake.decimals,
      tokens.mCake.symbol,
      'mCake',
  ),
  [ChainId.ONUS]: new Token(
      ChainId.ONUS,
      tokens.mCake.address[ChainId.ONUS],
      tokens.mCake.decimals,
      tokens.mCake.symbol,
      'mCake',
  ),
  [ChainId.ONUS_TESTNET]: new Token(
      ChainId.ONUS_TESTNET,
      tokens.mCake.address[ChainId.ONUS_TESTNET],
      tokens.mCake.decimals,
      tokens.mCake.symbol,
      'mCake',
  ),
};

export const CAKE = {
  [ChainId.BSC]: new Token(
      ChainId.BSC,
      tokens.cake.address[ChainId.BSC],
      tokens.cake.decimals,
      tokens.cake.symbol,
      'Cake',
  ),
  // [ChainId.BSC_TESTNET]: new Token(
  //     ChainId.BSC_TESTNET,
  //     tokens.cake.address[ChainId.BSC_TESTNET],
  //     tokens.cake.decimals,
  //     tokens.cake.symbol,
  //     'Cake',
  // ),
};


export const USDT = {
  [ChainId.ONUS]: new Token(
    ChainId.ONUS,
    tokens.usdtOnus.address[ChainId.ONUS],
    tokens.usdtOnus.decimals,
    tokens.usdtOnus.symbol,
    'ONUS-peg USDT Token',
  ),
  [ChainId.BSC_TESTNET]: new Token(
      ChainId.BSC_TESTNET,
      tokens.usdtBscTest.address[ChainId.BSC_TESTNET],
      tokens.usdtBscTest.decimals,
      tokens.usdtBscTest.symbol,
      'USDT Token',
  ),
  [ChainId.BSC]: new Token(
      ChainId.BSC,
      tokens.busdt.address[ChainId.BSC],
      tokens.busdt.decimals,
      tokens.busdt.symbol,
      'USDT Token',
  ),
};

export const BTCB = {
  [ChainId.BSC_TESTNET]: new Token(
      ChainId.BSC_TESTNET,
      tokens.btcb.address[ChainId.BSC_TESTNET],
      tokens.btcb.decimals,
      tokens.btcb.symbol,
      'BTCB Token',
  ),
  [ChainId.BSC]: new Token(
      ChainId.BSC,
      tokens.btcb.address[ChainId.BSC],
      tokens.btcb.decimals,
      tokens.btcb.symbol,
      'BTCB Token',
  ),
};

export const ONUS = {
  [ChainId.BSC_TESTNET]: new Token(
      ChainId.BSC_TESTNET,
      tokens.onus.address[ChainId.BSC_TESTNET],
      tokens.onus.decimals,
      tokens.onus.symbol,
      'ONUS Token',
  ),
  [ChainId.BSC]: new Token(
      ChainId.BSC,
      tokens.onus.address[ChainId.BSC],
      tokens.onus.decimals,
      tokens.onus.symbol,
      'ONUS Token',
  ),
};

export const VNDC_V2 = {
  [ChainId.BSC_TESTNET]: new Token(
      ChainId.BSC_TESTNET,
      tokens.vndcV2.address[ChainId.BSC_TESTNET],
      tokens.vndcV2.decimals,
      tokens.vndcV2.symbol,
      'ONUS Token',
  ),
  [ChainId.BSC]: new Token(
      ChainId.BSC,
      tokens.vndcV2.address[ChainId.BSC],
      tokens.vndcV2.decimals,
      tokens.vndcV2.symbol,
      'VNDC Token',
  ),
};

export const VNDC = {
  [ChainId.ONUS]: new Token(
      ChainId.BSC_TESTNET,
      tokens.vndc.address[ChainId.ONUS],
      tokens.vndc.decimals,
      tokens.vndc.symbol,
      'VNDC Token',
  ),
};


export default tokens;
