import BigNumber from 'bignumber.js';
import erc20Abi from 'config/abi/erc20.json';
import { PLATFORM, vaultsV2Config } from 'constants/vaults';
import multicall from 'utils/multicall';
import vaultABI from 'config/abi/vaultAbi.json';
import masterChefAbi from 'config/abi/masterchef.json';
import masterChefCakeAbi from 'config/abi/masterchefCake.json';
import masterChefBECOAbi from 'config/abi/masterchefBeco.json';
import masterChefBDSAbi from 'config/abi/masterchefBds.json';
import masterChefMiaAbi from 'config/abi/masterchefMia.json';

export const fetchVaultsTotalStaked = async (vaults, chainId) => {
  const calls = vaults.map((vault) => {
    return {
      address: vault.contractAddress,
      name: 'totalStakedWantTokens',
    };
  });

  const rawTotalStaked = await multicall(vaultABI, calls, chainId);

  const parsedTotalStaked = rawTotalStaked.map((lpBalance) => {
    return new BigNumber(lpBalance[0]._hex);
  });

  return parsedTotalStaked;
};

export const fetchVaultsPendingRewardBSC = async (vaults, chainId) => {
  const vaultsDFL = vaults.filter((vault) => vault.platform === PLATFORM.eCake);
  const vaultsCAKE = vaults.filter((vault) => vault.platform === PLATFORM.cake);

  const callsDFL = vaultsDFL.map((vault) => {
    return {
      address: vault.contractMasterChefAddress,
      name: 'pendingRewards',
      params: [vault.farmPid, vault.contractStratAddress],
    };
  });

  const callsCake = vaultsCAKE.map((vault) => {
    return {
      address: vault.contractMasterChefAddress,
      name: vault?.pendingRewardsMethod,
      params: [vault.farmPid, vault.contractStratAddress],
    };
  });

  const rawEarningsDFL = await multicall(masterChefAbi, callsDFL, chainId);
  const rawEarningsCake = await multicall(masterChefCakeAbi, callsCake, chainId);

  const parsedEarnings = rawEarningsDFL.map((earnings, index) => {
    return {
      vid: vaultsDFL[index].vid,
      earnings: new BigNumber(earnings).toJSON(),
    };
  });

  const parsedEarningsCake = rawEarningsCake.map((earnings, index) => {
    return {
      vid: vaultsCAKE[index].vid,
      earnings: new BigNumber(earnings[0]._hex).toJSON(),
    };
  });

  return [...parsedEarnings, ...parsedEarningsCake];
};

export const fetchVaultsPendingRewardKAI = async (vaults, chainId) => {
  const vaultsDFL = vaults.filter((vault) => vault.platform === PLATFORM.eCake && !vault.isFinished);
  const vaultsBECO = vaults.filter((vault) => vault.platform === PLATFORM.beco && !vault.isFinished);
  const vaultsBDS = vaults.filter((vault) => vault.platform === PLATFORM.bds && !vault.isFinished);

  const callsDFL = vaultsDFL.map((vault) => {
    return {
      address: vault.contractMasterChefAddress,
      name: 'pendingRewards',
      params: [vault.farmPid, vault.contractStratAddress],
    };
  });

  const callsBECO = vaultsBECO.map((vault) => {
    return {
      address: vault.contractMasterChefAddress,
      name: 'pendingBeco',
      params: [vault.farmPid, vault.contractStratAddress],
    };
  });

  const callsBDS = vaultsBDS.map((vault) => {
    return {
      address: vault.contractMasterChefAddress,
      name: 'pendingKRC20Token',
      params: [vault.farmPid, vault.contractStratAddress],
    };
  });

  const rawEarningsDFL = await multicall(masterChefAbi, callsDFL, chainId);
  const rawEarningsBECO = await multicall(masterChefBECOAbi, callsBECO, chainId);
  const rawEarningsBDS = await multicall(masterChefBDSAbi, callsBDS, chainId);

  const parsedEarnings = rawEarningsDFL.map((earnings, index) => {
    return {
      vid: vaultsDFL[index].vid,
      earnings: new BigNumber(earnings).toJSON(),
    };
  });

  const parsedEarningsBECO = rawEarningsBECO.map((earnings, index) => {
    return {
      vid: vaultsBECO[index].vid,
      earnings: new BigNumber(earnings[0]._hex).toJSON(),
    };
  });

  const parsedEarningsBDS = rawEarningsBDS.map((earnings, index) => {
    return {
      vid: vaultsBDS[index].vid,
      earnings: new BigNumber(earnings[0]._hex).toJSON(),
    };
  });

  return [...parsedEarnings, ...parsedEarningsBECO, ...parsedEarningsBDS];
};

export const fetchVaultsPendingRewardONUS = async (vaults, chainId) => {
  const vaultsMIA = vaults.filter((vault) => vault.platform === PLATFORM.mia);

  const callsMIA = vaultsMIA.map((vault) => {
    return {
      address: vault.contractMasterChefAddress,
      name: vault?.pendingRewardsMethod,
      params: [vault.farmPid, vault.contractStratAddress],
    };
  });

  const rawEarningsMia = await multicall(masterChefMiaAbi, callsMIA, chainId);

  const parsedEarningsMia = rawEarningsMia.map((earnings, index) => {
    return {
      vid: vaultsMIA[index].vid,
      earnings: new BigNumber(earnings[0]._hex).toJSON(),
    };
  });

  return [...parsedEarningsMia];
};

export const fetchVaultsTotalSupply = async (vaults, chainId) => {
  const calls = vaults.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress,
      name: 'totalSupply',
    };
  });

  const vaultsTotalSupply = await multicall(erc20Abi, calls, chainId);

  return [
    ...vaults.map((v, index) => ({
      vid: v.vid,
      totalSupply: new BigNumber(vaultsTotalSupply[index]).toJSON(),
    })),
  ];
};

export const fetchVaultsV2TotalStakedOfStrategy = async (chainId) => {
  const calls = vaultsV2Config[chainId].map((vault) => {
    return {
      address: vault.contractVaultStakedAddress,
      name: 'stakedWantTokens',
      params: [vault.strategyContractAddress],
    };
  });

  const rawStakedBalances = await multicall(vaultABI, calls, chainId);

  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex);
  });

  return parsedStakedBalances;
};

export const fetchVaultsV2TotalShares = async (chainId) => {
  const calls = vaultsV2Config[chainId].map((vault) => {
    return {
      address: vault.contractAddress,
      name: 'totalShares',
    };
  });

  const rawTotalShares = await multicall(vaultABI, calls, chainId);

  const parsedTotalShares = rawTotalShares.map((totalShares) => {
    return new BigNumber(totalShares[0]._hex);
  });

  return parsedTotalShares;
};
