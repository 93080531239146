import Tooltip from 'components/Tooltip/Tooltip';
import { AVERAGE_BLOCK_TIME } from 'config/index';
import { useMemo } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { FiClock } from 'react-icons/fi';
import { formatNumber } from 'utils/formatBalance';
import { getPoolBlockInfo } from 'views/Castles/helpers';
import PropTypes from 'prop-types';
import { useCurrentBlock } from 'store/block/hooks';
import {useChainId} from "wagmi";

const BlockCountdown = ({ pool, isIfo }) => {
  const chainId = useChainId();
  const currentBlock = useCurrentBlock();
  const {
    shouldShowBlockCountdown,
    blocksUntilStart,
    blocksRemaining,
    hasPoolStarted,
    blocksToDisplay,
    durationDisplay,
  } = getPoolBlockInfo(pool, currentBlock, chainId);

  const timer = useMemo(() => {
    return blocksToDisplay * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksToDisplay]);

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null;

    return (
      <>
        {days > 0 ? `${zeroPad(days)}d-` : ''}
        {zeroPad(hours)}h-{zeroPad(minutes)}m-{zeroPad(seconds)}s
      </>
    );
  };

  return (
    <>
      {shouldShowBlockCountdown && (
        <div className="flex justify-between items-center mb-1 text-white">
          <p>
            {hasPoolStarted
              ? `${isIfo ? 'Distribution ends in' : 'Rewards end in'}`
              : `${isIfo ? 'Distribution starts in' : 'Rewards start in'}`}
          </p>
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>~{formatNumber(blocksToDisplay, 0, 0)} Blocks</p>
              </div>
            }
          >
            <div className="flex items-center">
              {(blocksRemaining || blocksUntilStart) && currentBlock ? (
                <Countdown zeroPadTime={2} date={timer} renderer={renderCountdown} />
              ) : (
                '...'
              )}
              <FiClock className="ml-1" size={18} />
            </div>
          </Tooltip>
        </div>
      )}
      <div className="flex justify-between items-center mb-1 text-white">
        <p>{isIfo ? 'Distribution Period' : 'Duration'}</p>
        <p>{durationDisplay ? durationDisplay.toFixed(2) : '...'} days</p>
      </div>
    </>
  );
};

BlockCountdown.propTypes = {
  pool: PropTypes.object.isRequired,
};

export default BlockCountdown;
