import React from 'react';
import {useNavigate} from "react-router-dom";

const ComingSoon = () => {
    const navigate = useNavigate();
    navigate('/swap')
    return (
        <div >
            {/*<img alt="logo" src="/logo.svg" width="200" height="200"/>*/}
            {/*<p className="text-white text-xl font-bold">Coming Soon</p>*/}
        </div>
    );
};

export default ComingSoon;