import { classNames } from 'utils/styling';
import PropTypes from 'prop-types';
import { escapeRegExp } from 'utils/index';

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`); // match escaped "." characters via in a non-capturing group

const InputWithButton = ({
  value,
  onUserInput,
  placeholder = '0.0',
  btnOnClick,
  className,
  classNameInput,
  classNameBtn,
  ...rest
}) => {
  const enforcer = (nextUserInput) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput);
    }
  };

  return (
    <div className={classNames('flex items-center border-primary border-2 rounded-2xl truncate', className)}>
      <input
        {...rest}
        className={classNames('bg-transparent w-full py-2 rounded-2xl pl-2 pr-4 truncate', classNameInput)}
        value={value}
        onChange={(event) => {
          enforcer(event.target.value.replace(/,/g, '.'));
        }}
        // universal input options
        inputMode="decimal"
        autoComplete="off"
        autoCorrect="off"
        // text-specific options
        type="text"
        pattern="^[0-9]*[.,]?[0-9]*$"
        placeholder={placeholder}
        minLength={1}
        maxLength={79}
        spellCheck="false"
      />
      <span className={classNames('cursor-pointer mr-2', classNameBtn)} onClick={btnOnClick}>
        Max
      </span>
    </div>
  );
};

InputWithButton.propTypes = {
  value: PropTypes.string,
  onUserInput: PropTypes.func,
  placeholder: PropTypes.string,
  btnOnClick: PropTypes.func,
};

export default InputWithButton;
