import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import Logo from 'components/Logo/Logo';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useReward from 'hooks/useReward';
import { useCallback, useState } from 'react';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import HarvestFeeCountdownRow from 'views/Farms/components/HarvestFeeCountdown';
import ModalWarningHarvestFee from 'components/Modal/ModalWarningHarvestFee';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const Harvest = ({ farm, userDataLoaded, masterChefAddress, earningToken, onUpdateUserFarm }) => {
  const pid = farm.pid;
  const { account, chainId } = useActiveWeb3React();
  const [pendingTx, setPendingTx] = useState(false);
  const { onReward } = useReward(pid, masterChefAddress);
  const [open, setOpen] = useState(false);

  const toggleWarning = () => setOpen(!open);

  const { earnings, stakedBalance, unlockTimestamp } = farm.userData;

  const usdTokenEarning = earnings
    ? new BigNumber(getFullDisplayBalance(earnings)).times(farm.earningTokenPrice || 0).toNumber()
    : 0;

  const handleReward = useCallback(async () => {
    try {
      if (open) setOpen(false);
      setPendingTx(true);
      await onReward();
      showToastSuccess('Harvested', `Your ${earningToken.symbol} earnings have been sent to your wallet!`);
      onUpdateUserFarm();
      setPendingTx(false);
    } catch (e) {
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      console.error(e);
    }
  }, [earningToken.symbol, onReward, onUpdateUserFarm, open]);

  return (
    <div>
      <ModalWarningHarvestFee open={open} toggleModal={toggleWarning} onConfirm={handleReward} />
      <div className="flex">
        <div className="text-center">
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>{earningToken.symbol} Price</p>
                <p>{formatNumber(farm?.earningTokenPrice ?? 0, 2, 4)}$</p>
              </div>
            }
          >
            <div className="flex justify-center">
              <Logo size={40} src={`/tokens/${earningToken.symbol.toLowerCase()}.png`} alt="token logo" />
            </div>
          </Tooltip>
          <Value
            className="text-primary font-bold text-xl"
            value={account ? getBalanceNumber(earnings, earningToken.decimals) : 0}
          />
          <Value
            prefix="~"
            className="text-sm text-white"
            value={account ? usdTokenEarning : 0}
            decimals={2}
            unit="USD"
          />
        </div>
        <div className="ml-4">
          <p className="text-white font-bold text-xl">Pending Rewards</p>
          {!farm?.isFinished &&
            (account ? (
              userDataLoaded ? (
                <ButtonCustom
                  className="w-full"
                  disabled={pendingTx || earnings.eq(new BigNumber(0))}
                  isLoading={pendingTx}
                  onClick={() => {
                    const hasHarvestFee = unlockTimestamp?.toNumber() * 1000 > Date.now();
                    if (hasHarvestFee && chainId === ChainId.HARMONY) {
                      toggleWarning();
                    } else {
                      handleReward();
                    }
                  }}
                >
                  {pendingTx ? `Collecting ${earningToken.symbol}` : 'Harvest'}
                </ButtonCustom>
              ) : (
                <ButtonCustom className="w-full">
                  <Dots>Loading</Dots>
                </ButtonCustom>
              )
            ) : (
              <ButtonCustom className="w-full" disabled>
                Harvest
              </ButtonCustom>
            ))}
        </div>
      </div>
      {chainId === ChainId.HARMONY && stakedBalance.gt(0) && (
        <HarvestFeeCountdownRow unlockTimestamp={unlockTimestamp} />
      )}
    </div>
  );
};

export default Harvest;
