import { parseUnits } from '@ethersproject/units';
import tokens, { MCAKE, USDT } from 'constants/tokens';
import { ChainId, CurrencyAmount, JSBI, NativeCurrency, Token, TokenAmount } from 'ezcake-v2-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrency } from 'hooks/Tokens';
import { useTradeExactIn, useTradeExactOut } from 'hooks/Trades';
import useParsedQueryString from 'hooks/useParsedQueryString';
import { isAddress } from 'utils/index';
import { computeSlippageAdjustedAmounts } from 'utils/prices';
import { useCurrencyBalances } from 'hooks/wallet';
import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions';
import { useUserSlippageTolerance } from 'store/user/hooks';
import { WrapType } from "hooks/useWrapCallback";
import {useAccount, useChainId} from "wagmi";

export function useSwapState() {
  return useSelector((state) => state.swap);
}

export function useSwapActionHandlers() {
  const dispatch = useDispatch();
  const chainId = useChainId()

  const onCurrencySelection = useCallback(
    (field, currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token
              ? currency.address
              : currency === NativeCurrency[chainId]
              ? NativeCurrency[chainId].symbol
              : '',
        }),
      );
    },
    [dispatch, chainId],
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field, typedValue) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch],
  );

  const onChangeRecipient = useCallback(
    (recipient) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch],
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  };
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value, currency) {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();

    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed), currency.chainId);
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  // necessary for all paths to return a value
  return undefined;
}

const BAD_RECIPIENT_ADDRESSES = [
  '0x64203f29f4d6a7e199b6f6afbe65f1fa914c7c4e', // v2 factory
];

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade, checksummedAddress) {
  return (
    trade.route.path.some((token) => token.address === checksummedAddress) ||
    trade.route.pairs.some((pair) => pair.liquidityToken.address === checksummedAddress)
  );
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo() {
  const {address} = useAccount()

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);
  const to = address ?? null;

  const relevantTokenBalances = useCurrencyBalances(address ?? undefined, [
    inputCurrency ?? undefined,
    outputCurrency ?? undefined,
  ]);
  const isExactIn = independentField === Field.INPUT;
  const parsedAmount = tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined);

  const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined);
  const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn ? parsedAmount : undefined);

  const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut;

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1],
  };

  const currencies = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  };

  let inputError;
  if (!address) {
    inputError = 'Connect Wallet';
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount';
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token';
  }

  const formattedTo = isAddress(to);
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient';
  } else if (
    BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 ||
    (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo)) ||
    (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
  ) {
    inputError = inputError ?? 'Invalid recipient';
  }

  const [allowedSlippage] = useUserSlippageTolerance();

  const slippageAdjustedAmounts =
    v2Trade && allowedSlippage && computeSlippageAdjustedAmounts(v2Trade, allowedSlippage);

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    slippageAdjustedAmounts ? slippageAdjustedAmounts[Field.INPUT] : null,
  ];

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = `Insufficient ${amountIn.currency.symbol} balance`;
  }

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    v2Trade: v2Trade ?? undefined,
    inputError,
  };
}

const DEAFAULT_TOKEN_1 = {
  [ChainId.KAI]: USDT[ChainId.ONUS],
  [ChainId.ONUS]: USDT[ChainId.ONUS],
  [ChainId.BSC_TESTNET]: MCAKE[ChainId.BSC_TESTNET],
  [ChainId.BSC]: MCAKE[ChainId.BSC],
};

export function parseTokenAmountURLParameter(urlParam) {
  // eslint-disable-next-line no-restricted-globals
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : '';
}

function parseIndependentFieldURLParameter(urlParam) {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT;
}

export function queryParametersToSwapState(parsedQs, chainId) {
  let inputCurrency =
    typeof parsedQs?.outputCurrency === 'string'
      ? isAddress(parsedQs?.outputCurrency) || NativeCurrency[chainId]?.symbol
      : DEAFAULT_TOKEN_1?.[chainId]?.address || DEAFAULT_TOKEN_1?.[chainId]?.symbol || '';

  let outputCurrency = isAddress(parsedQs?.inputCurrency) || NativeCurrency[chainId].symbol;
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = '';
    } else {
      outputCurrency = '';
    }
  }

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch() {
  const chainId = useChainId()
  const dispatch = useDispatch();
  const parsedQs = useParsedQueryString();

  const [result, setResult] = useState();

  useEffect(() => {
    if (!chainId) return;
    const parsed = queryParametersToSwapState(parsedQs, chainId);

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId,
      }),
    );

    setResult({ inputCurrencyId: parsed[Field.INPUT].currencyId, outputCurrencyId: parsed[Field.OUTPUT].currencyId });
  }, [chainId]);

  return result;
}

export const useDefaultsSwap = () => {
  const chainId = useChainId()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            replaceSwapState({
                typedValue: '',
                field: WrapType.WRAP,
                inputCurrencyId: tokens.mCake.address[chainId],
                outputCurrencyId: undefined,
            }),
        );
    }, [chainId]);
};

