import { showToastTx } from 'components/CustomToast/CustomToast';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useCallback } from 'react';
import { compoundVault } from 'utils/calls/vaults';
import { useVaultContract } from 'hooks/useContract';

const useCompound = (vaultAddress) => {
  const { chainId } = useActiveWeb3React();
  const vaultContract = useVaultContract(vaultAddress);

  const handleCompound = useCallback(async () => {
    const txHash = await compoundVault(vaultContract);
    showToastTx(txHash, chainId);
    console.log(txHash);
  }, [chainId, vaultContract]);

  return { onCompound: handleCompound };
};

export default useCompound;
