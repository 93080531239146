import Input from 'components/Input/Input';
import { useCallback, useMemo, useRef, useState } from 'react';
import { NativeCurrency } from 'ezcake-v2-sdk';
import useDebounce from 'hooks/useDebounce';
import { useAllTokens, useToken } from 'hooks/Tokens';
import { isAddress } from 'utils';
import CommonBases from './CommonBases';
import CurrencyList from './CurrencyList';
import { filterTokens, useSortedTokensByQuery } from './filtering';
import useTokenComparator from './sorting';
import Card from 'components/Card/Card';
import { classNames } from 'utils/styling';
import { useRemoveUserAddedToken, useUserAddedTokens } from 'store/user/hooks';
import { FiTrash } from 'react-icons/fi';
import { CHAINS_SUPPORT_IMPORTED_TOKENS } from 'constants/swap';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {useChainId} from "wagmi";

const TABS = {
  ALL: 'All',
  IMPORTED: 'Imported',
};

function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  listHideTokens,
  showImportView,
  setImportToken,
}) {
  const chainId = useChainId()
  const [activeTab, setActiveTab] = useState(TABS.ALL);

  // refs for fixed size lists
  const fixedList = useRef();

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 200);

  const searchToken = useToken(debouncedQuery);

  const allTokens = useAllTokens();

  const tokenImports = useUserAddedTokens();

  const tokenComparator = useTokenComparator(false);

  const tokenImportsFiltered = useMemo(() => {
    return (debouncedQuery ? filterTokens(tokenImports, debouncedQuery) : tokenImports).sort(tokenComparator);
  }, [debouncedQuery, tokenImports, tokenComparator]);

  const filteredTokens = useMemo(() => {
    return filterTokens(Object.values(allTokens), debouncedQuery);
  }, [allTokens, debouncedQuery]);

  const sortedTokens = useMemo(() => {
    let tokens = filteredTokens;
    if (listHideTokens) {
      tokens = filteredTokens.filter(
        (token) => !listHideTokens.find((tokenFilter) => token.address === tokenFilter.address),
      );
    }

    return tokens.sort(tokenComparator);
  }, [filteredTokens, listHideTokens, tokenComparator]);

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery);

  const isImportedTab = activeTab === TABS.IMPORTED;

  const visibleCurrencies = useMemo(() => {
    if (searchToken && !isImportedTab) {
      return [searchToken];
    }
    return isImportedTab ? tokenImportsFiltered : [NativeCurrency[chainId], ...filteredSortedTokens];
  }, [searchToken, isImportedTab, tokenImportsFiltered, chainId, filteredSortedTokens]);

  const handleCurrencySelect = useCallback(
    (currency) => {
      onCurrencySelect(currency);
    },
    [onCurrencySelect],
  );

  const handleInput = useCallback((event) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === NativeCurrency[chainId].symbol.toLowerCase()) {
          handleCurrencySelect(NativeCurrency[chainId]);
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0]);
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, debouncedQuery, chainId],
  );

  const removeToken = useRemoveUserAddedToken();

  const removeImportedToken = useCallback(
    (token) => {
      removeToken(chainId, token.address);
    },
    [chainId, removeToken],
  );

  const removeAllImportToken = () => {
    tokenImports?.forEach(removeImportedToken);
  };

  return (
    <>
      <div>
        <div>
          <div className="flex flex-row bg-black5 rounded-[10px] p-1">
            <div className="min-w-[48px]  flex items-center justify-center">
              <img src="/icon/search.svg" alt="search"/>
            </div>
            <Input
              id="token-search-input"
              placeholder="Search name or paste address"
              className="bg-black5 border-none rounded-none caret-white text-gray2 px-0"
              value={searchQuery}
              onChange={handleInput}
              onKeyDown={handleEnter}
            />
            <div
              className={
              classNames("min-w-[48px]  flex items-center justify-center", searchQuery ? 'cursor-pointer' : 'hidden')
              }
              onClick={() => handleInput({ target: { value: '' } })}
            >
              <img src="/icon/close.svg" alt="search" className="w-[14px] h-[14px]"/>
            </div>
          </div>
          {showCommonBases && (
            <CommonBases chainId={chainId} onSelect={handleCurrencySelect} selectedCurrency={selectedCurrency}/>
          )}
        </div>
        {CHAINS_SUPPORT_IMPORTED_TOKENS.includes(chainId) && (
          <div>
            <Card className="p-2 my-2 text-white flex !bg-none text-lg font-bold">
              <div
                className={classNames(
                  activeTab === TABS.ALL ? 'text-primary' : 'text-white hover:opacity-80',
                  'mr-4 cursor-pointer',
                )}
                onClick={() => setActiveTab(TABS.ALL)}
              >
                {TABS.ALL}
              </div>
              <div
                className={classNames(
                  activeTab === TABS.IMPORTED ? 'text-primary' : 'text-white hover:opacity-80',
                  'cursor-pointer',
                )}
                onClick={() => setActiveTab(TABS.IMPORTED)}
              >
                {TABS.IMPORTED}
              </div>
            </Card>
          </div>
        )}

        {isImportedTab && visibleCurrencies.length > 0 && (
          <div className="flex justify-between items-center text-sm-md">
            <div>
              <p className="text-white">{visibleCurrencies.length} Custom Tokens</p>
            </div>
            <ButtonCustom className="!py-1 !px-2" onClick={removeAllImportToken}>
              <FiTrash size={13} />
              <p>Clear All</p>
            </ButtonCustom>
          </div>
        )}

        {visibleCurrencies?.length > 0 ? (
          <div>
            <CurrencyList
              isImportedTab={isImportedTab}
              height={390}
              currencies={visibleCurrencies}
              onCurrencySelect={handleCurrencySelect}
              otherCurrency={otherSelectedCurrency}
              selectedCurrency={selectedCurrency}
              fixedListRef={fixedList}
              showImportView={showImportView}
              setImportToken={setImportToken}
              removeImportedToken={removeImportedToken}
            />
          </div>
        ) : (
          <div className="h-[390px]">
            <p className="text-center mt-2 text-white/50">No results found.</p>
          </div>
        )}
      </div>
    </>
  );
}

export default CurrencySearch;
