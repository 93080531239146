import LineChartLoaderSVG from './LineChartLoaderSVG';
import BarChartLoaderSVG from './BarChartLoaderSVG';
import CandleChartLoaderSVG from './CandleChartLoaderSVG';

export const BarChartLoader = () => {
  return (
    <div className="h-full">
      <BarChartLoaderSVG />
    </div>
  );
};

export const LineChartLoader = () => {
  return (
    <div className="h-full">
      <LineChartLoaderSVG />
    </div>
  );
};

export const CandleChartLoader = () => {
  return (
    <div className="h-full">
      <CandleChartLoaderSVG />
    </div>
  );
};
