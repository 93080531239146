import Modal from 'components/Modal/Modal';
import ModalTitle from 'components/Modal/ModalTitle';
import PropTypes from 'prop-types';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const ModalWarningHarvestFee = ({ open, toggleModal, onConfirm, content }) => {
  return (
    <Modal open={open} onClose={toggleModal}>
      <ModalTitle onClose={toggleModal}>Warning</ModalTitle>
        <div className="p-4">
            <div className="text-center text-red-500">
                <p>{content}</p>
            </div>
            <div className="flex justify-center space-x-2 mt-4">
                <ButtonCustom onClick={onConfirm}>Confirm</ButtonCustom>
                <ButtonCustom color="secondary" onClick={() => toggleModal()}>
                    Close
                </ButtonCustom>
            </div>
        </div>
    </Modal>
  );
};

ModalWarningHarvestFee.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

export default ModalWarningHarvestFee;
