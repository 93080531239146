import React, { useState } from 'react';
import { formatExecutionPrice } from 'utils/prices';
import { FiRefreshCcw } from 'react-icons/fi';

const TradePrice = ({ trade }) => {
  const [showInverted, setShowInverted] = useState(false);

  return (
    <div>
      <p className="flex items-center text-sm-md">
        {formatExecutionPrice(trade, showInverted)}
        <FiRefreshCcw
          className="cursor-pointer ml-1 transition-all hover:rotate-180 duration-300"
          onClick={() => setShowInverted(!showInverted)}
          size={14}
        />
      </p>
    </div>
  );
};

export default TradePrice;
