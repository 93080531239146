import { createReducer } from '@reduxjs/toolkit';
import { FARMS_VIEW_DEFAULT } from 'constants/farms';
import { INITIAL_ALLOWED_SLIPPAGE, DEFAULT_DEADLINE_FROM_NOW } from 'constants/swap';
import { updateVersion } from 'store/global/actions';
import {
  addSerializedPair,
  addSerializedToken,
  removeSerializedPair,
  removeSerializedToken,
  updateUserExpertMode,
  updateUserSlippageTolerance,
  updateUserDeadline,
  updateUserSingleHopOnly,
  toggleTheme,
  updateUserStakedOnly,
  updateUserNetwork,
  updateUserViewMode,
  VAULT_VIEW_DEFAULT,
  addWatchlistToken,
  addWatchlistPool,
  setIsExchangeChartDisplayed,
} from './actions';
import { isAddress } from 'utils';

const currentTimestamp = () => new Date().getTime();

function pairKey(token0Address, token1Address) {
  return `${token0Address};${token1Address}`;
}

export const initialState = {
  userExpertMode: false,
  userSingleHopOnly: false,
  isExchangeChartDisplayed: true,
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  tokens: {},
  pairs: {},
  timestamp: currentTimestamp(),
  isDark: false,
  userStakedOnly: {},
  userNetwork: undefined,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateVersion, (state) => {
      // slippage isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userSlippageTolerance !== 'number') {
        state.userSlippageTolerance = INITIAL_ALLOWED_SLIPPAGE;
      }

      // deadline isnt being tracked in local storage, reset to default
      // noinspection SuspiciousTypeOfGuard
      if (typeof state.userDeadline !== 'number') {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW;
      }

      state.lastUpdateVersionTimestamp = currentTimestamp();
    })
    .addCase(updateUserExpertMode, (state, action) => {
      state.userExpertMode = action.payload.userExpertMode;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserNetwork, (state, action) => {
      state.userNetwork = action.payload.userNetwork;
    })
    .addCase(updateUserSingleHopOnly, (state, action) => {
      state.userSingleHopOnly = action.payload.userSingleHopOnly;
    })
    .addCase(addSerializedToken, (state, { payload: { serializedToken } }) => {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[serializedToken.chainId] = state.tokens[serializedToken.chainId] || {};
      state.tokens[serializedToken.chainId][serializedToken.address] = serializedToken;
      state.timestamp = currentTimestamp();
    })
    .addCase(removeSerializedToken, (state, { payload: { address, chainId } }) => {
      if (!state.tokens) {
        state.tokens = {};
      }
      state.tokens[chainId] = state.tokens[chainId] || {};
      delete state.tokens[chainId][address];
      state.timestamp = currentTimestamp();
    })
    .addCase(addSerializedPair, (state, { payload: { serializedPair } }) => {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const { chainId } = serializedPair.token0;
        state.pairs[chainId] = state.pairs[chainId] || {};
        state.pairs[chainId][pairKey(serializedPair.token0.address, serializedPair.token1.address)] = serializedPair;
      }
      state.timestamp = currentTimestamp();
    })
    .addCase(removeSerializedPair, (state, { payload: { chainId, tokenAAddress, tokenBAddress } }) => {
      if (state.pairs[chainId]) {
        // just delete both keys if either exists
        delete state.pairs[chainId][pairKey(tokenAAddress, tokenBAddress)];
        delete state.pairs[chainId][pairKey(tokenBAddress, tokenAAddress)];
      }
      state.timestamp = currentTimestamp();
    })
    .addCase(toggleTheme, (state) => {
      state.isDark = !state.isDark;
    })
    .addCase(updateUserStakedOnly, (state, action) => {
      const { field } = action.payload;

      if (!state.userStakedOnly) {
        state.userStakedOnly = {
          farms: false,
          miniFarms: false,
          vaults: false,
          pools: false,
        };
      }

      state.userStakedOnly[field] = !state.userStakedOnly[field];
    })
    .addCase(updateUserViewMode, (state, action) => {
      const { field, view } = action.payload;

      if (!state?.viewMode) {
        state.viewMode = {
          vaults: VAULT_VIEW_DEFAULT,
          farms: FARMS_VIEW_DEFAULT,
        };
      }

      state.viewMode[field] = view;
    })
    .addCase(addWatchlistToken, (state, { payload: { address } }) => {
      // state.watchlistTokens can be undefined for pre-loaded localstorage user state
      const tokenWatchlist = state.watchlistTokens ?? [];
      if (!tokenWatchlist.includes(isAddress(address))) {
        state.watchlistTokens = [...tokenWatchlist, isAddress(address)];
      } else {
        // Remove token from watchlist
        const newTokens = state.watchlistTokens.filter((x) => x !== isAddress(address));
        state.watchlistTokens = newTokens;
      }
    })
    .addCase(addWatchlistPool, (state, { payload: { address } }) => {
      // state.watchlistPools can be undefined for pre-loaded localstorage user state
      const poolsWatchlist = state.watchlistPools ?? [];
      if (!poolsWatchlist.includes(isAddress(address))) {
        state.watchlistPools = [...poolsWatchlist, isAddress(address)];
      } else {
        // Remove pool from watchlist
        const newPools = state.watchlistPools.filter((x) => isAddress(x) !== isAddress(address));
        state.watchlistPools = newPools;
      }
    })
    .addCase(setIsExchangeChartDisplayed, (state, { payload }) => {
      state.isExchangeChartDisplayed = payload;
    }),
);
