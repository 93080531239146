import tokens from './tokens';
import { ChainId } from 'ezcake-v2-sdk';

export const LOOT_DRAGON_ADDRESS = '0x8611eaa46Bc9d5d838cd7b5D2E79abB538AE99A4';
// export const LOOT_DRAGON_ADDRESS = '0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e' // Testnet

export const AI_GANGS_ADDRESS = '0x0A0a6455527C842849606D28479F6d33efB24a81';
export const TOKEN_BURN = {
  symbol: 'LDR',
  address: '0xb323E49eFFaaDAF085F145bdBb0aa8EcC88B9ceD',
  decimals: 18,
};

export const TOKEN_AIGANG = {
  symbol: 'AIGANG',
  address: '0x2b4b9241f970B9AF1fd680637541cDdA31e21Fde',
  decimals: 18,
};

export const TOKEN_AIGANG_BSC = {
  symbol: 'AIGANG',
  address: '0x573486909770bA1a9D68BAd094bB625624Fd6B48',
  decimals: 18,
};

export const NFTsConfig = [
  {
    id: 5,
    name: 'CitiGolf NFT',
    img: '/images/LPDiNFT_default.png',
    slug: 'citigolf',
    chainId: ChainId.KAI,
    poolAddress: '0x27073e85111b2e5c4F5e1c770Fd75DF30c8C79c0',
    saleInBatch: true,
    lootChestNumber: 27,
    tokenBurnChest: tokens.lpdi,
    tokenBurn: '',
    apiGetNFT: 'getLpdiNFTKai',
    amountBurnReceive: 5,
    lootChestValue: 1500,
    abi: 'lootDragon',
    content:
      "A unique & limited golf ball NFT collection based on Zodiac. A revolutionary step to create a metaverse of golf course. Collect these NFT golf balls to emerge in LPDi's gamefi world, or to publicly sell/auction on NFT marketplace on your own.",
    maxMint: 5000,
    openTime: 1635516000000,
    price: {
      value: 300,
      decimals: 0,
    },
    buyToken: tokens.lpdi,
    useOtherCoin: true,
    limitPerTime: 2,
    notComplete: false,
  },
  {
    id: 4,
    name: 'CitiGolf NFT',
    img: '/images/LPDiNFT_default.png',
    slug: 'citigolf',
    chainId: ChainId.BSC,
    poolAddress: '0xA323a1A02A35a6dfFA09b224FC05C1C3A61f4047',
    saleInBatch: true,
    lootChestNumber: 27,
    tokenBurnChest: tokens.lpdi,
    tokenBurn: '',
    apiGetNFT: 'getLpdiNFTBsc',
    amountBurnReceive: 5,
    lootChestValue: 1500,
    abi: 'lootDragon',
    content:
      "A unique & limited golf ball NFT collection based on Zodiac. A revolutionary step to create a metaverse of golf course. Collect these NFT golf balls to emerge in LPDi's gamefi world, or to publicly sell/auction on NFT marketplace on your own.",
    maxMint: 5000,
    openTime: 1635516000000,
    price: {
      value: 300,
      decimals: 0,
    },
    buyToken: tokens.lpdi,
    useOtherCoin: true,
    limitPerTime: 2,
    notComplete: false,
  },
  {
    id: 3,
    name: 'AI GANGS',
    img: '/images/nft-ai-gangs-default.jpg',
    slug: 'aigangs',
    chainId: ChainId.BSC,
    poolAddress: '0xc9f95CF075A96856E37BEC8B334265428657ebB1',
    lootChestNumber: 55,
    tokenBurnChest: tokens.bnb,
    tokenBurn: TOKEN_AIGANG_BSC,
    apiGetNFT: 'getAIGangsBsc',
    amountBurnReceive: 5,
    lootChestValue: 0.5,
    abi: 'lootDragon',
    content:
      'The collection is generated 100% by Artificial Intelligence from AI.ART Labs after training with various Deep Learning Algorithms for over 45 days.',
    maxMint: 6220,
    openTime: 123,
    price: {
      value: 0.1,
      decimals: 1,
    },
    buyToken: tokens.bnb,
    notComplete: false,
  },
  {
    id: 2,
    name: 'AI GANGS',
    img: '/images/nft-ai-gangs-default.jpg',
    slug: 'aigangs',
    chainId: ChainId.KAI,
    poolAddress: AI_GANGS_ADDRESS,
    lootChestNumber: 55,
    tokenBurnChest: tokens.kai,
    tokenBurn: TOKEN_AIGANG,
    apiGetNFT: 'getAIGangs',
    amountBurnReceive: 5,
    lootChestValue: 5000,
    abi: 'lootDragon',
    content:
      'The collection is generated 100% by Artificial Intelligence from AI.ART Labs after training with various Deep Learning Algorithms for over 45 days.',
    maxMint: 6220,
    openTime: 123,
    price: {
      value: 999,
      decimals: 0,
    },
    buyToken: tokens.kai,
    notComplete: false,
  },
  {
    id: 1,
    name: 'Loot Dragon',
    img: '/images/NFT-default-card.jpg',
    slug: 'dragon',
    singleChain: true,
    chainId: ChainId.BSC,
    poolAddress: LOOT_DRAGON_ADDRESS,
    tokenBurn: TOKEN_BURN,
    apiGetNFT: 'getDragon',
    tokenBurnChest: tokens.bnb,
    lootChestNumber: 50,
    amountBurnReceive: 0.5,
    lootChestValue: 0.5,
    abi: 'lootDragon',
    content:
      'There will be 10,000 Dragon NFTs. All Dragons. And, Lootable chests full of dopamine and mystery. Loot Dragon’s market place will open once all 10,000 tokens are minted. If Loot Dragon’s performance is anything to go by, this could come round faster than imagined.',
    maxMint: 10000,
    openTime: 1631633457209,
    price: {
      value: 0.1,
      decimals: 1,
    },
    buyToken: tokens.bnb,
    notComplete: false,
    data: {
      rarity: [
        {
          label: 'Dragon Lord',
          value: 1.25,
          decimals: 2,
        },
        {
          label: 'Loot Chest',
          value: 2,
          decimals: 0,
        },
        {
          label: 'Dragon King',
          value: 10,
          decimals: 0,
        },
        {
          label: 'Legendary Dragon',
          value: 15,
          decimals: 0,
        },
        {
          label: 'Rare Dragon',
          value: 30,
          decimals: 0,
        },
        {
          label: 'Normal Dragon',
          value: 41.75,
          decimals: 2,
        },
      ],
    },
  },
];
