import { useTokenContract } from 'hooks/useContract';
import { useCallback } from 'react';
import { approveFarm } from 'utils/calls/farms';
import { approveLockPool } from 'utils/calls/lockPool';
import {waitForTransactionReceipt} from "@wagmi/core";
import { approvePoolIdo } from 'utils/calls/IDOs';
import { approveVaultWithEthers } from 'utils/calls/vaults';
import {useConfig, useWriteContract} from 'wagmi'
import {ERC20_ABI} from "../config/abi/erc20";
import {UINT256_MAX} from "../config";
import BigNumber from "bignumber.js";
import {BIG_TEN} from "../utils/bigNumber";

export const useApprove = (tokenAddress, masterChefAddress) => {
  const tokenContract = useTokenContract(tokenAddress);

  const handleApprove = useCallback(async () => {
    return await approveFarm(tokenContract, masterChefAddress);
  }, [masterChefAddress, tokenContract]);

  return { onApprove: handleApprove };
};

export const useSousApprove = (tokenAddress, poolAddress) => {
  const { writeContractAsync } = useWriteContract()
  const config = useConfig();

  const handleApprove = useCallback(async (amount, decimals) => {
    const value = amount === UINT256_MAX ? UINT256_MAX : new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed();

    let txHash = writeContractAsync({
      abi: ERC20_ABI,
      address: tokenAddress,
      functionName: 'approve',
      args: [poolAddress, value],
    });
    await waitForTransactionReceipt(config, {hash: txHash})

  }, [poolAddress, config]);

  return { onApprove: handleApprove };
};

export const useVaultApprove = (contractAddress, poolAddress) => {
  const tokenContract = useTokenContract(poolAddress);

  const handleApprove = useCallback(async () => {
    return await approveVaultWithEthers(tokenContract, contractAddress);
  }, [contractAddress, tokenContract]);

  return { onApprove: handleApprove };
};

export const useApprovePoolIdo = (tokenAddress, poolAddress) => {
  const contract = useTokenContract(tokenAddress);
  const handleApprove = useCallback(async () => {
    return await approvePoolIdo(contract, poolAddress);
  }, [poolAddress, contract]);

  return { onApprove: handleApprove };
};

export const useLockPoolApprove = (contractAddress, tokenAddress) => {
  const tokenContract = useTokenContract(tokenAddress);

  const handleApprove = useCallback(async () => {
    return await approveLockPool(tokenContract, contractAddress);
  }, [contractAddress, tokenContract]);

  return { onApprove: handleApprove };
};
