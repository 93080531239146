import React, { useEffect } from 'react';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, AreaChart, Area } from 'recharts';
import { LineChartLoader } from 'views/Analytics/components/ChartLoaders/ChartLoaders';
import { PairDataTimeWindowEnum } from 'views/Swap/components/Chart/types';
import dayjs from 'dayjs';

// Calls setHoverValue and setHoverDate when part of chart is hovered
// Note: this NEEDs to be wrapped inside component and useEffect, if you plug it as is it will create big render problems (try and see console)
const HoverUpdater = ({ locale, payload, setHoverValue, setHoverDate }) => {
  useEffect(() => {
    setHoverValue(payload.value);
    setHoverDate(
      payload.time.toLocaleString(locale, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }),
    );
  }, [locale, payload.value, payload.time, setHoverValue, setHoverDate]);

  return null;
};

const getChartColors = ({ isChangePositive }) => {
  return isChangePositive
    ? { gradient1: '#00E7B0', gradient2: '#0C8B6C', stroke: '#31D0AA' }
    : { gradient1: '#FF537B', gradient2: '#FF537B', stroke: '#FF537B' };
};

const getAxisDateFormat = (interval) => {
  switch (interval) {
    case PairDataTimeWindowEnum.DAY:
      return 'h:mm A';
    case PairDataTimeWindowEnum.WEEK:
      return 'MMM D';
    case PairDataTimeWindowEnum.MONTH:
      return 'MMM D';
    case PairDataTimeWindowEnum.YEAR:
      return 'MMM D';
    default:
      return 'p MMM d';
  }
};

/**
 * Note: remember that it needs to be mounted inside the container with fixed height
 */
const LineChart = ({ data, setHoverValue, setHoverDate, isChangePositive, interval, isLoading }) => {
  const colors = getChartColors({ isChangePositive });

  if (isLoading) {
    return <LineChartLoader />;
  }

  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
        onMouseLeave={() => {
          if (setHoverDate) setHoverDate(undefined);
          if (setHoverValue) setHoverValue(undefined);
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="70%" stopColor={colors.gradient1} stopOpacity={0.4} />
            <stop offset="100%" stopColor={colors.gradient2} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          fontSize="12px"
          dataKey="time"
          axisLine={false}
          tickLine={false}
          tickFormatter={(time) => {
            return dayjs(new Date(time)).format(getAxisDateFormat(interval));
          }}
          minTickGap={8}
          stroke={'white'}
        />
        <YAxis dataKey="value" axisLine={false} tickLine={false} domain={['auto', 'auto']} hide />
        <Tooltip
          cursor={{ stroke: '#FF0000' }}
          contentStyle={{ display: 'none' }}
          formatter={(tooltipValue, name, props) => (
            <HoverUpdater
              locale={'en'}
              payload={props.payload}
              setHoverValue={setHoverValue}
              setHoverDate={setHoverDate}
            />
          )}
        />
        <Area dataKey="value" type="linear" stroke={colors.stroke} fill="url(#gradient)" strokeWidth={2} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
