import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLandsV2PublicDataAsync } from 'store/lands';

const useCheckTimeRound = (timeCheck) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      setTime((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timeCheck > Date.now()) {
      dispatch(fetchLandsV2PublicDataAsync());
    }
  }, [time, timeCheck]);
};

export default useCheckTimeRound;
