import { createSlice } from '@reduxjs/toolkit';
import { NFTsConfig } from '../../constants/NTFs';
import {
  fetchAllowanceNFT,
  fetchCanClaim,
  fetchNextStopId,
  fetchOwnerById,
  getMyNFTs,
  getNFTBurned,
  getNFTData,
} from './fetchNFT';
import lootDragonApi from '../../api/lootDragonApi';

const initialState = {
  data: NFTsConfig,
  myNFTs: undefined,
  dragon: undefined,
  dataLoaded: false,
  userData: undefined,
};

const NFTsSlice = createSlice({
  name: 'NFTs',
  initialState,
  reducers: {
    setNFTsData: (state, action) => {
      state.data = action.payload;
      state.dataLoaded = true;
    },
    setNFTsUserData: (state, action) => {
      const userData = action.payload;
      state.data = (state.data || []).map((nft) => {
        if (userData.id === nft.id) {
          return { ...nft, userData };
        }
        return nft;
      });
      state.userDataLoaded = true;
    },
    updateNFTUserData: (state, action) => {
      const { field, value, id } = action.payload;
      const index = state.data.findIndex((p) => p.id === id);

      if (index >= 0) {
        state.data[index] = {
          ...state.data[index],
          ...action.payload,
          userData: { ...state.data[index].userData, [field]: value },
        };
      }
    },
    setMyNFTs: (state, action) => {
      state.myNFTs = action.payload;
    },
    setDragon: (state, action) => {
      state.dragon = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const fetchNFTsPublicDataAsync = (chainId) => async (dispatch) => {
  try {
    const promise = NFTsConfig.filter((item) => item.chainId === chainId || item?.singleChain).map(async (nft) => {
      const minted = await getNFTData(nft);
      const canClaim = await fetchCanClaim(nft);
      let nextStopId;
      if (nft?.saleInBatch) {
        nextStopId = await fetchNextStopId(nft);
      }
      let totalSupply;
      if (nft?.tokenBurn) {
        totalSupply = await getNFTBurned(nft);
      }
      return {
        ...nft,
        minted,
        canClaim,
        NFTBurned: totalSupply / nft.amountBurnReceive,
        ...(nft?.saleInBatch ? { nextStopId } : {}),
      };
    });
    const dataNFTs = await Promise.all(promise);
    dispatch(setNFTsData(dataNFTs));
  } catch (error) {}
};

export const fetchNFTData = (NFT) => async (dispatch) => {
  try {
    const minted = await getNFTData(NFT);
    let totalSupply;
    if (NFT?.tokenBurn) {
      totalSupply = await getNFTBurned(NFT);
    }
    dispatch(
      updateNFTUserData({
        ...NFT,
        minted,
        NFTBurned: totalSupply / NFT.amountBurnReceive,
      }),
    );
  } catch (error) {}
};

export const fetchMyNFTsAsync = (account, chainId) => async (dispatch) => {
  try {
    const promise = NFTsConfig.filter((item) => item.chainId === chainId).map(async (NFT) => {
      const listId = await getMyNFTs(NFT, account);
      const promise = listId.map(async (id) => {
        const api = lootDragonApi[NFT.apiGetNFT];
        const response = await api(id);
        return {
          ...response,
          slug: NFT.slug,
          chainId: NFT.chainId,
        };
      });
      const data = await Promise.all(promise);

      return data;
    });
    const response = await Promise.all(promise);
    const myNFTs = response?.reduce((previousValue, currentValue) => previousValue.concat(currentValue), []);
    dispatch(setMyNFTs(myNFTs));
  } catch (error) {}
};

export const fetchDetailNFTAsync = (nft, id) => async (dispatch) => {
  try {
    const api = lootDragonApi[nft.apiGetNFT];
    const response = await api(id);
    const owner = await fetchOwnerById(nft, id);
    if (!owner) {
      dispatch(setDragon(null));
    } else {
      dispatch(
        setDragon({
          ...response,
          owner: owner || undefined,
        }),
      );
    }
  } catch (error) {
    dispatch(setDragon(null));
  }
};

export const fetchUserDataAsync = (nft, account) => async (dispatch) => {
  try {
    if (!nft?.useOtherCoin) return;
    const allowance = await fetchAllowanceNFT(nft, account);
    dispatch(
      setUserData({
        allowance,
        userDataLoaded: true,
      }),
    );
  } catch (error) {}
};

export const { setNFTsData, setNFTsUserData, updateNFTUserData, setMyNFTs, setDragon, setUserData } = NFTsSlice.actions;

export default NFTsSlice.reducer;
