import { TYPE_VIEW } from 'constants/index';
import tokens from 'constants/tokens';
import { ChainId } from 'ezcake-v2-sdk';
export const sortTypeFarms = {
  hot: 'hot',
  apr: 'apr',
  tvl: 'tvl',
};

export const FARMS_VIEW_DEFAULT = TYPE_VIEW.table;

export const platform_LP = {
  cake: 'CAKE-LP',
  jetswap: 'WINGS-LP',
};

export const sortListFarms = [
  {
    name: sortTypeFarms.hot,
    label: 'Hot',
  },
  {
    name: sortTypeFarms.apr,
    label: 'Apr',
  },
  {
    name: sortTypeFarms.tvl,
    label: 'Tvl',
  },
];

export const farmsConfig = {
  [ChainId.KAI]: [],
  [ChainId.BSC]: [],
  [ChainId.ONUS]: [],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [
    {
      pid: 0,
      stakingToken: {
        ...tokens.dflLp,
        address: {
          [ChainId.BSC_TESTNET]: '0x447792159cc6b6DdD9745BE3F733385892d83778',
        },
      },
      lpAddress: {
        [ChainId.BSC_TESTNET]: '0x447792159cc6b6DdD9745BE3F733385892d83778',
      },
      token0: tokens.mCake,
      token1: tokens.usdtBscTest,
      allocPoint: '4000',
      icon: 'mcake.png',
    },
  ],
};
export default farmsConfig;
