import React from 'react';
import { classNames } from 'utils/styling';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { renderSpanAddress } from 'utils';

const NoChartAvailable = ({ token0Address, token1Address, pairAddress,interval,setInterval }) => {
  const navigate = useNavigate();

  return (
    <>
        <div className="flex ml-auto mr-3 h-[37px] text-sm-md  text-white bg-green-900 max-w-min	rounded-xl">
            {['24H', '1W', '1M', '1Y'].map((text, index) => (
                <div
                    key={index}
                    className={classNames(
                        'px-3 py-2 whitespace-nowrap cursor-pointer',
                        interval === index && 'text-black bg-primary rounded-xl',
                    )}
                    onClick={() => setInterval(index)}
                >
                    {text}
                </div>
            ))}
        </div>
      <div className="flex justify-center items-center h-full flex-col">
        <FiAlertTriangle className="text-warning mt-5" size={40} />
        <p className="text-warning mb-5 mt-2">Chart is unavailable right now</p>
        <p className={classNames('text-left', 'mb-3')}>Token0: {renderSpanAddress(token0Address)}</p>
        <p className={classNames('text-left', 'mb-3')}>Token1: {renderSpanAddress(token1Address)}</p>
        {pairAddress ? (
          <p className={classNames('text-left', 'mb-3 text-sm-md')} color="textSubtle">
            Pair: {renderSpanAddress(pairAddress)}
          </p>
        ) : (
          <>
            <p>Pair not created yet.</p>
            <ButtonCustom className="mb-5" onClick={() => navigate(`/add/${token0Address}/${token1Address}`)}>
              Add Liquidity
            </ButtonCustom>
          </>
        )}
      </div>
    </>
  );
};

export default NoChartAvailable;
