import Logo from 'components/Logo/Logo';
import Tooltip from 'components/Tooltip/Tooltip';
import { formatNumber } from 'utils/formatBalance';
import { formatListLogo } from 'utils/formatLogo';

const RewardCell = ({ pool }) => {
  const earningTokens = pool.earningToken ? [pool.earningToken] : pool.earningTokens;
  const earningTokensPrice = pool.earningTokenPrice ? [pool.earningTokenPrice] : pool?.earningTokensPrice;
  const logos = formatListLogo(earningTokens);

  return (
    <div>
      <p>Reward{earningTokens.length > 1 ? 's' : ''}</p>
      <div className="flex">
        {earningTokens.map((token, index) => (
          <div className="flex items-center font-bold" key={index}>
            <Tooltip
              tooltip={
                <div className="whitespace-nowrap">
                  <p>{token.symbol} Price</p>
                  <p>{formatNumber(earningTokensPrice?.[index] ?? 0, 2, 4)}$</p>
                </div>
              }
            >
              <div>
                <Logo className="mr-1" src={logos.srcs[index]} alt={logos.alts[index]} size={30} />
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RewardCell;
