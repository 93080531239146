import axiosClient from './axiosClient';
import { API_BASE_URL } from 'config';

const pricesApi = {
    getPrices: (params) => {
        const url = '/tokens/get-price';
        return axiosClient.get(url, { params, customUrl: API_BASE_URL });
    },
    getAllPrices: (params) => {
        const url = '/tokens//get-all-price';
        return axiosClient.get(url, { params, customUrl: API_BASE_URL });
    },
};

export default pricesApi;
