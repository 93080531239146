import { binance, injected, walletconnect } from 'connectors/index';
import { ChainId } from 'ezcake-v2-sdk';

export const CASTLE_TAGS = {
  ifo: 'IFO',
  exclusive: 'exclusive',
  fairlaunch: 'fair launch',
  replaced: 'replaced',
  ended: 'ended',
  soldOut: 'sold out',
};

export const TABS = {
  live: 'live',
  finished: 'finished',
};

export const TYPE_VIEW = {
  grid: 'grid',
  table: 'table',
};

export const NetworkContextName = 'NETWORK';

export const ICON_CHAIN_URL = {
  [ChainId.KAI]: '/tokens/kai.png',
  [ChainId.BSC]: '/tokens/wbnb.png',
  [ChainId.HARMONY]: '/images/networks/harmonyone-network.jpg',
  [ChainId.ONUS]: '/images/networks/onus.jpg',
  [ChainId.ONUS_TESTNET]: '/images/networks/onus.jpg',
};

const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false
  }

  if (window.ethereum?.isMetaMask) {
    return true
  }

  if (window.ethereum?.providers?.some((p) => p.isMetaMask)) {
    return true
  }

  return false
}

export const SUPPORTED_WALLETS = {
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    deepLink: 'https://metamask.app.link/dapp/dapp.easycake.io/',
    primary: true,
    get installed() {
      return isMetamaskInstalled()
      // && metaMaskConnector.ready
    },
  },
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'injected.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  WALLET_CONNECT: {
    // connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'wallet-connect.png',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  TRUST_WALLET: {
    // connector: walletconnect,
    name: 'Trust Wallet',
    iconName: 'trustwallet.png',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    deepLink: 'https://link.trustwallet.com/open_url?coin_id=20000714&url=https://dapp.easycake.io/',
    get installed() {
      return typeof window !== 'undefined' && Boolean(window.ethereum?.isTrust)
    },
  },
  COIN98: {
    connector: injected,
    name: 'Coin98',
    iconName: 'coin98.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  RICE_WALLET: {
    // connector: walletconnect,
    name: 'Rice Wallet',
    iconName: 'rice-wallet.png',
    description: 'Connect to Rice Wallet',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  // Binance: {
  //   connector: binance,
  //   name: 'Binance Chain',
  //   iconName: 'bsc.jpg',
  //   description: 'Login using Binance hosted wallet',
  //   href: null,
  //   color: '#F0B90B',
  //   mobile: true,
  // },
};

export const SYMBOL_LP_PLATFORM = {
  kaidex: 'KLP',
  cake: 'CAKE-LP',
  jetswap: 'WINGS-LP',
  beco: 'Beco-LP',
  sushi: 'SLP',
  dfl: 'mCake-LP',
  eCake: 'mCake-LP',
  mia: 'MIA-V2',
};

export const NAME_PLATFORM_LP = {
  [SYMBOL_LP_PLATFORM.kaidex]: 'Kaidex',
  [SYMBOL_LP_PLATFORM.cake]: 'CakeSwap',
  [SYMBOL_LP_PLATFORM.jetswap]: 'JetSwap',
  [SYMBOL_LP_PLATFORM.beco]: 'BecoSwap',
  [SYMBOL_LP_PLATFORM.sushi]: 'SushiSwap',
  [SYMBOL_LP_PLATFORM.eCake]: 'mCake',
  [SYMBOL_LP_PLATFORM.mia]: 'Miaswap',
};
