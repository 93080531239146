import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import Logo from 'components/Logo/Logo';
import Value from 'components/Value/Value';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useSousHarvest } from 'hooks/useReward';
import { Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCastlesUserDataAsync, fetchCastlesUserDataAsyncV1 } from 'store/castles/index';
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { formatListLogo } from 'utils/formatLogo';
import { getPoolName } from 'utils/tokenHelpers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {getPoolBlockInfoClaim} from "../../../helpers";
import {useCurrentBlock} from "../../../../../store/block/hooks";
import {useSousUnstake} from "../../../../../hooks/useUnstake";
import {useAccount, useBalance, useChainId} from "wagmi";
import {MCAKE_ADDRESS} from "../../../../../constants/contracts";
import { useReadContract } from 'wagmi'
import {ERC20_ABI} from "../../../../../config/abi/erc20";
import tokens from "../../../../../constants/tokens";

const HarvestAction = ({
  isFees,
  earnings,
  earningTokens,
  earningTokensPrice,
  contractAddress,
  userDataLoaded,
  isIfo,
  stakedBalance,
                         claimBlock,
                         isFinished,
                         stakingTokenDecimal
}) => {
  const dispatch = useDispatch();
  const chainId = useChainId();
  const {address} = useAccount();
  const currentBlock = useCurrentBlock();
    const {data: mCakeBalance} = useReadContract({
      abi: ERC20_ABI,
      address: MCAKE_ADDRESS[chainId],
      functionName: 'balanceOf',
      args: [address],
      refetchInterval: 10000,
    })

  const [pendingTx, setPendingTx] = useState(false);
  const { onReward } = useSousHarvest(contractAddress, isFees);

  const { onUnstake } = useSousUnstake(contractAddress);

  const {
    shouldShowBlockCountdown,
  } = getPoolBlockInfoClaim(claimBlock, isFinished, currentBlock);

  const tokensEarningLabel = getPoolName(earningTokens);

  const usdTokensEarning = earnings?.map?.((earning, index) => {
    return earning
      ? new BigNumber(getFullDisplayBalance(earning, earningTokens[index].decimals))
          .times(earningTokensPrice?.[index] || 0)
          .toNumber()
      : 0;
  });

  // const handleReward = useCallback(async () => {
  //   try {
  //     setPendingTx(true);
  //     await onReward();
  //     // dispatch(fetchCastlesUserDataAsync(address, chainId));
  //     dispatch(fetchCastlesUserDataAsyncV1(address, chainId))
  //     showToastSuccess('Harvested', `Your ${tokensEarningLabel} earnings have been sent to your wallet!`);
  //     setPendingTx(false);
  //   } catch (e) {
  //     setPendingTx(false);
  //     showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
  //     console.error(e);
  //   }
  // }, [address, dispatch, onReward, tokensEarningLabel, chainId]);

  const handleReward = useCallback(async () => {
    try {
      setPendingTx(true);
      claimBlock ? await onUnstake(getFullDisplayBalance(stakedBalance, stakingTokenDecimal), stakingTokenDecimal) : await onReward();
      // await onUnstake(getFullDisplayBalance(stakedBalance, stakingTokenDecimal), stakingTokenDecimal);
      // dispatch(fetchCastlesUserDataAsync(address, chainId));
      dispatch(fetchCastlesUserDataAsyncV1(address, chainId))
      showToastSuccess('Harvested', `Your ${tokensEarningLabel} earnings have been sent to your wallet!`);
      setPendingTx(false);
    } catch (e) {
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      console.error(e);
    }
  }, [address, dispatch, onUnstake, tokensEarningLabel, chainId]);

  const logos = formatListLogo(earningTokens);

  const totalRewards = new BigNumber(stakedBalance).div(0.100181494773760107).times(100000000);
  const usdTotalRewards = new BigNumber(getFullDisplayBalance(totalRewards, earningTokens?.[0]?.decimals)).times(
    earningTokensPrice?.[0] || 0,
  );
  const formatmCakeBalance = getBalanceNumber(mCakeBalance, tokens.mCake.decimals);

  return (
      <div>
        <div className="flex justify-between items-center flex-wrap">
          <div>
            {isIfo ? (
                <>
                  <div className="flex items-center">
                    <Logo src={logos.srcs[0]} alt={logos.alts[0]} size={24}/>
                    <Value
                        className="text-primary font-bold text-xl ml-1"
                        value={getBalanceNumber(address ? totalRewards : 0)}
                        decimals={0}
                    />
                  </div>
                  {earningTokensPrice ? (
                      <Value
                          className="text-primary text-sm"
                          prefix="~"
                          value={address ? usdTotalRewards : 0}
                          decimals={3}
                          unit="USD"
                      />
                  ) : (
                      <span className="text-primary">~???USD</span>
                  )}
                </>
            ) : (
                earningTokens.map((earningToken, index) => (
                    <Fragment key={index}>
                      <div className="flex items-center flex-wrap">
                        <Logo src={logos.srcs[index]} alt={logos.alts[index]} size={28} className="!p-0.5"/>
                        <Value
                            className="text-primary font-bold text-xl ml-1"
                            value={earnings?.[index] ? getBalanceNumber(address ? earnings?.[index] : 0, earningToken.decimals) : 0}
                            decimals={6}
                        />
                      </div>
                      {earningTokensPrice ? (
                          <Value
                              className="text-primary text-sm"
                              prefix="~"
                              value={address ? usdTokensEarning?.[index] : 0}
                              decimals={3}
                              unit="USD"
                          />
                      ) : (
                          <span className="text-primary">~???USD</span>
                      )}
                    </Fragment>
                ))
            )}
          </div>
          {address ? (
              userDataLoaded ? (
                  <>
                    {isIfo ? (
                        <ButtonCustom disabled>Transferring Rewards</ButtonCustom>
                    ) : (
                        <div>
                          <ButtonCustom
                              disabled={pendingTx || earnings?.[0]?.eq(new BigNumber(0)) || shouldShowBlockCountdown || formatmCakeBalance < 1}
                              isLoading={pendingTx}
                              onClick={handleReward}
                          >
                            {pendingTx ? `Collecting` : claimBlock ? 'Harvest&Unstake' : 'Harvest'}
                          </ButtonCustom>
                        </div>
                    )}
                  </>
              ) : (
                  <ButtonCustom>
                    <Dots>Loading</Dots>
                  </ButtonCustom>
              )
          ) : (
              <ButtonCustom disabled>{claimBlock ? 'Harvest&Unstake' : 'Harvest'}</ButtonCustom>
          )}
        </div>
        {
          formatmCakeBalance < 1 ?
              <p class="text-white text-sm text-right">*You need to hold at least 1 {tokens.mCake.symbol}</p> : null
        }
      </div>
  );
};

export default HarvestAction;
