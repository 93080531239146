import { useCallback, useState, useMemo, Fragment } from 'react';
import { formatAmount } from 'utils/formatInfoNumbers';
import { ITEMS_PER_INFO_TABLE_PAGE } from 'constants/analytics';
import { DoubleCurrencyLogo } from 'views/Analytics/components/CurrencyLogo/CurrencyLogo';
import Skeleton from 'components/Skeleton/Skeleton';
import { Link } from 'react-router-dom';
import Pagination from 'views/Analytics/components/Pagination/Pagination';
import { useSWRPairs } from 'hooks/swr/analytics/pairs';
import { useWatchlistPools } from 'store/user/hooks';
import { isAddress } from 'utils';
import useActiveWeb3React from "../../../../hooks/useActiveWeb3React";

const SORT_FIELD = {
  name: 'name',
  volumeUSD: 'volume24h',
  liquidityUSD: 'reserveUSD',
  volumeUSDWeek: 'volume7d',
};

const LoadingRow = () => (
  <div className="responsiveGridPools">
    <Skeleton height={20} className="my-3" />
    <Skeleton height={20} className="my-3" />
    <Skeleton height={20} className="my-3" />
    <Skeleton height={20} className="my-3" />
    <Skeleton height={20} className="my-3" />
    <Skeleton height={20} className="my-3" />
    <Skeleton height={20} className="my-3" />
  </div>
);

const TableLoader = () => (
  <>
    <LoadingRow />
    <LoadingRow />
    <LoadingRow />
  </>
);

const DataRow = ({ poolData, index }) => {
  return (
    <Link to={`/analytics/pairs/${poolData.address}`}>
      <div className="responsiveGridPools my-4 hover:opacity-80">
        <p>{index + 1}</p>
        <div className="flex items-center">
          <DoubleCurrencyLogo currency0={poolData.token0} currency1={poolData.token1} />
          <p className="ml-3">
            {poolData.token0.symbol}/{poolData.token1.symbol}
          </p>
        </div>
        <p>
          $
          {formatAmount(poolData.volumeUSD, {
            displayThreshold: 0.001,
          })}
        </p>
        <p>
          $
          {formatAmount(poolData.volumeUSDWeek, {
            displayThreshold: 0.001,
          })}
        </p>
        <p>
          $
          {formatAmount(poolData.lpFees24h, {
            displayThreshold: 0.001,
          })}
        </p>
        <p>{formatAmount(poolData.lpApr7d)}%</p>
        <p>
          $
          {formatAmount(poolData.liquidityUSD, {
            displayThreshold: 0.001,
          })}
        </p>
      </div>
    </Link>
  );
};

const PoolTable = ({ address, isShowWatchList }) => {
  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.name);
  const [sortDirection, setSortDirection] = useState(true);
  const [savedPools] = useWatchlistPools();

  // pagination
  const [page, setPage] = useState(1);

  const { maxPage, poolDatas } = useSWRPairs({
    page,
    ...(address && { token: isAddress(address) }),
    sort: sortField,
    sortDir: sortDirection ? 'desc' : 'asc',
    ...(isShowWatchList && { listPair: savedPools.map(isAddress).join(',') }),
  });

  const loading = !poolDatas;

  const sortedPools = useMemo(() => {
    return poolDatas ? poolDatas : [];
  }, [poolDatas]);

  const handleSort = useCallback(
    (newField) => {
      setSortField(newField);
      setSortDirection(sortField !== newField ? true : !sortDirection);
    },
    [sortDirection, sortField],
  );

  const arrow = useCallback(
    (field) => {
      const directionArrow = !sortDirection ? '↑' : '↓';
      return sortField === field ? directionArrow : '';
    },
    [sortDirection, sortField],
  );

  return (
    <div className="tableWrapper shadow-lg">
      <div className="responsiveGridPools mb-5 mt-3">
        <p className="font-bold text-primary text-sm">#</p>
        <p className="font-bold text-primary uppercase text-sm">Pool</p>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.volumeUSD)}
        >
          Volume 24H {arrow(SORT_FIELD.volumeUSD)}
        </div>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.volumeUSDWeek)}
        >
          Volume 7D {arrow(SORT_FIELD.volumeUSDWeek)}
        </div>
        <div
          className=" font-bold text-primary uppercase text-sm"
          // onClick={() => handleSort(SORT_FIELD.lpFees24h)}
        >
          LP reward fees 24H {arrow(SORT_FIELD.lpFees24h)}
        </div>
        <div
          className=" font-bold text-primary uppercase text-sm"
          // onClick={() => handleSort(SORT_FIELD.lpApr7d)}
        >
          LP reward APR {arrow(SORT_FIELD.lpApr7d)}
        </div>
        <div
          className="cursor-pointer font-bold text-primary uppercase text-sm"
          onClick={() => handleSort(SORT_FIELD.liquidityUSD)}
        >
          Liquidity {arrow(SORT_FIELD.liquidityUSD)}
        </div>
      </div>
      <div className="bg-black1 h-0.5 w-full my-2" />
      {sortedPools.length > 0 ? (
        <>
          {sortedPools.map((poolData, i) => {
            if (poolData) {
              return (
                <Fragment key={poolData.address}>
                  <DataRow index={(page - 1) * ITEMS_PER_INFO_TABLE_PAGE + i} poolData={poolData} />
                  <div className="bg-black1 h-0.5 w-full my-2" />
                </Fragment>
              );
            }
            return null;
          })}
          {loading && <LoadingRow />}
          <Pagination page={page} maxPage={maxPage} setPage={setPage} />
        </>
      ) : (
        <>
          <TableLoader />
        </>
      )}
    </div>
  );
};

export default PoolTable;
