import Modal from 'components/Modal/Modal';
import ModalTitle from 'components/Modal/ModalTitle';
import { useCallback, useState } from 'react';

import CurrencySearch from 'components/SearchModal/CurrencySearch';
import usePrevious from 'hooks/usePrevious';
import ImportToken from 'components/SearchModal/ImportToken';

export const CurrencyModalView = {
  search: 0,
  importToken: 1,
};

export default function CurrencySearchModal({
  onDismiss = () => null,
  onCurrencySelect,
  selectedCurrency,
  otherSelectedCurrency,
  showCommonBases = false,
  open,
  listHideTokens,
}) {
  const [modalView, setModalView] = useState(CurrencyModalView.search);

  const handleDismiss = useCallback(() => {
    setModalView(CurrencyModalView.search);
    onDismiss();
  }, [onDismiss]);

  const handleCurrencySelect = useCallback(
    (currency) => {
      onCurrencySelect(Array.isArray(currency) ? currency[0] : currency);
      handleDismiss();
    },
    [handleDismiss, onCurrencySelect],
  );

  // for token import view
  const prevView = usePrevious(modalView);

  // used for import token flow
  const [importToken, setImportToken] = useState();

  const showImportView = useCallback(() => setModalView(CurrencyModalView.importToken), []);

  return (
    <Modal open={open} onClose={handleDismiss} className="w-full sm:w-unset">
      <ModalTitle
        onClose={onDismiss}
        className="flex-1 flex flex-row items-center"
        leftIcon={
          modalView === CurrencyModalView.importToken && (
            <button
              onClick={() =>
                setModalView(prevView && prevView !== CurrencyModalView.importToken ? prevView : CurrencyModalView.search)
              }
            >
              <img src="/icon/arrow_back.svg" alt="close" width={32} height={24}/>
            </button>
          )
        }
      >
        <div className="flex-1 ml-2">
          {modalView === CurrencyModalView.search ? 'Select a Token' : 'Import Token'}
        </div>
      </ModalTitle>
      <div className="py-2 px-4">
        {modalView === CurrencyModalView.search ? (
          <CurrencySearch
            listHideTokens={listHideTokens}
            onCurrencySelect={handleCurrencySelect}
            selectedCurrency={selectedCurrency}
            otherSelectedCurrency={otherSelectedCurrency}
            showCommonBases={showCommonBases}
            showImportView={showImportView}
            setImportToken={setImportToken}
          />
        ) : (
          <ImportToken
            tokens={[importToken]}
            // onBack={() =>
            //   setModalView(prevView && prevView !== CurrencyModalView.importToken ? prevView : CurrencyModalView.search)
            // }
            handleCurrencySelect={handleCurrencySelect}
          />
        )}
      </div>
    </Modal>
  );
}
