import { getPoolName, getTokenName } from 'utils/tokenHelpers';
import HarvestAction from 'views/Castles/components/CastlesCard/CardActionsContainer/HarvestAction';
import StakeAction from 'views/Castles/components/CastlesCard/CardActionsContainer/StakeAction';

const CardActionsContainer = ({ pool, earningTokens, userDataLoaded, isIfo }) => {
  const { userData } = pool;
  const poolName = getPoolName(earningTokens);
  const stakingNameDisplay = getTokenName(pool.token0, pool?.token1, pool.stakingToken);

  return (
    <div>
      <div>
        <p>
          <strong className="text-primary">{poolName}</strong>{' '}
          <span className="text-white">{isIfo ? 'Total Rewards' : 'Earned'}</span>
        </p>
        <HarvestAction
            stakingTokenDecimal={pool.stakingToken.decimals}
          isFees={!!pool?.fees?.withdrawalFee}
          stakedBalance={userData.stakedBalance}
          isFinished={pool.isFinished}
          claimBlock={pool.claimBlock}
          userDataLoaded={userDataLoaded}
          isIfo={isIfo}
          earningTokens={earningTokens}
          earnings={userData.earnings}
          contractAddress={pool.contractAddress}
          earningTokensPrice={pool.earningTokenPrice ? [pool.earningTokenPrice] : pool?.earningTokensPrice}
        />
      </div>
      <div>
        <p>
          <strong className="text-primary">{stakingNameDisplay}</strong> <span className="text-white">Staked</span>
        </p>
        <StakeAction pool={pool} userDataLoaded={userDataLoaded} isIfo={isIfo} />
      </div>
    </div>
  );
};

export default CardActionsContainer;
