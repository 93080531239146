import { RPC } from 'connectors/index';
import { ChainId } from 'ezcake-v2-sdk';
import { ethers } from 'ethers';

const simpleRpcProvider = {
  [ChainId.KAI]: new ethers.providers.JsonRpcProvider(RPC[ChainId.KAI]),
  [ChainId.BSC]: new ethers.providers.JsonRpcProvider(RPC[ChainId.BSC]),
  [ChainId.BSC_TESTNET]: new ethers.providers.JsonRpcProvider(RPC[ChainId.BSC_TESTNET]),
  [ChainId.HARMONY]: new ethers.providers.JsonRpcProvider(RPC[ChainId.HARMONY]),
  [ChainId.HARMONY_TESTNET]: new ethers.providers.JsonRpcProvider(RPC[ChainId.HARMONY_TESTNET]),
  [ChainId.ONUS]: new ethers.providers.JsonRpcProvider(RPC[ChainId.ONUS]),
  [ChainId.ONUS_TESTNET]: new ethers.providers.JsonRpcProvider(RPC[ChainId.ONUS_TESTNET]),
};

export default simpleRpcProvider;
