import React from 'react';
import Card from 'components/Card/Card';
import { NETWORK_LABEL } from 'constants/networks';

const WrongNetWork = ({ supportChain, type = 'IDO' }) => {
  return (
    <div
      className="px-4 sm:px-6"
      style={{
        minHeight: '65vh',
      }}
    >
      <div className="container max-w-screen-xl mx-auto">
        <Card className="my-20 px-4 py-8 text-white text-center">
          <h2 className="font-bold text-3xl py-2">Wrong Network Connected</h2>
          <div className="flex justify-center">
            <p className="mt-5 max-w-3xl text-lg">
              The {type} is currently on {NETWORK_LABEL[supportChain]} Chain but you are connected to another network.
              Please change the network to view this content.
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default WrongNetWork;
