import Card from 'components/Card/Card';
import { MASTERCHEF_ADDRESS } from 'constants/contracts';
import tokens from 'constants/tokens';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import CardActionsContainer from 'views/Farms/components/FarmsCard/CardActionsContainer/CardActionsContainer';
import CardInformation from 'views/Farms/components/FarmsCard/CardInformation';
import CardHeading from 'views/Farms/components/FarmsCard/CardHeading';
import DetailsSection from 'views/Farms/components/FarmsCard/DetailsSection';

const FarmCard = ({ farm, userDataLoaded, farms = [] }) => {
  const { chainId } = useActiveWeb3React();

  const farmNotBoosted = useMemo(() => {
    return farms.find((farmCurrent) => farmCurrent.pid === farm.pidFarmBoosted);
  }, [farm.pidFarmBoosted, farms]);

  return (
    <div className="w-96 max-w-full sm:w-full mx-auto relative">
      <Card>
        <div className="p-5">
          <CardHeading
            isFinished={farm.isFinished}
            multiplier={farm.mul}
            token1={farm.token1}
            token0={farm.token0}
            stakingToken={farm.stakingToken}
          />
          <CardInformation
            farmNotBoosted={farmNotBoosted}
            farm={farm}
            earningTokenSymbol={tokens.mCake.symbol}
            userDataLoaded={userDataLoaded}
          />
          <CardActionsContainer
            farm={farm}
            earningTokenSymbol={tokens.mCake.symbol}
            userDataLoaded={userDataLoaded}
            masterChefAddress={MASTERCHEF_ADDRESS[chainId]}
          />
          <DetailsSection
            farm={farm}
            token1={farm.token1}
            token0={farm.token0}
            lpAddress={farm.lpAddress}
            stakingToken={farm?.stakingToken}
          />
        </div>
      </Card>
    </div>
  );
};

FarmCard.propTypes = {
  farm: PropTypes.object,
};

export default FarmCard;
