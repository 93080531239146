import { classNames } from 'utils/styling';
import PropTypes from 'prop-types';
import { Switch as SwitchComponent } from '@headlessui/react';

const Switch = ({ label, checked, onChange, classNameLabel }) => {
  return (
    <SwitchComponent.Group>
      <SwitchComponent
        checked={checked}
        onChange={onChange}
        className={`${checked ? 'bg-gray-600' : 'bg-gray-600'} relative inline-flex h-6 w-12 items-center rounded-full`}
      >
        <span
          className={`${
            checked ? 'translate-x-6 bg-primary' : 'translate-x-1 bg-white'
          } inline-block h-5 w-5 transform rounded-full  transition`}
        />
      </SwitchComponent>
      {label && (
        <SwitchComponent.Label className={classNames('ml-3', classNameLabel)} passive>
          {label}
        </SwitchComponent.Label>
      )}
    </SwitchComponent.Group>

    // <div className={classNames('flex items-center justify-center', className)}>
    //   <label htmlFor={id} className="flex items-center cursor-pointer">
    //     <div className="relative">
    //       <input checked={checked} value={checked} onChange={onChange} type="checkbox" id={id} className="sr-only" />
    //       <div className="block bg-gray-600 w-10 h-6 rounded-full"></div>
    //       <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
    //     </div>
    //     <div className={classNames(classNameLabel, 'ml-3 font-medium')}>{label}</div>
    //   </label>
    // </div>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  classNameLabel: PropTypes.string,
  className: PropTypes.string,
};

export default Switch;
