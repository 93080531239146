import { classNames } from 'utils/styling';
import QuestionHelper from 'components/QuestionHelper/index';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import Countdown, { zeroPad } from 'react-countdown';

const HarvestFeeTimer = ({ timer }) => {
  const renderCountdown = ({ days, hours, minutes, completed }) => {
    if (completed) return <p className="mb-0">0d-0h-0m</p>;

    return (
      <p className="ml-2">
        {zeroPad(days)}d-{zeroPad(hours)}h-{zeroPad(minutes)}m
      </p>
    );
  };
  return <Countdown zeroPadTime={2} date={timer} renderer={renderCountdown} />;
};

const HarvestFeeCountdownRow = ({ unlockTimestamp, className }) => {
  const { account } = useActiveWeb3React();

  const hasHarvestFee = unlockTimestamp.toNumber() * 1000 > Date.now();

  // The user has made a deposit, but has no fee
  const noFeeToPay = !hasHarvestFee;

  // Show the timer if a user is connected, has deposited, and has an unstaking fee
  const shouldShowTimer = Boolean(account && hasHarvestFee);

  const getRowText = () => {
    if (noFeeToPay) {
      return 'Harvest Fee';
    }
    if (shouldShowTimer) {
      return 'Harvest fee until';
    }
    return `Harvest fee if withdrawn within 3 months`;
  };

  return (
    <div className={classNames(className, noFeeToPay ? 'text-green-500' : 'text-red-500', 'mt-2 flex')}>
      <div className="flex items-center justify-center">
        {noFeeToPay ? '0' : 50}% {getRowText()}
        <QuestionHelper
          text={
            <>
              <p>Harvest fee: 50% rewards</p>
              <p>
                Harvest after 3 months will not include a fee. Timer resets every time you stake,unstake or harvest in
                the pool.
              </p>
            </>
          }
        />
      </div>
      {shouldShowTimer && <HarvestFeeTimer timer={unlockTimestamp.toNumber() * 1000} />}
    </div>
  );
};

export default HarvestFeeCountdownRow;
