export const CurrencyLogo = ({ currency, size = '28px', ...rest }) => {
  return (
    <img
      className="shadow-md p-0.5"
      style={{
        width: size,
        height: size,
        background: 'white',
        borderRadius: '50%',
      }}
      src={currency?.logo?.url}
      alt="token logo"
      {...rest}
    />
  );
};
export const DoubleCurrencyLogo = ({ currency0, currency1, size = 28 }) => {
  return (
    <div className="flex">
      {currency0 && <CurrencyLogo currency={currency0} size={`${size.toString()}px`} />}
      {currency1 && <CurrencyLogo currency={currency1} size={`${size.toString()}px`} />}
    </div>
  );
};
