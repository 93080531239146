import CurrencyLogo from 'components/LogoSwap/CurrencyLogo';
import { useState } from 'react';
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal';
import { MdArrowDropDown } from 'react-icons/md';
import { FiPlus } from 'react-icons/fi';

export default function SelectIconButton({
  onCurrencyASelect,
  currencyA,
  onCurrencyBSelect,
  currencyB,
  showCommonBases,
}) {
  const [targetSelected, setTargetSelected] = useState('');
  const [presentCurrencyModal, setPresentCurrencyModal] = useState(false);
  const toggleCurrencyModal = () => {
    setPresentCurrencyModal((prevState) => !prevState);
  };

  return (
    <>
      <CurrencySearchModal
        open={presentCurrencyModal}
        onDismiss={toggleCurrencyModal}
        onCurrencySelect={targetSelected === 'A' ? onCurrencyASelect : onCurrencyBSelect}
        selectedCurrency={currencyA}
        showCommonBases={showCommonBases}
      />
      <div className="w-full flex gap-4 sm:gap-8 items-center flex-col sm:flex-row">
        <button
          className="border border-yellow-700 font-bold whitespace-nowrap bg-black1 flex-1 py-3 px-4 rounded w-full"
          onClick={() => {
            setTargetSelected('A');
            toggleCurrencyModal();
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {currencyA ? <CurrencyLogo currency={currencyA} /> : null}

              <p id="pair" className="ml-1">
                {(currencyA && currencyA.symbol && currencyA.symbol.length > 20
                  ? `${currencyA.symbol.slice(0, 4)}...${currencyA.symbol.slice(
                      currencyA.symbol.length - 5,
                      currencyA.symbol.length,
                    )}`
                  : currencyA?.symbol) || 'Select a token'}
              </p>
            </div>
            <MdArrowDropDown size={24} />
          </div>
        </button>
        <FiPlus size={24} />
        <button
          className="border border-yellow-700 font-bold whitespace-nowrap bg-black1 flex-1 py-3 px-4 rounded w-full"
          onClick={() => {
            setTargetSelected('B');
            toggleCurrencyModal();
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {currencyB ? <CurrencyLogo currency={currencyB} /> : null}

              <p id="pair" className="ml-1">
                {(currencyB && currencyB.symbol && currencyB.symbol.length > 20
                  ? `${currencyB.symbol.slice(0, 4)}...${currencyB.symbol.slice(
                      currencyB.symbol.length - 5,
                      currencyB.symbol.length,
                    )}`
                  : currencyB?.symbol) || 'Select a token'}
              </p>
            </div>
            <MdArrowDropDown size={24} />
          </div>
        </button>
      </div>
    </>
  );
}
