import { useCallback } from 'react';
import { showToastTx } from 'components/CustomToast/CustomToast';
import { useIDOLaunchpadContract } from './useContract';
import { claimIdo } from '../utils/calls/IDOs';
import useActiveWeb3React from './useActiveWeb3React';

const useClaimIdo = (idoAddress) => {
  const { chainId } = useActiveWeb3React();
  const contract = useIDOLaunchpadContract(idoAddress);

  const handleClaim = useCallback(async () => {
    let txHash;
    txHash = await claimIdo(contract);

    showToastTx(txHash, chainId);
  }, [contract, chainId]);

  return { onClaim: handleClaim };
};

export default useClaimIdo;
