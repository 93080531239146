import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import InputWithButton from 'components/NumericalInput/InputWithButton';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useSousApprove } from 'hooks/useApprove';
import { useSousStake } from 'hooks/useStake';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCastlesUserDataAsync, fetchCastlesUserDataAsyncV1 } from 'store/castles/index';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getPoolName, getTokenName } from 'utils/tokenHelpers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {UINT256_MAX} from "../../../../../config";
import {useAccount, useChainId} from "wagmi";

const Stake = ({ pool, userDataLoaded }) => {
  const dispatch = useDispatch();
  const chainId = useChainId();
  const {address} = useAccount();
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const [requestedApproval, setRequestedApproval] = useState(false);

  const { allowance, stakingTokenBalance } = pool.userData;
  const isApproved = address && allowance && allowance.isGreaterThan(0);

  const { onApprove } = useSousApprove(pool.stakingToken.address[chainId], pool.contractAddress);
  const { onStake } = useSousStake(pool.contractAddress);

  const stakingNameDisplay = getTokenName(pool.token0, pool?.token1, pool.stakingToken);
  const tokensEarningLabel = getPoolName(pool.isV2 ? pool.earningTokens : [pool.earningToken]);

  const usdValue = value && formatNumber(new BigNumber(value).times(pool.stakingTokenPrice || 0).toNumber());
  const usdBalance =
    stakingTokenBalance &&
    formatNumber(
      new BigNumber(getBalanceNumber(stakingTokenBalance, pool.stakingToken.decimals))
        .times(pool.stakingTokenPrice || 0)
        .toNumber(),
    );

  const handleTypeInput = (valueInput) => {
    setValue(valueInput);
  };

  const isInsufficientBalance = useMemo(() => {
    return new BigNumber(value).isGreaterThan(getFullDisplayBalance(stakingTokenBalance, pool.stakingToken.decimals));
  }, [value, stakingTokenBalance, pool.stakingToken.decimals]);

  const handleMaxInput = useCallback(() => {
    setValue(getFullDisplayBalance(stakingTokenBalance, pool.stakingToken.decimals));
  }, [pool.stakingToken.decimals, stakingTokenBalance]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (percent === 100) {
        handleMaxInput();
      } else {
        setValue(
          getFullDisplayBalance(
            new BigNumber(stakingTokenBalance).times(percent).dividedBy(100),
            pool.stakingToken.decimals,
            pool.stakingToken.decimals,
          ),
        );
      }
    },
    [pool.stakingToken.decimals, handleMaxInput, stakingTokenBalance],
  );

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove(value || UINT256_MAX, pool.stakingToken.decimals);
      showToastSuccess('Contract Enabled', `You can now stake in the ${tokensEarningLabel} pool!`);
      // dispatch(fetchCastlesUserDataAsync(account, chainId));
      dispatch(fetchCastlesUserDataAsyncV1(address, chainId))
      setRequestedApproval(false);
    } catch (e) {
      setRequestedApproval(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
    }
  }, [address, chainId, dispatch, onApprove, tokensEarningLabel, value]);

  const handleStake = useCallback(async () => {
    try {
      setPendingTx(true);
      await onStake(value, pool.stakingToken.decimals);
      // dispatch(fetchCastlesUserDataAsync(account, chainId));
      dispatch(fetchCastlesUserDataAsyncV1(address, chainId))
      showToastSuccess('Staked', `Your ${pool.stakingToken.symbol} funds have been staked in the pool!`);
      setValue('');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [address, chainId, dispatch, onStake, pool.stakingToken.decimals, pool.stakingToken.symbol, value]);

  return (
    <div>
      <div>
        <div className="flex font-bold text-white">
          <p>Token Balance:</p>
          <div className="ml-2">
            <>
              {address ? (
                userDataLoaded ? (
                  getFullDisplayBalance(stakingTokenBalance, pool.stakingToken.decimals)
                ) : (
                  <Dots />
                )
              ) : (
                '???'
              )}{' '}
              {stakingNameDisplay}
            </>
            <p className="text-sm mt-1">~{usdBalance || '0'} USD</p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex-1 text-white">
          <InputWithButton value={value} onUserInput={handleTypeInput} btnOnClick={handleMaxInput} />
          <p className="text-right text-sm mt-1">~{usdValue || '0'} USD</p>
          {/*<PercentPicker*/}
          {/*  isNumber={true}*/}
          {/*  className="text-black max-w-sm mx-auto"*/}
          {/*  onChangePercentInput={handlePercentInput}*/}
          {/*/>*/}
        </div>
        <div className="mt-2 text-black">
          {address ? (
            userDataLoaded ? (
              isApproved ? (
                <ButtonCustom
                  disabled={pendingTx || !new BigNumber(value).isGreaterThan(0) || isInsufficientBalance}
                  isLoading={pendingTx}
                  onClick={handleStake}
                  className="w-full"
                >
                  {isInsufficientBalance ? `Insufficient balance` : pendingTx ? 'Pending Confirmation' : 'Stake'}
                </ButtonCustom>
              ) : (
                <ButtonCustom
                  className="w-full"
                  isLoading={requestedApproval}
                  disabled={requestedApproval}
                  onClick={handleApprove}
                >
                  Approve Contract
                </ButtonCustom>
              )
            ) : (
              <ButtonCustom className="w-full" isLoading>
                Loading <Dots />
              </ButtonCustom>
            )
          ) : (
            <UnlockButton />
          )}
        </div>
      </div>
    </div>
  );
};

Stake.propTypes = {
  pool: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
};

export default Stake;
