import { showToastTx } from 'components/CustomToast/CustomToast';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useCallback } from 'react';
import { unstakeVault, unstakeVaultV2 } from 'utils/calls/vaults';
import { useVaultContract } from 'hooks/useContract';
import { ChainId } from 'ezcake-v2-sdk';

const useWithdraw = (vaultAddress) => {
  const { chainId } = useActiveWeb3React();
  const vaultContract = useVaultContract(vaultAddress);

  const handleWithdraw = useCallback(
    async (amount, decimals) => {
      const txHash =
        chainId === ChainId.ONUS
          ? await unstakeVaultV2(vaultContract, amount, decimals)
          : await unstakeVault(vaultContract, amount, decimals);
      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, vaultContract],
  );

  return { onWithdraw: handleWithdraw };
};

export default useWithdraw;
