import { useDispatch, useSelector } from 'react-redux';
import { transformLandsV2 } from './helpers';
import useRefresh from 'hooks/useRefresh';
import { useEffect } from 'react';
import {
  fetchLandDataAsync,
  fetchLandLinearDataAsync,
  fetchLandLinearUserDataAsync,
  fetchLandsPublicDataAsync,
  fetchLandsV2PublicDataAsync,
  fetchLandsV2UserDataAsync,
  fetchLandUserDataAsync,
} from './index';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import configLands from 'constants/lands';

export const useLands = () => {
  let pools = useSelector((state) => state.lands.data);

  return pools;
};

export const useLandsV2 = () => {
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();

  useEffect(() => {
    dispatch(fetchLandsV2PublicDataAsync());
    if (chainId && account) {
      dispatch(fetchLandsV2UserDataAsync(account));
    }
  }, [account, chainId, dispatch, fastRefresh]);

  const { landsV2, userDataLoadedV2 } = useSelector((state) => ({
    landsV2: state.lands.dataV2 || [],
    userDataLoadedV2: state.lands.userDataLoadedV2,
  }));

  return {
    landsV2: landsV2.map(transformLandsV2),
    userDataLoadedV2,
  };
};

export const useLandFromIdoId = (idoId) => {
  const { chainId } = useActiveWeb3React();
  const pool = useSelector((state) =>
    state.lands.data?.find(
      (p) => p.idoId === Number(idoId) || (idoId && idoId?.toString()?.toLowerCase() === p.slug?.toLowerCase()),
    ),
  );

  if (pool) return pool;

  if (chainId) {
    const land = getDefaultLandById(configLands?.[chainId], idoId);
    if (!land) return undefined;
  }

  return null;
};

export const getDefaultLandById = (lands, id) => {
  const land = lands?.find(
    (p) => p.idoId === Number(id) || (id && id?.toString()?.toLowerCase() === p.slug?.toLowerCase()),
  );
  if (!land) return undefined;
  return land;
};

export const useLandUser = (idoId) => {
  const pool = useSelector((state) => {
    const index = state.lands?.data?.findIndex(
      (x) => x.idoId === Number(idoId) || (idoId && idoId?.toString()?.toLowerCase() === x.slug?.toLowerCase()),
    );
    if (index !== -1) {
      return state.lands?.data?.[index]?.userData;
    }
    return undefined;
  });

  return pool;
};

export const useFetchLands = () => {
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const { chainId } = useActiveWeb3React();
  useEffect(() => {
    dispatch(fetchLandsPublicDataAsync(configLands));
  }, [dispatch, fastRefresh, chainId]);
};

export const useFetchLand = (id) => {
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const { chainId } = useActiveWeb3React();
  useEffect(() => {
    const land = getDefaultLandById(configLands, id);
    if (chainId && land?.chainId === chainId) {
      if (land.linearClaim) {
        dispatch(fetchLandLinearDataAsync(land, chainId));
      } else {
        dispatch(fetchLandDataAsync(land, chainId));
      }
    }
  }, [dispatch, fastRefresh, id, chainId]);
};

export const useFetchActiveLand = () => {
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const lands = useLands();

  const detectActiveLand = () => {
    let activeLand = undefined;
    lands
      ?.filter((land) => !land?.notComplete)
      ?.map((land) => {
        const optionNow = land.options.data
          .filter((option) => option.idoContract.closeTime > Date.now())
          .find((option) => option.idoContract.openTime > Date.now() || option.idoContract.closeTime < Date.now());
        if (
          optionNow &&
          (!activeLand || activeLand?.optionNow?.idoContract?.openTime > optionNow?.idoContract?.openTime)
        ) {
          activeLand = {
            ...land,
            optionNow,
          };
        }
      });
    if (activeLand) {
      return activeLand;
    }
    let landOngoing = undefined;
    lands
      ?.filter((land) => !land?.notComplete)
      ?.map((land) => {
        const optionNow = land.options.data
          .filter((option) => option.idoContract.closeTime > Date.now())
          .reduce(
            (a, b) => (!a?.idoContract?.closeTime || b?.idoContract?.closeTime > a?.idoContract?.closeTime ? b : a),
            undefined,
          );
        if (
          optionNow &&
          (!landOngoing || landOngoing?.optionNow?.idoContract?.closeTime < optionNow?.idoContract?.closeTime)
        ) {
          landOngoing = {
            ...land,
            optionNow,
          };
        }
      });
    if (landOngoing) {
      return landOngoing;
    }
  };
  useEffect(() => {
    const land = detectActiveLand();
    if (land) {
      dispatch(fetchLandDataAsync(land));
    }
  }, [dispatch, fastRefresh]);
};

export const useLandActive = () => {
  const lands = useSelector((state) => state.lands.data);
  let activeLand = undefined;
  lands
    ?.filter((land) => !land?.notComplete)
    ?.map((land) => {
      const optionNow = land.options.data
        .filter((option) => option.idoContract.closeTime > Date.now())
        .find((option) => option.idoContract.openTime > Date.now() || option.idoContract.closeTime < Date.now());
      if (
        optionNow &&
        (!activeLand || activeLand?.optionNow?.idoContract?.openTime > optionNow?.idoContract?.openTime)
      ) {
        activeLand = {
          ...land,
          optionNow,
        };
      }
    });
  if (activeLand) {
    return activeLand;
  }
  let landOngoing = undefined;
  lands
    ?.filter((land) => !land?.notComplete)
    ?.map((land) => {
      const optionNow = land.options.data
        .filter((option) => option.idoContract.closeTime > Date.now())
        .reduce(
          (a, b) => (!a?.idoContract?.closeTime || b?.idoContract?.closeTime > a?.idoContract?.closeTime ? b : a),
          undefined,
        );
      if (
        optionNow &&
        (!landOngoing || landOngoing?.optionNow?.idoContract?.closeTime < optionNow?.idoContract?.closeTime)
      ) {
        landOngoing = {
          ...land,
          optionNow,
        };
      }
    });
  if (landOngoing) {
    return landOngoing;
  }
  return undefined;
};

export const useFetchLandUserData = (idoId) => {
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const { chainId, account } = useActiveWeb3React();

  useEffect(() => {
    const land = getDefaultLandById(configLands, idoId);
    if (account && chainId && land?.chainId === chainId) {
      if (land?.linearClaim) {
        dispatch(fetchLandLinearUserDataAsync(account, land, chainId));
      } else {
        dispatch(fetchLandUserDataAsync(account, land, chainId));
      }
    }
  }, [dispatch, account, fastRefresh, chainId]);
};
