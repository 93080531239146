import { classNames } from 'utils/styling';
import Dropdown from 'components/Dropdown/Dropdown';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { listNewMenu } from 'config/menu';
import {useAccount, useChainId} from "wagmi";

const Menu = ({ className }) => {
  const chainId = useChainId()
  const account = useAccount()

  // const { chainId, account } = useActiveWeb3React();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={className}>
      {listNewMenu?.[chainId]?.map((menu, index) => {
        if (menu.child) {
          const pathString = location.pathname?.split('/')?.[1];
          return (
            <Dropdown
              key={index}
              menu={menu.title}
              classNameMenuItem="bg-container-main w-26-rem py-2  left-0"
              classNameMenu={classNames(
                'text-white xl:py-6',
                (menu?.activeRoute?.includes(location.pathname) || menu?.activeRoute?.includes(`/${pathString}`)) &&
                  'font-bold !text-primary',
              )}
              onClick={() => {
                if (menu.child.length === 1) {
                  navigate(menu.child[0].route);
                }
              }}
            >
              {menu.child.map((item, indexChild) => {
                if (!account && item?.auth) return null;
                const TagLink = item?.route ? NavLink : 'a';
                return (
                  <TagLink
                    className="px-2.5  py-2 bg-dropdown-item-hover text-white hover:text-white block  mx-1 rounded"
                    key={indexChild}
                    {...(item?.route
                      ? {
                          to: item.route,
                        }
                      : {
                          href: item?.url,
                          target: !item?.inside ? '_blank' : '',
                        })}
                  >
                    <div className="group w-full h-full flex flex-row items-center">
                      <div className="mr-3">{item.icon}</div>
                      <div className="flex-1">
                        {item.title}
                        <p className="group-hover:text-gray-100" style={{ fontSize: 13 }}>
                          {item?.desc}
                        </p>
                      </div>
                    </div>
                  </TagLink>
                );
              })}
            </Dropdown>
          );
        }

        if (menu.link) {
          return (
            <div
              className="mx-1 text-white px-2.5 py-2 xl:py-6 cursor-pointer"
              key={index}
              onClick={() => window.open(menu.link)}
            >
              <span className="w-full h-full">{menu.title}</span>
            </div>
          );
        }

        return (
          <NavLink
            className={({ isActive }) => classNames('text-white px-2.5 py-2 xl:py-6 mx-1', isActive && 'font-bold !text-primary')}
            key={index}
            to={menu.route}
          >
            <span className="w-full h-full">{menu.title}</span>
          </NavLink>
        );
      })}
    </div>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
};

export default Menu;
