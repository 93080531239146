import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import { TYPE_STRATEGY } from 'constants/vaults';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useCompound from 'hooks/useCompound';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getVaultStaked } from 'store/vaults/helpers';
import { fetchVaultsTotalStakedDataAsync, fetchVaultUserDataAsync } from 'store/vaults/index';
import { BIG_TEN } from 'utils/bigNumber';
import { formatNumber, getBalanceNumber } from 'utils/formatBalance';
import { getTokenName } from 'utils/tokenHelpers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const ManualCompound = ({ userDataLoaded, vault }) => {
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();
  const [pendingTx, setPendingTx] = useState(false);
  const { onCompound } = useCompound(vault.contractAddress);
  const vaultStaked = getVaultStaked(vault, chainId);
  const symbol = getTokenName(vault?.token0, vault?.token1, vault?.stakingToken);
  const symbolInVaultStaked = getTokenName(vaultStaked?.token0, vaultStaked?.token1, vaultStaked?.stakingToken);

  const usdTokenEarning =
    vault?.pendingReward && vault?.farmStaked?.earningTokenPrice
      ? formatNumber(
          vault.pendingReward
            .div(BIG_TEN.pow(vault?.farmStaked?.earningToken?.decimals))
            .times(vault?.farmStaked?.earningTokenPrice || 0)
            .toNumber(),
        )
      : 0;

  const handleCompound = useCallback(async () => {
    try {
      setPendingTx(true);
      await onCompound();
      dispatch(fetchVaultUserDataAsync(account));
      dispatch(fetchVaultsTotalStakedDataAsync());
      showToastSuccess('Successfully!!');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [account, dispatch, onCompound]);

  return (
    <div>
      <p className="font-bold mb-2">EXTRA COMPOUND</p>
      {vault.pendingReward && (
        <div className="flex font-bold">
          <p>Vault Pending Rewards:</p>
          <div className="ml-1">
            <p>
              {getBalanceNumber(vault.pendingReward, vault.stakingToken.decimals)}{' '}
              {vault?.farmStaked?.earningToken?.symbol}
            </p>
            <p className="text-sm mt-1">~{usdTokenEarning || '0'} USD</p>
          </div>
        </div>
      )}
      <div className="mt-2">
        {account ? (
          userDataLoaded ? (
            <ButtonCustom
              disabled={pendingTx}
              isLoading={pendingTx}
              onClick={handleCompound}
              className="w-full text-black"
            >
              Compound
            </ButtonCustom>
          ) : (
            <ButtonCustom className="w-full " isLoading>
              Loading <Dots />
            </ButtonCustom>
          )
        ) : (
          <UnlockButton />
        )}
      </div>
      {vault?.contractVaultStakedAddress ? (
        <div className="mt-2">
          {vault?.docsExpand ? (
            <p className="whitespace-pre-line">{vault.docsExpand}</p>
          ) : (
            <>
              <p>What does this Vault do?</p>
              <p>Average 30 minutes or faster, the Vault:</p>
              <ul>
                <li>1. Auto-harvest rewards</li>
                <li>2. Charge {vault.fees}% buyback fee on harvest rewards to buyback DFL and burn</li>
                <li>
                  {vault.typeStrategy === TYPE_STRATEGY.stratX4
                    ? vault.docs
                      ? vault.docs
                      : `3. Sell half rewards into ${symbol} to compound back, other half will be sold another half into WKAI and
            another half into BECO to add ${symbolInVaultStaked} LP and deposit into ${symbolInVaultStaked} Vault`
                    : `3. Sell half rewards into ${symbol}, use the other half of ${symbol} to add ${symbolInVaultStaked} LP to put in the highest APY Vault of ${symbolInVaultStaked} LP`}
                </li>
                <li>4. Every deposit, withdraw of stakers will also compound for the Vault</li>
                <li>5. You will receive Defily Dungeons Token (DDT) as receipt for your staking</li>
              </ul>
            </>
          )}
        </div>
      ) : (
        <div className="mt-2">
          {chainId === ChainId.KAI ? (
            <>
              <p>What does this Vault do?</p>
              <p>Average 30 minutes or faster, the Vault:</p>
              <ul>
                <li>1. Auto-harvest rewards</li>
                <li>2. Charge {vault.fees}% buyback fee on harvest rewards to buyback DFL and burn</li>
                <li>3. {vault.docs || 'Auto-compound back into your staked value'}</li>
                <li>4. Every deposit, withdraw of stakers will also compound for the Vault</li>
                <li>5. You will receive Defily Dungeons Token (DDT) as receipt for your staking</li>
              </ul>
            </>
          ) : (
            <>
              <p>What does this Vault do?</p>
              <p>Average 30 minutes or faster, the Vault:</p>
              <ul>
                <li>1. Auto-harvest rewards</li>
                <li>
                  2. Charge {vault.fees}% on harvest rewards to buyback DFL and burn, {vault.feesWithdraw || 1}% on
                  rewards to pay for the strategy developer
                </li>
                <li>3. Auto-compound back into your staked value</li>
                {chainId !== ChainId.ONUS && (
                  <li>4. Stakers will receive DeFily Dungeons Token (DDT) as receipt for your staking</li>
                )}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ManualCompound;
