import ContainerPage from 'components/Container/ContainerPage';
import PageHeader from 'components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <ContainerPage>
      <PageHeader logo="/logo.png" title="Page Not Found...!" />
      <ButtonCustom className="mx-auto" onClick={() => navigate('/')}>
        Back Home
      </ButtonCustom>
    </ContainerPage>
  );
};

export default NotFound;
