import React, {useCallback, useState} from 'react';
import { FiArrowLeft, FiCheckCircle, FiCopy, FiExternalLink } from 'react-icons/fi';
import Card from 'components/Card/Card';
import CurrencyLogo from 'components/LogoSwap/CurrencyLogo';
import { shortenAddress } from 'utils';
import Tooltip from 'components/Tooltip/Tooltip';
import useCopyClipboard from 'hooks/useCopyClipboard';
import { getExplorerLink } from 'utils/explorer';
import { useAddUserToken } from 'store/user/hooks';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {NETWORK_LABEL} from "../../constants/networks";
import {useChainId} from "wagmi";

const ImportToken = ({ tokens, handleCurrencySelect, onBack }) => {
  const [isCopied, setCopied] = useCopyClipboard();
  const chainId = useChainId()
  const [checked, setChecked] = useState(false);
  const addToken = useAddUserToken();

  const onClickImport = useCallback(() => {
    tokens.forEach(addToken);
    handleCurrencySelect?.(tokens);
  }, [tokens, addToken, handleCurrencySelect]);

  return (
    <div class="p-4">
      {onBack && <FiArrowLeft className="cursor-pointer mb-3" size={24} onClick={onBack} />}
      <div className="p-6 !bg-black1 rounded-xl border-[1px] border-yellow500">
        <div className="flex gap-2 items-center">
          <img src="/icon/carbon_warning.svg" alt="warning" width={21} height={21} />
          <p className="text-[#FDB32F] font-bold">Unknown Source</p>
        </div>
        {/*<p className="text-warning">This token isn’t frequently swapped. Please do your own research before trading.</p>*/}
        <div className="mt-2">
          <p className="text-lg text-white mb-3">Anyone can create a token on {NETWORK_LABEL[chainId]} even with the same name as
            existing ones. Please be careful of fake versions and scam tokens.</p>
          <p className="text-lg text-white">You should also check the available liquidity and pay attention to the Price
            Impact before trading.</p>
        </div>
      </div>
      {tokens.map((token) => (
        <Card key={token.address} className="mt-3 p-5 flex">
          <CurrencyLogo currency={token} size={'48px'}/>
          <div className="ml-2">
            <p className="text-lg font-bold text-white">{token.name}</p>
            <p className=" text-white/50 text-sm">{token.symbol}</p>
            <div className="text-[#FDB32F] flex items-center">
              <span className="mr-1">Address: {shortenAddress(token.address)}</span>
              {isCopied ? (
                <Tooltip tooltip="Copied!">
                  <FiCheckCircle size={16} />
                </Tooltip>
              ) : (
                <FiCopy className="cursor-pointer" size={16} onClick={() => setCopied(token.address)} />
              )}
              <a
                className="ml-1 cursor-pointer"
                rel="noreferrer"
                target="_blank"
                href={getExplorerLink(chainId, token.address, 'token')}
              >
                <FiExternalLink size={16} />
              </a>
            </div>
          </div>
        </Card>
      ))}

      <div className="flex justify-between items-center mt-10">
        <label className="flex items-center">
          <input
            type="checkbox"
            className="h-4 w-4 accent-yellow500"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <span className="ml-2 text-white">I understand</span>
        </label>
        <ButtonCustom
          className=""
          onClick={onClickImport}
          disabled={!checked}
        >
          Import
        </ButtonCustom>
      </div>

    </div>
  );
};

export default ImportToken;
