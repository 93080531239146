import BigNumber from 'bignumber.js';
import Dots from 'components/Loader/Dots';
import Value from 'components/Value/Value';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';

const YourStakedCell = ({ farm, userDataLoaded }) => {
  const { account } = useActiveWeb3React();
  const { stakedBalance } = farm.userData;

  const usdTokenStaking = stakedBalance
    ? new BigNumber(getFullDisplayBalance(stakedBalance, farm.stakingToken.decimals)).times(farm.price || 0).toNumber()
    : 0;

  return (
    <div>
      <p>Your Stake</p>
      {account ? (
        userDataLoaded ? (
          <>
            <Value
              className="font-bold"
              value={account ? getBalanceNumber(stakedBalance, farm.stakingToken.decimals) : 0}
            />
            <Value prefix="~" className="text-sm" value={account ? usdTokenStaking : 0} decimals={2} unit="USD" />
          </>
        ) : (
          <Dots className="font-bold" />
        )
      ) : (
        <>
          <p className="font-bold">???</p>
          <Value prefix="~" className="text-sm" value={0} decimals={0} unit="USD" />
        </>
      )}
    </div>
  );
};

export default YourStakedCell;
