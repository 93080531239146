import { Fragment, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FiChevronDown } from 'react-icons/fi';
import { classNames } from 'utils/styling';

const DropdownV2 = ({
  menu,
  children,
  className,
  classNameMenu,
  classNameMenuItem,
  onClick,
  bsPrefixMenu,
  isArrow = true,
}) => {
  const btnRef = useRef(null);

  return (
    <Menu as="div" className={classNames('relative inline-block', className)}>
      <Menu.Button
        ref={btnRef}
        className={classNames(
          bsPrefixMenu ? bsPrefixMenu : ' focus:boxShadow:none w-full px-5 py-2 shadow-sm focus:outline-none',
          classNameMenu,
        )}
      >
        <div className="flex items-center justify-center" onClick={onClick}>
          {menu}
          {isArrow && <FiChevronDown className="ml-1 shrink-0" size={16} />}
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          onClick={() => btnRef.current?.click()}
          className={classNames(
            'absolute right-0 z-50 w-full origin-top-left rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            classNameMenuItem,
          )}
        >
          <div className="py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownV2;
