// TODO PCS refactor ternaries
/* eslint-disable no-nested-ternary */
import { useCallback, useState, useMemo, Fragment } from 'react';
import { formatAmount } from 'utils/formatInfoNumbers';
import { TransactionType } from 'store/analytics/types';
import { getExplorerLink } from 'utils/explorer';
import { isAddress, shortenAddress } from 'utils';
import Skeleton from 'components/Skeleton/Skeleton';
import { FiExternalLink } from 'react-icons/fi';
import Pagination from 'views/Analytics/components/Pagination/Pagination';
import {
  useSWRBurnTransactions,
  useSWRMintTransactions,
  useSWRSwapTransactions,
  useSWRTransactions,
} from 'hooks/swr/analytics/transactions';
import dayjs from 'dayjs';
import {useChainId} from "wagmi";

const SORT_FIELD = {
  amountUSD: 'amountUSD',
  timestamp: 'timestamp',
  sender: 'sender',
  amountToken0: 'amountToken0',
  amountToken1: 'amountToken1',
};

const TableLoader = () => {
  const loadingRow = (
    <div className="responsiveGridTransactions">
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
      <Skeleton height={20} className="my-3" />
    </div>
  );
  return (
    <>
      {loadingRow}
      {loadingRow}
      {loadingRow}
      {loadingRow}
      {loadingRow}
    </>
  );
};

const DataRow = ({ transaction }) => {
  const chainId = useChainId()
  const abs0 = Math.abs(transaction.amountToken0);
  const abs1 = Math.abs(transaction.amountToken1);
  const outputTokenSymbol = transaction.amountToken0 < 0 ? transaction.token0Symbol : transaction.token1Symbol;
  const inputTokenSymbol = transaction.amountToken1 < 0 ? transaction.token0Symbol : transaction.token1Symbol;

  return (
    <div className="responsiveGridTransactions my-4">
      <a
        target="_blank"
        rel="noreferrer"
        className="hover:underline"
        href={getExplorerLink(chainId, transaction.hash, 'transaction')}
      >
        <span className="flex items-center">
          {transaction.type === TransactionType.MINT ? (
            <>
              Add <strong className="mx-1 text-primary">{transaction.token0Symbol}</strong> and{' '}
              <strong className="mx-1 text-primary">{transaction.token1Symbol}</strong>
            </>
          ) : transaction.type === TransactionType.SWAP ? (
            <>
              Swap <strong className="mx-1 text-primary">{inputTokenSymbol}</strong> for{' '}
              <strong className="mx-1 text-primary">{outputTokenSymbol}</strong>
            </>
          ) : (
            <>
              Remove <strong className="mx-1 text-primary">{transaction.token0Symbol}</strong> and{' '}
              <strong className="mx-1 text-primary">{transaction.token1Symbol}</strong>
            </>
          )}
          <FiExternalLink className="ml-2" size={20} />
        </span>
      </a>
      <span>
        $
        {formatAmount(transaction.amountUSD, {
          displayThreshold: 0.001,
        })}
      </span>
      <span>{`${formatAmount(abs0, {
        displayThreshold: 0.001,
      })} ${transaction.token0Symbol}`}</span>
      <span>{`${formatAmount(abs1, {
        displayThreshold: 0.001,
      })} ${transaction.token1Symbol}`}</span>
      <a
        href={getExplorerLink(chainId, transaction.sender, 'address')}
        target="_blank"
        rel="noreferrer"
        className="hover:underline text-primary flex items-center"
      >
        {shortenAddress(transaction.sender)}
        <FiExternalLink className="ml-2" size={20} />
      </a>
      <span>{dayjs(parseInt(transaction.timestamp, 10) * 1000).fromNow()}</span>
    </div>
  );
};

const TransactionTable = ({ tokenAddress, pairAddress }) => {
  const [sortField, setSortField] = useState(SORT_FIELD.timestamp);
  const [sortDirection, setSortDirection] = useState(true);

  const [page, setPage] = useState(1);

  const [txFilter, setTxFilter] = useState(undefined);

  const {
    maxPage: maxPageALL,
    transactions: allTransactions,
    isLoading: isLoadingAll,
  } = useSWRTransactions({
    page,
    sort: sortField,
    sortDir: sortDirection ? 'desc' : 'asc',
    ...(tokenAddress && { token: isAddress(tokenAddress) }),
    ...(pairAddress && { pair: isAddress(pairAddress) }),
  });

  const {
    maxPage: maxPageMint,
    transactions: mintTransactions,
    isLoading: isLoadingMint,
  } = useSWRMintTransactions({
    page,
    sort: sortField,
    sortDir: sortDirection ? 'desc' : 'asc',
    ...(tokenAddress && { token: isAddress(tokenAddress) }),
    ...(pairAddress && { pair: isAddress(pairAddress) }),
  });
  const {
    maxPage: maxPageBurn,
    transactions: burnTransactions,
    isLoading: isLoadingBurn,
  } = useSWRBurnTransactions({
    page,
    sort: sortField,
    sortDir: sortDirection ? 'desc' : 'asc',
    ...(tokenAddress && { token: isAddress(tokenAddress) }),
    ...(pairAddress && { pair: isAddress(pairAddress) }),
  });
  const {
    maxPage: maxPageSwap,
    transactions: swapTransactions,
    isLoading: isLoadingSwap,
  } = useSWRSwapTransactions({
    page,
    sort: sortField,
    sortDir: sortDirection ? 'desc' : 'asc',
    ...(tokenAddress && { token: isAddress(tokenAddress) }),
    ...(pairAddress && { pair: isAddress(pairAddress) }),
  });

  const maxPage = useMemo(() => {
    if (txFilter === TransactionType.MINT) {
      return maxPageMint;
    } else if (txFilter === TransactionType.BURN) {
      return maxPageBurn;
    } else if (txFilter === TransactionType.SWAP) {
      return maxPageSwap;
    } else {
      return maxPageALL;
    }
  }, [txFilter, maxPageALL, maxPageMint, maxPageBurn, maxPageSwap]);

  const isLoading = useMemo(() => {
    if (txFilter === TransactionType.MINT) {
      return isLoadingMint;
    } else if (txFilter === TransactionType.BURN) {
      return isLoadingBurn;
    } else if (txFilter === TransactionType.SWAP) {
      return isLoadingSwap;
    } else {
      return isLoadingAll;
    }
  }, [txFilter, isLoadingMint, isLoadingBurn, isLoadingSwap, isLoadingAll]);

  const transactions = useMemo(() => {
    if (txFilter === TransactionType.MINT) {
      return mintTransactions;
    }
    if (txFilter === TransactionType.BURN) {
      return burnTransactions;
    }
    if (txFilter === TransactionType.SWAP) {
      return swapTransactions;
    }
    return allTransactions;
  }, [txFilter, mintTransactions, burnTransactions, swapTransactions, allTransactions]);

  const handleFilter = useCallback(
    (newFilter) => {
      if (newFilter !== txFilter) {
        setTxFilter(newFilter);
        setPage(1);
      }
    },
    [txFilter],
  );

  const handleSort = useCallback(
    (newField) => {
      setSortField(newField);
      setSortDirection(sortField !== newField ? true : !sortDirection);
    },
    [sortDirection, sortField],
  );

  const arrow = useCallback(
    (field) => {
      const directionArrow = !sortDirection ? '↑' : '↓';
      return sortField === field ? directionArrow : '';
    },
    [sortDirection, sortField],
  );

  return (
    <div>
      <div className="flex mb-5">
        <div className="flex justify-center">
          <div className="flex items-center mr-4 mb-4" onClick={() => handleFilter(undefined)}>
            <input id="radio1" type="radio" name="radio" className="hidden" defaultChecked={txFilter === undefined} />
            <label htmlFor="radio1" className="flex items-center cursor-pointer">
              <span className="w-6 h-6 inline-block mr-1 rounded-full border border-grey"></span>
              All
            </label>
          </div>

          <div onClick={() => handleFilter(TransactionType.SWAP)} className="flex items-center mr-4 mb-4">
            <input
              defaultChecked={txFilter === TransactionType.SWAP}
              id="radio2"
              type="radio"
              name="radio"
              className="hidden"
            />
            <label htmlFor="radio2" className="flex items-center cursor-pointer">
              <span className="w-6 h-6 inline-block mr-1 rounded-full border border-grey"></span>
              Swaps
            </label>
          </div>
          <div onClick={() => handleFilter(TransactionType.MINT)} className="flex items-center mr-4 mb-4">
            <input
              defaultChecked={txFilter === TransactionType.MINT}
              id="radio3"
              type="radio"
              name="radio"
              className="hidden"
            />
            <label htmlFor="radio3" className="flex items-center cursor-pointer">
              <span className="w-6 h-6 inline-block mr-1 rounded-full border border-grey"></span>
              Adds
            </label>
          </div>
          <div onClick={() => handleFilter(TransactionType.BURN)} className="flex items-center mr-4 mb-4">
            <input
              defaultChecked={txFilter === TransactionType.BURN}
              id="radio4"
              type="radio"
              name="radio"
              className="hidden"
            />
            <label htmlFor="radio4" className="flex items-center cursor-pointer">
              <span className="w-6 h-6 inline-block mr-1 rounded-full border border-grey"></span>
              Removes
            </label>
          </div>
        </div>
      </div>
      <div className="tableWrapper shadow-lg">
        <div className="responsiveGridTransactions mb-5 mt-3">
          <p className="font-bold text-primary uppercase text-sm">Action</p>
          <div
            className=" font-bold text-primary uppercase text-sm"
            // onClick={() => handleSort(SORT_FIELD.amountUSD)}
          >
            Total Value {arrow(SORT_FIELD.amountUSD)}
          </div>
          <div
            className=" font-bold text-primary uppercase text-sm"
            // onClick={() => handleSort(SORT_FIELD.amountToken0)}
          >
            Token Amount {arrow(SORT_FIELD.amountToken0)}
          </div>
          <div
            className=" font-bold text-primary uppercase text-sm"
            // onClick={() => handleSort(SORT_FIELD.amountToken1)}
          >
            Token Amount {arrow(SORT_FIELD.amountToken1)}
          </div>
          <div
            className=" font-bold text-primary uppercase text-sm"
            // onClick={() => handleSort(SORT_FIELD.sender)}
          >
            Account {arrow(SORT_FIELD.sender)}
          </div>
          <div
            className="cursor-pointer font-bold text-primary uppercase text-sm"
            onClick={() => handleSort(SORT_FIELD.timestamp)}
          >
            Time {arrow(SORT_FIELD.timestamp)}
          </div>
        </div>
        <div className="bg-black1 h-0.5 w-full my-2" />

        {!isLoading ? (
          <>
            {transactions.map((transaction, index) => {
              if (transaction) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>
                    <DataRow transaction={transaction} />
                    <div className="bg-black1 h-0.5 w-full my-2" />
                  </Fragment>
                );
              }
              return null;
            })}
            {transactions.length === 0 ? (
              <div className="text-center my-5">
                <p>No Transactions</p>
              </div>
            ) : undefined}
            <Pagination page={page} maxPage={maxPage} setPage={setPage} />
          </>
        ) : (
          <>
            <TableLoader />
            {/* spacer */}
            <div />
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionTable;
