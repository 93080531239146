import { useState, memo } from 'react';
import NoChartAvailable from './NoChartAvailable';
import { getTimeWindowChange } from './utils';
import PairPriceDisplay from 'views/Swap/components/Chart/PairPriceDisplay';
import { classNames } from 'utils/styling';
import SwapLineChart from 'views/Swap/components/Chart/SwapLineChart';
import { useSWRPairChartPriceData } from 'hooks/swr/analytics/pairs';
import { INTERVAL } from 'views/Swap/components/Chart/types';

const BasicChart = ({ token0Address, token1Address, inputCurrency, outputCurrency }) => {
  const [interval, setInterval] = useState(2);

  const {
    pairPrices = [],
    pairId,
    error,
    data,
    isLoading,
  } = useSWRPairChartPriceData({
    token0: token0Address,
    token1: token1Address,
    interval: INTERVAL[interval],
  });
  const [hoverValue, setHoverValue] = useState();
  const [hoverDate, setHoverDate] = useState();
  const valueToDisplay = hoverValue || pairPrices[pairPrices.length - 1]?.value;
  const { changePercentage, changeValue } = getTimeWindowChange(pairPrices);
  const isChangePositive = changeValue >= 0;
  const currentDate = new Date().toLocaleString('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

  // Sometimes we might receive array full of zeros for obscure tokens while trying to derive data
  // In that case chart is not useful to users
  const isBadData =
    pairPrices &&
    pairPrices.length > 0 &&
    pairPrices.every(
      (price) => !price.value || price.value === 0 || price.value === Infinity || Number.isNaN(price.value),
    );

  if (isBadData || error || (data && pairPrices.length === 0)) {
    return <NoChartAvailable setInterval={setInterval} interval={interval} token0Address={token0Address} token1Address={token1Address} pairAddress={pairId} />;
  }

  return (
    <>
      <div className="flex justify-between flex-wrap text-sm-md px-3">
        <div>
          <PairPriceDisplay
            value={pairPrices?.length > 0 && valueToDisplay}
            inputSymbol={inputCurrency?.symbol}
            outputSymbol={outputCurrency?.symbol}
          >
            <p className={classNames(isChangePositive ? 'text-success' : 'text-failure', 'text-sm font-bold')}>
              {`${isChangePositive ? '+' : ''}${changeValue.toFixed(3)} (${changePercentage}%)`}
            </p>
          </PairPriceDisplay>
          <p className="text-sm">{hoverDate || currentDate}</p>
        </div>
        <div>
          <div className="flex justify-center mx-auto text-white bg-green-900 max-w-min	rounded-xl">
            {['24H', '1W', '1M', '1Y'].map((text, index) => (
              <div
                key={index}
                className={classNames(
                  'px-3 py-2 whitespace-nowrap cursor-pointer',
                  interval === index && 'text-black bg-primary rounded-xl',
                )}
                onClick={() => setInterval(index)}
              >
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`w-full`} style={{ height: '369px' }}>
        <SwapLineChart
          isLoading={isLoading}
          data={pairPrices}
          setHoverValue={setHoverValue}
          setHoverDate={setHoverDate}
          isChangePositive={isChangePositive}
          interval={interval}
        />
      </div>
    </>
  );
};

export default memo(BasicChart, (prev, next) => {
  return prev.token0Address === next.token0Address && prev.token1Address === next.token1Address;
});
