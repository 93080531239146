import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const Back = () => {
  const navigate = useNavigate();

  return (
    <FiArrowLeft
      className="mt-2 block text-primary z-30 cursor-pointer ml-3 sm:hidden"
      size={32}
      onClick={() => navigate(-1)}
    />
  );
};

export default Back;
