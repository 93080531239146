import { classNames } from 'utils/styling';
import { DEFAULT_NETWORK } from 'connectors/index';
import { NETWORK_ICON, NETWORK_LABEL } from 'constants/networks';
import DropdownV2 from 'components/Dropdown/DropdownV2';
import {useChainId, useSwitchChain} from "wagmi";

function Web3Network({ className }) {
    const chainId = useChainId()
    const { chains, switchChain } = useSwitchChain()

    return (
      <>
        <DropdownV2
            menu={
                <>
                    <img
                        src={chainId ? NETWORK_ICON[chainId] : NETWORK_ICON[DEFAULT_NETWORK]}
                        alt="Switch Network"
                        className="size-7 rounded-full bg-white"
                    />
                    <span className="hidden sm:block ml-1.5">{NETWORK_LABEL[chainId ? chainId : DEFAULT_NETWORK]}</span>
                </>
            }
            classNameMenuItem="bg-dropdown-menu py-2 border-1 box-shadow-dropdown-menu !w-52 !left-0"
            bsPrefixMenu={classNames('px-4 py-1 rounded text-primary text-xl bg-black font-bold block ', className)}
        >
          {chains.map((chain, i) => {
            const networkUser = chainId ? chainId : DEFAULT_NETWORK;
              if (networkUser === chain.id) {
              return (
                  <div key={i} className="px-4 mx-2 py-2  rounded bg-primary">
                    <div className="group w-full h-full flex flex-row items-center">
                      <div className="mr-3">
                        <img
                            src={NETWORK_ICON[chain.id]}
                            alt={`Switch to ${NETWORK_LABEL[chain.id]} Network`}
                            className="w-8 h-8 rounded-full bg-white"
                        />
                      </div>
                      <div className="flex-1">{NETWORK_LABEL[chain.id]}</div>
                    </div>
                  </div>
              );
            }

            return (
                <div key={i} className="px-4 mx-2 my-1 py-2  rounded bg-dropdown-item-hover text-white cursor-pointer">
                  <div className="group w-full h-full flex flex-row items-center" onClick={() => switchChain({chainId: chain.id})}>
                    <div className="mr-3">
                      <img
                          src={NETWORK_ICON[chain.id]}
                          alt={`Switch to ${NETWORK_LABEL[chain.id]} Network`}
                          className="w-8 h-8 rounded-full bg-white"
                      />
                    </div>
                    <div className="flex-1">{NETWORK_LABEL[chain.id]}</div>
                  </div>
                </div>
            );
          })}
        </DropdownV2>
      </>
  );
}

export default Web3Network;
