import BigNumber from 'bignumber.js';
import Dots from 'components/Loader/Dots';
import ModalApyCalculator from 'components/Modal/ModalApyCalculator';
import ModalApyCalculatorVault from 'components/Modal/ModalApyCalculatorVault';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { TYPE_STRATEGY } from 'constants/vaults';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useState } from 'react';
import { FiClipboard, FiHelpCircle } from 'react-icons/fi';
import { usePrices } from 'store/prices/hook';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getParameterCaseInsensitive, tokenEarnedPerThousandDaily } from 'utils/index';
import { TABS_VAULT_CARD } from 'views/Vaults/components/VaultsCard/VaultCard';

const Statistics = ({ vault, userDataLoaded, onChangeTab, toggleOpen }) => {
  const { account, chainId } = useActiveWeb3React();
  const [openApyCompound, setOpenApyCompound] = useState(false);
  const prices = usePrices();
  const { stakedBalance, stakingTokenBalance } = vault.userData;
  const priceStakingToken = getParameterCaseInsensitive(prices, vault.stakingToken.address[chainId]);
  const [openRoi, setOpenRoi] = useState(false);

  const apr = vault?.farmStaked?.apr;

  const usdHoldings = priceStakingToken
    ? new BigNumber(getFullDisplayBalance(stakedBalance, vault.stakingToken.decimals))
        .times(priceStakingToken)
        .toNumber()
    : 0;

  const usdAvailable = priceStakingToken
    ? new BigNumber(getFullDisplayBalance(stakingTokenBalance, vault.stakingToken.decimals))
        .times(priceStakingToken)
        .toNumber()
    : 0;

  const handleOpenModalApy = () => setOpenApyCompound((prevState) => !prevState);

  const [q0User, q1User] = getLpBreakdown(
    stakedBalance.div(BIG_TEN.pow(vault.stakingToken.decimals)).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  const [q0StakingUser, q1StakingUser] = getLpBreakdown(
    stakingTokenBalance.div(BIG_TEN.pow(vault.stakingToken.decimals)).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  const handleOpenAccount = (tabSelected) => {
    onChangeTab(tabSelected);
    toggleOpen(true);
  };

  return (
    <>
      {openApyCompound && (
        <ModalApyCalculatorVault onClose={handleOpenModalApy} open={openApyCompound} apy={vault.apy} data={vault} />
      )}
      {openRoi ? (
        <ModalApyCalculator
          earningTokenSymbol={vault?.farmStaked?.earningToken?.symbol}
          open={openRoi}
          tokenPrice={vault?.farmStaked?.earningTokenPrice}
          data={vault?.farmStaked}
          tokenEarnedPerThousandDaily={tokenEarnedPerThousandDaily(vault?.farmStaked?.stakedTvl, apr)}
          isFarm
          onClose={() => setOpenRoi(!openRoi)}
        />
      ) : null}
      <div>
        <p className="font-bold text-center mb-2 mt-2 text-white">STATISTICS</p>
        <div className="flex space-x-2 sm:space-x-4 mb-2 flex-wrap text-white">
          <div
            className="flex-1 bg-black1  rounded-md p-2 cursor-pointer"
            onClick={() => handleOpenAccount(TABS_VAULT_CARD.withdraw)}
          >
            <Tooltip tooltip="Your vault holdings are shown below. This is denominated in the underlying token and includes your initial deposit and earnings">
              <p className="flex items-center">
                Holdings <FiHelpCircle className="ml-1" size={14} />
              </p>
            </Tooltip>
            {account ? (
              userDataLoaded && priceStakingToken ? (
                <Tooltip
                  tooltip={
                    <div className="whitespace-nowrap">
                      <p>Your Deposits</p>
                      <p>
                        {formatNumber(getBalanceNumber(stakedBalance, vault.stakingToken.decimals))}{' '}
                        {vault.stakingToken.symbol}
                      </p>
                      {vault?.token1 && vault.farmStaked && (
                        <p>
                          {stakedBalance.gt(0) && (
                            <>
                              {formatNumber(getBalanceNumber(q0User, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                              {vault.farmStaked.token0.symbol} +{' '}
                              {formatNumber(getBalanceNumber(q1User, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                              {vault.farmStaked.token1.symbol}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  }
                >
                  <Value
                    className="font-bold sm:text-base"
                    value={account ? getBalanceNumber(stakedBalance, vault.stakingToken.decimals) : 0}
                  />
                  {usdHoldings > 0 && <Value prefix="~$" className="text-sm" value={account ? usdHoldings : 0} />}
                </Tooltip>
              ) : (
                <Dots className="font-bold sm:text-base" />
              )
            ) : (
              <span className="font-bold sm:text-base">???</span>
            )}
          </div>
          <div
            className="flex-1 bg-black1 rounded-md p-2 cursor-pointer"
            onClick={() => handleOpenAccount(TABS_VAULT_CARD.deposit)}
          >
            <Tooltip tooltip="Available to deposit">
              <p className="flex items-center">
                Available <FiHelpCircle className="ml-1" size={14} />
              </p>
            </Tooltip>
            {account ? (
              userDataLoaded && priceStakingToken ? (
                <Tooltip
                  tooltip={
                    <div className="whitespace-nowrap">
                      <p>Your Wallet</p>
                      <p>
                        {formatNumber(getBalanceNumber(stakingTokenBalance, vault.stakingToken.decimals))}{' '}
                        {vault.stakingToken.symbol}
                      </p>
                      {vault?.token1 && vault.farmStaked && (
                        <p>
                          {stakingTokenBalance.gt(0) && (
                            <>
                              {formatNumber(getBalanceNumber(q0StakingUser, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                              {vault.farmStaked.token0.symbol} +{' '}
                              {formatNumber(getBalanceNumber(q1StakingUser, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                              {vault.farmStaked.token1.symbol}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  }
                >
                  <Value
                    className="font-bold sm:text-base"
                    value={account ? getBalanceNumber(stakingTokenBalance, vault.stakingToken.decimals) : 0}
                  />
                  {usdAvailable > 0 && <Value prefix="~$" className="text-sm" value={account ? usdAvailable : 0} />}
                </Tooltip>
              ) : (
                <Dots className="font-bold sm:text-base" />
              )
            ) : (
              <span className="font-bold sm:text-base">???</span>
            )}
          </div>
        </div>
        {!vault.isFinished && (
          <div className="flex space-x-2 sm:space-x-4 mb-2 flex-wrap text-white">
            <div className="flex-1 bg-black1  rounded-md p-2">
              <p>APR</p>
              {vault?.farmStaked?.apr?.yearlyAPR ? (
                <p className="flex font-bold sm:text-base cursor-pointer" onClick={() => setOpenRoi(true)}>
                  {vault?.farmStaked?.apr?.yearlyAPR > 100000 ? '>99,999' : vault?.farmStaked?.apr?.yearlyAPR}%
                  <span className="flex items-center">
                    <FiClipboard className="ml-1" size={18} />
                  </span>
                </p>
              ) : (
                <Dots className="font-bold sm:text-base" />
              )}
            </div>
            <div className="flex-1 bg-black1  rounded-md p-2">
              <p>APY</p>
              {vault.apy.yearlyApy ? (
                <p
                  className="flex font-bold sm:text-base cursor-pointer text-primary cursor-pointer"
                  onClick={handleOpenModalApy}
                >
                  {vault.apy.yearlyApy > 100000 ? '>99,999' : formatNumber(vault.apy.yearlyApy, 2, 2)}%{' '}
                  <span className="flex items-center">
                    <FiClipboard className="ml-1" size={18} />
                  </span>
                </p>
              ) : (
                <Dots className="font-bold sm:text-base" />
              )}
            </div>
          </div>
        )}
        <div className="flex space-x-2 sm:space-x-4 mb-2 flex-wrap text-white">
          <div className="flex-1 bg-black1  rounded-md p-2">
            <p>
              {' '}
              <a
                rel="noreferrer"
                className="flex items-center"
                onClick={(e) => e.stopPropagation()}
                href="https://docs.defily.io/financial/dungeons-and-strategies"
                target="_blank"
              >
                Strategy <FiHelpCircle className="ml-1" size={14} />
              </a>
            </p>
            <a
              rel="noreferrer"
              className="font-bold sm:text-base"
              onClick={(e) => e.stopPropagation()}
              href="https://docs.defily.io/financial/dungeons-and-strategies"
              target="_blank"
            >
              {vault.typeStrategy
                ? vault.typeStrategy
                : vault.token1
                ? TYPE_STRATEGY.stratX2
                : TYPE_STRATEGY.strat100Back}
            </a>
          </div>
          <div className="flex-1 bg-black1  rounded-md p-2">
            <p>Fees</p>
            <div className="flex">
              <Tooltip
                tooltip={
                  <div className="w-80 whitespace-normal break-words">
                    <p>
                      {vault.fees}% of harvest rewards will be used to buyback DFL and burn to
                      0x000000000000000000000000000000000000dEaD
                    </p>
                  </div>
                }
              >
                <div className="font-bold sm:text-base">
                  <p>{vault.fees}%</p>
                </div>
              </Tooltip>
              <span className="mx-1 font-bold sm:text-base">+</span>
              <Tooltip
                tooltip={
                  <div className="w-80 whitespace-normal break-words">
                    <p>{vault.feesWithdraw || 1}% Withdrawal Fee on holdings will be sent back to Vault</p>
                  </div>
                }
              >
                <div className="font-bold sm:text-base">
                  <p>{vault.feesWithdraw || 1}%</p>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Statistics;
