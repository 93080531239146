import FarmCard from 'views/Farms/components/FarmsCard/FarmCard';

const FarmsCard = ({ farms, userDataLoaded }) => {
  return (
    <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-4">
      {farms.map((farm, index) => {
        return <FarmCard key={index} farm={farm} userDataLoaded={userDataLoaded} farms={farms} />;
      })}
    </div>
  );
};

export default FarmsCard;
