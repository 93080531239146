import { RiStarLine, RiStarFill } from 'react-icons/ri';

const SaveIcon = ({ fill = false, ...rest }) => {
  return (
    <div {...rest} className="cursor-pointer">
      {fill ? <RiStarFill size={24} color="#FFD700" /> : <RiStarLine size={24} />}
    </div>
  );
};

export default SaveIcon;
