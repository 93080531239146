import { TradeType } from 'ezcake-v2-sdk';
import { Field } from 'store/swap/actions';
import { useUserSlippageTolerance } from 'store/user/hooks';
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, formatExecutionPrice } from 'utils/prices';
import QuestionHelper from 'components/QuestionHelper';
import FormattedPriceImpact from './FormattedPriceImpact';
import SwapRoute from './SwapRoute';
import { FEE_SWAP_DETAIL } from 'constants/swap';
import { useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import {useChainId} from "wagmi";

function TradeSummary({ trade, allowedSlippage }) {
  const chainId = useChainId()
  const [showInverted, setShowInverted] = useState(false);

  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade);
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage);

  return (
    <div style={{ padding: '0 16px' }}>
      <div className="flex items-center justify-between flex-wrap mb-1">
        <p>Price</p>
        <p className="flex items-center">
          {formatExecutionPrice(trade, showInverted)}
          <FiRefreshCcw
            className="cursor-pointer ml-1 transition-all hover:rotate-180 duration-300"
            onClick={() => setShowInverted(!showInverted)}
            size={18}
          />
        </p>
      </div>
      <div className="flex items-center justify-between flex-wrap mb-1">
        <div className="flex items-center">
          <p>{isExactIn ? 'Minimum received' : 'Maximum sold'}</p>
          <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
        </div>
        <div>
          <p>
            {isExactIn
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ?? '-'}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between flex-wrap mb-1">
        <div className="flex items-center">
          <p>Price Impact</p>
          <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
        </div>
        <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
      </div>

      <div className="flex items-center justify-between flex-wrap mb-1">
        <div className="flex items-center">
          <p>Liquidity Provider Fee</p>
          <QuestionHelper text={FEE_SWAP_DETAIL[chainId]} />
        </div>
        <p className="break-words">
          {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
        </p>
      </div>
    </div>
  );
}

export function AdvancedSwapDetails({ trade }) {
  const [allowedSlippage] = useUserSlippageTolerance();

  const showRoute = Boolean(trade && trade.route.path.length > 2);

  return (
    <div className="text-white">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <div className="mt-4">
                <span className="flex items-center justify-center">
                  <p>Route</p>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </span>
                <SwapRoute trade={trade} />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
