import Back from 'components/Back/Back';
import { useNavigate, useParams } from 'react-router-dom';
import Detail from './components/Detail';
import { useFetchLand, useLandFromIdoId } from 'store/lands/hook';
import Loader from 'components/Loader/Loader';
import { useFetchLandUserData } from 'store/lands/hook';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import WrongNetWork from './components/WrongNetWork';

const Land = () => {
  const { chainId } = useActiveWeb3React();
  const navigate = useNavigate();
  const { id } = useParams();
  useFetchLand(id);
  useFetchLandUserData(id);
  const pool = useLandFromIdoId(id);

  if (pool === undefined) {
    navigate(-1);
  }

  if (!pool) {
    return (
      <>
        <Back />
        <div className="container mx-auto p-3 h-96">
          <Loader className="border-t-4 h-20 w-20 mx-auto" />
        </div>
      </>
    );
  }

  if (pool?.chainId !== undefined && pool.chainId !== chainId) {
    return <WrongNetWork supportChain={pool?.chainId} />;
  }

  return (
    <>
      <Back />
      <div className="px-4 sm:px-6">
        <div className="container max-w-screen-xl mx-auto">
          <Detail pool={pool} />
        </div>
      </div>
    </>
  );
};

export default Land;
