import React, { useState } from 'react';
import { classNames } from 'utils/styling';
import { FiChevronRight } from 'react-icons/fi';

const Collapse = ({ prefix, append, title, children, headerClassName, className, defaultExtend }) => {
  const [toggle, setToggle] = useState(!!defaultExtend);
  const onToggle = () => setToggle(!toggle);

  return (
    <div className={className}>
      <div
        className={classNames('flex flex-row justify-between cursor-pointer mb-3', headerClassName)}
        onClick={onToggle}
      >
        <div className={classNames('flex flex-row text-white')}>
          {prefix}
          <span>{title}</span>
          {append}
        </div>
        <div>
          <FiChevronRight
            color="white"
            className={classNames(
              'transition duration-100 transform rotate-0',
              toggle && 'transition duration-100 transform rotate-90',
            )}
          />
        </div>
      </div>
      <div
        className={classNames(
          'transition duration-700 h-0 overflow-y-hidden',
          toggle && 'transition duration-700 h-auto',
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
