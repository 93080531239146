import BigNumber from 'bignumber.js';
import castlesConfig, { castlesV2Config } from 'constants/castles';
import { BIG_ZERO } from 'utils/bigNumber';
import { getSouschefContract } from 'utils/contractHelpers';
import multicall from 'utils/multicall';

import sousChefABI from 'config/abi/sousChef.json';
import sousChefV2ABI from 'config/abi/sousChefV2.json';
import defilyAbi from 'config/abi/defily.json';

export const fetchPoolsBlockLimits = async (pools) => {
  const callsStartBlock = pools.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress,
      name: 'startBlock',
    };
  });
  const callsEndBlock = pools.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress,
      name: 'bonusEndBlock',
    };
  });

  const starts = await multicall(sousChefABI, callsStartBlock);
  const ends = await multicall(sousChefABI, callsEndBlock);

  return pools.map((cakePoolConfig, index) => {
    const startBlock = starts[index];
    const endBlock = ends[index];
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    };
  });
};

export const fetchPoolsTotalStaking = async (pools, chainId) => {
  const calls = pools.map((poolConfig) => {
    return {
      address: poolConfig.stakingToken.address[chainId],
      name: 'balanceOf',
      params: [poolConfig.contractAddress],
    };
  });

  const poolsTotalStaked = await multicall(defilyAbi, calls, chainId);

  return [
    ...pools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
    })),
  ];
};

export const fetchPoolStakingLimit = async (sousId, chainId) => {
  try {
    const config = [...castlesConfig[chainId], ...castlesV2Config[chainId]].find((pool) => pool.sousId === sousId);
    const sousContract = getSouschefContract(config.contractAddress);

    const stakingLimit = await sousContract.poolLimitPerUser();
    return new BigNumber(stakingLimit._hex);
  } catch (error) {
    return BIG_ZERO;
  }
};

export const fetchPoolsStakingLimits = async (poolsWithStakingLimit, chainId) => {
  const validPools = [...castlesConfig[chainId], ...castlesV2Config[chainId]]
    .filter((p) => !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.sousId));

  // Get the staking limit for each valid pool
  // Note: We cannot batch the calls via multicall because V1 castles do not have "poolLimitPerUser" and will throw an error
  const stakingLimitPromises = validPools.map((validPool) => fetchPoolStakingLimit(validPool.sousId, chainId));
  const stakingLimits = await Promise.all(stakingLimitPromises);

  return stakingLimits.reduce((accum, stakingLimit, index) => {
    return {
      ...accum,
      [validPools[index].sousId]: stakingLimit,
    };
  }, {});
};

export const fetchPoolsLimits = async (chainId) => {
  const validPools = [...castlesV2Config[chainId]].filter((p) => !p.isFinished);

  const calls = validPools.map((poolConfig) => {
    return {
      address: poolConfig.contractAddress,
      name: 'poolCap',
    };
  });

  const poolsTotalStaked = await multicall(sousChefV2ABI, calls, chainId);

  return validPools.reduce((accum, pool, index) => {
    return {
      ...accum,
      [validPools[index].sousId]: new BigNumber(poolsTotalStaked[index][0]._hex),
    };
  }, {});
};
