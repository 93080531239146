import { classNames } from 'utils/styling';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useState } from 'react';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';

import { getExplorerLink, getLinkGetTokenLP } from 'utils/explorer';
import { Link } from 'react-router-dom';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber } from 'utils/formatBalance';
import { DOMAIN } from "config";

const DetailsSection = ({ lpAddress, token0, token1, stakingToken, isTable, farm, earningToken }) => {
  const { chainId } = useActiveWeb3React();
  const [isView, setIsView] = useState(isTable);
  const linkGetToken = getLinkGetTokenLP(chainId, token0, token1, stakingToken);

  const stakedTvl = farm?.stakedTvl || 0;
  const [q0Farm, q1Farm] = getLpBreakdown(+farm?.staked, farm?.totalSupply, farm?.q0, farm?.q1);

  const apr = farm?.apr;

  const exchangeLink = {
      [ChainId.BSC_TESTNET]: token1
          ? `${DOMAIN}/#/analytics/pairs/${lpAddress[chainId]}`
          : `${DOMAIN}/#/analytics/tokens/${lpAddress[chainId]}`,
  };

  return (
    <div>
      {!isTable && (
        <div
          className="text-primary font-bold flex items-center justify-center cursor-pointer mt-4"
          onClick={() => setIsView(!isView)}
        >
          <span>Detail</span> {isView ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
        </div>
      )}
      {isTable && (
        <>
          <div className="flex md:hidden text-white">
            TVL:{' '}
            <Tooltip
              tooltip={
                <div className="whitespace-nowrap">
                  <p>Total Deposits</p>
                  <p>
                    {formatNumber(+farm?.staked)} {farm.stakingToken.symbol}
                  </p>
                  {farm?.token1 && (
                    <p>
                      {farm?.staked && (
                        <>
                          {formatNumber(getBalanceNumber(q0Farm, farm?.token0?.decimals), 2, 4)} {farm?.token0?.symbol}{' '}
                          + {formatNumber(getBalanceNumber(q1Farm, farm?.token1?.decimals), 2, 4)}{' '}
                          {farm?.token1?.symbol}
                        </>
                      )}
                    </p>
                  )}
                </div>
              }
            >
              <Value className="font-bold ml-1" prefix="$" value={stakedTvl} decimals={0} />
            </Tooltip>
          </div>
          {!farm.isFinished && (
            <div className="flex items-center text-white">
              <p>{earningToken.symbol} per day:</p>
              <p className="font-bold ml-1">{apr?.userDailyRewards ? formatNumber(+apr?.userDailyRewards, 0, 0) : 0}</p>
            </div>
          )}
          {[ChainId.BSC,ChainId.BSC_TESTNET].includes(chainId) && (
            <>
              <div className="flex items-center text-white">
                <p>Withdrawal Fee:</p>
                <p className="font-bold ml-1">{farm.withdrawalFee || 0}%</p>
              </div>
            </>
          )}
        </>
      )}
      {isView && (
        <div className={classNames('text-white mt-2', !isTable && 'text-center')}>
          {linkGetToken ? (
            <>
              {linkGetToken?.route ? (
                <Link
                  className={classNames(
                    'flex items-center  cursor-pointer hover:underline',
                    !isTable && 'justify-center',
                  )}
                  to={linkGetToken?.route}
                  target="_blank"
                >
                  {linkGetToken?.label}
                  <FiExternalLink className="ml-1" size={16} />
                </Link>
              ) : (
                <a
                  rel="noreferrer"
                  className={classNames('flex items-center hover:underline', !isTable && 'justify-center')}
                  target="_blank"
                  href={linkGetToken?.link}
                >
                  {linkGetToken?.label} <FiExternalLink className="ml-1" size={16} />
                </a>
              )}
            </>
          ) : null}
          <a
            rel="noreferrer"
            className={classNames('flex items-center  cursor-pointer hover:underline', !isTable && 'justify-center')}
            href={getExplorerLink(chainId, lpAddress[chainId], 'address')}
            target="_blank"
          >
            View Contract <FiExternalLink className="ml-1" size={16} />
          </a>
          {exchangeLink[chainId] && (
            <a
              rel="noreferrer"
              className={classNames('flex items-center  cursor-pointer hover:underline', !isTable && 'justify-center')}
              href={exchangeLink[chainId]}
              target="_blank"
            >
              View Exchange
              <FiExternalLink className="ml-1" size={16} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsSection;
