import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import { useCallback } from 'react';
import { useRefreshContract } from './useContract';
import { refresh } from '../utils/calls/vaults';

const useRefreshVault = () => {
  const refreshContract = useRefreshContract();

  const handleRefresh = useCallback(async () => {
    try {
      const txHash = await refresh(refreshContract);
      showToastSuccess('Refresh successfully!');
      console.log(txHash);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
    }
  }, [refreshContract]);

  return { onRefresh: handleRefresh };
};

export default useRefreshVault;
