import { UINT256_MAX } from 'config';
import { BIG_TEN } from '../bigNumber';
import BigNumber from 'bignumber.js';

export const approvePoolIdo = async (tokenContract, masterChefAddress) => {
  const tx = await tokenContract.approve(masterChefAddress, UINT256_MAX);
  await tx.wait();
  return tx.transactionHash;
};

export const stakePoolIdo = async (contract, amount, decimals) => {
  const tx = await contract.deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString());
  await tx.wait();
  return tx.transactionHash;
};

export const unStakePoolIdo = async (contract, amount, decimals) => {
  const tx = await contract.withdraw(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString());
  await tx.wait();
  return tx.transactionHash;
};

export const investMainCoin = async (contract, amount, decimals) => {
  const tx = await contract.invest({ value: new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString() });
  await tx.wait();
  return tx.transactionHash;
};

export const investAltCoin = async (contract, amount, decimals) => {
  const tx = await contract.invest(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString());
  await tx.wait();
  return tx.transactionHash;
};

export const claimIdo = async (contract) => {
  const tx = await contract.claimTokens();
  await tx.wait();
  return tx.transactionHash;
};

export const buyIDO = async (idoContract, amount, decimals = 18) => {
  const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString();
  const tx = await idoContract.buyToken({ value });
  await tx.wait();
  return tx.hash;
};
