import erc20ABI from 'config/abi/erc20.json';
import sousChefAbi from 'config/abi/sousChef.json';
import sousChefV2Abi from 'config/abi/sousChefV2.json';
import sousChefV3Abi from 'config/abi/sousChefV3.json';
import BigNumber from 'bignumber.js';
import multicall from 'utils/multicall';

export const fetchPoolUserAllowances = async (account, pools, chainId) => {
  const calls = pools.map((pool) => {
    return {
      address: pool.stakingToken.address[chainId],
      name: 'allowance',
      params: [account, pool.contractAddress],
    };
  });

  const rawLpAllowances = await multicall(erc20ABI, calls, chainId);
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON();
  });

  return parsedLpAllowances;
};

export const fetchPoolUserTokenBalances = async (account, pools, chainId) => {
  const calls = pools.map((pool) => {
    return {
      address: pool.stakingToken.address[chainId],
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls, chainId);

  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });

  return parsedTokenBalances;
};

export const fetchPoolUserStakedBalances = async (account, pools, chainId) => {
  const poolsNoWithDrawFee = pools.filter((pool) => !pool?.blockPeriod);
  const poolsWithDrawFee = pools.filter((pool) => pool?.blockPeriod);

  const callsNoWithDrawFee = poolsNoWithDrawFee.map((pool) => {
    return {
      address: pool.contractAddress,
      name: 'userInfo',
      params: [account],
    };
  });

  const callsWithDrawFee = poolsWithDrawFee.map((pool) => {
    return {
      address: pool.contractAddress,
      name: 'userInfo',
      params: [account],
    };
  });

  const rawBalancesNoWithDrawFee = await multicall(sousChefV2Abi, callsNoWithDrawFee, chainId);
  const rawBalancesWithDrawFee = await multicall(sousChefV3Abi, callsWithDrawFee, chainId);

  const parsedBalancesNoWithDrawFee = poolsNoWithDrawFee.map((pool, index) => {
    return {
      sousId: pool.sousId,
      lastStakingBlock: undefined,
      stakedBalance: new BigNumber(rawBalancesNoWithDrawFee[index]?.amount?._hex).toJSON(),
    };
  });

  const parsedBalancesWithDrawFee = poolsWithDrawFee.map((pool, index) => {
    return {
      sousId: pool.sousId,
      lastStakingBlock: rawBalancesWithDrawFee[index]?.lastStakingBlock?._hex
        ? new BigNumber(rawBalancesWithDrawFee[index].lastStakingBlock._hex).toJSON()
        : undefined,
      stakedBalance: new BigNumber(rawBalancesWithDrawFee[index]?.amount?._hex).toJSON(),
    };
  });

  return [...parsedBalancesNoWithDrawFee, ...parsedBalancesWithDrawFee];
};

export const fetchPoolUserStakedBalancesV1 = async (account, pools, chainId) => {
  const calls = pools.map((pool) => {
    return {
      address: pool.contractAddress,
      name: 'userInfo',
      params: [account],
    };
  });

  const rawBalances = await multicall(sousChefV3Abi, calls, chainId);

  const parsedBalances = rawBalances.map((pool, index) => {
    return {
      sousId: pools[index].sousId,
      stakedBalance: new BigNumber(rawBalances[index]?.amount?._hex).toJSON(),
    };
  });

  return parsedBalances;
};

export const fetchPoolUserEarnings = async (account, pools, chainId) => {
  const calls = pools.map((pool) => {
    return {
      address: pool.contractAddress,
      name: 'pendingReward',
      params: [account],
    };
  });

  const rawEarnings = await multicall(sousChefV2Abi, calls, chainId);
  const parsedEarnings = rawEarnings?.map((earnings) => {
    return earnings?.[0].map((earning) => new BigNumber(earning?._hex).toJSON());
  });

  return parsedEarnings;
};

export const fetchPoolUserEarningsV1 = async (account, pools, chainId) => {
  const calls = pools.map((pool) => {
    return   {
      address: pool.contractAddress,
      name: 'pendingReward',
      params: [account],
    }
  });

  const rawEarnings = await multicall(sousChefAbi, calls, chainId);
  const parsedEarnings = rawEarnings?.map((earnings) => {
    return earnings?.map((earning) => new BigNumber(earning?._hex).toJSON());
  });

  return parsedEarnings;
};


export const fetchPoolUser = async (account, pool, chainId) => {
  const callsErc = [
    {
      address: pool.stakingToken.address[chainId],
      name: 'allowance',
      params: [account, pool.contractAddress],
    },
    {
      address: pool.stakingToken.address[chainId],
      name: 'balanceOf',
      params: [account],
    },
    {
      address: pool.earningToken.address[chainId],
      name: 'balanceOf',
      params: [account],
    },
  ];

  const callsSousChef = [
    {
      address: pool.contractAddress,
      name: 'userInfo',
      params: [account],
    },
    {
      address: pool.contractAddress,
      name: 'pendingReward',
      params: [account],
    },
  ];

  const [[allowance], tokenBalance, earningsTokenBalance] = await multicall(erc20ABI, callsErc, chainId);
  const [stakedBalance, earnings] = await multicall(sousChefAbi, callsSousChef, chainId);

  return {
    allowance: new BigNumber(allowance._hex).toJSON(),
    stakingTokenBalance: new BigNumber(tokenBalance).toJSON(),
    earningsTokenBalance: new BigNumber(earningsTokenBalance).toJSON(),
    earnings: new BigNumber(earnings).toJSON(),
    stakedBalance: new BigNumber(stakedBalance.amount._hex).toJSON(),
  };
};

export const fetchPoolV2User = async (account, pool, chainId) => {
  const callsErc = [
    {
      address: pool.stakingToken.address[chainId],
      name: 'allowance',
      params: [account, pool.contractAddress],
    },
    {
      address: pool.stakingToken.address[chainId],
      name: 'balanceOf',
      params: [account],
    },
  ];

  const callsEarningTokensBalance = pool.earningTokens.map((earningToken) => ({
    address: earningToken.address[chainId],
    name: 'balanceOf',
    params: [account],
  }));

  const callsSousChef = [
    {
      address: pool.contractAddress,
      name: 'userInfo',
      params: [account],
    },
    {
      address: pool.contractAddress,
      name: 'pendingReward',
      params: [account],
    },
  ];

  const [[allowance], tokenBalance, ...earningBalance] = await multicall(
    erc20ABI,
    [...callsErc, ...callsEarningTokensBalance],
    chainId,
  );

  const [balances, earnings] = await multicall(
    pool.blockPeriod ? sousChefV3Abi : sousChefV2Abi,
    callsSousChef,
    chainId,
  );

  return {
    allowance: new BigNumber(allowance._hex).toJSON(),
    stakingTokenBalance: new BigNumber(tokenBalance).toJSON(),
    earningsTokenBalance: earningBalance.map((earningsTokenBalance) =>
      new BigNumber(earningsTokenBalance.balance._hex).toJSON(),
    ),
    earnings: earnings[0].map((earning) => new BigNumber(earning._hex).toJSON()),
    stakedBalance: new BigNumber(balances.amount._hex).toJSON(),
    lastStakingBlock: balances?.lastStakingBlock?._hex ? new BigNumber(balances.lastStakingBlock._hex).toJSON() : '0',
  };
};
