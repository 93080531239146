import { showToastTx } from 'components/CustomToast/CustomToast';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useLockPoolContract, useSousChefContract, useMasterChefContract } from 'hooks/useContract';
import { useCallback } from 'react';
import { stakeFarm } from 'utils/calls/farms';
import { stakeLockPool } from 'utils/calls/lockPool';
import { stakePoolIdo } from 'utils/calls/IDOs';
import {useChainId, useConfig, useWriteContract} from "wagmi";
import SOUSCHEF_ABI from "../config/abi/sousChef.json";
import BigNumber from "bignumber.js";
import {BIG_TEN} from "../utils/bigNumber";
import {waitForTransactionReceipt} from "@wagmi/core";

const useStake = (pid, masterChefAddress) => {
  const { chainId } = useActiveWeb3React();
  const masterChefContract = useMasterChefContract(masterChefAddress);

  const handleStake = useCallback(
    async (amount, decimals) => {
      const txHash = await stakeFarm(masterChefContract, pid, amount, decimals);

      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, pid, masterChefContract],
  );

  return { onStake: handleStake };
};

export const useSousStake = (poolAddress) => {
    const chainId = useChainId();
    const config = useConfig();
    const { writeContractAsync } = useWriteContract()

  const handleStake = useCallback(
    async (amount, decimals) => {
        const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed();
        let txHash = await writeContractAsync({
            abi: SOUSCHEF_ABI,
            address: poolAddress,
            functionName: 'deposit',
            args: [BigInt(value)],
        })

        await waitForTransactionReceipt(config, {hash: txHash})

        // let txHash = await stakePool(souschefContract, amount, decimals);
      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, config],
  );

    return { onStake: handleStake };
};

export const useStakePoolIdo = (poolAddress) => {
  const { chainId } = useActiveWeb3React();
  const contract = useSousChefContract(poolAddress);
  const handleStake = useCallback(
    async (amount, decimals) => {
      let txHash = await stakePoolIdo(contract, amount, decimals);
      showToastTx(txHash, chainId);
    },
    [chainId, contract],
  );

  return { onStake: handleStake };
};

export const useStakeLockPool = (contractAddress) => {
  const { chainId } = useActiveWeb3React();
  const lockPoolContract = useLockPoolContract(contractAddress);

  const handleStake = useCallback(
    async (amount, decimals) => {
      const txHash = await stakeLockPool(lockPoolContract, amount, decimals);

      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, lockPoolContract],
  );

  return { onStake: handleStake };
};

export default useStake;
