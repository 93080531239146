import { useState } from 'react';
import SettingsModal from 'views/Swap/components/Settings/SettingsModal';
import { FiSettings } from 'react-icons/fi';

export default function SettingsTab() {
  const [presentSettingsModal, setPresentSettingsModal] = useState(false);

  const toggleSettingsModal = () => setPresentSettingsModal((prevState) => !prevState);

  return (
    <>
      <SettingsModal open={presentSettingsModal} onDismiss={toggleSettingsModal} />
      <FiSettings className="cursor-pointer" onClick={toggleSettingsModal} size={24} />
    </>
  );
}
