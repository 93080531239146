import Logo from 'components/Header/Logo';
import Menu from 'components/Header/Menu';
import Web3Status from 'components/Web3Status/Web3Status';

const Header = () => {
  return (
    <header
      className=" py-4 xl:py-1 px-3 relative z-50 flex justify-between items-center"
    >
      <div className="max-w-screen-2xl mx-auto flex justify-between items-center flex-1 sm:gap-3">
        <Logo className="sm:flex " />
        <Menu className="xl:flex  sm:flex-1 flex-wrap hidden" />
        <div className="flex items-center justify-center gap-1 whitespace-nowrap  sm:justify-end">
          <Web3Status />
        </div>
      </div>
    </header>
  );
};

export default Header;
