/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useWatchlistPools } from 'store/user/hooks';
import { CurrencyLogo, DoubleCurrencyLogo } from 'views/Analytics/components/CurrencyLogo/CurrencyLogo';
import ChartCard from 'views/Analytics/components/InfoCharts/ChartCard/ChartCard';
import TransactionTable from 'views/Analytics/components/InfoTables/TransactionsTable';
import Percent from 'views/Analytics/components/Percent/Percent';
import SaveIcon from 'views/Analytics/components/SaveIcon/SaveIcon';
import { formatAmount } from 'utils/formatInfoNumbers';
import useMatchBreakpoints from 'contexts/MatchBreakpoints/useMatchBreakpoints';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { getExplorerLink } from 'utils/explorer';
import Card from 'components/Card/Card';
import { FiExternalLink } from 'react-icons/fi';
import Loader from 'components/Loader/Loader';
import { useSWRPairChartData, useSWRPairData } from 'hooks/swr/analytics/pairs';
import QuestionHelper from 'components/QuestionHelper';
import { classNames } from 'utils/styling';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { isAddress } from 'utils';
import {useChainId} from "wagmi";

const viewData = {
  day: 0,
  week: 1,
};

const PoolPage = () => {
  const { address: routeAddress } = useParams();
  const chainId = useChainId()
  const { isXs, isSm } = useMatchBreakpoints();
  const [showWeeklyData, setShowWeeklyData] = useState(viewData.day);

  // In case somebody pastes checksummed address into url (since GraphQL expects lowercase address)
  const address = routeAddress.toLowerCase();

  const { poolData } = useSWRPairData(address);
  const { chartData } = useSWRPairChartData(address);

  const [watchlistPools, addPoolToWatchlist] = useWatchlistPools();

  return (
    <div>
      {poolData ? (
        <>
          <div className="flex justify-between mb-5">
            <Breadcrumbs>
              <Link to="/analytics">
                <p>Analytics</p>
              </Link>
              <Link to="/analytics/pairs">
                <p>Pairs</p>
              </Link>
              <div>
                <p>{`${poolData.token0.symbol} / ${poolData.token1.symbol}`}</p>
              </div>
            </Breadcrumbs>
            <div className="flex justify-end items-center">
              <ExternalLink
                className="mr-1"
                href={getExplorerLink(chainId, address, 'address')}
                endIcon={<FiExternalLink className="ml-1" />}
              >
                View on Explorer
              </ExternalLink>
              <SaveIcon
                fill={watchlistPools.includes(isAddress(address))}
                onClick={() => addPoolToWatchlist(address)}
              />
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <DoubleCurrencyLogo currency0={poolData.token0} currency1={poolData.token1} size={32} />
              <p
                className={classNames('ml-3 font-bold', isXs || isSm ? 'text-2xl' : 'text-4xl')}
                id="info-pool-pair-title"
              >{`${poolData.token0.symbol} / ${poolData.token1.symbol}`}</p>
            </div>
            <div className="flex justify-between flex-wrap">
              <div>
                <Link to={`/analytics/tokens/${poolData.token0.address}`}>
                  <div className="flex tokenButton">
                    <CurrencyLogo currency={poolData.token0} size="24px" />
                    <p className="ml-2" style={{ whiteSpace: 'nowrap' }}>
                      {`1 ${poolData.token0.symbol} =  ${formatAmount(poolData.token1Price, {
                        notation: 'standard',
                        displayThreshold: 0.001,
                        tokenPrecision: true,
                      })} ${poolData.token1.symbol}`}
                    </p>
                  </div>
                </Link>
                <Link to={`/analytics/tokens/${poolData.token1.address}`}>
                  <div className="flex tokenButton">
                    <CurrencyLogo currency={poolData.token1} size="24px" />
                    <p className="ml-2" style={{ whiteSpace: 'nowrap' }}>
                      {`1 ${poolData.token1.symbol} =  ${formatAmount(poolData.token0Price, {
                        notation: 'standard',
                        displayThreshold: 0.001,
                        tokenPrecision: true,
                      })} ${poolData.token0.symbol}`}
                    </p>
                  </div>
                </Link>
              </div>
              <div className="flex">
                <Link to={`/add/${poolData.token0.address}/${poolData.token1.address}`}>
                  <ButtonCustom className="mr-2" color="secondary">
                    Add Liquidity
                  </ButtonCustom>
                </Link>
                <Link to={`/swap?inputCurrency=${poolData.token0.address}&outputCurrency=${poolData.token1.address}`}>
                  <ButtonCustom>Trade</ButtonCustom>
                </Link>
              </div>
            </div>
          </div>
          <div className="contentLayout">
            <div>
              <Card className="p-5">
                <div>
                  <div className="flex justify-between">
                    <div className="flex-1">
                      <p className="uppercase font-bold text-primary text-sm-md">Liquidity</p>
                      <p className="font-bold text-2xl">
                        $
                        {formatAmount(poolData.liquidityUSD, {
                          displayThreshold: 0.001,
                        })}
                      </p>
                      <Percent value={poolData.liquidityUSDChange} />
                    </div>
                    <div className="flex-1">
                      <p className="uppercase font-bold text-primary text-sm-md">LP reward APR</p>
                      <p className="font-bold text-2xl">{formatAmount(poolData.lpApr7d)}%</p>
                      <div className="flex items-center">
                        <QuestionHelper text="Based on last 7 days' performance. Does not account for impermanent loss" />
                        <p className="ml-1 text-sm whitespace-nowrap">7D performance</p>
                      </div>
                    </div>
                  </div>
                  <p className="uppercase font-bold text-primary text-sm-md mt-5">Total Tokens Locked</p>
                  <div className="flex lockedTokensContainer text-sm-md bg-black1">
                    <div className="flex justify-between">
                      <div className="flex items-center">
                        <CurrencyLogo currency={poolData.token0} size="24px" />
                        <p className="ml-1">{poolData.token0.symbol}</p>
                      </div>
                      <p>{formatAmount(poolData.liquidityToken0)}</p>
                    </div>
                    <div className="flex justify-between">
                      <div className="flex items-center">
                        <CurrencyLogo currency={poolData.token1} size="24px" />
                        <p className="ml-1">{poolData.token1.symbol}</p>
                      </div>
                      <p>{formatAmount(poolData.liquidityToken1)}</p>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className="mt-4 p-5">
                <div>
                  <div className="flex text-white bg-black1  rounded-3xl py-1 px-2 text-center">
                    <div
                      className={classNames(
                        'flex-1 px-4 py-2 whitespace-nowrap cursor-pointer',
                        showWeeklyData === viewData.day && 'text-black bg-primary rounded-2xl',
                      )}
                      onClick={() => setShowWeeklyData(viewData.day)}
                    >
                      <p>24H</p>
                    </div>
                    <div
                      className={classNames(
                        'flex-1 px-4 py-2 whitespace-nowrap cursor-pointer',
                        showWeeklyData === viewData.week && 'text-black bg-primary rounded-2xl',
                      )}
                      onClick={() => setShowWeeklyData(viewData.week)}
                    >
                      <p>7D</p>
                    </div>
                  </div>
                  <div className="flex mt-5">
                    <div className="flex-1">
                      <p className="uppercase font-bold text-primary text-sm">
                        {showWeeklyData ? 'Volume 7D' : 'Volume 24H'}
                      </p>
                      <p className="font-bold text-2xl">
                        $
                        {showWeeklyData
                          ? formatAmount(poolData.volumeUSDWeek, {
                              displayThreshold: 0.001,
                            })
                          : formatAmount(poolData.volumeUSD, {
                              displayThreshold: 0.001,
                            })}
                      </p>
                      <Percent value={showWeeklyData ? poolData.volumeUSDChangeWeek : poolData.volumeUSDChange} />
                    </div>
                    <div className="flex-1">
                      <p className="uppercase font-bold text-primary text-sm">
                        {showWeeklyData ? 'LP reward fees 7D' : 'LP reward fees 24H'}
                      </p>
                      <p className="font-bold text-2xl">
                        $
                        {showWeeklyData
                          ? formatAmount(poolData.lpFees7d, {
                              displayThreshold: 0.001,
                            })
                          : formatAmount(poolData.lpFees24h, {
                              displayThreshold: 0.001,
                            })}
                      </p>
                      <p className="text-sm">
                        out of{' '}
                        {showWeeklyData
                          ? formatAmount(poolData.totalFees7d, {
                              displayThreshold: 0.001,
                            })
                          : formatAmount(poolData.totalFees24h, {
                              displayThreshold: 0.001,
                            })}{' '}
                        total fees
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <ChartCard variant="pool" chartData={chartData} />
          </div>
          <h2 className="mt-10 mb-5 font-bold text-2xl">Transactions</h2>
          <TransactionTable pairAddress={address} />
        </>
      ) : (
        <div className="flex justify-center mt-10">
          <Loader className="border-t-4 h-20 w-20 mx-auto" />
        </div>
      )}
    </div>
  );
};

export default PoolPage;
