import { showToastTx } from 'components/CustomToast/CustomToast';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useLockPoolContract, useMasterChefContract, useSousChefContract } from 'hooks/useContract';
import { useCallback } from 'react';
import { harvestFarm, harvestFarmWithdraw } from 'utils/calls/farms';
import { lockHarvest } from 'utils/calls/lockPool';
import { soushHarvest, soushHarvestWithdraw } from 'utils/calls/pools';
import {useChainId, useConfig, useWriteContract} from "wagmi";
import SOUSCHEF_ABI from "../config/abi/sousChef.json";
import {waitForTransactionReceipt} from "@wagmi/core";

const useReward = (pid, masterChefAddress) => {
  const { chainId } = useActiveWeb3React();
  const masterChefContract = useMasterChefContract(masterChefAddress);

  const handleReward = useCallback(async () => {
    let txHash;
    txHash = await harvestFarm(masterChefContract, pid);

    showToastTx(txHash, chainId);
    console.log(txHash);
  }, [chainId, masterChefContract, pid]);

  const handleRewardWithDraw = useCallback(async () => {
    let txHash;

    txHash = await harvestFarmWithdraw(masterChefContract, pid);

    showToastTx(txHash, chainId);
    console.log(txHash);
  }, [chainId, pid, masterChefContract]);

  return { onReward: handleReward, onRewardWithDraw: handleRewardWithDraw };
};

export const useSousHarvest = (poolAddress, isWithdraw) => {
  const chainId = useChainId();
  const { writeContractAsync } = useWriteContract()
  const config = useConfig();

  const handleReward = useCallback(async () => {
    let txHash;
    // if (isWithdraw) {
    //   txHash = await soushHarvestWithdraw(souschefContract);
    // } else {
    //   txHash = await soushHarvest(souschefContract);
    // }

     txHash = await writeContractAsync({
      abi: SOUSCHEF_ABI,
      address: poolAddress,
      functionName: 'deposit',
      args: ['0'],
    })
    await waitForTransactionReceipt(config, {hash: txHash})
    // txHash = await soushHarvest(souschefContract);
    showToastTx(txHash, chainId);
    console.log(txHash);
  }, [chainId, isWithdraw, poolAddress,config]);

  return { onReward: handleReward };
};

export const useLockPoolClaim = (contractAddress) => {
  const { chainId } = useActiveWeb3React();
  const lockPoolContract = useLockPoolContract(contractAddress);

  const handleReward = useCallback(async () => {
    const txHash = await lockHarvest(lockPoolContract);

    showToastTx(txHash, chainId);
    console.log(txHash);
  }, [chainId, lockPoolContract]);

  return { onReward: handleReward };
};

export default useReward;
