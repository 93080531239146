import { getTokenName } from 'utils/tokenHelpers';
import HarvestAction from 'views/Farms/components/FarmsCard/CardActionsContainer/HarvestAction';
import StakeAction from 'views/Farms/components/FarmsCard/CardActionsContainer/StakeAction';

const CardActionsContainer = ({ farm, earningTokenSymbol, masterChefAddress, userDataLoaded }) => {
  const { userData } = farm;
  const lpTokenName = getTokenName(farm.token0, farm.token1, farm.stakingToken);

  return (
    <div>
      <div>
        <p>
          <strong className="text-primary">{earningTokenSymbol}</strong> <span className="text-white">Earned</span>
        </p>
        <HarvestAction
          stakedBalance={userData.stakedBalance}
          unlockTimestamp={userData.unlockTimestamp}
          isFinished={farm.isFinished}
          earningTokenSymbol={earningTokenSymbol}
          userDataLoaded={userDataLoaded}
          pid={farm.pid}
          masterChefAddress={masterChefAddress}
          earnings={userData.earnings}
          earningTokenPrice={farm.earningTokenPrice}
        />
      </div>
      <div>
        <p>
          <strong className="text-primary">{lpTokenName}</strong> <span className="text-white">Staked</span>
        </p>
        <StakeAction
          earningTokenSymbol={earningTokenSymbol}
          farm={farm}
          userDataLoaded={userDataLoaded}
          masterChefAddress={masterChefAddress}
        />
      </div>
    </div>
  );
};

export default CardActionsContainer;
