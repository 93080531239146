import { formatAmount } from 'utils/formatInfoNumbers';
import Skeleton from 'components/Skeleton/Skeleton';

const formatOptions = {
  notation: 'standard',
  displayThreshold: 0.001,
  tokenPrecision: true,
};

const PairPriceDisplay = ({ value, inputSymbol, outputSymbol, children, format = true, ...props }) => {
  return value ? (
    <div className="flex items-center" {...props}>
      <div>
        <p className="mr-2 font-bold text-2xl">
          {format ? formatAmount(typeof value === 'string' ? parseFloat(value) : value, formatOptions) : value}{' '}
          {inputSymbol && outputSymbol ? outputSymbol : ''}
        </p>
      </div>
      {children}
    </div>
  ) : (
    <Skeleton height="36px" width="128px" {...props} />
  );
};

export default PairPriceDisplay;
