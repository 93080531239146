import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useDispatch, useSelector } from 'react-redux';
import { transformUserData } from 'store/farms/helpers';
import useRefresh from 'hooks/useRefresh';
import { useEffect } from 'react';

import { fetchFarmUserDataAsync } from './index';

export const useFarms = () => {
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();

  useEffect(() => {
    if (account && chainId) {
      dispatch(fetchFarmUserDataAsync(account, chainId));
    }
  }, [account, dispatch, fastRefresh, chainId]);

  const { farms, userDataLoaded } = useSelector((state) => ({
    farms: state.farms.data[chainId],
    userDataLoaded: state.farms.userDataLoaded[chainId],
  }));

  return {
    farms: farms
      .map((farm) => ({ ...farm, userData: transformUserData(farm.userData) }))
      .filter((farm) => !farm.isHide),
    userDataLoaded,
  };
};

export const useFarmsNoAccount = () => {
  const { chainId } = useActiveWeb3React();

  const farms = useSelector((state) => state.farms.data[chainId].filter((farm) => !farm.isHide));

  return farms;
};

export const useFarmsByLpAddress = (address) => {
  const { chainId } = useActiveWeb3React();
  const farms = useSelector((state) =>
    state.farms.data[chainId]?.filter((item) => address.includes(item?.lpAddress[chainId])),
  );

  const farmSorts = address.reduce((result, current) => {
    const farm = farms?.find((item) => item.lpAddress[chainId] === current);
    if (farm) return [...result, farm];
    return result;
  }, []);
  return farmSorts;
};
