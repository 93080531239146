import React from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

const Pagination = ({ setPage, page, maxPage }) => {
  return (
    <div className="flex items-center justify-center  mt-3">
      <div
        onClick={() => {
          setPage(page === 1 ? page : page - 1);
        }}
      >
        <MdArrowBack size={20} className={`cursor-pointer ${page === 1 ? 'text-blue3' : 'text-primary'}`} />
      </div>

      <p className="mx-3">
        Page {page} of {maxPage}
      </p>
      <div
        onClick={() => {
          setPage(page === maxPage ? page : page + 1);
        }}
      >
        <MdArrowForward size={20} className={`cursor-pointer ${page === maxPage ? 'text-blue3' : 'text-primary'}`} />
      </div>
    </div>
  );
};

export default Pagination;
