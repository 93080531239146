import { useRef, useEffect } from 'react';
import { createChart } from 'lightweight-charts';
import { CandleChartLoader } from 'views/Analytics/components/ChartLoaders/ChartLoaders';
import dayjs from 'dayjs';

const CANDLE_CHART_HEIGHT = 250;

const CandleChart = ({ data, setValue, setLabel, ...rest }) => {
  const chartRef = useRef(null);

  // if chart not instantiated in canvas, create it
  useEffect(() => {
    if (data && !!chartRef?.current?.parentElement) {
      const chart = createChart(chartRef.current, {
        height: CANDLE_CHART_HEIGHT,
        width: chartRef.current.parentElement.clientWidth,
        layout: {
          backgroundColor: 'transparent',
          textColor: '#ffffff',
          fontFamily: 'Kanit, sans-serif',
          fontSize: 12,
        },
        rightPriceScale: {
          scaleMargins: {
            top: 0.1,
            bottom: 0.1,
          },
          borderVisible: false,
        },
        timeScale: {
          borderVisible: false,
          secondsVisible: true,
          tickMarkFormatter: (unixTime) => {
            return dayjs(unixTime * 1000).format('MM/DD h:mm A');
          },
        },
        watermark: {
          visible: false,
        },
        grid: {
          horzLines: {
            visible: false,
          },
          vertLines: {
            visible: false,
          },
        },
        crosshair: {
          horzLine: {
            visible: false,
            labelVisible: false,
          },
          mode: 1,
          vertLine: {
            visible: true,
            labelVisible: false,
            style: 3,
            width: 1,
            color: '#ffffff',
            labelBackgroundColor: '#1FC7D4',
          },
        },
      });

      chart.timeScale().fitContent();

      const series = chart.addCandlestickSeries({
        upColor: '#31D0AA',
        downColor: '#FF537B',
        borderDownColor: '#FF537B',
        borderUpColor: '#31D0AA',
        wickDownColor: '#FF537B',
        wickUpColor: '#31D0AA',
      });

      series.setData(data);

      // update the title when hovering on the chart
      chart.subscribeCrosshairMove((param) => {
        if (
          chartRef?.current &&
          (param === undefined ||
            param.time === undefined ||
            (param && param.point && param.point.x < 0) ||
            (param && param.point && param.point.x > chartRef.current.clientWidth) ||
            (param && param.point && param.point.y < 0) ||
            (param && param.point && param.point.y > CANDLE_CHART_HEIGHT))
        ) {
          // reset values
          if (setValue) setValue(undefined);
          if (setLabel) setLabel(undefined);
        } else if (series && param) {
          const timestamp = param.time;
          const now = new Date(timestamp * 1000);
          const time = `${now.toLocaleString('en', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            timeZone: 'UTC',
          })} (UTC)`;
          const parsed = param.seriesPrices.get(series);
          if (setValue) setValue(parsed?.open);
          if (setLabel) setLabel(time);
        }
      });

      return () => chart.remove();
    }
  }, [data, setLabel, setValue]);

  return (
    <>
      {!data && <CandleChartLoader />}
      <div ref={chartRef} id="candle-chart" {...rest} />
    </>
  );
};

export default CandleChart;
