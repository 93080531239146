import BasicChart from './BasicChart';
import DoubleCurrencyLogo from 'components/LogoSwap/DoubleLogo';
import CurrencyLogo from 'components/LogoSwap/CurrencyLogo';
import { FiRefreshCcw } from 'react-icons/fi';

const PriceChart = ({ inputCurrency, outputCurrency, onSwitchTokens, token0Address, token1Address }) => {
  return (
    <div className="w-full text-white">
      <div className="flex justify-between p-3">
        <div className="items-center flex text-xl">
          {outputCurrency ? (
            <DoubleCurrencyLogo currency0={inputCurrency} currency1={outputCurrency} size={24} margin />
          ) : (
            inputCurrency && <CurrencyLogo currency={inputCurrency} size="24px" style={{ marginRight: '8px' }} />
          )}
          {inputCurrency && (
            <div className="font-bold ml-2">
              {outputCurrency ? (
                <>
                  <strong>{inputCurrency.symbol}</strong>/<span >{outputCurrency.symbol}</span>
                </>
              ) : (
                inputCurrency.symbol
              )}
            </div>
          )}
          <FiRefreshCcw
            className="cursor-pointer ml-1 transition-all hover:rotate-180 duration-300"
            onClick={onSwitchTokens}
            size={16}
          />
        </div>
      </div>
      <BasicChart
        token0Address={token0Address}
        token1Address={token1Address}
        inputCurrency={inputCurrency}
        outputCurrency={outputCurrency}
      />
    </div>
  );
};

export default PriceChart;
