import { classNames } from 'utils/styling';
import { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import Collapse from '../Collapse';
import { listNewMenu } from 'config/menu';
import {useChainId} from "wagmi";

const MenuMobile = ({ className }) => {
    const chainId = useChainId()
  const [open, setOpen] = useState(false);

  const closeMenu = () => setOpen(false);

  return (
    <div className={className}>
      <FiMenu className="text-primary ml-4 cursor-pointer block xl:hidden shrink-0" size={32} onClick={() => setOpen(!open)} />
      {open && <div className="z-40 opacity-8 fixed top-0 left-0 w-full h-full" onClick={closeMenu} />}
      <div
        className={classNames(
          'transition-transform h-full overflow-y-auto duration-200 shadow-lg absolute left-full z-50 bg-container-main py-4 w-screen menu-slide-mobile',
          open && 'transform -translate-x-full',
          !open && 'transform -translate-x-0',
        )}
        style={{
          top: '0',
          position: 'fixed',
        }}
      >
        <div className="flex flex-row justify-end">
          <div className="rounded p-3 bg-dropdown-item-hover mr-4 cursor-pointer" onClick={closeMenu}>
            <FiX color="white" />
          </div>
        </div>
        {listNewMenu?.[chainId]?.map((menu, index) => {
          if (menu.link) {
            return (
              <div
                className="text-white px-10 py-2.5 block whitespace-nowrap cursor-pointer bg-dropdown-item-hover"
                key={index}
                onClick={() => window.open(menu.link)}
              >
                <div className="w-full h-full flex flex-row items-center">
                  {/*<span>{menu?.icon}</span>*/}
                  <span>{menu.title}</span>
                </div>
              </div>
            );
          }

          if (menu.child) {
            return (
              <Collapse key={index} className="py-1.5" headerClassName="px-10" defaultExtend title={menu?.title}>
                {menu.child?.map((item, i) => {
                  const TagLink = item?.route ? NavLink : 'a';
                  return (
                    <TagLink
                      key={`collapse-${i}`}
                      className="block pl-10 bg-dropdown-item-hover"
                      {...(item?.route
                        ? {
                            to: item.route,
                          }
                        : {
                            href: item?.url,
                            target: !item?.inside ? '_blank' : '',
                          })}
                      onClick={closeMenu}
                    >
                      <div className="group h-full flex flex-row items-center ml-1 px-4 py-2.5 text-white">
                        <div className="mr-3">{item.icon}</div>
                        <div className="flex-1">{item.title}</div>
                      </div>
                    </TagLink>
                  );
                })}
              </Collapse>
            );
          }

          return (
            <NavLink
              className={({ isActive }) =>
                classNames(
                  'group text-white px-10 py-2.5 block whitespace-nowrap bg-dropdown-item-hover',
                  isActive && 'text-black bg-primary',
                )
              }
              key={index}
              to={menu.route}
              onClick={closeMenu}
            >
              <div className="w-full h-full flex flex-row items-center">
                {/*<span className="group-hover:hover:text-blue1">{menu?.icon}</span>*/}
                <span>{menu.title}</span>
              </div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MenuMobile;
