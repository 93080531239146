import { showToastTx } from 'components/CustomToast/CustomToast';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useLockPoolContract, useMasterChefContract, useSousChefContract } from 'hooks/useContract';
import { useCallback } from 'react';
import { unstakeFarm } from 'utils/calls/farms';
import { unstakeLockPool } from 'utils/calls/lockPool';
import { unstakeEmergencyPool } from 'utils/calls/pools';
import { unStakePoolIdo } from 'utils/calls/IDOs';
import {useChainId, useConfig, useWaitForTransactionReceipt, useWriteContract} from "wagmi";
import SOUSCHEF_ABI from "../config/abi/sousChef.json";
import BigNumber from "bignumber.js";
import {BIG_TEN} from "../utils/bigNumber";
import {waitForTransactionReceipt} from "@wagmi/core";

const useUnstake = (pid, masterChefAddress) => {
  const { chainId } = useActiveWeb3React();
  const masterChefContract = useMasterChefContract(masterChefAddress);

  const handleUnstake = useCallback(
    async (amount, decimals) => {
      let txHash;
      txHash = await unstakeFarm(masterChefContract, pid, amount, decimals);

      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, masterChefContract, pid],
  );

  return { onUnstake: handleUnstake };
};

export const useSousUnstake = (poolAddress) => {
    const chainId = useChainId();
    const config = useConfig();
    const {  writeContractAsync } = useWriteContract()

    const handleUnstake = useCallback(
    async (amount, decimals) => {
        const value = new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed();
        let txHash = await writeContractAsync({
            abi: SOUSCHEF_ABI,
            address: poolAddress,
            functionName: 'withdraw',
            args: [value],
        })

        await waitForTransactionReceipt(config, {hash: txHash})

      // const txHash = await unstakePool(souschefContract, amount, decimals);

      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, config],
  );

    return { onUnstake: handleUnstake };
};

export const useSousUnstakeEmergency = (poolAddress) => {
  const { account, chainId } = useActiveWeb3React();
  const souschefContract = useMasterChefContract(poolAddress);

  const handleUnstake = useCallback(async () => {
    let txHash;

    txHash = await unstakeEmergencyPool(souschefContract, account);

    showToastTx(txHash, chainId);
    console.log(txHash);
  }, [account, souschefContract, chainId]);

  return { onUnstakeEmergency: handleUnstake };
};

export const useUnStakeIdo = (poolAddress) => {
  const { chainId } = useActiveWeb3React();
  const contract = useSousChefContract(poolAddress);

  const handleUnstake = useCallback(
    async (amount, decimals) => {
      let txHash;

      txHash = await unStakePoolIdo(contract, amount, decimals);

      showToastTx(txHash, chainId);
    },
    [contract, chainId],
  );

  return { onUnstake: handleUnstake };
};

export const useUnStakeLockPool = (contractAddress) => {
  const { chainId } = useActiveWeb3React();
  const lockPoolContract = useLockPoolContract(contractAddress);

  const handleUnstake = useCallback(
    async (amount, decimals) => {
      const txHash = await unstakeLockPool(lockPoolContract, amount, decimals);

      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [lockPoolContract, chainId],
  );

  return { onUnstake: handleUnstake };
};

export default useUnstake;
