import { ChainId } from 'ezcake-v2-sdk';
import { BIG_TEN } from 'utils/bigNumber';

export const UINT256_MAX = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const AVERAGE_BLOCK_TIME = {
  [ChainId.KAI]: 5,
  [ChainId.BSC]: 3,
  [ChainId.HARMONY]: 2,
  [ChainId.ONUS]: 3,
  [ChainId.ONUS_TESTNET]: 3,
  [ChainId.BSC_TESTNET]: 3,
};

export const BLOCK_PER_YEAR = {
  [ChainId.KAI]: (60 / AVERAGE_BLOCK_TIME[ChainId.KAI]) * 60 * 24 * 365,
  [ChainId.BSC]: (60 / AVERAGE_BLOCK_TIME[ChainId.BSC]) * 60 * 24 * 365,
  [ChainId.BSC_TESTNET]: (60 / AVERAGE_BLOCK_TIME[ChainId.BSC_TESTNET]) * 60 * 24 * 365,
  [ChainId.HARMONY]: (60 / AVERAGE_BLOCK_TIME[ChainId.HARMONY]) * 60 * 24 * 365,
  [ChainId.HARMONY_TESTNET]: (60 / AVERAGE_BLOCK_TIME[ChainId.HARMONY_TESTNET]) * 60 * 24 * 365,
  [ChainId.ONUS]: (60 / AVERAGE_BLOCK_TIME[ChainId.ONUS]) * 60 * 24 * 365,
  [ChainId.ONUS_TESTNET]: (60 / AVERAGE_BLOCK_TIME[ChainId.ONUS_TESTNET]) * 60 * 24 * 365,
};
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18);
export const BLOCK_START = {
  [ChainId.KAI]: 2747474,
  [ChainId.BSC]: 10535514,
  [ChainId.HARMONY]: 19269915,
};
export const TRADING_FEE = {
  [ChainId.KAI]: 0.25,
  [ChainId.BSC]: 0.17,
  [ChainId.HARMONY]: 0.17,
  [ChainId.ONUS]: 0.25,
  [ChainId.ONUS_TESTNET]: 0.25,
};

export const FAST_INTERVAL = 10000;
export const SLOW_INTERVAL = 60000;

export const pathBgFooter = [
  '/pools',
  '/swap',
  '/liquidity',
  '/find',
  '/zap',
  '/pipe',
  '/bridge',
  '/remove',
  '/vaults',
  '/battles',
  '/castles',
  '/farms',
  '/mini-farms',
  '/stake',
];
// eslint-disable-next-line no-undef
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// eslint-disable-next-line no-undef
export const API_NFT_URL = process.env.REACT_APP_API_NFT_URL;
// eslint-disable-next-line no-undef
export const API_BRIDGE_URL = process.env.REACT_APP_API_BRIDGE_URL;
// eslint-disable-next-line no-undef
export const API_ANALYTICS_URL = process.env.REACT_APP_API_ANALYTICS_URL;
export const DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
