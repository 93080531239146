import { TOTAL_FEE, LP_HOLDERS_FEE, WEEKS_IN_YEAR } from 'constants/analytics';

export const getLpFeesAndApr = (volumeUSD, volumeUSDWeek, liquidityUSD) => {
  const totalFees24h = volumeUSD * TOTAL_FEE;
  const totalFees7d = volumeUSDWeek * TOTAL_FEE;
  const lpFees24h = volumeUSD * LP_HOLDERS_FEE;
  const lpFees7d = volumeUSDWeek * LP_HOLDERS_FEE;

  const lpApr7d = liquidityUSD > 0 ? (volumeUSDWeek * LP_HOLDERS_FEE * WEEKS_IN_YEAR * 100) / liquidityUSD : 0;
  return {
    totalFees24h,
    totalFees7d,
    lpFees24h,
    lpFees7d,
    lpApr7d: lpApr7d !== Infinity ? lpApr7d : 0,
  };
};
