import AppHeader from 'components/AppHeader/AppHeader';
import Card from 'components/Card/Card';
import CurrencyInputPanel from 'components/CurrencyInputPanel/index';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import { CurrencyAmount, JSBI, Percent } from 'ezcake-v2-sdk';
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback';
import { useSwapCallback } from 'hooks/useSwapCallback';
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback';
import { useCallback, useEffect, useState } from 'react';
import { FiArrowDown } from 'react-icons/fi';
import { Field } from 'store/swap/actions';
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'store/swap/hooks';
import {
    useExchangeChartManager,
    useExpertModeManager,
    useUserDeadline,
    useUserSingleHopOnly,
    useUserSlippageTolerance,
} from 'store/user/hooks/index';
import maxAmountSpend from 'utils/maxAmountSpend';
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices';
import AdvancedSwapDetailsDropdown from 'views/Swap/components/AdvancedSwapDetailsDropdown';
import confirmPriceImpactWithoutFee from 'views/Swap/components/confirmPriceImpactWithoutFee';
import ConfirmSwapModal from 'views/Swap/components/ConfirmSwapModal';
import { SwapCallbackError } from 'views/Swap/components/SwapCallbackError';
import Dots from 'components/Loader/Dots';
import useRefreshBlockNumber from 'views/Swap/hooks/useRefreshBlockNumber';
import TradePrice from 'views/Swap/components/TradePrice';
import RefreshIcon from 'views/Swap/components/RefreshIcon';
import ModalTokenWarning from 'components/Modal/Modal.TokenWarning';
import useWarningImport from 'views/Swap/hooks/useWarningImport';
import PriceChartContainer from 'views/Swap/components/Chart/PriceChartContainer';
import { CHART_SUPPORTED } from 'constants/swap';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { classNames } from "utils/styling";
import {useAccount, useChainId} from "wagmi";

const Swap = () => {
    const chainId = useChainId()
    const {address} = useAccount()
    useDefaultsFromURLSearch();
    const { refreshBlockNumber, isLoading } = useRefreshBlockNumber();
    const [isChartDisplayed] = useExchangeChartManager();

    // for expert mode
    const [isExpertMode] = useExpertModeManager();
    // get custom setting values for user
    const [deadline] = useUserDeadline();

    // get custom setting values for user
    const [allowedSlippage] = useUserSlippageTolerance();

    // swap state
    const { independentField, typedValue } = useSwapState();

    const { v2Trade, currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo();

    const { isShowModalImportToken, importTokensNotInDefault, onDismissTokenWarning } = useWarningImport(
        currencies[Field.INPUT],
        currencies[Field.OUTPUT],
    );

    const {
        wrapType,
        execute: onWrap,
        inputError: wrapInputError,
        pendingTx: isLoadingWrap,
    } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue);

    const showWrap = wrapType !== WrapType.NOT_APPLICABLE;

    const trade = showWrap ? undefined : v2Trade;

    const parsedAmounts =
        showWrap
            ? {
                [Field.INPUT]: parsedAmount,
                [Field.OUTPUT]: parsedAmount,
            }
            : {
                [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
                [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
            };

    const { onSwitchTokens, onCurrencySelection, onUserInput } = useSwapActionHandlers();
    const isValid = !swapInputError;
    const dependentField = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

    const handleTypeInput = useCallback(
        (value) => {
            onUserInput(Field.INPUT, value);
        },
        [onUserInput],
    );

    const handleTypeOutput = useCallback(
        (value) => {
            onUserInput(Field.OUTPUT, value);
        },
        [onUserInput],
    );

    // modal and loading
    const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState({
        showConfirm: false,
        tradeToConfirm: undefined,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        txHash: undefined,
    });

    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]:
            showWrap
                ? parsedAmounts[independentField]?.toExact() ?? ''
                : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
    };

    const route = trade?.route;
    const userHasSpecifiedInputOutput = Boolean(
        currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
    );
    const noRoute = !route;

    // check whether the user has approved the router on the input token
    const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage);

    // check if user has gone through approval process, used to show two step buttons, reset on token change
    const [approvalSubmitted, setApprovalSubmitted] = useState(false);

    // mark when a user has submitted an approval, reset onTokenSelection for input field
    // mark when a user has submitted an approval, reset onTokenSelection for input field
    useEffect(() => {
        if (approval === ApprovalState.PENDING) {
            setApprovalSubmitted(true);
        }

        if (approval === ApprovalState.NOT_APPROVED) {
            setApprovalSubmitted(false);
        }
    }, [approval, approvalSubmitted]);

    const maxAmountInput = maxAmountSpend(currencyBalances[Field.INPUT]);
    // const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

    // the callback to execute the swap
    const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, deadline);

    const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

    const [singleHopOnly] = useUserSingleHopOnly();

    const handleSwap = useCallback(() => {
        if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
            return;
        }
        if (!swapCallback) {
            return;
        }
        setSwapState({
            attemptingTxn: true,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: undefined,
            txHash: undefined,
        });
        swapCallback()
            .then((hash) => {
                setSwapState({
                    attemptingTxn: false,
                    tradeToConfirm,
                    showConfirm,
                    swapErrorMessage: undefined,
                    txHash: hash,
                });
            })
            .catch((error) => {
                setSwapState({
                    attemptingTxn: false,
                    tradeToConfirm,
                    showConfirm,
                    swapErrorMessage: error.message,
                    txHash: undefined,
                });
            });
    }, [priceImpactWithoutFee, swapCallback, tradeToConfirm, showConfirm]);

    const handleWrap = useCallback(async () => {
        if (showWrap) {
            await onWrap();
        }
        // clear input after wrap success
        onUserInput(Field.INPUT, '');
    }, [onUserInput, onWrap,  showWrap]);

    // warnings on slippage
    const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

    // show approve flow when: no error on inputs, not approved or pending, or approved in current session
    // never show if price impact is above threshold in non expert mode
    const showApproveFlow =
        !swapInputError &&
        (approval === ApprovalState.NOT_APPROVED ||
            approval === ApprovalState.PENDING ||
            (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
        !(priceImpactSeverity > 3 && !isExpertMode);

    const handleConfirmDismiss = useCallback(() => {
        setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash });

        // if there was a tx hash, we want to clear the input
        if (txHash) {
            onUserInput(Field.INPUT, '');
        }
    }, [tradeToConfirm, attemptingTxn, swapErrorMessage, txHash, onUserInput]);

    const handleAcceptChanges = useCallback(() => {
        setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }));
    }, [trade]);

    const resetSwapState = useCallback(() => {
        setSwapState({
            showConfirm: false,
            tradeToConfirm: undefined,
            attemptingTxn: false,
            swapErrorMessage: undefined,
            txHash: undefined,
        });
    }, []);

    const handleInputSelect = useCallback(
        (inputCurrency) => {
            setApprovalSubmitted(false); // reset 2 step UI for approvals

            resetSwapState();

            onCurrencySelection(Field.INPUT, inputCurrency);
        },
        [chainId, currencies, onCurrencySelection],
    );

    const handleOutputSelect = useCallback(
        (outputCurrency) => {
            resetSwapState();

            onCurrencySelection(Field.OUTPUT, outputCurrency);
        },
        [chainId, currencies, onCurrencySelection],
    );

    const handleMaxInput = useCallback(() => {
        if (maxAmountInput) {
            onUserInput(Field.INPUT, maxAmountInput.toExact());
        }
    }, [maxAmountInput, onUserInput]);

    const handlePercentInput = useCallback(
        (percent) => {
            if (!currencyBalances[Field.INPUT]) return;

            if (maxAmountInput && percent.equalTo(new Percent('100'))) {
                onUserInput(Field.INPUT, maxAmountInput.toExact());
            } else {
                onUserInput(
                    Field.INPUT,
                    new CurrencyAmount(currencies[Field.INPUT], percent.multiply(maxAmountInput.raw).quotient).toExact(),
                );
            }
        },
        [currencies, currencyBalances, maxAmountInput, onUserInput],
    );

    const hasAmount = Boolean(parsedAmount);

    const onRefreshPrice = useCallback(() => {
        if (hasAmount) {
            refreshBlockNumber();
        }
    }, [hasAmount, refreshBlockNumber]);

    return (
        <div className="flex lg:flex-row flex-col lg:space-x-8 lg:space-y-0 space-y-5 lg:items-start items-center justify-center">
            <div className="w-full max-w-md sm:w-[425px] flex  flex-col">
                <ModalTokenWarning
                    isOpen={isShowModalImportToken}
                    tokens={importTokensNotInDefault}
                    onDismiss={onDismissTokenWarning}
                />
                <ConfirmSwapModal
                    open={showConfirm}
                    onDismiss={handleConfirmDismiss}
                    trade={trade}
                    originalTrade={tradeToConfirm}
                    onAcceptChanges={handleAcceptChanges}
                    attemptingTxn={attemptingTxn}
                    txHash={txHash}
                    allowedSlippage={allowedSlippage}
                    onConfirm={handleSwap}
                    swapErrorMessage={swapErrorMessage}
                />
                <Card className="p-6 border border-primary ">
                    <AppHeader title="Swap" subtitle="Trade tokens is an instant" isChart={CHART_SUPPORTED.includes(chainId)}/>
                    <CurrencyInputPanel
                        label={independentField === Field.OUTPUT && !showWrap  && trade ? 'From (estimated)' : 'From'}
                        value={formattedAmounts[Field.INPUT]}
                        onMax={handleMaxInput}
                        currency={currencies[Field.INPUT]}
                        pair={currencies[Field.INPUT]?.tokenInfo?.token1 ? currencies[Field.INPUT]?.tokenInfo : undefined}
                        onUserInput={handleTypeInput}
                        onCurrencySelect={handleInputSelect}
                        otherCurrency={currencies[Field.OUTPUT]}
                        showCommonBases
                        showPercentPicker
                        showUSDT
                        onDismissTokenWarning={onDismissTokenWarning}
                        onChangePercentInput={handlePercentInput}
                        id="swap-currency-input"
                    />
                    {/*<PercentPicker onChangePercentInput={handlePercentInput} />*/}
                    <div className="flex justify-center">
                        <div className="rounded-lg p-1 cursor-pointer my-2 hover:bg-black1">
                            <FiArrowDown
                                size={20}
                                onClick={() => {
                                    setApprovalSubmitted(false); // reset 2 step UI for approvals
                                    onSwitchTokens();
                                }}
                                color="white"
                                className="hover:-rotate-180  transition-[transform] duration-300 ease-in-out delay-200"
                            />
                        </div>
                    </div>
                    <CurrencyInputPanel
                        value={formattedAmounts[Field.OUTPUT]}
                        onUserInput={handleTypeOutput}
                        label={independentField === Field.INPUT && !showWrap  && trade ? 'To (estimated)' : 'To'}
                        showMaxButton={false}
                        currency={currencies[Field.OUTPUT]}
                        pair={currencies[Field.OUTPUT]?.tokenInfo?.token1 ? currencies[Field.OUTPUT]?.tokenInfo : undefined}
                        onCurrencySelect={handleOutputSelect}
                        otherCurrency={currencies[Field.INPUT]}
                        showCommonBases
                        showUSDT
                        showPriceImpact
                        onDismissTokenWarning={onDismissTokenWarning}
                        id="swap-currency-output"
                    />
                    <div className="flex justify-between items-center text-white text-sm mt-2">
                        <p>Slippage Tolerance:</p>
                        <p className="font-bold text-primary text-sm-md ml-2">{allowedSlippage / 100}%</p>
                    </div>
                    {showWrap  ? null : (
                        <div className={classNames("flex items-center text-white mt-1", !trade && 'hidden' )}>
                            <RefreshIcon disabled={!hasAmount} className="w-6 h-6 mr-1 cursor-pointer" onClick={onRefreshPrice} />
                            {trade ? <TradePrice trade={trade} /> : '-'}
                        </div>
                    )}
                    <div className="mt-4">
                        {!address ? (
                            <UnlockButton />
                        ) : showWrap  ? (
                            <>
                                {showWrap && (
                                    <ButtonCustom
                                        isLoading={isLoadingWrap}
                                        className="w-full"
                                        disabled={Boolean(wrapInputError) || isLoadingWrap}
                                        onClick={handleWrap}
                                    >
                                        {wrapInputError ??
                                            (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                                    </ButtonCustom>
                                )}
                            </>
                        ) : noRoute && userHasSpecifiedInputOutput ? (
                            <ButtonCustom className="w-full text-sm-md" disabled>
                                <p>
                                    Insufficient liquidity for this trade.
                                    {singleHopOnly && <span className="block">Try enabling multi-hop trades.</span>}
                                </p>
                            </ButtonCustom>
                        ) : showApproveFlow ? (
                            <>
                                <ButtonCustom
                                    className="w-full"
                                    variant={approval === ApprovalState.APPROVED ? 'filled' : 'outlined'}
                                    onClick={approveCallback}
                                    disabled={approval !== ApprovalState.NOT_APPROVED}
                                    isLoading={approval === ApprovalState.PENDING}
                                >
                                    {approval === ApprovalState.PENDING ? (
                                        <Dots>Approving</Dots>
                                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                                        `You can now trade ${currencies[Field.INPUT]?.symbol}`
                                    ) : (
                                        `Approve ${currencies[Field.INPUT]?.symbol ?? ''}`
                                    )}
                                </ButtonCustom>
                                <ButtonCustom
                                    className="w-full mt-2 font-bold"
                                    color={priceImpactSeverity > 2 || priceImpactSeverity > 3 ? 'danger' : 'primary'}
                                    onClick={() => {
                                        if (isExpertMode) {
                                            handleSwap();
                                        } else {
                                            setSwapState({
                                                tradeToConfirm: trade,
                                                attemptingTxn: false,
                                                swapErrorMessage: undefined,
                                                showConfirm: true,
                                                txHash: undefined,
                                            });
                                        }
                                    }}
                                    disabled={
                                        !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                                    }
                                >
                                    {isLoading ? (
                                        <Dots>Calculating best price</Dots>
                                    ) : priceImpactSeverity > 3 && !isExpertMode ? (
                                        'Price Impact High'
                                    ) : priceImpactSeverity > 2 ? (
                                        'Swap Anyway'
                                    ) : (
                                        'Swap'
                                    )}
                                </ButtonCustom>
                            </>
                        ) : (
                            <ButtonCustom
                                className="w-full font-bold"
                                onClick={() => {
                                    if (isExpertMode) {
                                        handleSwap();
                                    } else {
                                        setSwapState({
                                            tradeToConfirm: trade,
                                            attemptingTxn: false,
                                            swapErrorMessage: undefined,
                                            showConfirm: true,
                                            txHash: undefined,
                                        });
                                    }
                                }}
                                id="swap-button"
                                width="100%"
                                disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                            >
                                {isLoading ? (
                                    <Dots>Calculating best price</Dots>
                                ) : (
                                    swapInputError ||
                                    (priceImpactSeverity > 3 && !isExpertMode
                                        ? `Price Impact Too High`
                                        : priceImpactSeverity > 2
                                            ? 'Swap Anyway'
                                            : 'Swap')
                                )}
                            </ButtonCustom>
                        )}
                        {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                    </div>
                </Card>
                <AdvancedSwapDetailsDropdown trade={trade} />
            </div>
            {CHART_SUPPORTED.includes(chainId) && isChartDisplayed && (
                <Card className="flex-1 container-chart-swap border border-primary">
                    <PriceChartContainer inputCurrency={currencies[Field.INPUT]} outputCurrency={currencies[Field.OUTPUT]} />
                </Card>
            )}
        </div>
    );
};

export default Swap;
