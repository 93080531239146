import {bsc, bscTestnet} from 'wagmi/chains'
import {PROJECT_ID} from "../config";
import {createAppKit} from "@reown/appkit";
import {WagmiAdapter} from "@reown/appkit-adapter-wagmi";
import {getAppKit} from "@reown/appkit/react";

import { defineChain } from '@reown/appkit/networks';

// Define the custom network
const onus = defineChain({
    id: 1975,
    caipNetworkId: 'eip155:1975',
    chainNamespace: 'eip155',
    name: 'ONUS Chain',
    nativeCurrency: {
        name: 'ONUS',
        symbol: 'ONUS',
        decimals: 18,
    },
    rpcUrls: {
        default: {
            http: ['https://rpc.onuschain.io/'],
            webSocket: [],
        },
    },
    blockExplorers: {
        default: { name: 'Explorer', url: 'https://explorer.onuschain.io/' },
    },
})

// 2. Create a metadata object - optional
const metadata = {
    name: 'EasyCake',
    description: 'EasyCake is a DeFi platform that allows users to earn interest on their crypto assets.',
    url: 'https://reown.com/appkit', // origin must match your domain & subdomain
    icons: ['https://assets.reown.com/reown-profile-pic.png']
}

// 3. Set the networks
const networks = [bsc ,onus]

// 4. Create Wagmi Adapter
export const wagmiAdapter = new WagmiAdapter({
    networks,
    projectId: PROJECT_ID,
    ssr: true
});

createAppKit({
    defaultNetwork: bsc,
    adapters: [wagmiAdapter],
    networks,
    projectId: PROJECT_ID,
    metadata,
    features: {
        analytics: true,
        swaps: false,
        onramp: false,
        email: false,
        emailShowWallets: false,
        socials: false,
        history: false,
        allWallets: false
    },
    themeVariables: {
        '--w3m-accent': '#FFBE00',
        '--w3m-color-mix': '#000000',
        '--w3m-border-radius-master': '2px',
    }
})
getAppKit(wagmiAdapter.appKit)

// export const config = createConfig({
//     chains: [bsc],
//     connectors: [
//         injected(),
//         walletConnect({ projectId }),
//         metaMask(),
//         safe(),
//     ],
//     transports: {
//         [bsc.id]: http(),
//     },
// })