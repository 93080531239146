import { classNames } from 'utils/styling';
import Dots from 'components/Loader/Dots';
import ModalApyCalculator from 'components/Modal/ModalApyCalculator';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useState } from 'react';
import { FiChevronUp, FiClipboard, FiHelpCircle } from 'react-icons/fi';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber } from 'utils/formatBalance';
import { tokenEarnedPerThousandDaily } from 'utils/index';

const CardInformation = ({ farm, earningTokenSymbol, farmNotBoosted }) => {
  const { chainId } = useActiveWeb3React();
  const [openRoi, setOpenRoi] = useState(false);

  const apr = farm?.apr;

  const stakedTvl = farm?.stakedTvl || 0;

  const [q0Farm, q1Farm] = getLpBreakdown(+farm?.staked, farm?.totalSupply, farm?.q0, farm?.q1);

  return (
    <div className="mb-3 mt-5">
      {openRoi ? (
        <ModalApyCalculator
          earningTokenSymbol={earningTokenSymbol}
          open={openRoi}
          tokenPrice={farm.earningTokenPrice}
          data={farm}
          tokenEarnedPerThousandDaily={tokenEarnedPerThousandDaily(stakedTvl, apr)}
          isFarm
          onClose={() => setOpenRoi(!openRoi)}
        />
      ) : null}
      {!farm.isFinished && (
        <>
          <div className="flex justify-between items-center mb-1">
            <p className="text-white">APR</p>
            {apr ? (
              <p
                onClick={() => setOpenRoi(true)}
                className={classNames(
                  'relative flex items-center cursor-pointer hover:opacity-50 font-bold',
                  farm.isBoosted ? 'text-primary' : 'text-white',
                )}
              >
                {farm.isBoosted && <FiChevronUp />}
                {`${apr.yearlyAPR === '0' ? '???' : formatNumber(+apr.yearlyAPR)}%`}
                <FiClipboard className="ml-1 cursor-pointer" size={18} />
                {farm.isBoosted && (
                  <span className="absolute bottom-5 right-0 line-through text-white">
                    {formatNumber(+farmNotBoosted?.apr?.yearlyAPR || 0)}%
                  </span>
                )}
              </p>
            ) : (
              <Dots className="text-white" />
            )}
          </div>
          <div className="flex justify-between items-center mb-1 text-white">
            <p>{earningTokenSymbol} per day</p>
            <p className="font-bold">{apr?.userDailyRewards ? formatNumber(+apr?.userDailyRewards, 0, 0) : 0}</p>
          </div>
        </>
      )}
      <div className="flex justify-between items-center mb-1 text-white">
        <p>Earn</p>
        <Tooltip
          tooltip={
            <div className="whitespace-nowrap">
              <p>{earningTokenSymbol} Price</p>
              <p>{formatNumber(farm?.earningTokenPrice ?? 0, 2, 4)}$</p>
            </div>
          }
        >
          <p className="font-bold">{earningTokenSymbol}</p>
        </Tooltip>
      </div>
      <div className="flex justify-between items-center text-white mb-1">
        <p>TVL</p>
        <Tooltip
          tooltip={
            <div className="whitespace-nowrap">
              <p>Total Deposits</p>
              <p>
                {formatNumber(+farm?.staked)} {farm.stakingToken.symbol}
              </p>
              {farm?.token1 && (
                <p>
                  {farm?.staked && (
                    <>
                      {formatNumber(getBalanceNumber(q0Farm, farm?.token0?.decimals), 2, 4)} {farm?.token0?.symbol} +{' '}
                      {formatNumber(getBalanceNumber(q1Farm, farm?.token1?.decimals), 2, 4)} {farm?.token1?.symbol}
                    </>
                  )}
                </p>
              )}
            </div>
          }
        >
          <Value className="font-bold" prefix="$" value={stakedTvl} decimals={0} />
        </Tooltip>
      </div>
      {[ChainId.HARMONY, ChainId.ONUS].includes(chainId) && (
        <>
          <div className="flex justify-between items-center text-white mb-1">
            <p>Withdrawal Fee</p>
            <p>{farm.withdrawalFee || 0}%</p>
          </div>
          {ChainId.HARMONY === chainId && (
            <div className="flex justify-between items-center text-white">
              <p>Harvest Fee</p>
              <div>
                <Tooltip
                  tooltip={
                    <>
                      <p>Harvest fee: 50% rewards</p>
                      <p>
                        Harvest after 3 months will not include a fee. Timer resets every time you stake,unstake or
                        harvest in the pool.
                      </p>
                    </>
                  }
                >
                  <p>
                    50% rewards <FiHelpCircle className="ml-1 inline-block align-text-bottom" size={16} />
                  </p>
                </Tooltip>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CardInformation;
