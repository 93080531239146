import Card from 'components/Card/Card';
import { CASTLE_TAGS } from 'constants/index';
import PropTypes from 'prop-types';
import CardActionsContainer from 'views/Castles/components/CastlesCard/CardActionsContainer/CardActionsContainer';
import CardHeading from 'views/Castles/components/CastlesCard/CardHeading';
import CardInformation from 'views/Castles/components/CastlesCard/CardInformation';
import DetailsSection from 'views/Castles/components/CastlesCard/DetailsSection';
import UnstakingFeeCountdownRow from 'views/Castles/components/CastlesCard/UnstakingFeeCountdownRow';

const CastleCard = ({ pool, index, userDataLoaded }) => {
  const isIfo = pool.tags.includes(CASTLE_TAGS.ifo);

  return (
    <div
      // fix tooltip zIndex
      style={{
        zIndex: 10000000 + index,
      }}
      className="w-96 max-w-full sm:w-full mx-auto relative"
    >
      <Card className="relative">
        <div className="p-5">
          <CardHeading
            isFinished={pool.isFinished}
            tags={pool.tags}
            stakingToken={pool.stakingToken}
            token0={pool.token0}
            token1={pool?.token1}
          />
          <CardInformation
            pool={pool}
            isFinished={pool.isFinished}
            earningTokens={pool.earningToken ? [pool.earningToken] : pool.earningTokens}
            stakedTvl={pool.stakedTvl}
            isIfo={isIfo}
          />
          {pool?.fees?.withdrawalFee && (
            <UnstakingFeeCountdownRow
              stakedBalance={pool.userData.stakedBalance}
              className="text-white"
              blockPeriod={pool.blockPeriod}
              lastStakingBlock={pool.userData.lastStakingBlock}
              fees={pool.fees.withdrawalFee}
            />
          )}
          <CardActionsContainer
              pool={pool}
              earningTokens={pool.earningToken ? [pool.earningToken] : pool.earningTokens}
              isIfo={isIfo}
              userDataLoaded={userDataLoaded}
          />
          <DetailsSection pool={pool} isIfo={isIfo} />
        </div>
      </Card>
    </div>
  );
};

CastleCard.propTypes = {
  pool: PropTypes.object,
};

export default CastleCard;
