import React from 'react';
import { classNames } from 'utils/styling';

const Skeleton = ({ className, width, height }) => {
  return (
    <div
      style={{
        height,
        width,
      }}
      className={classNames('bg-gray-400 rounded-full w-full animate-pulse', className)}
    />
  );
};

export default Skeleton;
