import { Pair, Token, NativeCurrency } from 'ezcake-v2-sdk';

export function currencyId(currency) {
  if (currency === NativeCurrency[currency?.chainId]) return NativeCurrency[currency?.chainId].symbol;
  if (currency instanceof Token) return currency.address;
  throw new Error('invalid currency');
}

export function pairId(currency) {
  if (currency instanceof Pair) return currency.liquidityToken.address;
  throw new Error('invalid pair');
}

export default currencyId;
