import { classNames } from 'utils/styling';
import Dropdown from 'components/Dropdown/Dropdown';
import InputSearch from 'components/Input/InputSearch';
import Switch from 'components/Switch/Switch';
import { TYPE_VIEW } from 'constants/index';
import PropTypes from 'prop-types';
import { FiGrid, FiMenu } from 'react-icons/fi';

const ActionTool = ({
  className,
  setSearch,
  setIsStakedOnly,
  isStakedOnly,
  sortList,
  sortBy,
  setSortBy,
  labelStaked = 'Staked Only',
  hideStakedOnly = false,
  typeView,
  setTypeView,
}) => {
  return (
    <div className={classNames('relative z-30 mb-5 sm:mb-10 mx-3 sm:mx-5', className)}>
      <div className="block flex-wrap items-center lg:flex justify-between">
        <div className="flex items-center flex-1 sm:max-w-3xl">
          <InputSearch
            className="flex-1"
            placeholder="Search..."
            classNameInput="w-full"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="text-primary border-primary border-2 rounded-xl p-2 mx-2 my-2 md:mx-10">
            <span className="mx-4">
              Sort by <span className="ml-3">|</span>
            </span>
            <Dropdown
              isArrow
              menu={<span>{sortList.find((sort) => sort.name === sortBy).label}</span>}
              bsPrefixMenu="p-0 mr-4"
              classNameMenuItem="bg-primary text-black"
            >
              {sortList.map((item, index) => (
                <div
                  key={index}
                  className="px-4 py-2 hover:bg-gray-200 block cursor-pointer"
                  onClick={() => setSortBy(item.name)}
                >
                  <span className="w-full h-full">{item.label}</span>
                </div>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="flex">
          <div className="flex items-center mx-2 md:mx-10">
            {!hideStakedOnly && (
              <Switch
                className="my-2"
                onChange={() => setIsStakedOnly((prevState) => !prevState)}
                checked={isStakedOnly}
                label={labelStaked}
                classNameLabel="text-white"
              />
            )}
          </div>
          {typeView && (
            <div className="flex">
              <div
                className={classNames(
                  'rounded-l-xl p-2',
                  typeView === TYPE_VIEW.table
                    ? ' text-primary border-primary border'
                    : 'bg-black1 text-white cursor-pointer',
                )}
                onClick={() => setTypeView(TYPE_VIEW.table)}
              >
                <FiMenu size={24} />
              </div>
              <div
                className={classNames(
                  'rounded-r-xl p-2',
                  typeView === TYPE_VIEW.grid
                    ? 'text-primary border-primary border'
                    : 'bg-black1 text-white cursor-pointer',
                )}
                onClick={() => setTypeView(TYPE_VIEW.grid)}
              >
                <FiGrid size={24} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ActionTool.propTypes = {
  className: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  setIsStakedOnly: PropTypes.func,
  isStakedOnly: PropTypes.bool,
  sortList: PropTypes.array.isRequired,
  sortBy: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  labelStaked: PropTypes.string,
  hideStakedOnly: PropTypes.bool,
};

export default ActionTool;
