import Card from 'components/Card/Card';
import { useState } from 'react';
import CardApy from 'views/Vaults/components/VaultsCard/CardApy';
import CardHeading from 'views/Vaults/components/VaultsCard/CardHeading';
import Statistics from 'views/Vaults/components/VaultsCard/Statistics';
import YourAccount from 'views/Vaults/components/VaultsCard/YourAccount/YourAccount';

export const TABS_VAULT_CARD = {
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  compound: 'Compound',
};

const VaultCard = ({ vault, userDataLoaded }) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(TABS_VAULT_CARD.deposit);

  const handleChangeTab = (tabSelected) => {
    if (tab !== tabSelected) {
      setTab(tabSelected);
    }
  };

  const toggleOpen = () => setOpen((prevState) => !prevState);

  return (
    <div>
      <Card className="p-2 sm:p-5">
        <CardApy vault={vault} userDataLoaded={userDataLoaded} />
        <CardHeading vault={vault} userDataLoaded={userDataLoaded} />
        <Statistics vault={vault} userDataLoaded={userDataLoaded} onChangeTab={handleChangeTab} toggleOpen={setOpen} />
        <YourAccount
          vault={vault}
          userDataLoaded={userDataLoaded}
          open={open}
          tab={tab}
          onChangeTab={handleChangeTab}
          toggleOpen={toggleOpen}
        />
      </Card>
    </div>
  );
};

export default VaultCard;
