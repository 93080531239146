import React, { useRef, useState } from 'react';
import InputSearch from 'components/Input/InputSearch';
import ContentSearch from 'views/Analytics/components/InfoSearch/ContentSearch';
import useDebounce from 'hooks/useDebounce';

const Blackout = () => <div className="fixed min-h-screen w-screen bg-black z-10 opacity-70 left-0 top-0" />;

const Search = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [value, setValue] = useState('');
  const debouncedSearchTerm = useDebounce(value, 600);

  const inputRef = useRef(null);

  return (
    <div className="relative">
      {showMenu ? <Blackout /> : null}
      <InputSearch
        className={showMenu ? 'z-50 relative bg-black1' : 'bg-black1'}
        ref={inputRef}
        placeholder="Search pools or tokens"
        classNameInput="w-[300px]"
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setShowMenu(true)}
        value={value}
      />
      {showMenu && (
        <ContentSearch
          inputRef={inputRef}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          debouncedSearchTerm={debouncedSearchTerm}
        />
      )}
    </div>
  );
};

export default Search;
