import Value from 'components/Value/Value';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatNumber } from 'utils/formatBalance';
import Limit from 'views/Castles/components/CastlesCard/Limit';
import { getLinkGetTokenLP } from 'utils/explorer';
import { FiChevronDown, FiExternalLink } from 'react-icons/fi';
import { useChainId} from "wagmi";

const DetailsSection = ({ pool, isIfo }) => {
    const chainId = useChainId();
    const [isView, setIsView] = useState(false);

  const earningTokens = pool.earningToken ? [pool.earningToken] : pool.earningTokens;
  const { stakingLimit, poolLimit, stakingToken } = pool;
  const stakedTvl = pool.stakedTvl;
  const linkGetToken = getLinkGetTokenLP(chainId, pool?.token0, pool?.token1, stakingToken);

  return (
    <div>
      <div
        className="text-primary font-bold flex items-center justify-center cursor-pointer mt-4 flex-wrap"
        onClick={() => setIsView(!isView)}
      >
        <span>Detail</span> {isView ? <FiChevronDown size={16} /> : <FiChevronDown size={16} />}
      </div>
      {isView && (
        <div className="text-white mt-2">
          <div className="flex justify-between items-center text-white">
            <p>TVL</p>
            <Value className="font-bold" prefix="$" value={stakedTvl ? +stakedTvl : 0} decimals={0}/>
          </div>
          {isIfo &&
            !pool.isFinished &&
            earningTokens.map((earningToken) => (
              <div className="flex justify-between items-center text-white">
                <p>{earningToken.symbol} price</p>
                <p className="font-bold">
                  {pool?.earningTokensPrice ? formatNumber(pool?.earningTokensPrice, 4, 4) : 0}$
                </p>
              </div>
            ))}
          <Limit
            isIfo={isIfo}
            stakingToken={stakingToken}
            poolLimit={poolLimit}
            stakingLimit={stakingLimit}
            stakingTokenPrice={pool.stakingTokenPrice}
          />
          {earningTokens.length > 1 ? (
            earningTokens.map((earningToken) => (
              <a
                rel="noreferrer"
                className="flex items-center justify-end cursor-pointer hover:underline"
                href={earningToken.projectLink}
                target="_blank"
              >
                View Project Site ({earningToken.symbol})
                <FiExternalLink className="ml-1" size={16} />
              </a>
            ))
          ) : (
            <a
              rel="noreferrer"
              className="flex items-center justify-end cursor-pointer hover:underline"
              href={earningTokens[0].projectLink}
              target="_blank"
            >
              View Project Site
              <FiExternalLink className="ml-1" size={16} />
            </a>
          )}
          {linkGetToken ? (
            <>
              {linkGetToken?.route ? (
                <Link
                  className="flex items-center justify-end cursor-pointer hover:underline"
                  to={linkGetToken?.route}
                  target="_blank"
                >
                  {linkGetToken?.label}
                  <FiExternalLink className="ml-1" size={16} />
                </Link>
              ) : (
                <a
                  rel="noreferrer"
                  className="flex justify-end items-center hover:underline"
                  target="_blank"
                  href={linkGetToken?.link}
                >
                  {linkGetToken?.label} <FiExternalLink className="ml-1" size={16} />
                </a>
              )}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DetailsSection;
