import Container from 'components/Container/Container';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import LayoutSwap from 'components/LayoutSwap/LayoutSwap';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AVAILABLE_ROUTE, CHAIN_ROUTE_SWAP, PATH_SWAP } from 'config/route';
import {useChainId} from "wagmi";

const Layout = ({ children }) => {
  const chainId = useChainId()
  const location = useLocation();
  const pathname = location?.pathname;
  const navigate = useNavigate();

  const isRouteSwap = useMemo(() => {
    return (
      !!PATH_SWAP.find(
        (item) => pathname?.substring(0, item?.length) && pathname?.substring(0, item?.length)?.toLowerCase() === item,
      ) && CHAIN_ROUTE_SWAP.includes(chainId)
    );
  }, [chainId, pathname]);

  useEffect(() => {
    if (!isRouteSwap) {
      window.scrollTo(0, 0);
    }
  }, [isRouteSwap, pathname]);

  useEffect(() => {
    const pathString = pathname?.split('/')?.[1]?.toLowerCase();
    if (chainId && !AVAILABLE_ROUTE[chainId].includes(pathString)) {
      navigate('/');
    }
  }, [chainId, navigate, pathname]);

  return (
    <Container>
      <Header />
      <div className="flex-1 relative">{isRouteSwap ? <LayoutSwap>{children}</LayoutSwap> : children}</div>
      <Footer />
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
