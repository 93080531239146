import { classNames } from 'utils/styling';
import Logo from 'components/Header/Logo';
import { BiLogoTelegram, BiLogoTwitter } from 'react-icons/bi';
import { FiMail } from 'react-icons/fi';
import InputSubscribe from 'components/Input/InputSubcribe';
import { Link } from "react-router-dom";

const Footer = () => {

  const menuList = [
    {
      id: 'about-us',
      title: 'About us',
      link: '',
      children: [
        { label: 'hi@easycake.io', link: '', icon: <FiMail size={20} className="inline-block text-primary" /> },
        { label: 'Ecosystem', link: '', icon: <FiMail size={20} className="inline-block text-primary" /> },
      ],
    },
    {
      id: 'product',
      title: 'Product',
      link: '',
      children: [
        { label: 'Farms', link: '/' },
        { label: 'Trade', link: 'https://pancakeswap.finance/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xd77b2d571256ce5fb3365bfff3c5859d1ef40f0a&chain=bsc' },
        { label: 'Analytics', link: 'https://pancakeswap.finance/info/v3/tokens/0xd77b2d571256ce5fb3365bfff3c5859d1ef40f0a' },
      ],
    },
    {
      id: 'information',
      title: 'Information',
      link: '',
      children: [
        { label: 'Whitepaper', link: 'https://ecake.gitbook.io/easycake' },
        { label: 'Term of Privacy & Use', link: '' },
        { label: 'Blog', link: '' },
        { label: 'Roadmap', link: '' },
      ],
    },
  ];

  return (
    <footer
      className={classNames(
        'max-w-screen-2xl mx-auto px-4 xl:px-20 py-7 relative z-20 text-white flex flex-col gap-16 w-full',
      )}
    >
      <div className={classNames('', 'flex flex-wrap items-center justify-between gap-8 xl:gap-20')}>
        <Logo className="w-[234px]" width={234} height={116} />
        <div className="flex flex-wrap gap-8 justify-between lg:justify-around flex-auto">
          {menuList.map((item) => (
            <ol key={item?.id}>
              <Link to={item.link} className="text-primary font-bold text-xl">
                {item.title}
              </Link>
              {item.children?.map((i) => (
                <li key={i.label} className="mt-4">
                  <Link to={i.link} className="whitespace-normal flex gap-2 items-center">
                    {i.icon} <p className="whitespace-nowrap">{i.label}</p>
                  </Link>
                </li>
              ))}
            </ol>
          ))}
        </div>
        <div className="lg:text-right flex flex-col gap-4 flex-none">
          <p className="text-primary font-bold text-xl">Subscribe to our newsletter</p>
          <InputSubscribe
            className="flex-1"
            placeholder="Input your email"
            classNameInput="w-full"
          />
          <p className="text-sm">For product announcements and exclusive insights</p>
          <div className="flex lg:justify-end gap-4 text-black">
            {/*<a href="#" className="flex h-6 w-6 bg-white rounded-full justify-center items-center">*/}
            {/*  <BiLogoFacebook size={18} />*/}
            {/*</a>*/}
            <a href="https://x.com/easycake_" className="flex h-6 w-6 bg-white rounded-full justify-center items-center">
              <BiLogoTwitter />
            </a>
            {/*<a href="#" className="flex h-6 w-6 bg-white rounded-full justify-center items-center">*/}
            {/*  <BiLogoYoutube />*/}
            {/*</a>*/}
            <a href="https://t.me/EasycakeOfficial" className="flex h-6 w-6 bg-white rounded-full justify-center items-center">
              <BiLogoTelegram />
            </a>
          </div>
        </div>
      </div>
      <hr className="border-black6" />
      <div className="flex justify-center flex-wrap text-black6">Copyright © 2024 EasyCake. All Rights Reserved</div>
    </footer>
  );
};

export default Footer;
