import Dots from 'components/Loader/Dots';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber } from 'utils/formatBalance';

const TvlCell = ({ farm }) => {
  const stakedTvl = farm?.stakedTvl || 0;
  const [q0Farm, q1Farm] = getLpBreakdown(+farm?.staked, farm?.totalSupply, farm?.q0, farm?.q1);

  return (
    <div>
      <p>TVL</p>
      <Tooltip
        tooltip={
          <div className="whitespace-nowrap">
            <p>Total Deposits</p>
            <p>
              {formatNumber(+farm?.staked)} {farm.stakingToken.symbol}
            </p>
            {farm?.token1 && (
              <p>
                {farm?.staked && (
                  <>
                    {formatNumber(getBalanceNumber(q0Farm, farm?.token0?.decimals), 2, 4)} {farm?.token0?.symbol} +{' '}
                    {formatNumber(getBalanceNumber(q1Farm, farm?.token1?.decimals), 2, 4)} {farm?.token1?.symbol}
                  </>
                )}
              </p>
            )}
          </div>
        }
      >
        {+stakedTvl > 0 ? (
          <Value className="font-bold" prefix="$" value={stakedTvl} decimals={0} />
        ) : (
          <Dots className="font-bold" />
        )}
      </Tooltip>
    </div>
  );
};

export default TvlCell;
