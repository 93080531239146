import DoubleLogo from 'components/Logo/DoubleLogo';
import Tooltip from 'components/Tooltip/Tooltip';
import { NAME_PLATFORM_LP } from 'constants/index';
import { formatLogo } from 'utils/formatLogo';
import { getTokenName } from 'utils/tokenHelpers';

const AssetsCell = ({ farm }) => {
  const lpTokenName = getTokenName(farm.token0, farm.token1, farm.stakingToken, true);
    console.log('farm.token0');
    console.log(farm.token0);
    console.log('farm.token1');
    console.log(farm.token1);
    const logo = formatLogo(farm.token0, farm.token1);

  return (
    <div className="flex items-center">
      <DoubleLogo right="-right-6" src0={logo?.src0} src1={logo?.src1} alt0={logo?.alt0} alt1={logo?.alt1} size={40} />
      <div className="flex flex-col ml-8 items-start">
        <p className="text-center text-primary text-lg font-bold">{lpTokenName}</p>
        <div className="flex items-center">
          {!farm.isFinished && (
            <p className="bg-primary px-2 rounded-lg min-w-min text-black">
              {farm.isFinished ? 0 : farm.mul ? farm.mul : '...'}X
            </p>
          )}
          {farm?.token1 && (
            <Tooltip
              tooltip={
                <div className="whitespace-nowrap">
                  <p>{NAME_PLATFORM_LP[farm.stakingToken.symbol]}</p>
                </div>
              }
            >
              <img
                className="ml-1 w-8 h-8 rounded-full bg-white p-1"
                width="20"
                src={`/platform/${NAME_PLATFORM_LP[farm.stakingToken.symbol].toLowerCase()}.png`}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetsCell;
