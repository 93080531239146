import useSWR from 'swr';
import { analyticsUrlApi } from 'api/url';
import { fetcherAnalytics } from 'api/axiosClient';
import { TransactionType } from 'store/analytics/types';
import { mapBurns, mapMints, mapSwaps } from 'store/analytics/queries/helpers';
import { ITEMS_PER_INFO_TABLE_PAGE } from 'constants/analytics';
import {useChainId} from "wagmi";

const defaultOptions = {
  rowPerPage: ITEMS_PER_INFO_TABLE_PAGE,
  sort: 'timestamp',
  sortDir: 'desc',
};

export const useSWRTransactions = (query) => {
    const chainId = useChainId()
  const { data, ...rest } = useSWR(
[    analyticsUrlApi.getAllTransactions({
      ...defaultOptions,
      ...query,
    }), chainId],
      (url)=> fetcherAnalytics(url[0], chainId),
  );

  return {
    transactions: data
      ? data.data.map((transaction) => {
          if (transaction.type === TransactionType.MINT) {
            return mapMints(transaction);
          } else if (transaction.type === TransactionType.BURN) {
            return mapBurns(transaction);
          } else {
            return mapSwaps(transaction);
          }
        })
      : [],
    maxPage: data ? data.totalPage : 1,
    ...rest,
  };
};

export const useSWRMintTransactions = (query) => {
    const chainId = useChainId()
  const { data, ...rest } = useSWR(
[    analyticsUrlApi.getMintTransactions({
      ...defaultOptions,
      ...query,
        chainId
    }), chainId],
      (url)=> fetcherAnalytics(url[0], chainId),
  );

  return {
    transactions: data ? data.data.map(mapMints) : [],
    maxPage: data ? data.totalPage : 1,
    ...rest,
  };
};

export const useSWRBurnTransactions = (query) => {
    const chainId = useChainId()

  const { data, ...rest } = useSWR(
[    analyticsUrlApi.getBurnTransactions({
      ...defaultOptions,
      ...query,
    }), chainId],
      (url)=> fetcherAnalytics(url[0], chainId),
  );

  return {
    transactions: data ? data.data.map(mapBurns) : [],
    maxPage: data ? data.totalPage : 1,
    ...rest,
  };
};

export const useSWRSwapTransactions = (query) => {
    const chainId = useChainId()

  const { data, ...rest } = useSWR(
[    analyticsUrlApi.getSwapTransactions({
      ...defaultOptions,
      ...query,
    }), chainId],
      (url)=> fetcherAnalytics(url, chainId),
  );

  return {
    transactions: data ? data.data.map(mapSwaps) : [],
    maxPage: data ? data.totalPage : 1,
    ...rest,
  };
};
