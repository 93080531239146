import { classNames } from 'utils/styling';
import QuestionHelper from 'components/QuestionHelper/index';
import { AVERAGE_BLOCK_TIME } from 'config/index';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import Countdown, { zeroPad } from 'react-countdown';
import { useCurrentBlock } from 'store/block/hooks';

const WithdrawalFeeTimer = ({ lastStakingBlock, blockPeriod }) => {
  const { chainId } = useActiveWeb3React();
  const currentBlock = useCurrentBlock();

  const timer = (lastStakingBlock + blockPeriod - currentBlock) * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();

  const renderCountdown = ({ days, hours, minutes, completed }) => {
    if (completed) return <p className="mb-0">0d-0h-0m</p>;

    return (
      <p className="ml-2">
        {zeroPad(days)}d-{zeroPad(hours)}h-{zeroPad(minutes)}m
      </p>
    );
  };
  return <Countdown zeroPadTime={2} date={timer} renderer={renderCountdown} />;
};

const UnstakingFeeCountdownRow = ({ fees, lastStakingBlock, blockPeriod, stakedBalance, className }) => {
  const currentBlock = useCurrentBlock();
  const { account, chainId } = useActiveWeb3React();

  const hasUnstakingFee = lastStakingBlock + blockPeriod > currentBlock && stakedBalance.gt(0);

  const feeTimeHours = (blockPeriod * AVERAGE_BLOCK_TIME[chainId]) / 3600;

  // The user has made a deposit, but has no fee
  const noFeeToPay = lastStakingBlock && !hasUnstakingFee && stakedBalance.gt(0);

  // Show the timer if a user is connected, has deposited, and has an unstaking fee
  const shouldShowTimer = Boolean(account && lastStakingBlock && hasUnstakingFee);

  const getRowText = () => {
    if (noFeeToPay) {
      return 'Unstaking Fee';
    }
    if (shouldShowTimer) {
      return 'unstaking fee until';
    }
    return `unstaking fee if withdrawn within ${feeTimeHours}h`;
  };

  if (!fees) return null;

  return (
    <div className={classNames(className, noFeeToPay ? 'text-green-500' : 'text-red-500', 'flex mt-2 text-sm-md')}>
      <div className="flex items-center">
        {noFeeToPay ? '0' : fees}% {getRowText()}
        <QuestionHelper
          text={
            <>
              <p>Unstaking fee: {fees}%</p>
              <p>
                Only applies within {feeTimeHours / 24} days of staking. Unstaking after {feeTimeHours / 24} days will
                not include a fee. Timer resets every time you stake in the pool.
              </p>
            </>
          }
        />
      </div>
      {shouldShowTimer && <WithdrawalFeeTimer lastStakingBlock={lastStakingBlock} blockPeriod={blockPeriod} />}
    </div>
  );
};

export default UnstakingFeeCountdownRow;
