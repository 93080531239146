import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import ModalCurrency from 'components/Modal/ModalCurrency';
import Value from 'components/Value/Value';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useApprove } from 'hooks/useApprove';
import useStake from 'hooks/useStake';
import useUnstake from 'hooks/useUnstake';
import { useCallback, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { fetchFarmUserDataAsync } from 'store/farms/index';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getTokenName } from 'utils/tokenHelpers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import UnlockButton from 'components/UnlockButton/UnlockButton';

const StakeAction = ({ farm, userDataLoaded, masterChefAddress, earningTokenSymbol }) => {
  const pid = farm.pid;
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();
  const lpAddress = farm.lpAddress[chainId];
  const [requestedApproval, setRequestedApproval] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);

  const lpTokenName = getTokenName(farm.token0, farm.token1, farm.stakingToken);
  const { allowance, stakedBalance, stakingTokenBalance, unlockTimestamp } = farm.userData;

  const { onApprove } = useApprove(lpAddress, masterChefAddress);
  const isApproved = account && allowance && allowance.isGreaterThan(0);

  const usdTokenStaking = stakedBalance
    ? new BigNumber(getFullDisplayBalance(stakedBalance, farm.stakingToken.decimals)).times(farm.price || 0).toNumber()
    : 0;

  const [q0user, q1user] = getLpBreakdown(
    stakedBalance.div(BIG_TEN.pow(farm.stakingToken.decimals)).toNumber(),
    farm.totalSupply,
    farm.q0,
    farm.q1,
  );

  const { onStake } = useStake(pid, masterChefAddress);
  const { onUnstake } = useUnstake(pid, masterChefAddress);

  const handleStake = async (amount) => {
    await onStake(amount, farm.stakingToken.decimals);
    dispatch(fetchFarmUserDataAsync(account, chainId));
    showToastSuccess('Staked', `Your ${lpTokenName} funds have been staked in the pool!`);
  };

  const handleUnstake = async (amount) => {
    await onUnstake(amount, farm.stakingToken.decimals);
    dispatch(fetchFarmUserDataAsync(account, chainId));
    showToastSuccess('Unstaked', `Your ${earningTokenSymbol} earnings have also been harvested to your wallet!`);
  };

  const toggleDeposit = () => setOpenDeposit(!openDeposit);
  const toggleWithdraw = () => setOpenWithdraw(!openWithdraw);

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove();
      showToastSuccess('Contract Enabled', `You can now stake in the ${lpTokenName} pool!`);
      dispatch(fetchFarmUserDataAsync(account, chainId));
      setRequestedApproval(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setRequestedApproval(false);
    }
  }, [account, chainId, dispatch, lpTokenName, onApprove]);

  return (
    <>
      <ModalCurrency
        title={`Deposit ${lpTokenName} Tokens`}
        max={stakingTokenBalance}
        onDismiss={toggleDeposit}
        open={openDeposit}
        lpTokenName={lpTokenName}
        onConfirm={handleStake}
        priceCurrency={farm.price}
        decimals={farm.stakingToken.decimals}
        unlockTimestamp={unlockTimestamp}
        isDeposit={true}
      />
      <ModalCurrency
        title={`Withdraw ${lpTokenName}`}
        max={stakedBalance}
        onDismiss={toggleWithdraw}
        open={openWithdraw}
        lpTokenName={lpTokenName}
        onConfirm={handleUnstake}
        priceCurrency={farm.price}
        decimals={farm.stakingToken.decimals}
        unlockTimestamp={unlockTimestamp}
      />
      <div className="flex justify-between items-center">
        <div className="mr-1">
          <Value
            className="text-primary font-bold text-xl"
            value={account ? getBalanceNumber(stakedBalance, farm.stakingToken.decimals) : 0}
          />
          {farm.token1 && stakedBalance.gt(0) && (
            <p className="text-primary">
              {formatNumber(getBalanceNumber(q0user, farm.token0.decimals), 2, 4)} {farm.token0.symbol} +{' '}
              {formatNumber(getBalanceNumber(q1user, farm.token1.decimals), 2, 4)} {farm.token1.symbol}
            </p>
          )}
          <Value
            prefix="~"
            className="text-primary text-sm"
            value={account ? usdTokenStaking : 0}
            decimals={2}
            unit="USD"
          />
        </div>
        {account ? (
          userDataLoaded ? (
            farm.isFinished ? (
              <ButtonCustom className="mr-1" size="sm" onClick={toggleWithdraw}>
                Unstake
              </ButtonCustom>
            ) : isApproved ? (
              <>
                {stakedBalance.eq(new BigNumber(0)) ? (
                  <ButtonCustom onClick={toggleDeposit}>Stake</ButtonCustom>
                ) : (
                  <div className="flex items-center">
                    <ButtonCustom className="mr-1" size="sm" onClick={toggleWithdraw}>
                      <FiMinus />
                    </ButtonCustom>
                    <ButtonCustom size="sm" onClick={toggleDeposit}>
                      <FiPlus />
                    </ButtonCustom>
                  </div>
                )}
              </>
            ) : (
              <ButtonCustom isLoading={requestedApproval} disabled={requestedApproval} onClick={handleApprove}>
                Approve Contract
              </ButtonCustom>
            )
          ) : (
            <ButtonCustom>
              <Dots>Loading</Dots>
            </ButtonCustom>
          )
        ) : (
          <UnlockButton fullWidth={false} />
        )}
      </div>
    </>
  );
};

export default StakeAction;
