import { ChainId } from 'ezcake-v2-sdk';

const Bsc = '/images/networks/bsc-network.jpg';
const Kai = '/images/wallets/kai.png';
const harmony = '/images/networks/harmonyone-network.png';
const onus = '/images/networks/onus.jpg';

export const NETWORK_ICON = {
  [ChainId.BSC]: Bsc,
  [ChainId.BSC_TESTNET]: Bsc,
  [ChainId.HARMONY]: harmony,
  [ChainId.HARMONY_TESTNET]: harmony,
  [ChainId.KAI]: Kai,
  [ChainId.ONUS]: onus,
  [ChainId.ONUS_TESTNET]: onus,
};

export const NETWORK_LABEL = {
  [ChainId.BSC]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [ChainId.KAI]: 'Kardiachain',
  [ChainId.HARMONY]: 'Harmony',
  [ChainId.HARMONY_TESTNET]: 'Harmony Testnet',
  [ChainId.ONUS]: 'ONUS',
  [ChainId.ONUS_TESTNET]: 'ONUS Testnet',
};

export const NETWORK_WALLET_LABEL = {
  [ChainId.BSC]: 'BEP20',
  [ChainId.BSC_TESTNET]: 'BEP20 Testnet',
  [ChainId.KAI]: 'KRC20',
  [ChainId.ONUS]: 'ONUS',
  [ChainId.ONUS_TESTNET]: 'ONUS Testnet',
};
