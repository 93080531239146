import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExplorerLink } from 'utils/explorer';

import { checkedTransaction, finalizeTransaction } from './actions';
import { useCurrentBlock } from '../block/hooks';
import {useChainId, useConfig} from "wagmi";
import {waitForTransactionReceipt} from "@wagmi/core";

export function shouldCheck(currentBlock, tx) {
  if (tx.receipt) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = currentBlock - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  }
  if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  }
  // otherwise every block
  return true;
}

export default function Updater() {
  const chainId = useChainId()
  const config = useConfig()

  const currentBlock = useCurrentBlock();

  const dispatch = useDispatch();
  const state = useSelector((s) => s.transactions);

  const transactions = useMemo(() => (chainId ? state[chainId] ?? {} : {}), [chainId, state]);

    useEffect(() => {
    if (!chainId || !config || !currentBlock) return;

    Object.keys(transactions)
      .filter((hash) => shouldCheck(currentBlock, transactions[hash]))
      .forEach((hash) => {
        console.log('hash');
        console.log(hash);
        waitForTransactionReceipt(config, {hash})
          .then((receipt) => {
            if (receipt) {
                dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber.toString(),
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                }),
              );

              const toast = receipt.status === "success" ? showToastSuccess : showToastError;
              toast(
                'Transaction receipt',
                <div>
                  <p>{transactions[hash]?.summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`}</p>
                  {chainId && (
                    <a rel="noreferrer" target="_blank" href={getExplorerLink(chainId, hash, 'transaction')}>
                      View on Explorer
                    </a>
                  )}
                </div>,
              );
            } else {
              dispatch(checkedTransaction({ chainId, hash, blockNumber: currentBlock }));
            }
          })
          .catch((error) => {
            console.error(`failed to check transaction hash: ${hash}`, error);
          });
      });
  }, [chainId, config, transactions, currentBlock, dispatch]);

  return null;
}
