import BigNumber from 'bignumber.js';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { usePrices } from 'store/prices/hook';
import { calculatorStakedBalanceOfVaultV2, getVaultStaked } from 'store/vaults/helpers';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getParameterCaseInsensitive } from 'utils/index';

const HoldingsV2Cell = ({ vault }) => {
  const { account, chainId } = useActiveWeb3React();
  const vaultStaked = getVaultStaked(vault, chainId);
  const { userData } = vault;
  const prices = usePrices();
  const priceStakingToken = getParameterCaseInsensitive(prices, vault.stakingToken.address[chainId]);
  const priceStakingTokenInVaultStaked = getParameterCaseInsensitive(
    prices,
    getVaultStaked(vault, chainId).stakingToken.address[chainId],
  );
  const { stakedBalance } = vault.userData;

  const { totalStakedBalance } = calculatorStakedBalanceOfVaultV2(
    vault,
    userData,
    priceStakingTokenInVaultStaked,
    priceStakingToken,
    vault.stakingToken.decimals,
    vaultStaked.stakingToken.decimals,
  );

  const usdHoldings = useMemo(() => {
    return priceStakingToken
      ? new BigNumber(getFullDisplayBalance(totalStakedBalance, vault.stakingToken.decimals))
          .times(priceStakingToken)
          .toNumber()
      : 0;
  }, [priceStakingToken, totalStakedBalance, vault.stakingToken.decimals]);

  const [q0User, q1User] = getLpBreakdown(
    stakedBalance.div(vault.stakingToken.decimals).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  return (
    <>
      <Tooltip
        tooltip={
          <div className="whitespace-nowrap">
            <p>Your Deposits</p>
            <p>
              {formatNumber(getBalanceNumber(stakedBalance, vault.stakingToken.decimals))} {vault.stakingToken.symbol}
            </p>
            {vault.token1 && vault.farmStaked && (
              <p>
                {stakedBalance.gt(0) && (
                  <>
                    {formatNumber(getBalanceNumber(q0User, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                    {vault.farmStaked.token0.symbol} +{' '}
                    {formatNumber(getBalanceNumber(q1User, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                    {vault.farmStaked.token1.symbol}
                  </>
                )}
              </p>
            )}
          </div>
        }
      >
        <Value value={account ? getBalanceNumber(totalStakedBalance.toNumber(), vault.stakingToken.decimals) : 0} />
        {usdHoldings > 0 && <Value prefix="~$" className="text-sm" value={account ? usdHoldings : 0} />}
      </Tooltip>
    </>
  );
};

HoldingsV2Cell.propTypes = {
  vault: PropTypes.object.isRequired,
};

export default HoldingsV2Cell;
