import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useRefresh from 'hooks/useRefresh';
import { useEffect, useMemo } from 'react';
import castlesConfig from 'constants/castles';
import { usePrices } from 'store/prices/hook';

import { fetchCastlesUserDataAsync, fetchCastlesUserDataAsyncV1, fetchCastleUserDataAsync } from './index';
import { transformPool } from './helpers';
import BigNumber from 'bignumber.js';
import { BIG_ZERO } from 'utils/bigNumber';
import {useAccount, useChainId} from "wagmi";

export const useFetchPoolUserData = () => {
  const { pid } = useParams();
  const dispatch = useDispatch();
  const chainId = useChainId();
  const { address } = useAccount();

  const { slowRefresh } = useRefresh();
  const pool = useMemo(() => {
    return [...castlesConfig[chainId]].find((p) => {
      return p.sousId === +pid;
    });
  }, [chainId, pid]);

  useEffect(() => {
    if (address && pool && chainId) {
      dispatch(fetchCastleUserDataAsync(address, pool, chainId));
    }
  }, [dispatch, slowRefresh, address, pool, chainId]);
};

export const useCastles = () => {
  const prices = usePrices();
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  const chainId = useChainId();
  const { address } = useAccount();

  useEffect(() => {
    if (address && chainId) {
      // dispatch(fetchCastlesUserDataAsync(account, chainId));
      dispatch(fetchCastlesUserDataAsyncV1(address, chainId));
    }
  }, [address, dispatch, fastRefresh, chainId]);

  const { castles, userDataLoaded } = useSelector((state) => ({
    castles: state.castles.data[chainId],
    userDataLoaded: state.castles.userDataLoaded[chainId],
  }));

  return useMemo(() => {
    return {
      pools: castles.map((castle) => transformPool(castle, prices, chainId)),
      userDataLoaded,
    };
  }, [castles, userDataLoaded, prices, chainId]);
};

export const useCastlesNoAccount = () => {
  const prices = usePrices();
  const chainId = useChainId();
  const pools = useSelector((state) => state.castles.data[chainId]);

  return useMemo(() => {
    return pools.map((castle) => transformPool(castle, prices, chainId));
  }, [pools, prices, chainId]);
};

export const useCastleFromPid = (sousId) => {
  const prices = usePrices();
  const chainId = useChainId();
  const pool = useSelector((state) => state.castles.data[chainId].find((p) => p.sousId === +sousId));

  if (!pool) return undefined;

  return transformPool(pool, prices, chainId);
};

export const useCastleUser = (sousId) => {
  const userData = useSelector((state) => state.castles.userData?.[sousId]);

  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    earnings: userData ? new BigNumber(userData.earnings) : BIG_ZERO,
    earningsTokenBalance: userData ? new BigNumber(userData.earningsTokenBalance) : BIG_ZERO,
    userDataLoaded: userData?.userDataLoaded || false,
  };
};
