import { ChainId } from 'ezcake-v2-sdk';
import { ethers } from 'ethers';

// ABI
import erc20Abi from 'config/abi/erc20.json';
import dragonAbi from 'config/abi/dragon.json';
import masterChef from 'config/abi/masterchef.json';
import masterChefHarmony from 'config/abi/masterchefHarmony.json';
import sousChefAbi from 'config/abi/sousChef.json';
import sousChefV2Abi from 'config/abi/sousChefV2.json';
import dragonBattleAbi from 'config/abi/dragonBattle.json';
import dragonBattleBossAbi from 'config/abi/dragonBattleBoss.json';
import lpContractAbi from 'config/abi/lpToken.json';
import routerAbi from 'config/abi/router.json';
import routerKAIAbi from 'config/abi/routerKAI.json';
import ido1Abi from 'config/abi/IDO1.json';
import idoLinear from 'config/abi/IDOLinear.json';
import lootDragon from 'config/abi/lootDragon.json';
import wrapLpAbi from 'config/abi/wrapLP.json';
import zapAbi from 'config/abi/zap.json';
import bridgeAbi from 'config/abi/bridge.json';
import simpleRpcProvider from 'utils/providers';

const getContract = (abi, address, signer, chainId = 24) => {
  if (!address) return null;

  const signerOrProvider = signer ?? simpleRpcProvider[chainId];
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getERC20Contract = (address, chainId, signer) => {
  if (!address) return null;
  return getContract(erc20Abi, address, signer, chainId);
};

export function getRouterContract(routerAddress, signer, chainId) {
  return getContract(chainId === ChainId.KAI ? routerKAIAbi : routerAbi, routerAddress, signer, chainId);
}

export function getZapContract(zapAddress, signer, chainId) {
  return getContract(zapAbi, zapAddress, signer, chainId);
}

export function getBridgeContract(bridgeAddress, signer, chainId) {
  return getContract(bridgeAbi, bridgeAddress, signer, chainId);
}

export const getDragonContract = (dragonAddress, signer, chainId) => {
  if (!dragonAddress) return null;

  return getContract(dragonAbi, dragonAddress, signer, chainId);
};

export const getWrapLPContract = (contractAddress, signer, chainId) => {
  if (!contractAddress) return null;

  return getContract(wrapLpAbi, contractAddress, signer, chainId);
};

export const getMasterChefContract = (masterChefAddress, chainId, signer) => {
  return getContract(chainId === ChainId.HARMONY ? masterChefHarmony : masterChef, masterChefAddress, signer, chainId);
};

export const getMasterChefContractWithAbi = (abi, address, signer, chainId) => {
  return getContract(abi, address, signer, chainId);
};

export const getSouschefContract = (address, signer, chainId) => {
  return getContract(sousChefAbi, address, signer, chainId);
};

export const getMintLootDragonContract = (address, signer, chainId) => {
  return getContract(lootDragon, address, signer, chainId);
};

export const getSouschefV2Contract = (address, signer, chainId) => {
  return getContract(sousChefV2Abi, address, signer, chainId);
};

export const getDragonBattleContract = (address, signer, chainId) => {
  return getContract(dragonBattleAbi, address, signer, chainId);
};

export const getDragonBattleBossContract = (address, signer, chainId) => {
  return getContract(dragonBattleBossAbi, address, signer, chainId);
};

export const getLpContract = (lpAddress, chainId, signer) => {
  return getContract(lpContractAbi, lpAddress, signer, chainId);
};

export const getIDOLaunchpadContract = (chainId, address, signer) => {
  return getContract(ido1Abi, address, signer, chainId);
};

export const getIDOLinearContract = (chainId, address, signer) => {
  return getContract(idoLinear, address, signer, chainId);
};
