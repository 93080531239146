import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useIDOV2Contract } from 'hooks/useContract';
import { showToastTx } from 'components/CustomToast/CustomToast';
import { useCallback } from 'react';
import { buyIDO } from 'utils/calls/IDOs';

export const useBuyIDO = (idoAddress) => {
  const { chainId } = useActiveWeb3React();
  const idoContract = useIDOV2Contract(idoAddress);

  const handleBuy = useCallback(
    async (amount, decimals) => {
      const txHash = await buyIDO(idoContract, amount, decimals);

      showToastTx(txHash, chainId);
      console.log(txHash);
    },
    [chainId, idoContract],
  );

  return { onBuy: handleBuy };
};
