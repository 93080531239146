import { ChainId } from 'ezcake-v2-sdk';

export const MCAKE_ADDRESS = {
  [ChainId.KAI]: '0x77F9149ba9353FE2d3dc6065ebfE5530885D3d3B',
  [ChainId.BSC]: '0xD77B2D571256ce5fB3365bfff3C5859D1Ef40f0a',
  [ChainId.BSC_TESTNET]: '0x0FE843f83A9808aFf8838C52A6740E90dab2c036',
  [ChainId.HARMONY]: '0x77F9149ba9353FE2d3dc6065ebfE5530885D3d3B',
  [ChainId.ONUS]: '0x77F9149ba9353FE2d3dc6065ebfE5530885D3d3B',
  [ChainId.ONUS_TESTNET]: '0x77F9149ba9353FE2d3dc6065ebfE5530885D3d3B',
};

export const BRIDGE_ANYSWAP = {
  [ChainId.BSC]: '0x5b531c46db853fd0fda4736ac013d7a25e8b1083',
};

export const MULTICALL_ADDRESS = {
  [ChainId.BSC_TESTNET]: '0xae11C5B5f29A6a25e955F0CB8ddCc416f522AF5C',
  [ChainId.BSC]: '0xa9193376D09C7f31283C54e56D013fCF370Cd9D9',
  [ChainId.KAI]: '0x95C7e4AA82381dB726d0303B12DA4A001548f6D4',
  [ChainId.HARMONY]: '0xdDCbf776dF3dE60163066A5ddDF2277cB445E0F3',
  [ChainId.ONUS]: '0x748C384F759Cc596f0D9Fa96dcaBE8a11e443b30',
  [ChainId.ONUS_TESTNET]: '0x9F34a0d179E3F081df3313300A8ED95DF1583774',
};

export const MULTICALL_V3_ADDRESS = {
  [ChainId.BSC]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.BSC_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.KAI]: '0x101a426fC25c9E8bd13ADE29eDD5713079Bf1F1b',
  [ChainId.HARMONY]: '0xBC854D076F68e3086a8D7C6477E92883Df9fff59',
  [ChainId.ONUS]: '0x812f87c4433B0D9C605dA07E0de9A918cB42C901',
};

export const MASTERCHEF_ADDRESS = {
  [ChainId.BSC]: '0x75A2145510b7CeefB812d5Afa1b20f94eC0BAf57',
  [ChainId.KAI]: '0x0245a1f57Ee84b55Cf489Eb5F3d27355014e57f8',
  [ChainId.HARMONY]: '0x3b441bf2522927BCf41c1c24786E7a8E9a56B234',
  [ChainId.ONUS]: '0x7d7BB9Cf3e7EDcEa12131a567174f14f5c45Ae91',
  [ChainId.BSC_TESTNET]: '0x99ab4f5f843b0cc5c2644490c500c661e428e981',
};

export const MASTERCHEF_ADDRESS_CAKE = {
  [ChainId.BSC]: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
};

export const MASTERCHEF_ADDRESS_MIA = {
  [ChainId.ONUS]: '0x891725887638D23f6aa282859d36DB4B0C001eFd',
};

export const REFRESH_ADDRESS = {
  [ChainId.KAI]: '0xCC76dDa470db8c19622eb034B6075Be6A1358313',
};

