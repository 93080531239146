import BigNumber from 'bignumber.js';
import { AVERAGE_BLOCK_TIME, BLOCK_PER_YEAR } from 'config/index';
import { TYPE_STRATEGY } from 'constants/vaults';
import { ChainId } from 'ezcake-v2-sdk';
import { BIG_TEN } from 'utils/bigNumber';

export function getFarmApr(totalAllocPoints, allocPoints, rewardsPerBlock, rewardPrice, stakedTvl, chainId) {
  const poolWeight = new BigNumber(allocPoints).div(totalAllocPoints);

  const userDailyRewards = new BigNumber(rewardsPerBlock)
    .times(new BigNumber(86400).div(AVERAGE_BLOCK_TIME[chainId]))
    .times(poolWeight);

  const rewardsPerWeek = rewardsPerBlock.times(604800).div(AVERAGE_BLOCK_TIME[chainId]);
  const poolRewardsPerWeek = poolWeight.times(rewardsPerWeek);
  const usdPerWeek = poolRewardsPerWeek.times(new BigNumber(rewardPrice));

  const weeklyAPR = usdPerWeek.div(new BigNumber(stakedTvl)).times(100);
  const monthlyAPR = weeklyAPR.times(30 / 7);
  const yearlyAPR = weeklyAPR.times(365 / 7);

  return {
    weeklyAPR: weeklyAPR.isNaN() || !weeklyAPR.isFinite() ? '0' : weeklyAPR.toFixed(2),
    yearlyAPR: yearlyAPR.isNaN() || !yearlyAPR.isFinite() ? '0' : yearlyAPR.toFixed(2),
    monthlyAPR: monthlyAPR.isNaN() || !monthlyAPR.isFinite() ? '0' : monthlyAPR.toFixed(2),
    userDailyRewards: userDailyRewards.isNaN() ? '0' : userDailyRewards.toJSON(),
  };
}

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @param decimalTokenEarning decimals of token reward
 * @param chainId chain id of network
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice,
  rewardTokenPrice,
  totalStaked,
  tokenPerBlock,
  decimalTokenEarning,
  chainId,
) => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice)
    .times(new BigNumber(tokenPerBlock).div(BIG_TEN.pow(decimalTokenEarning)))
    .times(BLOCK_PER_YEAR[chainId]);

  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked);
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();
};

export const getPoolAprV2 = (
  stakingTokenPrice,
  rewardTokensPrice,
  totalStaked,
  tokensPerBlock,
  earningTokens,
  chainId,
) => {
  const totalRewardsPricePerYear = earningTokens.reduce((acc, earningToken, index) => {
    const totalRewardPricePerYear = new BigNumber(rewardTokensPrice[index])
      .times(new BigNumber(tokensPerBlock[index]).div(BIG_TEN.pow(earningToken.decimals)))
      .times(BLOCK_PER_YEAR[chainId]);

    return acc.plus(totalRewardPricePerYear);
  }, new BigNumber(0));

  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked);
  const apr = totalRewardsPricePerYear.div(totalStakingTokenInPool).times(100);

  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();
};

export const getVaultApy = (aprYearly, fees, chainId) => {
  if (!aprYearly) return 0;

  const apr30Minutes = ((+aprYearly / 100) * ((100 - fees) / 100)) / 365 / 24 / 2;
  const aprFiveMinutes = ((+aprYearly / 100) * ((100 - fees) / 100)) / 365 / 24 / 12;

  const aprCal = chainId === ChainId.KAI ? aprFiveMinutes : apr30Minutes;
  const x = chainId === ChainId.KAI ? 288 : 48;

  const yearlyApy = (1 + aprCal) ** (x * 365.25) - 1;
  const monthly180Apy = (1 + aprCal) ** (x * 180) - 1;
  const monthly60Apy = (1 + aprCal) ** (x * 60) - 1;
  const monthlyApy = (1 + aprCal) ** (x * 30) - 1;
  const weeklyApy = (1 + aprCal) ** (x * 7) - 1;
  const dailyApy = (1 + aprCal) ** x - 1;

  return {
    yearlyApy: Number.isNaN(yearlyApy) ? 0 : yearlyApy * 100,
    monthly180Apy: Number.isNaN(monthly180Apy) ? 0 : monthly180Apy * 100,
    monthly60Apy: Number.isNaN(monthly60Apy) ? 0 : monthly60Apy * 100,
    monthlyApy: Number.isNaN(monthlyApy) ? 0 : monthlyApy * 100,
    weeklyApy: Number.isNaN(weeklyApy) ? 0 : weeklyApy * 100,
    dailyApy: Number.isNaN(dailyApy) ? 0 : dailyApy * 100,
  };
};

export const getVaultV2Apy = (aprWeekOfVault, aprWeekOfVaultStaked, feesVault, feesVaultStaked, type) => {
  if (!aprWeekOfVault || !aprWeekOfVaultStaked) return 0;

  const aprFiveMinutes = ((+aprWeekOfVault / 100) * ((100 - feesVault) / 100)) / 7 / 24 / 12;
  const aprX4 = ((+aprWeekOfVaultStaked / 100) * ((100 - feesVaultStaked) / 100)) / 7 / 24 / 12;

  let yearlyApy;
  let monthly180Apy;
  let monthly60Apy;
  let monthlyApy;
  let weeklyApy;
  let dailyApy;

  if (type === TYPE_STRATEGY.stratX4) {
    yearlyApy =
      (1 + aprFiveMinutes / 2) ** (288 * 365.25) +
      ((aprFiveMinutes / 2) * [(1 + aprX4) ** (288 * 365.25 + 1) - 1]) / aprX4 -
      1;

    monthly180Apy =
      (1 + aprFiveMinutes / 2) ** (288 * 180) +
      ((aprFiveMinutes / 2) * [(1 + aprX4) ** (288 * 180 + 1) - 1]) / aprX4 -
      1;

    monthly60Apy =
      (1 + aprFiveMinutes / 2) ** (288 * 60) + ((aprFiveMinutes / 2) * [(1 + aprX4) ** (288 * 60 + 1) - 1]) / aprX4 - 1;

    monthlyApy =
      (1 + aprFiveMinutes / 2) ** (288 * 30) + ((aprFiveMinutes / 2) * [(1 + aprX4) ** (288 * 30 + 1) - 1]) / aprX4 - 1;
    weeklyApy =
      (1 + aprFiveMinutes / 2) ** (288 * 7) + ((aprFiveMinutes / 2) * [(1 + aprX4) ** (288 * 7 + 1) - 1]) / aprX4 - 1;

    dailyApy = (1 + aprFiveMinutes / 2) ** 288 + ((aprFiveMinutes / 2) * [(1 + aprX4) ** (288 + 1) - 1]) / aprX4 - 1;
  } else if (type === TYPE_STRATEGY.strat100X4 || type === TYPE_STRATEGY.dragon) {
    yearlyApy = 1 + (aprFiveMinutes * [(1 + aprX4) ** (288 * 365.25 + 1) - 1]) / aprX4 - 1;

    monthly180Apy = 1 + (aprFiveMinutes * [(1 + aprX4) ** (288 * 180 + 1) - 1]) / aprX4 - 1;

    monthly60Apy = 1 + (aprFiveMinutes * [(1 + aprX4) ** (288 * 60 + 1) - 1]) / aprX4 - 1;

    monthlyApy = 1 + (aprFiveMinutes * [(1 + aprX4) ** (288 * 30 + 1) - 1]) / aprX4 - 1;

    weeklyApy = 1 + (aprFiveMinutes * [(1 + aprX4) ** (288 * 7 + 1) - 1]) / aprX4 - 1;

    dailyApy = 1 + (aprFiveMinutes * [(1 + aprX4) ** (288 + 1) - 1]) / aprX4 - 1;
  }

  return {
    yearlyApy: Number.isNaN(yearlyApy) ? 0 : yearlyApy * 100,
    monthlyApy: Number.isNaN(monthlyApy) ? 0 : monthlyApy * 100,
    monthly180Apy: Number.isNaN(monthly180Apy) ? 0 : monthly180Apy * 100,
    monthly60Apy: Number.isNaN(monthly60Apy) ? 0 : monthly60Apy * 100,
    weeklyApy: Number.isNaN(weeklyApy) ? 0 : weeklyApy * 100,
    dailyApy: Number.isNaN(dailyApy) ? 0 : dailyApy * 100,
  };
};
