import { parseBytes32String } from '@ethersproject/strings';
import { ChainId, NativeCurrency, Token } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useBytes32TokenContract, useTokenContract } from 'hooks/useContract';
import { useMemo } from 'react';
import { arrayify } from 'ethers/lib/utils';
import { NEVER_RELOAD, useSingleCallResult } from 'store/multicall/hooks';

import { isAddress } from 'utils';
import { lists } from 'constants/lists';
import { useUserAddedTokens } from 'store/user/hooks';
import {useChainId, useReadContracts} from "wagmi";
import {ERC20_ABI} from "../config/abi/erc20";

export class WrappedTokenInfo extends Token {
  constructor(tokenInfo) {
    super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name);
    this.tokenInfo = tokenInfo;
    this.isWhiteListed = true;
  }

  logoURI() {
    return `/tokens/${this.tokenInfo.symbol.toLowerCase()}.png`;
  }
}

/**
 * An empty result, useful as a default.
 */
const EMPTY_LIST = {
  [ChainId.KAI]: {},
  [ChainId.BSC]: {},
  [ChainId.BSC_TESTNET]: {},
  [ChainId.HARMONY]: {},
  [ChainId.ONUS]: {},
  [ChainId.ONUS_TESTNET]: {},
};

export function listToTokenMap() {
  const map = lists.reduce(
    (tokenMap, tokenInfo) => {
      const token = new WrappedTokenInfo(tokenInfo);
      if (tokenMap[token.chainId][token.address] !== undefined) throw Error('Duplicate tokens.');
      return {
        ...tokenMap,
        [token.chainId]: {
          ...tokenMap[token.chainId],
          [token.address]: token,
        },
      };
    },
    { ...EMPTY_LIST },
  );
  return map;
}

export function useTokenList() {
  const userAddedTokens = useUserAddedTokens();

  return useMemo(() => {
    try {
      if (userAddedTokens.length > 0) {
        const listMap = listToTokenMap();
        userAddedTokens.forEach((token) => {
          if (token.chainId in listMap) {
            const tokenWrapped = new WrappedTokenInfo(token);
            listMap[tokenWrapped.chainId][tokenWrapped.address] = tokenWrapped;
          }
        });
        return listMap;
      } else {
        return listToTokenMap();
      }
    } catch (error) {
      console.error('Could not show token list due to error', error);
      return EMPTY_LIST;
    }
  }, [userAddedTokens]);
}

export function useAllTokens() {
  const chainId = useChainId()
  const allTokens = useTokenList();

  return useMemo(() => {
    return allTokens[chainId];
  }, [allTokens, chainId]);
}

export function useIsLoadedTokenDefault() {
  const defaultTokens = useAllTokens();
  const tokenImports = useUserAddedTokens();
  return Object.keys(defaultTokens).length > tokenImports.length;
}

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/;

function parseStringOrBytes32(str, bytes32, defaultValue) {
  return str && str.length > 0
    ? str
    : // need to check for proper bytes string and valid terminator
    bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0
    ? parseBytes32String(bytes32)
    : defaultValue;
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress) {
  const chainId = useChainId()
  const tokens = useAllTokens();

  const address = isAddress(tokenAddress);

  const tokenContract = useTokenContract(address || undefined, false);
  const tokenContractBytes32 = useBytes32TokenContract(address || undefined, false);

  const token = address ? tokens[address] : undefined;

  const tokenName = useSingleCallResult(token ? undefined : tokenContract, 'name', undefined, NEVER_RELOAD);
  const tokenNameBytes32 = useSingleCallResult(
    token ? undefined : tokenContractBytes32,
    'name',
    undefined,
    NEVER_RELOAD,
  );

  const symbol = useSingleCallResult(token ? undefined : tokenContract, 'symbol', undefined, NEVER_RELOAD);
  const symbolBytes32 = useSingleCallResult(
    token ? undefined : tokenContractBytes32,
    'symbol',
    undefined,
    NEVER_RELOAD,
  );
  const decimals = useSingleCallResult(token ? undefined : tokenContract, 'decimals', undefined, NEVER_RELOAD);

  return useMemo(() => {
    if (token) return token;
    if (!chainId || !address) return undefined;
    if (decimals.loading || symbol.loading || tokenName.loading) return null;
    if (decimals.result) {
      return new Token(
        chainId,
        address,
        decimals.result[0],
        parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
        parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token'),
      );
    }
    return undefined;
  }, [
    address,
    chainId,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    token,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
  ]);
}

export function useCurrency(currencyId) {
  const chainId = useChainId()
  const isBNB = currencyId?.toUpperCase() === NativeCurrency[chainId]?.symbol?.toUpperCase();
  const token = useToken(isBNB ? undefined : currencyId);
  return isBNB ? NativeCurrency[chainId] : token;
}
