import AppHeader from 'components/AppHeader/AppHeader';
import Card from 'components/Card/Card';
import CurrencyLogo from 'components/LogoSwap/CurrencyLogo';
import DoubleCurrencyLogo from 'components/LogoSwap/DoubleLogo';
import { MinimalPositionCard } from 'components/PositionCard/PositionCard';
import Slider from 'components/Slider/Slider';
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal/index';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import { ROUTER_ADDRESS } from 'constants/swap';
import { useCurrency } from 'hooks/Tokens';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler';
import { useCallback, useMemo, useState } from 'react';
import { ChainId, currencyEquals, NativeCurrency, Percent, WETH } from 'ezcake-v2-sdk';
import { BigNumber } from 'ethers';
import CurrencyInputPanel from 'components/CurrencyInputPanel';
import { FiArrowDown, FiPlus } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field } from 'store/burn/actions';
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from 'store/burn/hooks';
import { useTransactionAdder } from 'store/transactions/hooks';
import { useUserDeadline, useUserSlippageTolerance } from 'store/user/hooks/index';
import currencyId from 'utils/currencyId';
import {calculateGasMargin, calculateSlippageAmount, isZero} from 'utils/index';
import { wrappedCurrency } from 'utils/wrappedCurrency';
import { getRouterContract } from 'utils/contractHelpers';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {useAccount, useChainId, useConfig} from "wagmi";
import {useRouterContract} from "../../hooks/useContract";
import {estimateGas, writeContract} from "@wagmi/core";

export default function RemoveLiquidity() {
  const { currencyIdA, currencyIdB } = useParams();
  const navigate = useNavigate();
  const chainId = useChainId()
  const config = useConfig()
  const {address} = useAccount()
  const routerContract = useRouterContract()

  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined];
  const [tokenA, tokenB] = useMemo(
    () => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)],
    [currencyA, currencyB, chainId],
  );

  // burn state
  const { independentField, typedValue } = useBurnState();
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined);
  const { onUserInput: _onUserInput } = useBurnActionHandlers();
  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDetailed, setShowDetailed] = useState(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState('');
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  };

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'));

  // allowance handling
  const [signatureData, setSignatureData] = useState(null);
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], ROUTER_ADDRESS[chainId]);

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field, value) => {
      setSignatureData(null);
      return _onUserInput(field, value);
    },
    [_onUserInput],
  );

  const onLiquidityInput = useCallback((value) => onUserInput(Field.LIQUIDITY, value), [onUserInput]);
  const onCurrencyAInput = useCallback((value) => onUserInput(Field.CURRENCY_A, value), [onUserInput]);
  const onCurrencyBInput = useCallback((value) => onUserInput(Field.CURRENCY_B, value), [onUserInput]);

  // tx sending
  const addTransaction = useTransactionAdder();

  async function onRemove() {
    if (!chainId || !address || !deadline) throw new Error('missing dependencies');
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts;
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts');
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    };

    if (!currencyA || !currencyB) throw new Error('missing tokens');
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error('missing liquidity amount');

    const currencyBIsETH = currencyB === NativeCurrency[chainId];
    const oneCurrencyIsETH = currencyA === NativeCurrency[chainId] || currencyBIsETH;

    if (!tokenA || !tokenB) throw new Error('could not wrap');

    let methodNames;
    let args;
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens'];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          address,
          `0x${(Math.floor(new Date().getTime() / 1000) + deadline).toString(16)}`,
        ];
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity'];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          address,
          `0x${(Math.floor(new Date().getTime() / 1000) + deadline).toString(16)}`,
        ];
      }
    }
    // we have a signataure, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityKAIWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens'];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          address,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit'];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          address,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ];
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.');
    }

    const safeGasEstimates = await Promise.all(
      methodNames.map((methodName) =>
          estimateGas(config, {
            chainId,
            from: address,
            to: routerContract.address,
            data: routerContract.interface.encodeFunctionData(methodName, args),
          })
          .then((value)=> {
            return calculateGasMargin(BigNumber.from(value))
          })
          .catch((err) => {
            console.error(`estimateGas failed`, methodName, args, err);
            return undefined;
          }),
      ),
    );

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate),
    );

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.');
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation];
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

      setAttemptingTxn(true);

      await writeContract(config, {
        chainId,
        abi: routerContract.abi,
        address: routerContract.address,
        functionName: methodName,
        args,
        gasLimit: calculateGasMargin(safeGasEstimate),
      })        .then((response) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencyA?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
          });

          setTxHash(response);
        })
        .catch((err) => {
          setAttemptingTxn(false);
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(err);
        });
    }
  }

  function modalHeader() {
    return (
      <div>
        <div className="flex justify-between items-center text-2xl">
          <p className="font-bold">{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</p>
          <div className="flex items-center">
            <CurrencyLogo currency={currencyA} />
            <p className="font-bold ml-2">{currencyA?.symbol}</p>
          </div>
        </div>
        <div>
          <FiPlus size={16} className="my-2" />
        </div>
        <div className="flex justify-between items-center text-2xl">
          <p className="font-bold">{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</p>
          <div className="flex items-center">
            <CurrencyLogo currency={currencyB} />
            <p className="font-bold ml-2">{currencyB?.symbol}</p>
          </div>
        </div>
        <p className="text-md mt-2 mb-4 italic">
          Output is estimated. If the price changes by more than {allowedSlippage / 100} your transaction will revert.
        </p>
      </div>
    );
  }

  function modalBottom() {
    return (
      <>
        <div className="flex justify-between items-center">
          {currencyA?.symbol ?? ''}/{currencyB?.symbol ?? ''} Burned
          <div className="flex justify-between items-center">
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin />
            <p className="font-bold text-xl">{parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}</p>
          </div>
        </div>
        {pair && (
          <div className="flex justify-between mt-2">
            <p>Price</p>
            <div className="font-bold">
              <p className="text-right">
                1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </p>
              <p className="text-right">
                1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </p>
            </div>
          </div>
        )}
        <ButtonCustom
          className="w-full mt-4"
          disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)}
          onClick={onRemove}
        >
          Confirm
        </ButtonCustom>
      </>
    );
  }

  const pendingText = useMemo(() => {
    const amountA = parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '';
    const symbolA = currencyA?.symbol ?? '';
    const amountB = parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '';
    const symbolB = currencyB?.symbol ?? '';

    return `Removing ${amountA} ${symbolA} and ${amountB} ${symbolB}`;
  }, [currencyA?.symbol, currencyB?.symbol, parsedAmounts]);

  const liquidityPercentChangeCallback = useCallback(
    (value) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString());
    },
    [onUserInput],
  );

  const oneCurrencyIsETH = currencyA === NativeCurrency[chainId] || currencyB === NativeCurrency[chainId];
  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(WETH[chainId], currencyA)) ||
        (currencyB && currencyEquals(WETH[chainId], currencyB))),
  );

  const handleSelectCurrencyA = useCallback(
    (currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        navigate(`/remove/${currencyId(currency)}/${currencyIdA}`);
      } else {
        navigate(`/remove/${currencyId(currency)}/${currencyIdB}`);
      }
    },
    [currencyIdA, currencyIdB],
  );
  const handleSelectCurrencyB = useCallback(
    (currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        navigate(`/remove/${currencyIdB}/${currencyId(currency)}`);
      } else {
        navigate(`/remove/${currencyIdA}/${currencyId(currency)}`);
      }
    },
    [currencyIdA, currencyIdB],
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0');
    }
    setTxHash('');
  }, [onUserInput, txHash]);

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback,
  );

  return (
    <>
      <TransactionConfirmationModal
        open={showConfirm}
        title="You will receive"
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash || ''}
        content={() => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />}
        pendingText={pendingText}
      />
      <Card className="max-w-md mx-auto p-5 border-2 border-primary">
        <AppHeader backTo="/liquidity" title="Remove Liquidity" />
        <Card color="primary" className="p-2">
          <p>
            <strong>Tip:</strong> Removing pool tokens converts your position back into underlying tokens at the current
            rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.
          </p>
        </Card>
        <div className="flex items-center justify-between my-4">
          <p className="text-white">Remove Amount</p>
          <ButtonCustom onClick={() => setShowDetailed(!showDetailed)}>
            {showDetailed ? 'Simple' : 'Detailed'}
          </ButtonCustom>
        </div>
        {!showDetailed && (
          <Card className="bg-black1 p-2">
            <p className="text-6xl font-bold text-white" style={{ lineHeight: 1 }}>
              {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
            </p>
            <div className="mx-2">
              <Slider
                name="lp-amount"
                min={0}
                max={100}
                value={innerLiquidityPercentage}
                onValueChanged={(value) => setInnerLiquidityPercentage(Math.ceil(value))}
                mb="16px"
              />
            </div>
            <div className="flex flex-wrap justify-evenly">
              <ButtonCustom onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}>25%</ButtonCustom>
              <ButtonCustom onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}>50%</ButtonCustom>
              <ButtonCustom onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}>75%</ButtonCustom>
              <ButtonCustom onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}>Max</ButtonCustom>
            </div>
          </Card>
        )}
        {!showDetailed && (
          <>
            <FiArrowDown className="mx-auto text-white my-3" />
            <div className="text-white">
              <p className="font-bold uppercase mb-1">You will receive</p>
              <Card className="p-2 bg-black1">
                <div className="flex justify-between mb-2">
                  <p>{formattedAmounts[Field.CURRENCY_A] || '-'}</p>
                  <div className="flex items-center">
                    <CurrencyLogo currency={currencyA} />
                    <p id="remove-liquidity-tokena-symbol" className="ml-2">
                      {currencyA?.symbol}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p>{formattedAmounts[Field.CURRENCY_B] || '-'}</p>
                  <div className="flex items-center">
                    <CurrencyLogo currency={currencyB} />
                    <p id="remove-liquidity-tokenb-symbol" className="ml-2">
                      {currencyB?.symbol}
                    </p>
                  </div>
                </div>
                {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                  <div style={{ justifyContent: 'flex-end', fontSize: '14px' }}>
                    {oneCurrencyIsETH ? (
                      <button className="text-sm ml-1 bg-primary rounded px-1.5 py-px text-black">
                        <Link
                          to={`/remove/${currencyA === NativeCurrency[chainId] ? WETH[chainId].address : currencyIdA}/${
                            currencyB === NativeCurrency[chainId] ? WETH[chainId].address : currencyIdB
                          }`}
                        >
                          Receive {WETH[chainId]?.symbol}
                        </Link>
                      </button>
                    ) : oneCurrencyIsWETH ? (
                      <button className="text-sm bg-primary rounded px-1.5 py-px text-black">
                        <Link
                          to={`/remove/${
                            currencyA && currencyEquals(currencyA, WETH[chainId])
                              ? NativeCurrency[chainId].symbol
                              : currencyIdA
                          }/${
                            currencyB && currencyEquals(currencyB, WETH[chainId])
                              ? NativeCurrency[chainId].symbol
                              : currencyIdB
                          }`}
                        >
                          Receive {NativeCurrency[chainId]?.symbol}
                        </Link>
                      </button>
                    ) : null}
                  </div>
                ) : null}
              </Card>
            </div>
          </>
        )}
        {showDetailed && (
          <div>
            <CurrencyInputPanel
              value={formattedAmounts[Field.LIQUIDITY]}
              onUserInput={onLiquidityInput}
              onMax={() => {
                onUserInput(Field.LIQUIDITY_PERCENT, '100');
              }}
              showMaxButton={!atMaxAmount}
              disableCurrencySelect
              currency={pair?.liquidityToken}
              pair={pair}
              id="liquidity-amount"
              onCurrencySelect={() => null}
            />
            <FiArrowDown className="mx-auto text-white my-3" />
            <CurrencyInputPanel
              hideBalance
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onCurrencyAInput}
              onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
              showMaxButton={!atMaxAmount}
              currency={currencyA}
              label="Output"
              onCurrencySelect={handleSelectCurrencyA}
              id="remove-liquidity-tokena"
              showUSDT
            />
            <FiPlus className="mx-auto text-white my-3" />
            <CurrencyInputPanel
              hideBalance
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onCurrencyBInput}
              onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
              showMaxButton={!atMaxAmount}
              currency={currencyB}
              label="Output"
              onCurrencySelect={handleSelectCurrencyB}
              id="remove-liquidity-tokenb"
              showUSDT
            />
          </div>
        )}
        {pair && (
          <div className="text-white flex justify-between" style={{ marginTop: '16px' }}>
            <p className="font-bold uppercase">Prices</p>
            <div>
              <div className="flex justify-end">
                <p>1 {currencyA?.symbol} =</p>
                <p>
                  {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                </p>
              </div>
              <div className="flex justify-end">
                <p>1 {currencyB?.symbol} =</p>
                <p>
                  {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="relative">
          {!address ? (
            <UnlockButton className="mt-4" />
          ) : (
            <div className="flex justify-between mt-4">
              <ButtonCustom
                style={{
                  width: '48%',
                }}
                className="w-full"
                onClick={approveCallback}
                disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                isLoading={approval === ApprovalState.PENDING}
              >
                {approval === ApprovalState.PENDING
                  ? 'Approving...'
                  : approval === ApprovalState.APPROVED || signatureData !== null
                  ? 'Approved'
                  : 'Approve'}
              </ButtonCustom>
              <ButtonCustom
                style={{
                  width: '48%',
                }}
                className="w-full"
                color={
                  !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                    ? 'danger'
                    : 'primary'
                }
                onClick={() => {
                  setShowConfirm(true);
                }}
                disabled={!isValid || (signatureData === null && approval !== ApprovalState.APPROVED)}
              >
                {error || 'Remove'}
              </ButtonCustom>
            </div>
          )}
        </div>
      </Card>
      {pair ? (
        <div className="mx-auto" style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
        </div>
      ) : null}
    </>
  );
}
