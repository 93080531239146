import BigNumber from 'bignumber.js';
import { ChainId } from 'ezcake-v2-sdk';
import { getPoolApr, getPoolAprV2 } from 'utils/apr';
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber';
import { getBalanceNumber } from 'utils/formatBalance';
import { getParameterCaseInsensitive } from 'utils/index';

export const transformUserData = (pool, userData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    earnings: userData
      ? userData?.earnings?.map((earning) => new BigNumber(earning))
      : pool?.earningTokens?.map(() => BIG_ZERO),
    lastStakingBlock: userData ? +userData.lastStakingBlock : 0,
  };
};

export const transformPool = (pool, prices, chainId) => {
  const { totalStaked, stakingLimit, poolLimit, ...rest } = pool;
  const stakingTokenPrice =
    getParameterCaseInsensitive(
      prices,
      pool.stakingTokenGetPrice ? pool.stakingTokenGetPrice.address[chainId] : pool.stakingToken.address[chainId],
    ) || 0;

  let apr;
  let stakedTvl;

  if (pool.isV2) {
    const { tokensPerBlock } = pool;
    const earningTokensPrice = pool.earningTokens.map(
      (earningToken) => getParameterCaseInsensitive(prices, earningToken.address[chainId]) || 0,
    );

    apr = !pool.isFinished
      ? getPoolAprV2(
          stakingTokenPrice,
          earningTokensPrice,
          getBalanceNumber(new BigNumber(totalStaked), pool.stakingToken.decimals),
          tokensPerBlock,
          pool.earningTokens,
          chainId,
        )
      : 0;

    stakedTvl = new BigNumber(new BigNumber(totalStaked).div(BIG_TEN.pow(pool.stakingToken.decimals)))
        .times(new BigNumber(stakingTokenPrice))
        .toJSON();
    return {
      ...rest,
      apr,
      stakedTvl,
      stakingTokenPrice,
      earningTokensPrice,
      totalStaked: new BigNumber(totalStaked),
      stakingLimit: new BigNumber(stakingLimit),
      poolLimit: new BigNumber(poolLimit),
      userData: transformUserData(pool, pool.userData),
    };
  }
  const { tokenPerBlock } = pool;
  const earningTokenPrice = getParameterCaseInsensitive(prices, pool.earningToken.address[chainId]) || 0;

  apr = !pool.isFinished
    ? getPoolApr(
        stakingTokenPrice,
        earningTokenPrice,
        getBalanceNumber(new BigNumber(totalStaked), pool.stakingToken.decimals),
        parseFloat(tokenPerBlock),
        pool.earningToken.decimals,
        chainId,
      )
    : 0;

  stakedTvl = new BigNumber(new BigNumber(totalStaked).div(BIG_TEN.pow(pool.stakingToken.decimals)))
    .times(new BigNumber(stakingTokenPrice))
    .toJSON();

  return {
    ...rest,
    apr,
    stakedTvl,
    stakingTokenPrice,
    earningTokenPrice,
    totalStaked: new BigNumber(totalStaked),
    stakingLimit: new BigNumber(stakingLimit),
    poolLimit: new BigNumber(poolLimit),
    userData: transformUserData(pool, pool.userData),
  };
};
