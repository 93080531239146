import { ethers } from 'ethers';
import { MULTICALL_ADDRESS } from 'constants/contracts';

import multicallAbi from 'config/abi/multicall.json';
import simpleRpcProvider from 'utils/providers';

const multicall = async (abi, calls, chainId = 24) => {
  try {
    const provider = simpleRpcProvider[chainId];

    const multi = new ethers.Contract(MULTICALL_ADDRESS[chainId], multicallAbi, provider);

    const itf = new ethers.utils.Interface(abi);

    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)]);

    const { returnData } = await multi.aggregate(calldata);

    const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call));

    return res;
  } catch (e) {
    console.log('chainId');
    console.log(chainId);
    console.log('calls');
    console.log(calls);
    console.log('multicall');
    console.log(e);
  }
};

export default multicall;
