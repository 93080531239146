import { Fragment, memo } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { unwrappedToken } from 'utils/wrappedCurrency';

function SwapRoute({ trade }) {
  return (
    <div className="flex items-center justify-center flex-wrap w-full">
      {trade.route.path.map((token, i, path) => {
        const isLastItem = i === path.length - 1;
        const currency = unwrappedToken(token);
        return (
          <Fragment key={i}>
            <div className="flex">
              <p>{currency.symbol}</p>
            </div>
            {!isLastItem && <FiChevronRight size={12} />}
          </Fragment>
        );
      })}
    </div>
  );
}

export default memo(SwapRoute);
