import { classNames } from 'utils/styling';
import { MASTERCHEF_ADDRESS } from 'constants/contracts';
import tokens from 'constants/tokens';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { Fragment, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useExpanded, useTable } from 'react-table';
import { fetchFarmUserDataAsync } from 'store/farms/index';
import ActionPanel from 'views/Farms/components/FarmsTable/ActionPanel/ActionPanel';
import AprCell from 'views/Farms/components/FarmsTable/Cells/AprCell';
import AssetsCell from 'views/Farms/components/FarmsTable/Cells/AssetsCell';
import EarnedCell from 'views/Farms/components/FarmsTable/Cells/EarnedCell';
import RewardCell from 'views/Farms/components/FarmsTable/Cells/RewardCell';
import ToggleShowCell from 'views/Farms/components/FarmsTable/Cells/ToggleShowCell';
import TvlCell from 'views/Farms/components/FarmsTable/Cells/TvlCell';
import YourStakedCell from 'views/Farms/components/FarmsTable/Cells/YourStakedCell';

const FarmsTable = ({ farms, userDataLoaded }) => {
  const dispatch = useDispatch();
  const { chainId, account } = useActiveWeb3React();

  const columns = useMemo(
    () => [
      {
        id: 'asset',
        Header: 'asset',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <AssetsCell farm={row.original} />,
      },
      {
        id: 'staked',
        Header: 'staked',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <YourStakedCell farm={row.original} userDataLoaded={userDataLoaded} />,
      },
      {
        id: 'earned',
        Header: 'earned',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <EarnedCell farm={row.original} userDataLoaded={userDataLoaded} />,
      },
      {
        id: 'apr',
        Header: 'apr',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row, data }) => {
          return <AprCell farm={row.original} earningTokenSymbol={tokens.mCake.symbol} farms={data} />;
        },
      },
      {
        id: 'tvl',
        Header: 'tvl',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <TvlCell farm={row.original} />,
      },
      {
        id: 'reward',
        Header: 'reward',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <RewardCell earningToken={tokens.mCake} farm={row.original} />,
      },
      {
        id: 'expander',
        Header: 'expander',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <ToggleShowCell row={row} />,
      },
    ],
    [userDataLoaded],
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data: farms,
      autoResetExpanded: false,
    },
    useExpanded,
  );

  const handleUpdateUserFarm = () => {
    dispatch(fetchFarmUserDataAsync(account, chainId));
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      return (
        <ActionPanel
          farm={row.original}
          userDataLoaded={userDataLoaded}
          earningToken={tokens.mCake}
          masterChefAddress={MASTERCHEF_ADDRESS[chainId]}
          onUpdateUserFarm={handleUpdateUserFarm}
        />
      );
    },
    [chainId, handleUpdateUserFarm, userDataLoaded],
  );

  return (
    <div className="mt-4">
      <table className="min-w-full" {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={i}>
                  <tr className="bg-black1" {...row.getRowProps()} {...row.getToggleRowExpandedProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          colSpan={cell.column.id === 'expander' ? 1 : 0}
                          className={classNames(
                            'px-1 sm:px-4 py-2 sm:px-6 sm:py-4 sm:text-md text-white whitespace-nowrap',
                            cell.column.id === 'apr' && 'align-top',
                            cell.column.id === 'earned' && 'align-top hidden sm:table-cell',
                            cell.column.id === 'tvl' && 'align-top hidden md:table-cell',
                            cell.column.id === 'reward' && 'align-top hidden lg:table-cell',
                            cell.column.id === 'staked' && 'hidden lg:table-cell align-top',
                            index === 0 && 'rounded-l-md',
                            index === row.cells.length - 1 && 'rounded-r-md',
                          )}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr className="bg-black1">
                      <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                    </tr>
                  )}
                  <tr className="bg-transparent	">
                    <td className="py-2" colSpan={visibleColumns.length} />
                  </tr>
                </Fragment>
              );
            })
          ) : (
            <tr className="text-white text-center">
              <td className="py-2" colSpan={99999}>
                <p className="text-white text-center">No Data</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FarmsTable;
