import { currencyEquals, NativeCurrency, WETH } from 'ezcake-v2-sdk';
import { useMemo, useState } from 'react';
import { tryParseAmount } from 'store/swap/hooks';
import { useTransactionAdder } from 'store/transactions/hooks';
import { useCurrencyBalance } from 'hooks/wallet';
import { useWETHContract } from './useContract';
import {useAccount, useChainId, useConfig} from "wagmi";
import {waitForTransactionReceipt, writeContract} from "@wagmi/core";
import {isZero} from "../utils";

export const WrapType = {
  NOT_APPLICABLE: 0,
  WRAP: 1,
  UNWRAP: 2,
};

export const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE };
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(inputCurrency, outputCurrency, typedValue) {
  const [pendingTx, setPendingTx] = useState(false);
  const chainId = useChainId()
  const config = useConfig()
  const {address} = useAccount()
  const wethContract = useWETHContract();
  const balance = useCurrencyBalance(address ?? undefined, inputCurrency);
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency), [inputCurrency, typedValue]);
  const addTransaction = useTransactionAdder();

  return useMemo(() => {
    if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE;

    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount);

    if (inputCurrency === NativeCurrency[chainId] && currencyEquals(WETH[chainId], outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  setPendingTx(true);
                  const txReceipt = await writeContract(config, {
                    chainId,
                    abi: wethContract.abi,
                    address: wethContract.address,
                    functionName: 'deposit',
                    value: `0x${inputAmount.raw.toString(16)}`
                  });

                  await waitForTransactionReceipt(config, {hash: txReceipt})

                  setPendingTx(false);
                  addTransaction(txReceipt, {
                    summary: `Wrap ${inputAmount.toSignificant(6)} ${NativeCurrency[chainId].symbol} to ${
                      WETH[chainId].symbol
                    }`,
                  });
                } catch (error) {
                  setPendingTx(false);
                  console.error('Could not deposit', error);
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : `Insufficient ${NativeCurrency[chainId].symbol} balance`,
        pendingTx,
      };
    }
    if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === NativeCurrency[chainId]) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  setPendingTx(true);
                  const txReceipt = await writeContract(config, {
                    chainId,
                    abi: wethContract.abi,
                    address: wethContract.address,
                    functionName: 'withdraw',
                    args: [`0x${inputAmount.raw.toString(16)}`]
                  });

                  await waitForTransactionReceipt(config, {hash: txReceipt})

                  setPendingTx(false);
                  addTransaction(txReceipt, {
                    summary: `Unwrap ${inputAmount.toSignificant(6)} ${WETH[chainId].symbol} to ${
                      NativeCurrency[chainId].symbol
                    }`,
                  });
                } catch (error) {
                  setPendingTx(false);
                  console.error('Could not withdraw', error);
                }
              }
            : undefined,
        inputError: sufficientBalance ? undefined : `Insufficient ${WETH[chainId].symbol} balance`,
        pendingTx,
      };
    }
    return NOT_APPLICABLE;
  }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance, pendingTx, config, addTransaction]);
}
