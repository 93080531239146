import { Provider } from 'react-redux';
import MulticallUpdater from 'store/multicall/updater';
import TransactionUpdater from 'store/transactions/updater';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'
import { wagmiAdapter} from './connectors/config'

import PropTypes from 'prop-types';

import { RefreshContextProvider } from 'contexts/RefreshContext';
import store from 'store';

function Updaters() {
  return (
    <>
      <MulticallUpdater />
      <TransactionUpdater />
    </>
  );
}

const queryClient = new QueryClient()

const Providers = ({ children }) => {
  return (
      <WagmiProvider config={wagmiAdapter.wagmiConfig}>
          <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                  <RefreshContextProvider>
                      <Updaters/>
                      {children}
                  </RefreshContextProvider>
              </Provider>
          </QueryClientProvider>
      </WagmiProvider>
  );
};

Providers.propTypes = {
  children: PropTypes.node,
};

export default Providers;
