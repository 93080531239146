import BigNumber from 'bignumber.js';
import { DEFAULT_TOKEN_DECIMAL } from 'config/index';
import { getPoolPrices } from 'store/farms/helpers';
import { getFarmApr } from 'utils/apr';
import { BIG_TEN } from 'utils/bigNumber';
import { getParameterCaseInsensitive } from 'utils/index';
import multicall from 'utils/multicall';
import masterChefAbi from 'config/abi/masterchef.json';
import erc20 from 'config/abi/erc20.json';
import UNIV2PairAbi from 'config/abi/lpToken.json';

export const getFarms = async (farmsToFetch, masterChefAddress, pricesFetch = {}, earningToken, chainId) => {
  let prices = {
    ...pricesFetch,
  };

  const [[totalAllocPointsRes], [rewardsPerBlockRes]] = await multicall(
    masterChefAbi,
    [
      {
        address: masterChefAddress,
        name: 'totalAllocPoint',
      },
      {
        address: masterChefAddress,
        name: 'rewardPerBlock',
      },
    ],
    chainId,
  );

  const totalAllocPoints = new BigNumber(totalAllocPointsRes._hex);

  const rewardsPerBlock = new BigNumber(rewardsPerBlockRes._hex).div(BIG_TEN.pow(earningToken.decimals));

  const callsTotalSupply = farmsToFetch.map((farm) => ({
    address: farm.stakingToken.address[chainId],
    name: 'totalSupply',
  }));

  const callsBalance = farmsToFetch.map((farm) => ({
    address: farm.stakingToken.address[chainId],
    name: 'balanceOf',
    params: [masterChefAddress],
  }));

  const farmsLp = farmsToFetch.filter((farmsToFetch) => farmsToFetch?.token1);

  const callsReserves = farmsLp.map((farm) => ({
    address: farm.stakingToken.address[chainId],
    name: 'getReserves',
  }));

  const promise = [
    multicall(erc20, callsTotalSupply, chainId),
    multicall(erc20, callsBalance, chainId),
    multicall(UNIV2PairAbi, callsReserves, chainId),
  ];

  const response = await Promise.all(promise);

  const totalSupplys = response?.[0];
  const stakedBalance = response?.[1];
  const totalReserves = response?.[2];

  const parsReservesLp = farmsLp.map((farm, index) => ({
    ...farm,
    q0: totalReserves[index]._reserve0._hex,
    q1: totalReserves[index]._reserve1._hex,
  }));

  const poolInfosFetch = farmsToFetch.map((farm, index) => {
    if (farm.token1) {
      const farmLp = parsReservesLp.find((farmLp) => farm.pid === farmLp.pid);
      return {
        ...farmLp,
        ...farm,
        totalSupply: new BigNumber(totalSupplys[index]).div(DEFAULT_TOKEN_DECIMAL).toJSON(),
        staked: new BigNumber(stakedBalance[index]).div(DEFAULT_TOKEN_DECIMAL).toJSON(),
      };
    }

    return {
      ...farm,
      decimals: farm.token0.decimals,
      address: farm.token0.address[chainId],
      tokens: [farm.token0.address[chainId]],
      totalSupply: new BigNumber(totalSupplys[index]).div(BIG_TEN.pow(farm.token0.decimals)).toJSON(),
      staked: new BigNumber(stakedBalance[index]).div(BIG_TEN.pow(farm.token0.decimals)).toJSON(),
    };
  });

  const poolPrices = poolInfosFetch.map((poolInfo) => {
    return {
      ...getPoolPrices(prices, poolInfo, chainId),
      ...poolInfo,
    };
  });

  const earningTokenPrice = getParameterCaseInsensitive(prices, earningToken.address[chainId]);

  const farmsInfo = poolPrices.map((farm) => {
    let stakedTvl = Number.isNaN(farm?.stakedTvl) ? 0 : farm?.stakedTvl ?? 0;

    if (farm.price) {
      prices[farm.lpAddress[chainId]] = farm.price;
    }

    const apr = getFarmApr(totalAllocPoints, farm.allocPoint, rewardsPerBlock, earningTokenPrice, stakedTvl, chainId);

    return {
      apr,
      mul: farm.allocPoint / 100,
      ...farm,
      stakedTvl,
      earningTokenPrice: earningTokenPrice,
      earningToken,
    };
  });

  return {
    prices,
    farms: farmsInfo,
  };
};

export const getLpBreakdown = (userStaked, totalSupply, price0, price1) => {
  const userPct = userStaked / totalSupply;
  const q0user = userPct * price0;
  const q1user = userPct * price1;

  return [q0user, q1user];
};
