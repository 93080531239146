import MenuMobile from 'components/Header/MenuMobile';
import Web3Connect from 'components/Web3Connect/Web3Connect';
import {useAccount} from "wagmi";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import {useAppKit} from "@reown/appkit/react";
import {shortenAddress} from "../../utils";
import Web3Network from "../Web3Network/Web3Network";

function Web3StatusInner() {
    const account = useAccount()
    const { open } = useAppKit()

    function handleOpen(){
        try{
            open({ view: 'Account' })
        } catch(_e){
            open({ view: 'Account' })
        }
    }

    if (account.address) {
    return (
      <div className="flex items-center  justify-center">
        {/*<EstimateBalance />*/}
        <div className="flex items-center gap-2">
            <Web3Network />
          <ButtonCustom
              size="sm" className="font-bold text-sm-md"
              onClick={handleOpen}>{shortenAddress(account.address)}</ButtonCustom>
        </div>
        <MenuMobile  />
      </div>
    );
  } else {
    return (
      <>
          <Web3Network />
          <Web3Connect />
            <MenuMobile />
      </>
    );
  }
}

export default function Web3Status() {
  return (
    <>
      <Web3StatusInner />
    </>
  );
}
