import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import InputWithButton from 'components/NumericalInput/InputWithButton';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import { useSousUnstake } from 'hooks/useUnstake';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCastlesUserDataAsync, fetchCastlesUserDataAsyncV1 } from 'store/castles/index';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getTokenName } from 'utils/tokenHelpers';
import FeeSummary from 'views/Castles/components/CastlesCard/FeeSummary';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {useAccount, useChainId} from "wagmi";

const Unstake = ({ pool, userDataLoaded }) => {
  const dispatch = useDispatch();
  const chainId = useChainId();
  const {address} = useAccount();
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);

  const { stakedBalance, lastStakingBlock } = pool.userData;

  const { onUnstake } = useSousUnstake(pool.contractAddress);

  const stakingNameDisplay = getTokenName(pool.token0, pool?.token1, pool.stakingToken);

  const usdValue = value && formatNumber(new BigNumber(value).times(pool.stakingTokenPrice || 0).toNumber());
  const usdBalance =
    stakedBalance &&
    formatNumber(
      new BigNumber(getBalanceNumber(stakedBalance, pool.stakingToken.decimals))
        .times(pool.stakingTokenPrice || 0)
        .toNumber(),
    );

  const handleTypeInput = (valueInput) => {
    setValue(valueInput);
  };

  const isInsufficientBalance = useMemo(() => {
    return new BigNumber(value).isGreaterThan(getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals));
  }, [value, stakedBalance, pool.stakingToken.decimals]);

  const handleMaxInput = useCallback(() => {
    setValue(getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals));
  }, [pool.stakingToken.decimals, stakedBalance]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (percent === 100) {
        handleMaxInput();
      } else {
        setValue(
          getFullDisplayBalance(new BigNumber(stakedBalance).times(percent).dividedBy(100), pool.stakingToken.decimals),
        );
      }
    },
    [handleMaxInput, pool.stakingToken.decimals, stakedBalance],
  );

  const handleUnstake = useCallback(async () => {
    try {
      setPendingTx(true);
      await onUnstake(value, pool.stakingToken.decimals);
      // dispatch(fetchCastlesUserDataAsync(account, chainId));
      dispatch(fetchCastlesUserDataAsyncV1(address, chainId))
      showToastSuccess(
        'Unstaked',
        `Your ${pool.stakingToken.symbol} earnings have also been harvested to your wallet!`,
      );
      setValue('');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [address, chainId, dispatch, onUnstake, pool.stakingToken.decimals, pool.stakingToken.symbol, value]);

  return (
    <div>
      <div>
        <div className="flex font-bold text-white">
          <p>Stake Balance:</p>
          <div className="ml-2">
            <p>
              {address ? (
                userDataLoaded ? (
                  getFullDisplayBalance(stakedBalance, pool.stakingToken.decimals)
                ) : (
                  <Dots />
                )
              ) : (
                '???'
              )}{' '}
              {stakingNameDisplay}
            </p>
            <p className="text-sm mt-1">~{usdBalance || '0'} USD</p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex-1 text-white">
          <InputWithButton value={value} onUserInput={handleTypeInput} btnOnClick={handleMaxInput} />
          <p className="text-right text-sm mt-1">~{usdValue || '0'} USD</p>
          {/*<PercentPicker*/}
          {/*  isNumber={true}*/}
          {/*  className="text-black max-w-sm mx-auto"*/}
          {/*  onChangePercentInput={handlePercentInput}*/}
          {/*/>*/}
          {pool?.fees?.withdrawalFee && (
            <FeeSummary
              stakedBalance={stakedBalance}
              lastStakingBlock={lastStakingBlock}
              blockPeriod={pool.blockPeriod}
              withdrawalFee={pool.fees.withdrawalFee}
              stakeAmount={value}
              stakingTokenSymbol={pool.stakingToken.symbol}
            />
          )}
        </div>
        <div className="mt-2 text-black">
          {address ? (
            userDataLoaded ? (
              <ButtonCustom
                disabled={pendingTx || !new BigNumber(value).isGreaterThan(0) || isInsufficientBalance}
                isLoading={pendingTx}
                onClick={handleUnstake}
                className="w-full"
              >
                {isInsufficientBalance ? `Insufficient balance` : pendingTx ? 'Pending Confirmation' : 'Unstake'}
              </ButtonCustom>
            ) : (
              <ButtonCustom className="w-full" isLoading>
                Loading <Dots />
              </ButtonCustom>
            )
          ) : (
            <UnlockButton className="w-full"/>
          )}
        </div>
      </div>
    </div>
  );
};

Unstake.propTypes = {
  pool: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
};

export default Unstake;
