import {BTCB, CAKE, MCAKE, ONUS, USDT, VNDC, VNDC_V2} from 'constants/tokens';
import { ChainId, JSBI, Percent, WETH } from 'ezcake-v2-sdk';

export const ROUTER_ADDRESS = {
  [ChainId.KAI]: '0x66153fDc998252C0A98764933e2fC8D1B1009C2B',
  [ChainId.ONUS]: '0x785bc3471e839FB32Ec6Dc1b8A5C5f568DA9cD56',
  [ChainId.ONUS_TESTNET]: '0x18e0b40Fb41C54400a6229b8B7bD1c6e499fFF18',
  [ChainId.BSC_TESTNET]: '0xE101820B83c88d3A768A09a5682FDb13658a61bB',
  [ChainId.BSC]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
};

export const CHART_SUPPORTED = [];

export const CHAINS_SUPPORT_IMPORTED_TOKENS = [ChainId.ONUS, ChainId.BSC_TESTNET, ChainId.BSC];

export const BIG_INT_ZERO = JSBI.BigInt(0);
// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 1200;

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000));
export const BIPS_BASE = JSBI.BigInt(10000);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW = new Percent(JSBI.BigInt(100), BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM = new Percent(JSBI.BigInt(300), BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = new Percent(JSBI.BigInt(500), BIPS_BASE); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN = new Percent(JSBI.BigInt(1000), BIPS_BASE); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT = new Percent(JSBI.BigInt(1500), BIPS_BASE); // 15%

export const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR = {
  [ChainId.KAI]: [],
  [ChainId.ONUS]: [],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET],MCAKE[ChainId.BSC_TESTNET]],
  [ChainId.BSC]: [WETH[ChainId.BSC], USDT[ChainId.BSC],BTCB[ChainId.BSC]],
};

export const PINNED_PAIRS = {
  [ChainId.KAI]: [],
  [ChainId.ONUS]: [],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [[MCAKE[ChainId.BSC_TESTNET], WETH[ChainId.BSC_TESTNET]]],
  [ChainId.BSC]: [[USDT[ChainId.BSC], WETH[ChainId.BSC]]],
};

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  [ChainId.KAI]: [],
  [ChainId.ONUS]: [WETH[ChainId.ONUS]],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET],MCAKE[ChainId.BSC_TESTNET]],
  [ChainId.BSC]: [WETH[ChainId.BSC], USDT[ChainId.BSC], BTCB[ChainId.BSC], MCAKE[ChainId.BSC], CAKE[ChainId.BSC]],
};

/**
 * Addittional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES = {
  [ChainId.KAI]: {},
  [ChainId.ONUS]: {},
  [ChainId.ONUS_TESTNET]: {},
  [ChainId.BSC_TESTNET]: {},
  [ChainId.BSC]: {},
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.KAI]]
 */
export const CUSTOM_BASES = {
  [ChainId.KAI]: {},
  [ChainId.ONUS]: {},
  [ChainId.ONUS_TESTNET]: {},
  [ChainId.BSC_TESTNET]: {},
  [ChainId.BSC]: {},
};

// used to ensure the user doesn't send so much BNB so they end up with <.1
export const MIN_KAI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(17)); // .1 KAI
export const MIN_BSC = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(15)); // .001
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000));

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES = {
  [ChainId.KAI]: [],
  [ChainId.ONUS]: [VNDC[ChainId.ONUS]],
  [ChainId.ONUS_TESTNET]: [],
  [ChainId.BSC_TESTNET]: [MCAKE[ChainId.BSC_TESTNET], USDT[ChainId.BSC_TESTNET]],
  [ChainId.BSC]: [MCAKE[ChainId.BSC],VNDC_V2[ChainId.BSC], ONUS[ChainId.BSC],USDT[ChainId.BSC]],
};

export const swapSupportFeesTokens = [];

export const FEE_SWAP_DETAIL = {
  [ChainId.KAI]: (
    <>
    </>
  ),
  [ChainId.ONUS]: (
      <>
        <p>For each trade a 0.3% fee is paid</p>
        <p>- 0.25% to LP token holders</p>
        <p>- 0.05% to protocol</p>
      </>
  ),
  [ChainId.BSC_TESTNET]: (
      <>
        <p>For each trade a 0.3% fee is paid</p>
        <p>- 0.25% to LP token holders</p>
        <p>- 0.05% to protocol</p>
      </>
  ),
  [ChainId.BSC]: (
      <>
        <p>For each trade a 0.3% fee is paid</p>
        <p>- 0.25% to LP token holders</p>
        <p>- 0.05% to protocol</p>
      </>
  ),
};

export const FEE_SWAP = {
  [ChainId.KAI]: 0.25,
  [ChainId.ONUS]: 0.3,
  [ChainId.ONUS_TESTNET]: 0.3,
  [ChainId.BSC_TESTNET]: 0.3,
  [ChainId.BSC]: 0.3,
};

export const FEE_PROVIDER_SWAP = {
  [ChainId.KAI]: 0.25,
  [ChainId.ONUS]: 0.25,
  [ChainId.ONUS_TESTNET]: 0.25,
  [ChainId.BSC_TESTNET]: 0.25,
  [ChainId.BSC]: 0.25,
};
