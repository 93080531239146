import useSWRInfinite from 'swr/infinite';
import { ITEMS_PER_INFO_TABLE_PAGE } from 'constants/analytics';
import { fetcherAnalytics } from 'api/axiosClient';
import {useChainId} from "wagmi";

const useSwrInfiniteWithScroll = ({ query, getKeyUrl, config }) => {
  const chainId = useChainId()
  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
    (index) =>
      [getKeyUrl({
        ...query,
        page: index + 1,
        rowPerPage: query?.rowPerPage || ITEMS_PER_INFO_TABLE_PAGE,
      }), chainId],
      (url)=> fetcherAnalytics(url[0], chainId),
    config,
  );

  const formatData = data ? data.map((item) => item.data) : [];

  const total = data?.[0]?.totalItems || 0;
  const dataShowing = formatData ? [].concat(...formatData) : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.data?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.data?.length < (query?.rowPerPage || ITEMS_PER_INFO_TABLE_PAGE));
  const isRefreshing = isValidating && data && data.length === size;

  return {
    data,
    dataShowing,
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    isRefreshing,
    setSize,
    size,
    total,
    mutate,
  };
};

export default useSwrInfiniteWithScroll;
