import BigNumber from 'bignumber.js';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import InputWithButton from 'components/NumericalInput/InputWithButton';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useUnstake from 'hooks/useUnstake';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getTokenName } from 'utils/tokenHelpers';
import ModalWarningHarvestFee from 'components/Modal/ModalWarningHarvestFee';
import { ChainId } from 'ezcake-v2-sdk';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const Unstake = ({ farm, userDataLoaded, masterChefAddress, earningToken, onUpdateUserFarm }) => {
  const pid = farm.pid;
  const { account, chainId } = useActiveWeb3React();
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const [open, setOpen] = useState(false);

  const toggleWarning = () => setOpen(!open);

  const { stakedBalance, unlockTimestamp } = farm.userData;

  const { onUnstake } = useUnstake(pid, masterChefAddress);

  const lpTokenName = getTokenName(farm.token0, farm.token1, farm.stakingToken);

  const usdValue = value && formatNumber(new BigNumber(value).times(farm.price || 0).toNumber());
  const usdBalance =
    stakedBalance &&
    formatNumber(
      new BigNumber(getBalanceNumber(stakedBalance, farm.stakingToken.decimals)).times(farm.price || 0).toNumber(),
    );

  const handleTypeInput = (valueInput) => {
    setValue(valueInput);
  };

  const isInsufficientBalance = useMemo(() => {
    return new BigNumber(value).isGreaterThan(getFullDisplayBalance(stakedBalance, farm.stakingToken.decimals));
  }, [value, stakedBalance, farm.stakingToken.decimals]);

  const handleMaxInput = useCallback(() => {
    setValue(getFullDisplayBalance(stakedBalance, farm.stakingToken.decimals));
  }, [farm.stakingToken.decimals, stakedBalance]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (percent === 100) {
        handleMaxInput();
      } else {
        setValue(
          getFullDisplayBalance(
            new BigNumber(stakedBalance).times(percent).dividedBy(100),
            farm.stakingToken.decimals,
            farm.stakingToken.decimals,
          ),
        );
      }
    },
    [farm.stakingToken.decimals, handleMaxInput, stakedBalance],
  );

  const handleUnstake = useCallback(async () => {
    try {
      if (open) setOpen(false);
      setPendingTx(true);
      await onUnstake(value, farm.stakingToken.decimals);
      onUpdateUserFarm();
      showToastSuccess('Unstaked', `Your ${earningToken.symbol} earnings have also been harvested to your wallet!`);
      setValue('');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [earningToken.symbol, farm.stakingToken.decimals, onUnstake, onUpdateUserFarm, open, value]);

  const [q0User, q1User] = getLpBreakdown(
    stakedBalance.div(BIG_TEN.pow(farm.stakingToken.decimals)).toNumber(),
    farm?.totalSupply,
    farm?.q0,
    farm?.q1,
  );

  return (
    <div>
      <ModalWarningHarvestFee
        open={open}
        toggleModal={toggleWarning}
        content="Withdraw will auto-harvest your rewards. Harvest before 3 months lock will cost you 50% rewards and send to Stake and Lock Pool. Are you sure to continue?"
        onConfirm={handleUnstake}
      />
      <div>
        <div className="flex font-bold text-white">
          <p>Stake Balance:</p>
          <div className="ml-2">
            <>
              {account ? (
                userDataLoaded ? (
                  getFullDisplayBalance(stakedBalance, farm.stakingToken.decimals)
                ) : (
                  <Dots />
                )
              ) : (
                '???'
              )}{' '}
              {lpTokenName}
            </>
            {farm?.token1 && (
              <p>
                {stakedBalance.gt(0) && (
                  <>
                    ({formatNumber(getBalanceNumber(q0User, farm.token0.decimals), 2, 4)} {farm.token0.symbol} +{' '}
                    {formatNumber(getBalanceNumber(q1User, farm.token1.decimals), 2, 4)} {farm.token1.symbol})
                  </>
                )}
              </p>
            )}
            <p className="text-sm mt-1">~{usdBalance || '0'} USD</p>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex-1 text-white">
          <InputWithButton value={value} onUserInput={handleTypeInput} btnOnClick={handleMaxInput} />
          <p className="text-right text-sm mt-1">~{usdValue || '0'} USD</p>
          <PercentPicker
            isNumber={true}
            className="text-black max-w-sm mx-auto"
            onChangePercentInput={handlePercentInput}
          />
        </div>
        <div className="mt-2 text-black">
          {account ? (
            userDataLoaded ? (
              <ButtonCustom
                disabled={pendingTx || !new BigNumber(value).isGreaterThan(0) || isInsufficientBalance}
                isLoading={pendingTx}
                onClick={() => {
                  const hasHarvestFee = unlockTimestamp?.toNumber() * 1000 > Date.now();
                  if (hasHarvestFee && chainId === ChainId.HARMONY) {
                    toggleWarning();
                  } else {
                    handleUnstake();
                  }
                }}
                className="w-full"
              >
                {isInsufficientBalance ? `Insufficient balance` : pendingTx ? 'Pending Confirmation' : 'Unstake'}
              </ButtonCustom>
            ) : (
              <ButtonCustom className="w-full" isLoading>
                Loading <Dots />
              </ButtonCustom>
            )
          ) : (
            <UnlockButton />
          )}
        </div>
      </div>
    </div>
  );
};

Unstake.propTypes = {
  farm: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
  masterChefAddress: PropTypes.string.isRequired,
};

export default Unstake;
