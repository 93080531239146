import BigNumber from 'bignumber.js';
import erc20Abi from 'config/abi/erc20.json';
import masterChefAbi from 'config/abi/masterchef.json';
import masterChefHarmonyAbi from 'config/abi/masterchefHarmony.json';
import { ChainId } from 'ezcake-v2-sdk';
import { getERC20Contract, getMasterChefContract } from 'utils/contractHelpers';
import multicall from 'utils/multicall';

export const fetchFarmUserAllowances = async (account, farmsToFetch, masterChefAddress, chainId) => {
  const calls = farmsToFetch.map((farm) => {
    return { address: farm.lpAddress[chainId], name: 'allowance', params: [account, masterChefAddress] };
  });

  const rawLpAllowances = await multicall(erc20Abi, calls, chainId);
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON();
  });

  return parsedLpAllowances;
};

export const fetchFarmUserTokenBalances = async (account, farmsToFetch, chainId) => {
  const calls = farmsToFetch.map((farm) => {
    return {
      address: farm.lpAddress[chainId],
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(erc20Abi, calls, chainId);
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });
  return parsedTokenBalances;
};

export const fetchFarmUserStakedBalances = async (account, farmsToFetch, masterChefAddress, chainId) => {
  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    };
  });

  const rawStakedBalances = await multicall(
    chainId === ChainId.HARMONY ? masterChefHarmonyAbi : masterChefAbi,
    calls,
    chainId,
  );
  const parsedStakedBalances = rawStakedBalances.map((userInfo) => {
    return {
      amount: new BigNumber(userInfo[0]._hex).toJSON(),
      unlockTimestamp: new BigNumber(userInfo?.unlockTimestamp?._hex || 0).toJSON(),
    };
  });

  return parsedStakedBalances;
};

export const fetchFarmUserEarnings = async (account, farmsToFetch, masterChefAddress, chainId) => {
  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'pendingRewards',
      params: [farm.pid, account],
    };
  });

  const rawEarnings = await multicall(masterChefAbi, calls, chainId);
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON();
  });

  return parsedEarnings;
};

export const fetchFarmUserAllowance = async (account, lpAddress, masterChefAddress, chainId) => {
  const tokenContract = getERC20Contract(lpAddress, chainId);
  const res = await tokenContract.allowance(account, masterChefAddress);
  return new BigNumber(res._hex).toJSON();
};

export const fetchFarmUserTokenBalance = async (account, lpAddress, chainId) => {
  const tokenContract = getERC20Contract(lpAddress, chainId);
  const res = await tokenContract.balanceOf(account);

  return new BigNumber(res._hex).toJSON();
};

export const fetchFarmUserEarning = async (account, pid, masterChefAddress, chainId) => {
  const masterChefContract = getMasterChefContract(masterChefAddress, chainId);
  const earnings = await masterChefContract.pendingRewards(pid, account);

  return new BigNumber(earnings._hex).toJSON();
};
