import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import Dots from 'components/Loader/Dots';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useCompound from 'hooks/useCompound';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchVaultsTotalStakedDataAsync, fetchVaultUserDataAsync } from 'store/vaults/index';
import { BIG_TEN } from 'utils/bigNumber';
import { formatNumber, getFullDisplayBalance } from 'utils/formatBalance';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const ManualCompoundCard = ({ userDataLoaded, vault }) => {
  const dispatch = useDispatch();
  const { account, chainId } = useActiveWeb3React();
  const [pendingTx, setPendingTx] = useState(false);
  const { onCompound } = useCompound(vault.contractAddress);

  const usdTokenEarning =
    vault?.pendingReward && vault?.farmStaked?.earningTokenPrice
      ? formatNumber(
          vault.pendingReward
            .div(BIG_TEN.pow(vault?.farmStaked?.earningToken.decimals))
            .times(vault?.farmStaked?.earningTokenPrice || 0)
            .toNumber(),
        )
      : 0;

  const handleCompound = useCallback(async () => {
    try {
      setPendingTx(true);
      await onCompound();
      dispatch(fetchVaultUserDataAsync(account, chainId));
      dispatch(fetchVaultsTotalStakedDataAsync(chainId));
      showToastSuccess('Successfully!!');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [account, dispatch, onCompound, chainId]);

  return (
    <div className="rounded-2xl border border-black2 bg-black1 p-3 mt-4">
      <div className="h-full flex flex-col justify-between">
        <div>
          <p className="mb-2 text-white">EXTRA COMPOUND</p>
          {vault.pendingReward && (
            <div className="flex text-white">
              <p>Vault Pending Rewards:</p>
              <div className="ml-2">
                <p>
                  {getFullDisplayBalance(vault.pendingReward, vault?.farmStaked?.earningToken?.decimals, 2)}{' '}
                  {vault?.farmStaked?.earningToken?.symbol}
                </p>
                <p className="text-sm mt-1">~{usdTokenEarning || '0'} USD</p>
              </div>
            </div>
          )}
          <div className="mt-2">
            {account ? (
              userDataLoaded ? (
                <ButtonCustom disabled={pendingTx} isLoading={pendingTx} onClick={handleCompound} className="w-full">
                  Compound
                </ButtonCustom>
              ) : (
                <ButtonCustom className="w-full" isLoading>
                  Loading <Dots />
                </ButtonCustom>
              )
            ) : (
              <UnlockButton />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualCompoundCard;
