import BigNumber from 'bignumber.js';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import PropTypes from 'prop-types';
import { usePrices } from 'store/prices/hook';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import { getParameterCaseInsensitive } from 'utils/index';

const HoldingsCell = ({ vault }) => {
  const { account, chainId } = useActiveWeb3React();
  const prices = usePrices();
  const priceStakingToken = getParameterCaseInsensitive(prices, vault.stakingToken.address[chainId]);
  const { stakedBalance } = vault.userData;

  const usdHoldings = priceStakingToken
    ? new BigNumber(getFullDisplayBalance(stakedBalance, vault.stakingToken.decimals))
        .times(priceStakingToken)
        .toNumber()
    : 0;

  const [q0User, q1User] = getLpBreakdown(
    stakedBalance.div(BIG_TEN.pow(vault.stakingToken.decimals)).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  return (
    <>
      <Tooltip
        tooltip={
          <div className="whitespace-nowrap">
            <p>Your Deposits</p>
            <p>
              {formatNumber(getBalanceNumber(stakedBalance, vault.stakingToken.decimals))} {vault.stakingToken.symbol}
            </p>
            {vault.token1 && vault.farmStaked && (
              <p>
                {stakedBalance.gt(0) && (
                  <>
                    {formatNumber(getBalanceNumber(q0User, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                    {vault.farmStaked.token0.symbol} +{' '}
                    {formatNumber(getBalanceNumber(q1User, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                    {vault.farmStaked.token1.symbol}
                  </>
                )}
              </p>
            )}
          </div>
        }
      >
        <Value value={account ? getBalanceNumber(stakedBalance, vault.stakingToken.decimals) : 0} />
        {usdHoldings > 0 && <Value prefix="~$" className="text-sm" value={account ? usdHoldings : 0} />}
      </Tooltip>
    </>
  );
};

HoldingsCell.propTypes = {
  vault: PropTypes.object.isRequired,
};

export default HoldingsCell;
