import multicall from '../../utils/multicall';
import lootDragonAbi from 'config/abi/lootDragon.json';
import erc20Abi from 'config/abi/erc20.json';
import BigNumber from 'bignumber.js';
import { BIG_TEN } from '../../utils/bigNumber';
import erc20ABI from '../../config/abi/erc20.json';
const ABI = {
  lootDragon: lootDragonAbi,
};

export const getNFTData = async (nft) => {
  try {
    if (!nft?.poolAddress) return null;
    const calls = [
      {
        address: nft.poolAddress,
        name: 'minted',
      },
    ];
    const minted = await multicall(ABI[nft.abi], calls, nft.chainId);
    return new BigNumber(minted).toNumber();
  } catch (error) {
    console.log(error);
  }
};

export const fetchCanClaim = async (nft) => {
  try {
    if (!nft?.poolAddress) return null;
    const calls = [
      {
        address: nft.poolAddress,
        name: 'canClaim',
      },
    ];
    const [canClaim] = await multicall(ABI[nft.abi], calls, nft.chainId);
    return canClaim?.[0];
  } catch (error) {
    console.log(error);
  }
};

export const fetchNextStopId = async (nft) => {
  try {
    if (!nft?.poolAddress) return null;
    const calls = [
      {
        address: nft.poolAddress,
        name: 'NEXT_STOP_ID',
      },
    ];
    const [nextStopId] = await multicall(ABI[nft.abi], calls, nft.chainId);
    return new BigNumber(nextStopId).toNumber();
  } catch (error) {
    console.log(error);
  }
};

export const getNFTBurned = async (nft) => {
  try {
    const calls = [
      {
        address: nft.tokenBurn.address,
        name: 'totalSupply',
      },
    ];
    const [totalSupply] = await multicall(erc20Abi, calls, nft.chainId);
    return new BigNumber(totalSupply).dividedBy(BIG_TEN.pow(nft.tokenBurn.decimals)).toNumber();
  } catch (error) {
    console.log(error);
  }
};

export const getMyNFTs = async (NFT, account) => {
  try {
    const calls = [
      {
        address: NFT.poolAddress,
        name: 'balanceOf',
        params: [account],
      },
    ];
    const [balance] = await multicall(lootDragonAbi, calls, NFT.chainId);
    const balanceNFT = new BigNumber(balance).toNumber();
    const calls2 = [];
    if (balanceNFT) {
      let i = 0;
      while (i < balanceNFT) {
        calls2.push({
          address: NFT.poolAddress,
          name: 'tokenOfOwnerByIndex',
          params: [account, i],
        });
        i += 1;
      }
    }
    const data = await multicall(lootDragonAbi, calls2, NFT.chainId);
    const listId =
      data?.map((value) => new BigNumber(value)?.toNumber())?.filter((val) => typeof val === 'number') || [];
    return listId;
  } catch (error) {
    console.log(error);
  }
};

export const fetchOwnerById = async (nft, id) => {
  try {
    const call = [
      {
        address: nft.poolAddress,
        name: 'ownerOf',
        params: [id],
      },
    ];
    const [res] = await multicall(lootDragonAbi, call, nft.chainId);
    return res?.[0];
  } catch (error) {}
};

export const fetchAllowanceNFT = async (nft, account) => {
  const calls = [
    {
      address: nft?.buyToken?.address?.[nft?.chainId],
      name: 'allowance',
      params: [account, nft.poolAddress],
    },
  ];
  const [allowance] = await multicall(erc20ABI, calls, nft?.chainId);
  return new BigNumber(allowance).toJSON();
};
