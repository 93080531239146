/* eslint-disable react/no-array-index-key */
import React, { Children, isValidElement } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import 'styles/breadcrumbs.scss';
import { classNames } from 'utils/styling';

const insertSeparators = (items, separator) =>
  items.reduce((accum, item, index) => {
    if (index === 0) {
      return [...accum, item];
    }

    return [
      ...accum,
      <div className="separator" aria-hidden key={`separator-${index}`}>
        {separator}
      </div>,
      item,
    ];
  }, []);

const DefaultSeparator = <FiChevronRight color="currentColor" width="24px" />;

const Breadcrumbs = ({ className, separator = DefaultSeparator, children }) => {
  const validItems = Children.toArray(children).filter((child) => isValidElement(child));
  const items = insertSeparators(validItems, separator);

  return (
    <ul className={classNames('styledBreadcrumbs', className)}>
      {items.map((item, index) => (
        <li key={`child-${index}`}>{item}</li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
