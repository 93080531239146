import masterChefCakeAbi from 'config/abi/masterchefCake.json';
import masterChefMiaAbi from 'config/abi/masterchefMia.json';
import { MASTERCHEF_ADDRESS_CAKE, MASTERCHEF_ADDRESS_MIA } from 'constants/contracts';
import tokens from 'constants/tokens';
import { PLATFORM } from 'constants/vaults';
import { ChainId } from 'ezcake-v2-sdk';

export const farmsOutsideConfig = {
  [ChainId.BSC]: [
    {
      abi: masterChefCakeAbi,
      contractAddress: MASTERCHEF_ADDRESS_CAKE[ChainId.BSC],
      earningToken: tokens.cake,
      methodPerBlock: 'cakePerBlock',
      platform: PLATFORM.cake,
      farmsConfig: [
        {
          pid: 423,
          stakingToken: {
            ...tokens.cakeLp,
            address: {
              [ChainId.BSC]: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
            },
          },
          lpAddress: {
            [ChainId.BSC]: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
          },
          token0: tokens.busdc,
          token1: tokens.busdt,
        },
        {
          pid: 258,
          stakingToken: {
            ...tokens.cakeLp,
            address: {
              [ChainId.BSC]: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
            },
          },
          lpAddress: {
            [ChainId.BSC]: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
          },
          token0: tokens.busdt,
          token1: tokens.busd,
        },
        {
          pid: 283,
          stakingToken: {
            ...tokens.cakeLp,
            address: {
              [ChainId.BSC]: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
            },
          },
          lpAddress: {
            [ChainId.BSC]: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
          },
          token0: tokens.busdc,
          token1: tokens.busd,
        },
        {
          pid: 282,
          stakingToken: {
            ...tokens.cakeLp,
            address: {
              [ChainId.BSC]: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
            },
          },
          lpAddress: {
            [ChainId.BSC]: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
          },
          token0: tokens.bdai,
          token1: tokens.busd,
        },
        {
          pid: 293,
          stakingToken: {
            ...tokens.cakeLp,
            address: {
              [ChainId.BSC]: '0x05faf555522Fa3F93959F86B41A3808666093210',
            },
          },
          lpAddress: {
            [ChainId.BSC]: '0x05faf555522Fa3F93959F86B41A3808666093210',
          },
          token0: tokens.ust,
          token1: tokens.busd,
        },
      ],
    },
  ],
  [ChainId.ONUS]: [
    {
      abi: masterChefMiaAbi,
      contractAddress: MASTERCHEF_ADDRESS_MIA[ChainId.ONUS],
      earningToken: tokens.mia,
      methodPerBlock: 'tokenPerBlock',
      platform: PLATFORM.mia,
      rewardPerBlock: 0.5,
      farmsConfig: [
        {
          pid: 0,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0xADe7762EF1A20B36fc935c7553f7BdeB80680a55',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0xADe7762EF1A20B36fc935c7553f7BdeB80680a55',
          },
          token0: tokens.wonus,
          token1: tokens.mia,
        },
        {
          pid: 1,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0xDE74Dd9Da2b38F92cCCE5f6bf46f3D34b21ce79C',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0xDE74Dd9Da2b38F92cCCE5f6bf46f3D34b21ce79C',
          },
          token0: tokens.mia,
          token1: tokens.busd,
        },
        {
          pid: 3,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0x6ed76CD41960159938BEE34FfdE6Aba47A25E5cA',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0x6ed76CD41960159938BEE34FfdE6Aba47A25E5cA',
          },
          token0: tokens.mia,
          token1: tokens.vndc,
        },
        {
          pid: 4,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0x64C9D16BF9BbAB164677894c432e40EA5E068f69',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0x64C9D16BF9BbAB164677894c432e40EA5E068f69',
          },
          token0: tokens.mia,
          token1: tokens.usdtOnus,
        },
        {
          pid: 8,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0x4b99fc04de32Adc6302b318520aebF3Ed96b9b90',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0x4b99fc04de32Adc6302b318520aebF3Ed96b9b90',
          },
          token0: tokens.usdo,
          token1: tokens.mia,
        },
        {
          pid: 9,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0xa1b23C01fC911cAc3795c2170dac0f13585e1823',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0xa1b23C01fC911cAc3795c2170dac0f13585e1823',
          },
          token0: tokens.btc,
          token1: tokens.vndc,
        },
        {
          pid: 10,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0x738F874f7f7002b020D62B5A975507D264402670',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0x738F874f7f7002b020D62B5A975507D264402670',
          },
          token0: tokens.eth,
          token1: tokens.vndc,
        },
        {
          pid: 11,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0x979CD82E93DB1458c6d8c862ea7aD6dC27486C41',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0x979CD82E93DB1458c6d8c862ea7aD6dC27486C41',
          },
          token0: tokens.bnbKai,
          token1: tokens.vndc,
        },
        {
          pid: 5,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0x5e995e666f21FC3aA3D5757304bb8Bd8bAA96774',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0x5e995e666f21FC3aA3D5757304bb8Bd8bAA96774',
          },
          token0: tokens.vndc,
          token1: tokens.usdtOnus,
        },
        {
          pid: 7,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0xDC108A0021fA96f8A4BF5971F45bC5EA4F065296',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0xDC108A0021fA96f8A4BF5971F45bC5EA4F065296',
          },
          token0: tokens.usdo,
          token1: tokens.vndc,
        },
        {
          pid: 12,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0xeb1DC5110505D71347e168D48A2490f010794393',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0xeb1DC5110505D71347e168D48A2490f010794393',
          },
          token0: tokens.vndc,
          token1: tokens.rice,
        },
        {
          pid: 6,
          stakingToken: {
            ...tokens.miaLP,
            address: {
              [ChainId.ONUS]: '0x9a380A25D2250807aee3c7087aAD2A5b9b31a00E',
            },
          },
          lpAddress: {
            [ChainId.ONUS]: '0x9a380A25D2250807aee3c7087aAD2A5b9b31a00E',
          },
          token0: tokens.wonus,
          token1: tokens.vndc,
        },
      ],
    },
  ],
};
