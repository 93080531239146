import { NavLink, useLocation } from 'react-router-dom';
import { classNames } from 'utils/styling';

const pathNameLiquidity = ['/liquidity', '/add', '/remove', '/find'];
const TabHeader = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex justify-center mx-auto text-white my-10 text-sm-md max-w-min rounded overflow-hidden border border-primary-1">
      <NavLink
        className={({ isActive }) =>
          classNames('px-4 py-2 whitespace-nowrap font-semibold', isActive ? 'text-black bg-primary rounded-r' : 'text-primary')
        }
        to="/swap"
      >
        Swap
      </NavLink>
      <NavLink
        className={() =>
          classNames(
            'px-4 py-2 whitespace-nowrap font-semibold',
            pathNameLiquidity.includes(pathname) ? 'text-black bg-primary rounded-l' : 'text-primary',
          )
        }
        to="/liquidity"
      >
        Liquidity
      </NavLink>
    </div>
  );
};

export default TabHeader;
