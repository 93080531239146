import { createSlice } from '@reduxjs/toolkit';
import { MASTERCHEF_ADDRESS } from 'constants/contracts';
import farmsConfig from 'constants/farms';
import { ChainId } from 'ezcake-v2-sdk';
import fetchFarm from 'store/farms/fetchFarm';
import {
  fetchFarmUserAllowance,
  fetchFarmUserAllowances,
  fetchFarmUserEarning,
  fetchFarmUserEarnings,
  fetchFarmUserStakedBalances,
  fetchFarmUserTokenBalance,
  fetchFarmUserTokenBalances,
} from 'store/farms/fetchFarmUser';
import { setPricesData } from 'store/prices/index';

const initialState = {
  data: farmsConfig,
  userDataLoaded: {
    [ChainId.KAI]: false,
    [ChainId.BSC]: false,
    [ChainId.BSC_TESTNET]: false,
    [ChainId.HARMONY]: false,
    [ChainId.ONUS_TESTNET]: false,
  },
};

export const farmsSlice = createSlice({
  name: 'farms',
  initialState,
  reducers: {
    setFarmsData: (state, action) => {
      const { liveFarmsData, chainId } = action.payload;
      state.data[chainId] = state.data[chainId].map((farm) => {
        const liveFarmData = liveFarmsData.find((entry) => entry.pid === farm.pid);
        return { ...farm, ...liveFarmData };
      });
    },
    setFarmsUserData: (state, action) => {
      const { userData, chainId } = action.payload;
      state.data[chainId] = state.data[chainId].map((farm) => {
        const userFarmData = userData.find((entry) => entry.pid === farm.pid);
        return { ...farm, userData: userFarmData };
      });
      state.userDataLoaded[chainId] = true;
    },
    updateFarmsUserData: (state, action) => {
      const { field, value, pid, chainId } = action.payload;
      const index = state.data[chainId].findIndex((p) => p.pid === pid);

      if (index >= 0) {
        state.data[chainId][index] = {
          ...state.data[chainId][index],
          userData: { ...state.data[chainId][index].userData, [field]: value },
        };
      }
    },
    setUserData: (state, action) => {
      state.userData[action.payload.pid] = {
        ...state.userData[action.payload.pid],
        userDataLoaded: true,
        ...action.payload,
      };
    },
  },
});

// async action

export const fetchFarmsDataAsync = (prices, chainId) => async (dispatch) => {
  try {
    const res = await fetchFarm(prices, chainId);
    dispatch(
      setFarmsData({
        liveFarmsData: res.farms,
        chainId,
      }),
    );
    dispatch(
      setPricesData({
        chainId,
        prices: res.prices,
      }),
    );
  } catch (error) {
    console.log(error);
  }
};

export const fetchFarmUserDataAsync = (account, chainId) => async (dispatch) => {
  try {
    const promise = [
      fetchFarmUserAllowances(
        account,
        farmsConfig[chainId].filter((farm) => !farm.isHide),
        MASTERCHEF_ADDRESS[chainId],
        chainId,
      ),
      fetchFarmUserTokenBalances(
        account,
        farmsConfig[chainId].filter((farm) => !farm.isHide),
        chainId,
      ),
      fetchFarmUserStakedBalances(
        account,
        farmsConfig[chainId].filter((farm) => !farm.isHide),
        MASTERCHEF_ADDRESS[chainId],
        chainId,
      ),
      fetchFarmUserEarnings(
        account,
        farmsConfig[chainId].filter((farm) => !farm.isHide),
        MASTERCHEF_ADDRESS[chainId],
        chainId,
      ),
    ];

    const response = await Promise.all(promise);

    const allowances = response?.[0];
    const stakingTokenBalances = response?.[1];
    const stakedBalances = response?.[2];
    const earningsBalances = response?.[3];

    const userData = farmsConfig[chainId]
      .filter((farm) => !farm.isHide)
      .map((farm, index) => ({
        pid: farm.pid,
        allowance: allowances[index],
        stakingTokenBalance: stakingTokenBalances[index],
        stakedBalance: stakedBalances[index].amount,
        unlockTimestamp: stakedBalances[index].unlockTimestamp,
        earnings: earningsBalances[index],
      }));

    dispatch(
      setFarmsUserData({
        userData,
        chainId,
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

export const updateUserAllowance = (account, pid, chainId) => async (dispatch) => {
  const farm = farmsConfig[chainId].find((farm) => farm.pid === pid);
  const allowance = await fetchFarmUserAllowance(
    account,
    farm.lpAddress[chainId],
    MASTERCHEF_ADDRESS[chainId],
    chainId,
  );
  dispatch(updateFarmsUserData({ pid: farm.pid, field: 'allowance', value: allowance, chainId }));
};

export const updateUserStakingBalance = (account, pid, chainId) => async (dispatch) => {
  const farm = farmsConfig[chainId].find((farm) => farm.pid === pid);
  const tokenBalance = await fetchFarmUserTokenBalance(account, farm.lpAddress[chainId], chainId);
  dispatch(updateFarmsUserData({ pid: farm.pid, field: 'stakingTokenBalance', value: tokenBalance, chainId }));
};

export const updateUserEarningsBalance = (account, pid, chainId) => async (dispatch) => {
  const earnings = await fetchFarmUserEarning(account, pid, MASTERCHEF_ADDRESS[chainId], chainId);
  dispatch(updateFarmsUserData({ pid: pid, field: 'earnings', value: earnings, chainId }));
};

// Actions
export const { setFarmsData, setFarmsUserData, updateFarmsUserData } = farmsSlice.actions;
export default farmsSlice.reducer;
