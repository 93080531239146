import { MASTERCHEF_ADDRESS } from 'constants/contracts';
import farmsConfig from 'constants/farms';
import tokens from 'constants/tokens';
import { getFarms } from 'utils/farmsHelpers';

const fetchFarms = async (pricesFetch, chainId) => {
  return await getFarms(farmsConfig[chainId], MASTERCHEF_ADDRESS[chainId], pricesFetch, tokens.mCake, chainId);
};

export default fetchFarms;
