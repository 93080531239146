import useSWR from 'swr';
import { analyticsUrlApi } from 'api/url';
import { ITEMS_PER_INFO_TABLE_PAGE } from 'constants/analytics';
import { fetcherAnalytics } from 'api/axiosClient';
import { mapPairDayData, mapPairPriceData, parsePoolData } from 'store/analytics/queries/helpers';
import useSwrInfiniteWithScroll from 'hooks/swr/useSwrInfiniteWithScroll';
import { isAddress } from 'utils';
import {useChainId} from "wagmi";

export const useSWRPairs = (query) => {
  const chainId = useChainId()

  const { data, ...rest } = useSWR(
    analyticsUrlApi.getPairs({
      rowPerPage: ITEMS_PER_INFO_TABLE_PAGE,
      ...query,
    }),
      (url) => fetcherAnalytics(url, chainId),
  );

  return {
    poolDatas: data ? data.data.map(parsePoolData) : [],
    maxPage: data ? data.totalPage : 1,
    ...rest,
  };
};

export const useSWRPairData = (address) => {
  const chainId = useChainId()
  const { data, ...rest } = useSWR(address ? analyticsUrlApi.getPairData(address) : null, (url)=> fetcherAnalytics(url, chainId), {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return {
    poolData: data ? parsePoolData(data) : undefined,
    ...rest,
  };
};

export const useSWRPairChartPriceData = (query) => {
  const chainId = useChainId()

  const { data, ...rest } = useSWR(
    analyticsUrlApi.getPairChartPriceData({
      ...query,
      token0: isAddress(query.token0),
      token1: isAddress(query.token1),
    }),
      (url)=> fetcherAnalytics(url, chainId),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );

  return {
    pairPrices: data
      ? data?.data?.map?.((pairPrice) => mapPairPriceData(pairPrice, isAddress(query.token0), data.pair))
      : [],
    pairId: data ? data?.pair?._id : undefined,
    data,
    ...rest,
  };
};

export const useSWRPairChartData = (address) => {
  const chainId = useChainId()
  const { data, ...rest } = useSWR(analyticsUrlApi.getPairChartData(address), (url)=> fetcherAnalytics(url, chainId));

  return {
    chartData: data ? data.map(mapPairDayData) : [],
    ...rest,
  };
};

export const getKeyUrlListPairs = (query) => {
  return analyticsUrlApi.getPairs(query);
};

export const useSWRSearchPairsInfinite = (query) => {
  const { dataShowing, ...rest } = useSwrInfiniteWithScroll({
    query: query,
    getKeyUrl: getKeyUrlListPairs,
    config: {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  });

  return {
    poolDatas: dataShowing ? dataShowing.map(parsePoolData) : [],
    ...rest,
  };
};
