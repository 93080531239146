import { NavItem, TabContent, TabPane, Tabs } from 'components/Tabs/Tabs';
import { CASTLE_TAGS } from 'constants/index';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Detail from 'views/Castles/components/CastlesTable/ActionPanel/Detail/Detail';
import Harvest from 'views/Castles/components/CastlesTable/ActionPanel/Harvest';
import Stake from 'views/Castles/components/CastlesTable/ActionPanel/Stake';
import Stats from 'views/Castles/components/CastlesTable/ActionPanel/Stats';
import Unstake from 'views/Castles/components/CastlesTable/ActionPanel/Unstake';

const TABS_CASTLE = {
  stake: 'Stake',
  unstake: 'Unstake',
};

const ActionPanel = ({ pool, userDataLoaded }) => {
  const isIfo = pool.tags.includes(CASTLE_TAGS.ifo);

  const [tab, setTab] = useState(pool?.isFinished ? TABS_CASTLE.unstake : TABS_CASTLE.stake);

  const handleChangeTab = (tabSelected) => {
    if (tab !== tabSelected) {
      setTab(tabSelected);
    }
  };

  return (
    <div className="px-2 py-1 sm:px-6 sm:py-4">
      <div className="block lg:flex flex-wrap lg:space-x-2 space-y-2 lg:space-y-0">
        <div className="flex-1">
          <div className="rounded-2xl border border-black2 bg-black1  p-3 h-full">
            <Detail pool={pool} userDataLoaded={userDataLoaded} />
          </div>
        </div>
        <div className="flex-1 flex flex-col space-y-2">
          <div className="rounded-2xl border border-black2 bg-black1 p-3 flex-1">
            <Harvest pool={pool} userDataLoaded={userDataLoaded} />
          </div>
          <div className="rounded-2xl border border-black2 bg-black1 p-3 flex-1">
            <Stats pool={pool} userDataLoaded={userDataLoaded} />
          </div>
        </div>
        {!pool.isFinished && (
          <div className="flex-1">
            <div className="rounded-2xl border border-black2 bg-black1  p-3 h-full">
              {
                !pool.claimBlock && (
                      <Tabs className="w-full justify-center">
                        {!pool?.isFinished && (
                            <NavItem active={tab === TABS_CASTLE.stake} onClick={() => handleChangeTab(TABS_CASTLE.stake)}>
                              {TABS_CASTLE.stake}
                            </NavItem>
                        )}
                        <NavItem active={tab === TABS_CASTLE.unstake} onClick={() => handleChangeTab(TABS_CASTLE.unstake)}>
                          {TABS_CASTLE.unstake}
                        </NavItem>
                      </Tabs>
                  )
              }
              <TabContent className="mt-4" activeTab={tab}>
                <TabPane tabId={TABS_CASTLE.stake}>
                  {!pool.isFinished && <Stake pool={pool} userDataLoaded={userDataLoaded} />}
                </TabPane>
                {
                  !pool.claimBlock && (
                        <TabPane tabId={TABS_CASTLE.unstake}>
                          <Unstake pool={pool} userDataLoaded={userDataLoaded} />
                        </TabPane>
                    )
                }
              </TabContent>
            </div>
          </div>
        )}
        {pool.isFinished && !isIfo && !pool.claimBlock && (
          <div className="rounded-2xl border border-black2 bg-black1  p-3 h-full">
            <Unstake pool={pool} userDataLoaded={userDataLoaded} />
          </div>
        )}
      </div>
    </div>
  );
};

ActionPanel.propTypes = {
  pool: PropTypes.object.isRequired,
  userDataLoaded: PropTypes.bool.isRequired,
};

export default ActionPanel;
