import { classNames } from 'utils/styling';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Logo = ({ className, width, height }) => {
  return (
    <div >
      <Link to="/" className={classNames('flex items-center', className)}>
        <img alt="logo" src="/logo.png" width={width || '108'} height={height || '57'} />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
