import React from 'react';
import { TbChartBar, TbChartBarOff } from 'react-icons/tb';
import { useExchangeChartManager } from 'store/user/hooks';

const IconShowChart = () => {
  const [isChartDisplayed, setUserChartPreference] = useExchangeChartManager();
  function toggleTransactionModal() {
    setUserChartPreference(!isChartDisplayed);
  }

  return (
    <div>
      {isChartDisplayed ? (
        <TbChartBar onClick={toggleTransactionModal} className="cursor-pointer" size={24} />
      ) : (
        <TbChartBarOff onClick={toggleTransactionModal} className="cursor-pointer" size={24} />
      )}
    </div>
  );
};

export default IconShowChart;
