import useSWR from 'swr';
import { analyticsUrlApi } from 'api/url';
import { fetcherAnalytics } from 'api/axiosClient';
import { formatProtocolData, mapDayData } from 'store/analytics/queries/helpers';
import {useChainId} from "wagmi";

export const useSWRProtocolChartData = () => {
  const chainId = useChainId()
  const { data, ...rest } = useSWR([analyticsUrlApi.getProtocolChartData, chainId], (url)=> fetcherAnalytics(url[0], chainId));

  return {
    chartData: data ? data.map(mapDayData) : [],
    ...rest,
  };
};

export const useSWRProtocolData = () => {
  const chainId = useChainId()
  const { data, ...rest } = useSWR([analyticsUrlApi.getProtocolData, chainId], (url)=> fetcherAnalytics(url[0], chainId));

  return {
    protocolData: data ? formatProtocolData(data) : undefined,
    ...rest,
  };
};
