import axios from 'axios';
import { API_ANALYTICS_URL, API_NFT_URL } from 'config/index';
import queryString from 'query-string';
import {ChainId} from "ezcake-v2-sdk";
// Set up default config for http requests here

// Please have a look at here `https://github.com/axios/axios#request-
// config` for the full list of configs

const axiosClient = axios.create({
  baseURL: API_NFT_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // check sever side
  if (typeof window !== 'undefined') {
    // config formData
    if (config?.customUrl) {
      config.baseURL = config.customUrl;
    }
    if (config?.wallet) {
      config.headers.wallet = config.wallet;
    }
    if (config?.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response?.data?.payload) {
      return response.data.payload;
    }

    if (response?.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    // Handle errors
    throw error;
  },
);

export const fetcherAnalytics = (url, chainId) => {
    return  axiosClient
        .get(url, {
            baseURL:  chainId === ChainId.ONUS ? `${API_ANALYTICS_URL}/onus/v1` : `${API_ANALYTICS_URL}/v1`,
        })
        .then((res) => {
            return res;
        });
}

export default axiosClient;
