import Tooltip from 'components/Tooltip/Tooltip';
import { AVERAGE_BLOCK_TIME } from 'config/index';
import { CASTLE_TAGS } from 'constants/index';
import { useMemo } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { FiClock } from 'react-icons/fi';
import { formatNumber } from 'utils/formatBalance';
import {getPoolBlockInfo, getPoolBlockInfoClaim, getPoolBlockInfoStake} from 'views/Castles/helpers';
import { useCurrentBlock } from 'store/block/hooks';
import {useChainId} from "wagmi";

const CountdownCell = ({ pool }) => {
  const chainId = useChainId();
  const isIfo = pool.tags.includes(CASTLE_TAGS.ifo);

  const currentBlock = useCurrentBlock();
  const {
    shouldShowBlockCountdown,
    blocksUntilStart,
    blocksRemaining,
    hasPoolStarted,
    blocksToDisplay,
    durationDisplay,
  } = getPoolBlockInfo(pool, currentBlock, chainId);

  const {
    blocksUntilStake,
    blocksRemaining: blocksRemainingStake,
    hasPoolStaked,
    blocksToDisplay: blocksToDisplayStake,
    shouldShowBlockCountdownUnStaking,
    blocksUntilUnStaking,
  } = getPoolBlockInfoStake(pool, currentBlock);

  const {
    shouldShowBlockCountdown: shouldShowBlockCountdownClaim,
    blocksToDisplay: blocksToDisplayClaim,
  } = getPoolBlockInfoClaim(pool.claimBlock, pool.isFinished ,currentBlock);

  const timer = useMemo(() => {
    return blocksToDisplay * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksToDisplay]);

  const timerStake = useMemo(() => {
    return blocksToDisplayStake * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksToDisplay]);

  const timerBlockUnstaking = useMemo(() => {
    return blocksUntilUnStaking * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksUntilUnStaking]);

  const timerBlockClaim = useMemo(() => {
    return blocksToDisplayClaim * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksToDisplayClaim]);

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null;

    return (
      <>
        {days > 0 ? `${zeroPad(days)}d-` : ''}
        {zeroPad(hours)}h-{zeroPad(minutes)}m-{zeroPad(seconds)}s
      </>
    );
  };

  return (
    <>
      {pool.isV2 && shouldShowBlockCountdown && !isIfo && (
        <div className="flex items-center mb-1 text-white">
          <p>
            {hasPoolStaked
              ? `${isIfo ? 'Buy-in open until:' : 'Staking is open until:'}`
              : `${isIfo ? 'Buy-in starts in:' : 'Staking starts in:'}`}
          </p>
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>~{formatNumber(blocksToDisplayStake, 0, 0)} Blocks</p>
              </div>
            }
          >
            <div className="flex items-center ml-1">
              {(blocksRemainingStake || blocksUntilStake) && currentBlock ? (
                <Countdown zeroPadTime={2} date={timerStake} renderer={renderCountdown} />
              ) : (
                '...'
              )}
              <FiClock className="ml-1" size={18} />
            </div>
          </Tooltip>
        </div>
      )}
      {shouldShowBlockCountdownUnStaking && !isIfo && (
        <div className="flex items-center mb-1 text-white">
          <p>Unstake open in:</p>
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>~{formatNumber(blocksToDisplayStake, 0, 0)} Blocks</p>
              </div>
            }
          >
            <div className="flex items-center ml-1">
              {blocksUntilUnStaking && currentBlock ? (
                <Countdown zeroPadTime={2} date={timerBlockUnstaking} renderer={renderCountdown} />
              ) : (
                '...'
              )}
              <FiClock className="ml-1" size={18} />
            </div>
          </Tooltip>
        </div>
      )}
      {shouldShowBlockCountdown && (
        <div className="flex mb-1">
          <p>
            {hasPoolStarted
              ? `${isIfo ? 'Distribution ends in:' : 'Rewards end in:'}`
              : `${isIfo ? 'Distribution starts in:' : 'Rewards start in:'}`}
          </p>
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>~{formatNumber(blocksToDisplay, 0, 0)} Blocks</p>
              </div>
            }
          >
            <div className="flex items-center ml-1">
              {(blocksRemaining || blocksUntilStart) && currentBlock ? (
                <Countdown zeroPadTime={2} date={timer} renderer={renderCountdown} />
              ) : (
                '...'
              )}
              <FiClock className="ml-1" size={18} />
            </div>
          </Tooltip>
        </div>
      )}
      <div className="flex items-center mb-1 text-white">
        <p>{isIfo ? 'Distribution Period:' : 'Duration:'}</p>
        <p className="ml-1">{durationDisplay ? durationDisplay.toFixed(2) : '...'} days</p>
      </div>
      {shouldShowBlockCountdownClaim && !isIfo && (
          <div className="flex items-center mb-1 text-white">
            <p>Claiming starts in:</p>
            <Tooltip
                tooltip={
                  <div className="whitespace-nowrap">
                    <p>~{formatNumber(blocksToDisplayClaim, 0, 0)} Blocks</p>
                  </div>
                }
            >
              <div className="flex items-center ml-1">
                {blocksToDisplayClaim && currentBlock ? (
                    <Countdown zeroPadTime={2} date={timerBlockClaim} renderer={renderCountdown} />
                ) : (
                    '...'
                )}
                <FiClock className="ml-1" size={18} />
              </div>
            </Tooltip>
          </div>
      )}

    </>
  );
};

export default CountdownCell;
