import Loader from 'components/Loader/Loader';
import CurrencyLogo from 'components/LogoSwap/CurrencyLogo';
import DoubleCurrencyLogo from 'components/LogoSwap/DoubleLogo';
import { useCallback } from 'react';
import { currencyEquals, NativeCurrency, Token } from 'ezcake-v2-sdk';
import { FixedSizeList } from 'react-window';
import { useCurrencyBalance } from 'hooks/wallet';
import { classNames } from 'utils/styling';
import { FiCheck, FiTrash } from 'react-icons/fi';
import { useUserAddedTokens } from 'store/user/hooks';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {useAccount, useChainId} from "wagmi";

function currencyKey(currency) {
  return currency instanceof Token
    ? currency.address
    : currency === NativeCurrency[currency.chainId]
    ? NativeCurrency[currency.chainId].symbol
    : '';
}

function Balance({ balance }) {
  return <p className="text-white/50">{balance.toSignificant(4)}</p>;
}

function CurrencyRow({ currency, onSelect, isSelected, otherSelected, style, isImportedTab, removeImportedToken }) {
    const {address} = useAccount()
  const balance = useCurrencyBalance(address ?? undefined, currency);

  const onClickRemove = (e) => {
    e.stopPropagation();
    removeImportedToken?.(currency);
  };

  const pair = currency?.tokenInfo?.token1 ? currency.tokenInfo : undefined;

  // only show add or remove buttons if not on selected list
  return (
    <div
      style={style}
      className={classNames(
        'flex justify-between text-black items-center relative rounded-md  cursor-pointer',
        !(otherSelected || isSelected) && 'hover:bg-gray-200/30',
        (otherSelected || isSelected) && 'opacity-50',
      )}
      onClick={onSelect}
    >
      <div className="flex items-center px-2">
        {pair ? (
          <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} />
        ) : (
          <CurrencyLogo currency={currency} size={'30px'} />
        )}
        <div className="ml-[9px]">
          <p className="font-bold text-lg text-white leading-6">
            {pair ? (
              <>
                {pair.token0.symbol}/{pair.token1.symbol}
              </>
            ) : (
              currency.symbol
            )}{' '}
          </p>
          <p className="text-sm text-white/50">{currency.name}</p>
        </div>
      </div>
      <div className="mr-2 flex items-center">
        {isImportedTab ? (
          <FiTrash className="text-red-500" size={20} onClick={onClickRemove} />
        ) : balance ? (
          <Balance balance={balance} />
        ) : address ? (
          <Loader />
        ) : null}{' '}
        {isSelected && <FiCheck size={20} className="ml-1 text-white" />}
      </div>
    </div>
  );
}

function ImportRow({ currency, setImportToken, showImportView, style }) {
  return (
    <div style={style} className={classNames('flex justify-between text-black items-center relative rounded-md')}>
      <div className="flex items-center px-2">
        <CurrencyLogo currency={currency} size={'30px'}/>
        <div className="ml-[9px]">
          <p className="font-bold text-lg text-white leading-6">{currency.symbol} </p>
          <p className="text-sm text-white/50">{currency.name}</p>
        </div>
      </div>
      <ButtonCustom
        onClick={() => {
          setImportToken && setImportToken(currency);
          showImportView();
        }}
        className="rounded-md text-sm font-semibold h-[32px]"
      >
        Import
      </ButtonCustom>
    </div>
  );
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showImportView,
  setImportToken,
  isImportedTab,
  removeImportedToken,
}) {
    const chainId = useChainId()
  const tokenImports = useUserAddedTokens();

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency = data[index];
      const isSelected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, currency));
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency));

      const showImport =
        !tokenImports.find((importedToken) => importedToken.address === currency.address) &&
        currency !== NativeCurrency[chainId] &&
        !currency.isWhiteListed;

      const handleSelect = () => onCurrencySelect(currency);

      if (showImport) {
        return (
          <ImportRow
            style={style}
            currency={currency}
            showImportView={showImportView}
            setImportToken={setImportToken}
            dim={true}
          />
        );
      }

      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
          isImportedTab={isImportedTab}
          removeImportedToken={removeImportedToken}
        />
      );
    },
    [
      chainId,
      isImportedTab,
      onCurrencySelect,
      otherCurrency,
      removeImportedToken,
      selectedCurrency,
      setImportToken,
      showImportView,
      tokenImports,
    ],
  );

  const itemKey = useCallback((index, data) => currencyKey(data[index]), []);

  return (
    <FixedSizeList
      className="mt-2 scrollbar-custom"
      height={height}
      ref={fixedListRef}
      width="100%"
      itemData={currencies}
      itemCount={currencies.length}
      itemSize={60}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  );
}
