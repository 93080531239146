import React, { useCallback, useMemo, useState } from 'react';
import useToggle from 'hooks/useToggle';
import { Transition } from '@headlessui/react';
import { FiArrowDown, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';
import Dots from 'components/Loader/Dots';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import InputWithButton from 'components/NumericalInput/InputWithButton';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import BigNumber from 'bignumber.js';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import { classNames } from 'utils/styling';
import { useBuyIDO } from 'hooks/useBuy';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import { useDispatch } from 'react-redux';
import { fetchLandsV2UserDataAsync } from 'store/lands';
import SwitchNetworkButton from 'components/SwitchNetworkButton/SwitchNetworkButton';
import { NETWORK_LABEL } from 'constants/networks';
import Countdown, { zeroPad } from 'react-countdown';
import Badge from 'views/Lands/components/Badge';
import { STATUS_TIME, STATUS_TIME_LABEL } from 'constants/lands';
import useRefresh from 'hooks/useRefresh';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const STATUS = {
  open: 'open',
  opening: 'opening',
  end: 'end',
  endRound: 'endRound',
};

const YourAccount = ({ userDataLoaded, pool }) => {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const [open, toggleOpen] = useToggle(false);
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const { userData } = pool;
  const { paymentTokenBalance, tokenBalance } = userData;

  const { onBuy } = useBuyIDO(pool.idoContract);

  const estimateValue = useMemo(() => {
    if (!value) return '';
    const amount = new BigNumber(value);
    const estimate = amount.multipliedBy(getBalanceNumber(pool.pricePerTokenEstimate, pool.token.decimals));
    return estimate;
  }, [pool.pricePerTokenEstimate, pool.token.decimals, value]);

  const handleTypeInput = (valueInput) => {
    setValue(valueInput);
  };

  const isInsufficientBalance = useMemo(() => {
    return new BigNumber(value).isGreaterThan(getFullDisplayBalance(paymentTokenBalance, pool.tokenPayment.decimals));
  }, [value, paymentTokenBalance, pool.tokenPayment.decimals]);

  const isInsufficientMaxTokenPerRound = useMemo(() => {
    return new BigNumber(estimateValue).isGreaterThan(pool.tokenPerRound);
  }, [estimateValue, pool.tokenPerRound]);

  const isInsufficientTokenRemaining = useMemo(() => {
    return new BigNumber(estimateValue).isGreaterThan(
      getFullDisplayBalance(pool.currentRound.unsold, pool.tokenPayment.decimals),
    );
  }, [estimateValue, pool.currentRound.unsold, pool.tokenPayment.decimals]);

  const handleMaxInput = useCallback(() => {
    setValue(getFullDisplayBalance(paymentTokenBalance, pool.tokenPayment.decimals));
  }, [pool.tokenPayment.decimals, paymentTokenBalance]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (percent === 100) {
        handleMaxInput();
      } else {
        setValue(
          getFullDisplayBalance(
            new BigNumber(paymentTokenBalance).times(percent).dividedBy(100),
            pool.tokenPayment.decimals,
            pool.tokenPayment.decimals,
          ),
        );
      }
    },
    [pool.tokenPayment.decimals, handleMaxInput, paymentTokenBalance],
  );

  const handleBuy = useCallback(async () => {
    try {
      setPendingTx(true);
      await onBuy(value, pool.tokenPayment.decimals);
      dispatch(fetchLandsV2UserDataAsync(account));
      showToastSuccess(`Buy ${pool.token.symbol} successfully`);
      setValue('');
      setPendingTx(false);
    } catch (e) {
      console.log(e);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      setPendingTx(false);
    }
  }, [account, dispatch, onBuy, pool.token.symbol, pool.tokenPayment.decimals, value]);

  const status = useMemo(() => {
    if (pool.endTime < Date.now()) return STATUS.end;
    const unsoldFormat = getBalanceNumber(pool?.currentRound?.unsold, pool?.token?.decimals);

    if (unsoldFormat < 0.1) return STATUS.endRound;

    if (pool.startTime > Date.now()) return STATUS.open;

    return STATUS.opening;
  }, [pool?.currentRound?.unsold, pool.endTime, pool.startTime, pool?.token?.decimals]);

  const rendererStartIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <div />;
    }

    return (
      <div className="flex mt-2 justify-center">
        <Badge className="flex  items-center">
          <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
          Opens in {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </Badge>
      </div>
    );
  };

  const timerRound = useMemo(() => {
    if (pool.currentRound.startTime > Date.now()) {
      return {
        status: STATUS_TIME.open,
        time: pool.currentRound.startTime,
      };
    }

    const unsoldFormat = getBalanceNumber(pool?.currentRound?.unsold, pool?.token?.decimals);

    if (
      pool.currentRound.startTime < Date.now() &&
      pool.currentRound.endTime > Date.now() &&
      pool?.currentRound?.round < pool.totalRound &&
      unsoldFormat < 0.1
    ) {
      return {
        status: STATUS_TIME.nextRound,
        time: pool.currentRound.endTime,
      };
    }

    if (pool.currentRound.startTime < Date.now() && pool.currentRound.endTime > Date.now()) {
      return {
        status: STATUS_TIME.ongoing,
        time: pool.currentRound.endTime,
      };
    }

    if (pool.currentRound.endTime < Date.now() || (pool.currentRound === pool.totalRound && unsoldFormat < 0.1)) {
      return {
        status: STATUS_TIME.end,
      };
    }
  }, [pool, fastRefresh]);

  const rendererRoundStartIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="flex mt-2 justify-center sm:justify-start">
          <Badge className="!text-sm-md flex justify-start items-center text-red-600 bg-red-opacity-25">
            <div className="rounded-50 p-1 mr-1 bg-red-600 mr-2" />
            Close
          </Badge>
        </div>
      );
    }

    return (
      <div className="flex mt-2 justify-center">
        <Badge className="flex  items-center !text-sm-md">
          <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
          {STATUS_TIME_LABEL[timerRound?.status]} {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m{' '}
          {zeroPad(seconds)}s
        </Badge>
      </div>
    );
  };

  return (
    <div className="mt-5 text-white max-w-md w-full mx-auto">
      <p onClick={toggleOpen} className="font-bold flex items-center justify-center cursor-pointer">
        Your Account {open ? <FiChevronUp /> : <FiChevronDown />}
      </p>
      <Transition
        show={!open}
        unmount={false}
        enter="transition ease duration-300 transform"
        enterFrom="opacity-0 -translate-y-12"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease duration-200 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-12"
      >
        <div className="rounded-2xl border border-yellow-800 bg-black1 p-3 mt-4">
          <div>
            <div className="flex font-bold">
              <p>Balance:</p>
              <div className="ml-2">
                <div>
                  {account ? (
                    userDataLoaded ? (
                      getFullDisplayBalance(paymentTokenBalance, pool.tokenPayment.decimals)
                    ) : (
                      <Dots />
                    )
                  ) : (
                    '???'
                  )}{' '}
                  {pool.tokenPayment.symbol}
                </div>
                <div>
                  {account ? (
                    userDataLoaded ? (
                      getFullDisplayBalance(tokenBalance, pool.token.decimals)
                    ) : (
                      <Dots />
                    )
                  ) : (
                    '???'
                  )}{' '}
                  {pool.token.symbol}
                </div>
              </div>
            </div>
            <div className="mt-2">
              {status === STATUS.opening ? (
                <div>
                  <p className="mb-2">Pay</p>
                  <div className="flex-1">
                    <InputWithButton value={value} onUserInput={handleTypeInput} btnOnClick={handleMaxInput} />
                  </div>
                  <PercentPicker isNumber={true} className="text-black" onChangePercentInput={handlePercentInput} />
                  <div className="flex justify-center">
                    <div className="rounded-lg p-1 cursor-pointer my-2 hover:bg-black1">
                      <FiArrowDown size={20} color="white" />
                    </div>
                  </div>
                  <div>
                    <p className="mb-2">Receive (estimate)</p>
                    <input
                      className={classNames(
                        'border-primary bg-transparent border-2 rounded-2xl w-full py-2 pl-2 pr-4 truncate',
                      )}
                      value={estimateValue}
                      // universal input options
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      disabled
                      // text-specific options
                      type="text"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      placeholder="0.0"
                      minLength={1}
                      maxLength={79}
                      spellCheck="false"
                    />
                  </div>
                  <div className="mt-4 text-black">
                    {chainId === pool.chainId ? (
                      account ? (
                        userDataLoaded ? (
                          <ButtonCustom
                            disabled={pendingTx || !new BigNumber(value).isGreaterThan(0) || isInsufficientBalance}
                            isLoading={pendingTx}
                            onClick={handleBuy}
                            className="w-full"
                          >
                            {isInsufficientBalance
                              ? `Insufficient balance`
                              : isInsufficientMaxTokenPerRound
                              ? `Exceed max token per round`
                              : isInsufficientTokenRemaining
                              ? `Insufficient token remaining`
                              : pendingTx
                              ? 'Pending Confirmation'
                              : 'Confirm'}
                          </ButtonCustom>
                        ) : (
                          <ButtonCustom className="w-full" isLoading>
                            Loading <Dots />
                          </ButtonCustom>
                        )
                      ) : (
                        <UnlockButton />
                      )
                    ) : (
                      <div>
                        <p className="text-red-500 text-sm-md mb-1">
                          Wrong network, please use {NETWORK_LABEL[pool.chainId]}
                        </p>
                        <SwitchNetworkButton chainId={pool.chainId} />
                      </div>
                    )}
                  </div>
                </div>
              ) : status === STATUS.open ? (
                <div>
                  <Countdown zeroPadTime={2} date={pool.startTime} renderer={rendererStartIn} />
                  {!account && <UnlockButton />}
                </div>
              ) : (
                <div>
                  <p className="text-primary font-bold text-center mb-1">
                    {status === STATUS.end ? 'Sale has ended.' : 'Round has ended. Please wait for next round.'}
                    {status === STATUS.endRound && timerRound?.time && (
                      <Countdown zeroPadTime={2} date={timerRound.time} renderer={rendererRoundStartIn} />
                    )}
                  </p>
                  {!account && <UnlockButton />}
                </div>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default YourAccount;
