import React from 'react';
import Card from 'components/Card/Card';
import { useNavigate } from 'react-router-dom';
import { ICON_CHAIN_URL } from 'constants/index';
import { useNFT } from '../../../store/NFTs/hook';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';

const NFTCard = ({ nft }) => {
  const { chainId } = useActiveWeb3React();
  const NFTProject = useNFT(nft?.slug, chainId);
  const navigate = useNavigate();
  return (
    <Card
      className="m-4 overflow-hidden box-shadow-nft-card cursor-pointer"
      onClick={() => navigate(`/nft-collection/${nft?.slug}/${nft?.token_id}`)}
    >
      <div className="relative overflow-hidden">
        <img
          src={
            Number(nft?.token_id) && Number(nft?.token_id) % NFTProject?.lootChestNumber === 0
              ? '/images/loot-chest.jpg'
              : nft?.image
          }
          style={{
            maxHeight: 290,
          }}
        />
        <div className="absolute right-2 top-2">
          <img
            src={ICON_CHAIN_URL[nft?.chainId]}
            style={{
              width: 25,
            }}
            className="mr-2"
          />
        </div>
      </div>

      <div className="py-4">
        <p className="text-white text-center text-lg">#{nft?.token_id}</p>
        {nft?.description ? <p className="text-white text-center">{nft?.description}</p> : null}
        {nft?.attributes?.map((item, index) => (
          <h3 className="text-white text-center" key={index}>
            <span className="capitalize">{item?.trait_type}</span>: <span className="capitalize">{item?.value}</span>
          </h3>
        ))}
      </div>
    </Card>
  );
};

export default NFTCard;
