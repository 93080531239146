export const getTokenName = (token0, token1, tokenLp, hideSymbolLp = false) => {
  const name = token1
    ? `${token0?.symbol}-${token1?.symbol} ${!hideSymbolLp ? tokenLp?.symbol : ''}`
    : token0?.symbol ?? '';

  return name;
};

export const getTokenNameVault = (token0, token1, tokenLp, hideSymbolLp = false) => {
  const name = token1 ? (
    <span>
      {token0?.symbol}-{token1?.symbol} <br className="block sm:hidden" /> {!hideSymbolLp ? tokenLp?.symbol : ''}
    </span>
  ) : (
    token0?.symbol ?? ''
  );

  return name;
};

export const getPoolName = (earningTokens) => {
  return earningTokens.reduce((acc, earningToken, index) => {
    return acc + `${index !== 0 ? ', ' : ''}${earningToken.symbol}`;
  }, '');
};
