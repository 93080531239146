import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useRefresh from 'hooks/useRefresh';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFarmsDataAsync } from 'store/farms/index';
import { fetchCastlesPublicDataAsync, fetchCastlesStakingLimitsAsync } from 'store/castles/index';
import { getBlockNumber } from 'utils/index';
import { getPrices } from 'utils/priceHelpers';
import {setPricesData} from "./prices";
import {useChainId} from "wagmi";

const fetchDataWithChain = {
  [ChainId.KAI]:  () => {},
  [ChainId.BSC]: async (dispatch, chainId) => {
    const [prices, blockNumber] = await Promise.all([getPrices(chainId), getBlockNumber(chainId)]);
    // farms
    // dispatch(fetchFarmsDataAsync(prices, chainId));
    // dispatch(fetchFarmsOutsideDataAsync(prices, chainId));
    dispatch(setPricesData({prices, chainId}));

    // pools
    dispatch(fetchCastlesStakingLimitsAsync(chainId));
    dispatch(fetchCastlesPublicDataAsync(blockNumber, chainId));
  },
  [ChainId.HARMONY]:  () => {},
  [ChainId.ONUS]: () => {},
  [ChainId.ONUS_TESTNET]: async () => {},
  [ChainId.BSC_TESTNET]: async (dispatch, chainId) => {
    const [prices, blockNumber] = await Promise.all([getPrices(chainId), getBlockNumber(chainId)]);

    // farms
    dispatch(fetchFarmsDataAsync(prices, chainId));

    // pools
    dispatch(fetchCastlesStakingLimitsAsync(chainId));
    dispatch(fetchCastlesPublicDataAsync(blockNumber, chainId));
  },
};
export const useFetchPublicData = () => {
  const chainId = useChainId();
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    if (chainId) {
      fetchDataWithChain[chainId](dispatch, chainId);
    }
  }, [slowRefresh, chainId, dispatch]);
};
