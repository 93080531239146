import AppHeader from 'components/AppHeader/AppHeader';
import Card from 'components/Card/Card';
import CurrencyInputPanel from 'components/CurrencyInputPanel/index';
import DoubleCurrencyLogo from 'components/LogoSwap/DoubleLogo';
import { MinimalPositionCard } from 'components/PositionCard/PositionCard';
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal/index';
import UnlockButton from 'components/UnlockButton/UnlockButton';
import { ROUTER_ADDRESS } from 'constants/swap';
import { useCurrency } from 'hooks/Tokens';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { PairState } from 'hooks/usePairs';
import { useCallback, useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { ChainId, CurrencyAmount, currencyEquals, NativeCurrency, Percent, WETH } from 'ezcake-v2-sdk';
import { Field } from 'store/mint/actions';
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from 'store/mint/hooks';
import { useTransactionAdder } from 'store/transactions/hooks';
import { useIsExpertMode, useUserDeadline, useUserSlippageTolerance } from 'store/user/hooks/index';
import currencyId from 'utils/currencyId';
import {calculateGasMargin, calculateSlippageAmount, isZero} from 'utils/index';
import maxAmountSpend from 'utils/maxAmountSpend';
import { wrappedCurrency } from 'utils/wrappedCurrency';
import ConfirmAddModalBottom from 'views/AddLiquidity/ConfirmAddModalBottom';
import PoolPriceBar from 'views/AddLiquidity/PoolPriceBar';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import SelectIconButton from 'components/CurrencyInputPanel/SelectIconButton';
import {useAccount, useChainId, useConfig} from "wagmi";
import {estimateGas, writeContract} from "@wagmi/core";
import {useRouterContract} from "../../hooks/useContract";

export default function AddLiquidity() {
  const { address } = useAccount();
  const chainId = useChainId()
  const config = useConfig()
  const { currencyIdA, currencyIdB } = useParams();
  const navigate = useNavigate();
  const routerContract = useRouterContract()

  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId]))),
  );

  const expertMode = useIsExpertMode();

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

  // txn values
  const [deadline] = useUserDeadline(); // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
  const [txHash, setTxHash] = useState('');

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  // get the max amounts user can add
  const maxAmounts = [Field.CURRENCY_A, Field.CURRENCY_B].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field]),
    };
  }, {});

  // const atMaxAmounts = [Field.CURRENCY_A, Field.CURRENCY_B].reduce((accumulator, field) => {
  //   return {
  //     ...accumulator,
  //     [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
  //   }
  // }, {})

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], ROUTER_ADDRESS[chainId]);
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], ROUTER_ADDRESS[chainId]);

  const addTransaction = useTransactionAdder();

  async function onAdd() {
    if (!chainId || !config || !address) return;
    // const router = getRouterContract(ROUTER_ADDRESS[chainId], library.getSigner(), chainId);

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts;
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    };

    let estimate;
    let method;
    let args;
    let value;
    if (currencyA === NativeCurrency[chainId] || currencyB === NativeCurrency[chainId]) {
      const tokenBIsETH = currencyB === NativeCurrency[chainId];
      method = 'addLiquidityETH';
      estimate = 'addLiquidityETH';
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        address,
        `0x${(Math.floor(new Date().getTime() / 1000) + deadline).toString(16)}`,
      ];
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString());
    } else {
      method = 'addLiquidity';
      estimate = 'addLiquidity';
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        address,
        `0x${(Math.floor(new Date().getTime() / 1000) + deadline).toString(16)}`,
      ];
      value = null;
    }

    setAttemptingTxn(true);

    await estimateGas(config, {
      chainId,
      from: address,
      to: routerContract.address,
      data: routerContract.interface.encodeFunctionData(method, args),
      ...(value ? { value } : {}),
    })
        .then((estimatedGasLimit) =>
            writeContract(config, {
              chainId,
              abi: routerContract.abi,
              address: routerContract.address,
              functionName: method,
              args,
              ...(value && !isZero(value) ? { value } : {}),
              // gasLimit: calculateGasMargin(gasEstimate),
            }).then((response) => {
              setAttemptingTxn(false);

              addTransaction(response, {
                summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
                    currencies[Field.CURRENCY_A]?.symbol
                } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
              });

              setTxHash(response);
            }),
        )
        .catch((err) => {
          setAttemptingTxn(false);
          // we only care if the error is something _other_ than the user rejected the tx
          if (err?.code !== 4001) {
            console.error(err);
          }
        })

    // await estimate(...args, value ? { value } : {})
    //   .then((estimatedGasLimit) =>
    //     method(...args, {
    //       ...(value ? { value } : {}),
    //       gasLimit: calculateGasMargin(estimatedGasLimit),
    //     }).then((response) => {
    //       setAttemptingTxn(false);
    //
    //       addTransaction(response, {
    //         summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
    //           currencies[Field.CURRENCY_A]?.symbol
    //         } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
    //       });
    //
    //       setTxHash(response.hash);
    //     }),
    //   )
    //   .catch((err) => {
    //     setAttemptingTxn(false);
    //     // we only care if the error is something _other_ than the user rejected the tx
    //     if (err?.code !== 4001) {
    //       console.error(err);
    //     }
    //   });
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <div className="flex items-center">
        <p className="text-4xl">{`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}</p>
        <DoubleCurrencyLogo
          currency0={currencies[Field.CURRENCY_A]}
          currency1={currencies[Field.CURRENCY_B]}
          size={30}
        />
      </div>
    ) : (
      <div>
        <div className="flex items-center">
          <p className="text-4xl font-bold">{liquidityMinted?.toSignificant(6)}</p>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </div>
        <div>
          <p className="text-xl my-2">
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
          </p>
        </div>
        <p className="text-md italic mb-2">
          Output is estimated. If the price changes by more than {allowedSlippage / 100} your transaction will revert.
        </p>
      </div>
    );
  };

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    );
  };

  const pendingText = useMemo(() => {
    const amountA = parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '';
    const symbolA = currencies[Field.CURRENCY_A]?.symbol ?? '';
    const amountB = parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '';
    const symbolB = currencies[Field.CURRENCY_B]?.symbol ?? '';
    return `Supplying ${amountA} ${symbolA} and ${amountB} ${symbolB}`;
  }, [currencies, parsedAmounts]);

  const handleCurrencyASelect = useCallback(
    (currencyA_) => {
      const newCurrencyIdA = currencyId(currencyA_);
      if (newCurrencyIdA === currencyIdB) {
        navigate(`/add/${currencyIdB}/${currencyIdA}`);
      } else {
        navigate(`/add/${newCurrencyIdA}/${currencyIdB}`);
      }
    },
    [currencyIdB, currencyIdA],
  );
  const handleCurrencyBSelect = useCallback(
    (currencyB_) => {
      const newCurrencyIdB = currencyId(currencyB_);
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          navigate(`/add/${currencyIdB}/${newCurrencyIdB}`);
        } else {
          navigate(`/add/${newCurrencyIdB}`);
        }
      } else {
        navigate(`/add/${currencyIdA || NativeCurrency[chainId]}/${newCurrencyIdB}`);
      }
    },
    [currencyIdA, currencyIdB],
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('');
    }
    setTxHash('');
  }, [onFieldAInput, txHash]);

  const handlePercentInput = useCallback(
    (percent) => {
      if (!currencyA) return;
      if (percent.equalTo(new Percent('100'))) {
        onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
      } else {
        onFieldAInput(
          new CurrencyAmount(currencyA, percent.multiply(maxAmounts[Field.CURRENCY_A].raw).quotient).toExact(),
        );
      }
    },
    [currencyA, maxAmounts, onFieldAInput],
  );

  const handlePercentOutput = useCallback(
    (percent) => {
      if (!currencyB) return;
      if (percent.equalTo(new Percent('100'))) {
        onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
      } else {
        onFieldBInput(
          new CurrencyAmount(currencyB, percent.multiply(maxAmounts[Field.CURRENCY_B].raw).quotient).toExact(),
        );
      }
    },
    [currencyB, maxAmounts, onFieldBInput],
  );

  return (
    <>
      <TransactionConfirmationModal
        open={showConfirm}
        title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        content={() => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />}
        pendingText={pendingText}
        currencyToAdd={pair?.liquidityToken}
      />
      <Card className="max-w-3xl mx-auto p-5 border border-yellow-800 text-white">
        <AppHeader
          title="Add Liquidity"
          backTo="/liquidity"
          helper="Tip: When you add liquidity, you will receive pool tokens representing your position.
              These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any
              time."
        />
        <div>
          <p className="font-bold text-2xl mb-3">Choose Token Pair</p>
          <div>
            <SelectIconButton
              onCurrencyASelect={handleCurrencyASelect}
              currencyA={currencies[Field.CURRENCY_A]}
              onCurrencyBSelect={handleCurrencyBSelect}
              currencyB={currencies[Field.CURRENCY_B]}
              showCommonBases
            />
          </div>
        </div>
        <div>
          <div>
            {noLiquidity && (
              <div className="bg-black1 p-4 my-4 rounded">
                <div className="mb-2">
                  <div className="text-white">
                    <p className="font-bold">You are the first liquidity provider.</p>
                    <p>The ratio of tokens you add will set the price of this pool.</p>
                    <p>Once you are happy with the rate click supply to review.</p>
                  </div>
                </div>
              </div>
            )}
            <p className="font-bold text-lg mb-2 mt-11">Deposit amount</p>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
              }}
              // showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              onCurrencySelect={handleCurrencyASelect}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
              showUSDT
              disableCurrencySelect
              onChangePercentInput={handlePercentInput}
            />
            {/* <PercentPicker onChangePercentInput={handlePercentInput} /> */}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
              }}
              // showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases
              showUSDT
              disableCurrencySelect
              onChangePercentInput={handlePercentOutput}
            />
            {/* <PercentPicker onChangePercentInput={handlePercentOutput} /> */}

            {currencies[Field.CURRENCY_A] &&
              currencies[Field.CURRENCY_B] &&
              pairState !== PairState.INVALID &&
              noLiquidity && (
                <>
                  <div className="p-2 text-white mt-4">
                    <div>
                      <p className="text-white mb-2 text-lg font-bold">
                        {noLiquidity ? 'Initial prices and pool share' : 'Prices and pool share'}
                      </p>
                    </div>{' '}
                    <div>
                      <PoolPriceBar
                        currencies={currencies}
                        poolTokenPercentage={poolTokenPercentage}
                        noLiquidity={noLiquidity}
                        price={price}
                      />
                    </div>
                  </div>
                </>
              )}

            {!address ? (
              <UnlockButton className="mt-5 font-bold" />
            ) : (
              <div className="mt-4">
                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING) &&
                  isValid && (
                    <div className="flex justify-between mb-2">
                      {approvalA !== ApprovalState.APPROVED && (
                        <ButtonCustom
                          style={{
                            width: approvalB !== ApprovalState.APPROVED ? '48%' : '100%',
                          }}
                          className="w-full"
                          onClick={approveACallback}
                          disabled={approvalA === ApprovalState.PENDING}
                          isLoading={approvalA === ApprovalState.PENDING}
                        >
                          {approvalA === ApprovalState.PENDING
                            ? `Approving ${currencies[Field.CURRENCY_A]?.symbol}`
                            : `Approve ${currencies[Field.CURRENCY_A]?.symbol}`}
                        </ButtonCustom>
                      )}
                      {approvalB !== ApprovalState.APPROVED && (
                        <ButtonCustom
                          style={{
                            width: approvalA !== ApprovalState.APPROVED ? '48%' : '100%',
                          }}
                          className="w-full"
                          onClick={approveBCallback}
                          disabled={approvalB === ApprovalState.PENDING}
                          isLoading={approvalB === ApprovalState.PENDING}
                        >
                          {approvalB === ApprovalState.PENDING
                            ? `Approving ${currencies[Field.CURRENCY_B]?.symbol}`
                            : `Approve ${currencies[Field.CURRENCY_B]?.symbol}`}
                        </ButtonCustom>
                      )}
                    </div>
                  )}
                <ButtonCustom
                  className="w-full"
                  color={
                    !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                      ? 'danger'
                      : 'primary'
                  }
                  onClick={() => {
                    if (expertMode) {
                      onAdd();
                    } else {
                      setShowConfirm(true);
                    }
                  }}
                  disabled={
                    !isValid ||
                    approvalA !== ApprovalState.APPROVED ||
                    approvalB !== ApprovalState.APPROVED ||
                    (chainId === ChainId.KAI ? noLiquidity : false)
                  }
                >
                  {chainId === ChainId.KAI ? (noLiquidity ? 'Invalid pair' : error ?? 'Supply') : error ?? 'Supply'}
                </ButtonCustom>
              </div>
            )}
          </div>
        </div>
      </Card>
      {pair && !noLiquidity && pairState !== PairState.INVALID ? (
        <div className="mx-auto" style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
        </div>
      ) : null}
    </>
  );
}
