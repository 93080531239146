import PoolTable from 'views/Analytics/components/InfoTables/PoolsTable';
import { useWatchlistPools } from 'store/user/hooks';
import Card from 'components/Card/Card';

const PoolsOverview = () => {
  const [savedPools] = useWatchlistPools();

  return (
    <>
      <h1 className="mb-5 text-primary text-4xl font-bold">Your Watchlist</h1>
      {savedPools.length > 0 ? (
        <PoolTable isShowWatchList />
      ) : (
        <Card className="p-5">
          <p>Saved pools will appear here</p>
        </Card>
      )}
      <h2 className="mt-10 mb-5 font-bold text-2xl">All Pools</h2>
      <PoolTable />
    </>
  );
};

export default PoolsOverview;
