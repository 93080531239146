import { ERC20_ABI, ERC20_BYTES32_ABI } from 'config/abi/erc20';
import {  MULTICALL_ADDRESS, MULTICALL_V3_ADDRESS, REFRESH_ADDRESS } from 'constants/contracts';
import { useMemo } from 'react';
import MULTICALL_ABI from 'config/abi/multicall.json';
import MULTICALL_V3_ABI from 'config/abi/multicall_V3.json';
import { ChainId, WETH } from 'ezcake-v2-sdk';
import { ROUTER_ADDRESS } from 'constants/swap';
import WETH_ABI from 'config/abi/weth.json';
import ROUTER_ABI from 'config/abi/router.json';
import ROUTER_KAI_ABI from 'config/abi/routerKAI.json';
import VAULT_ABI from 'config/abi/vaultAbi.json';
import VAULT_V2_ABI from 'config/abi/vault_V2_Abi.json';
import DRAGON_ABI from 'config/abi/dragon.json';
import tokens from 'constants/tokens';
import REFRESH_ABI from 'config/abi/refresh.json';
import MASTERCHEF_HARMONY_ABI from 'config/abi/masterchefHarmony.json';
import MASTERCHEF_ABI from 'config/abi/masterchef.json';
import LOCK_POOL_ABI from 'config/abi/lockPool.json';
import SOUSCHEF_ABI from 'config/abi/sousChef.json';
import LOOT_DRAGON_ABI from 'config/abi/lootDragon.json';
import DRAGON_BATTLE_ABI from 'config/abi/dragonBattle.json';
import DRAGON_BATTLE_BOSS_ABI from 'config/abi/dragonBattleBoss.json';
import IDO1_ABI from 'config/abi/IDO1.json';
import IDO_LINEAR_ABI from 'config/abi/IDOLinear.json';
import WRAPLP_ABI from 'config/abi/wrapLP.json';
import IDO_V2_ABI from 'config/abi/IDO_V2_ABI.json';
import { useChainId} from "wagmi";
import { Interface } from "ethers/lib/utils"

// account is optional
// export function getContract(address, ABI, chainId, account, library) {
//   if (!isAddress(address) || address === AddressZero) {
//     throw Error(`Invalid 'address' parameter '${address}'.`);
//   }
//
//   const provider = account ? library.getSigner(account).connectUnchecked() : simpleRpcProvider[chainId];
//
//   return new ethers.Contract(address, ABI, provider);
// }

function useContract(address, ABI) {
  const chainId = useChainId()

  return useMemo(() => {
    if (!address || !ABI) return null;
    try {
      return  {
        address: address,
        interface: new Interface(ABI),
        abi: ABI,
      };
      // return getContract({
      //   address, abi: ABI, chainId, client: config
      // });
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, chainId]);
}

export function useTokenContract(tokenAddress) {
  return useContract(tokenAddress, ERC20_ABI);
}

export function useBytes32TokenContract(tokenAddress) {
  return useContract(tokenAddress, ERC20_BYTES32_ABI);
}

export function useMulticallContract() {
  const chainId = useChainId()
  return useContract(MULTICALL_ADDRESS[chainId], MULTICALL_ABI, false);
}

export function useMulticallV3Contract() {
  const chainId = useChainId()
  return useContract(MULTICALL_V3_ADDRESS[chainId], MULTICALL_V3_ABI, false);
}

export function useWETHContract() {
  const chainId = useChainId()
  return useContract(WETH[chainId].address, WETH_ABI);
}

export const ROUTER_ABI_WITH_CHAIN = {
  [ChainId.KAI]: ROUTER_KAI_ABI,
  [ChainId.ONUS]: ROUTER_ABI,
  [ChainId.ONUS_TESTNET]: ROUTER_ABI,
  [ChainId.BSC_TESTNET]: ROUTER_ABI,
  [ChainId.BSC]: ROUTER_ABI,
};

export function useRouterContract() {
  const chainId = useChainId()
  return useContract(ROUTER_ADDRESS[chainId], ROUTER_ABI_WITH_CHAIN[chainId]);
}

export function useTokenWrapContract(tokenAddress) {
  const chainId = useChainId()
  return useContract(
    tokenAddress,
    tokenAddress === tokens.cakelp1.address[chainId] || tokenAddress === tokens.cakelp2.address[chainId]
      ? WRAPLP_ABI
      : DRAGON_ABI,
  );
}

export const VAULT_ABI_WITH_CHAIN = {
  [ChainId.KAI]: VAULT_ABI,
  [ChainId.BSC]: VAULT_ABI,
  [ChainId.ONUS]: VAULT_V2_ABI,
};

export const useVaultContract = (vaultAddress) => {
  const chainId = useChainId()
  return useContract(vaultAddress, VAULT_ABI_WITH_CHAIN[chainId]);
};

export function useRefreshContract() {
  const chainId = useChainId()
  return useContract(REFRESH_ADDRESS[chainId], REFRESH_ABI);
}

export const MASTERCHEF_ABI_WITH_CHAIN = {
  [ChainId.KAI]: MASTERCHEF_ABI,
  [ChainId.BSC]: MASTERCHEF_ABI,
  [ChainId.HARMONY]: MASTERCHEF_HARMONY_ABI,
  [ChainId.ONUS]: MASTERCHEF_HARMONY_ABI,
  [ChainId.BSC_TESTNET]: MASTERCHEF_HARMONY_ABI,
};

export const useMasterChefContract = (masterChefAddress) => {
  const chainId = useChainId()
  return useContract(masterChefAddress, MASTERCHEF_ABI_WITH_CHAIN[chainId]);
};

export const useLockPoolContract = (contractAddress) => {
  return useContract(contractAddress, LOCK_POOL_ABI);
};

export const useSousChefContract = (poolAddress) => {
  return useContract(poolAddress, SOUSCHEF_ABI);
};

export const useMintDragonContract = (poolAddress) => {
  return useContract(poolAddress, LOOT_DRAGON_ABI);
};

export const useBattleContract = (battleAddress) => {
  return useContract(battleAddress, DRAGON_BATTLE_ABI);
};

export const useBattleBossContract = (battleAddress) => {
  return useContract(battleAddress, DRAGON_BATTLE_BOSS_ABI);
};

export const useIDOLaunchpadContract = (contract) => {
  return useContract(contract, IDO1_ABI);
};

export const useIDOLinearContract = (contract) => {
  return useContract(contract, IDO_LINEAR_ABI);
};

export const useIDOV2Contract = (idoAddress) => {
  return useContract(idoAddress, IDO_V2_ABI);
};
