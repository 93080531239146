import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import PropTypes from 'prop-types';
import { BIG_TEN } from 'utils/bigNumber';
import { getLpBreakdown } from 'utils/farmsHelpers';
import { formatNumber, getBalanceNumber } from 'utils/formatBalance';
import ToggleShowCell from 'views/Vaults/components/VaultsTable/Table/Cells/ToggleShowCell';

const VaultAssetsCell = ({ row }) => {
  const vault = row.original;
  const [q0Vault, q1Vault] = getLpBreakdown(
    vault?.totalStaked.div(BIG_TEN.pow(vault.stakingToken.decimals)).toNumber(),
    vault?.farmStaked?.totalSupply,
    vault.farmStaked?.q0,
    vault.farmStaked?.q1,
  );

  return (
    <div className="flex justify-between">
      {vault.stakedTvl ? (
        <Tooltip
          tooltip={
            <div className="whitespace-nowrap">
              <p>Total Deposits</p>
              <p>
                {formatNumber(getBalanceNumber(vault?.totalStaked, vault.stakingToken.decimals))}{' '}
                {vault.stakingToken.symbol}
              </p>
              {vault.token1 && vault.farmStaked && (
                <p>
                  {vault?.totalStaked.gt(0) && (
                    <>
                      {formatNumber(getBalanceNumber(q0Vault, vault.farmStaked.token0.decimals), 2, 4)}{' '}
                      {vault.farmStaked.token0.symbol} +{' '}
                      {formatNumber(getBalanceNumber(q1Vault, vault.farmStaked.token1.decimals), 2, 4)}{' '}
                      {vault.farmStaked.token1.symbol}
                    </>
                  )}
                </p>
              )}
            </div>
          }
        >
          <Value prefix="$" value={vault.stakedTvl} decimals={0} />
        </Tooltip>
      ) : (
        <p className="text-white">...</p>
      )}
      <div className="sm:hidden">
        <ToggleShowCell row={row} />
      </div>
    </div>
  );
};

VaultAssetsCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export default VaultAssetsCell;
