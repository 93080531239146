import useSWR from 'swr';
import { analyticsUrlApi } from 'api/url';
import { ITEMS_PER_INFO_TABLE_PAGE } from 'constants/analytics';
import { fetcherAnalytics } from 'api/axiosClient';
import { mapTokenDayData, mapTokenPriceData, parseTokenData } from 'store/analytics/queries/helpers';
import useSwrInfiniteWithScroll from 'hooks/swr/useSwrInfiniteWithScroll';
import {useChainId} from "wagmi";

export const useSWRTokens = (query) => {
  const chainId = useChainId()

  const { data, ...rest } = useSWR(
[    analyticsUrlApi.getTokens({
      rowPerPage: ITEMS_PER_INFO_TABLE_PAGE,
      ...query,
    }), chainId],
      (url)=> fetcherAnalytics(url[0], chainId),
  );

  return {
    tokenDatas: data ? data?.data?.map?.(parseTokenData) : [],
    maxPage: data ? data?.totalPage : 1,
    ...rest,
  };
};

export const useSWRTokenData = (address) => {
  const chainId = useChainId()
  const { data, ...rest } = useSWR(address ? [analyticsUrlApi.getTokenData(address), chainId] : null, (url)=> fetcherAnalytics(url?.[0], chainId), {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return {
    tokenData: data ? parseTokenData(data) : undefined,
    ...rest,
  };
};

export const useSWRTokenChartData = (address) => {
  const chainId = useChainId()
  const { data, ...rest } = useSWR([analyticsUrlApi.getTokenChartData(address), chainId], (url)=> fetcherAnalytics(url[0], chainId));

  return {
    chartData: data ? data.map(mapTokenDayData) : [],
    ...rest,
  };
};

export const useSWRTokenPriceChartData = (address) => {
  const chainId = useChainId()
  const { data, ...rest } = useSWR([analyticsUrlApi.getTokenPriceChartData(address), chainId], (url)=> fetcherAnalytics(url[0], chainId));

  return {
    priceData: data ? data.map(mapTokenPriceData) : [],
    ...rest,
  };
};

export const getKeyUrlListTokens = (query) => {
  return analyticsUrlApi.getTokens(query);
};

export const useSWRSearchTokensInfinite = (query) => {
  const { dataShowing, ...rest } = useSwrInfiniteWithScroll({
    query: query,
    getKeyUrl: getKeyUrlListTokens,
    config: {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  });

  return {
    tokenDatas: dataShowing ? dataShowing.map(parseTokenData) : [],
    ...rest,
  };
};
