// eslint-disable-next-line camelcase
import useSWR, { useSWRConfig, unstable_serialize } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { FAST_INTERVAL, SLOW_INTERVAL } from '../../config';
import {useChainId, useConfig} from "wagmi";
import { getBlockNumber } from '@wagmi/core'

const REFRESH_BLOCK_INTERVAL = 6000;

export const usePollBlockNumber = () => {
  const { cache, mutate } = useSWRConfig();
  const config = useConfig()
    const chainId = useChainId();

  const { data } = useSWR(
    ['blockNumber', chainId],
    async () => {
      const blockNumber = await getBlockNumber(config)
        if (!cache.get(unstable_serialize(['initialBlockNumber', chainId]))) {
        mutate(['initialBlockNumber', chainId], blockNumber.toString());
      }
      return blockNumber.toString();
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    },
  );

  useSWR(
    [FAST_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data;
    },
    {
      refreshInterval: FAST_INTERVAL,
    },
  );

  useSWR(
    [SLOW_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data;
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  );
};

export const useCurrentBlock = () => {
    const chainId = useChainId()
  const { data: currentBlock = 0 } = useSWRImmutable(['blockNumber', chainId]);
  return currentBlock;
};

export const useInitialBlock = () => {
    const chainId = useChainId();
  const { data: initialBlock = 0 } = useSWRImmutable(['initialBlockNumber', chainId]);
  return initialBlock;
};
