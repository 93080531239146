import BigNumber from 'bignumber.js';
import tokens from 'constants/tokens';
import vaultsConfig, { PLATFORM } from 'constants/vaults';
import { getVaultApy, getVaultV2Apy } from 'utils/apr';
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber';
import { getFullDisplayBalance } from 'utils/formatBalance';
import { getParameterCaseInsensitive } from 'utils/index';

export const transformVault = (pool) => {
  const { totalStaked, userData, pendingReward, ...rest } = pool;

  return {
    ...rest,
    totalStaked: totalStaked ? new BigNumber(totalStaked) : BIG_ZERO,
    pendingReward: pendingReward ? new BigNumber(pendingReward) : BIG_ZERO,
    userData: transformUserData(userData),
  };
};

export const transformVaultV2 = (pool) => {
  const { totalStaked, totalStakedOfStrategy, totalShare, userData, totalSupply, ...rest } = pool;

  return {
    ...rest,
    totalStaked: totalStaked ? new BigNumber(totalStaked) : BIG_ZERO,
    totalStakedOfStrategy: totalStaked ? new BigNumber(totalStakedOfStrategy) : BIG_ZERO,
    totalShare: totalShare ? new BigNumber(totalShare) : BIG_ZERO,
    totalSupply: totalSupply ? new BigNumber(totalSupply) : BIG_ZERO,
    userData: transformUserData(userData),
  };
};

export const transformUserData = (userData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    sharesBalance: userData ? new BigNumber(userData.sharesBalance) : BIG_ZERO,
  };
};

export function getVaultStaked(vaultV2, chainId) {
  return vaultsConfig[chainId].find(
    (vaultV1) => vaultV1?.contractAddress?.toLowerCase() === vaultV2?.contractVaultStakedAddress?.toLowerCase(),
  );
}

export const formatVault = (prices, farms, farmsOutside, farmsOutsideV2, vault, chainId) => {
  const farmsChosen = {
    [PLATFORM.eCake]: farms,
    [PLATFORM.cake]: farmsOutsideV2,
    [PLATFORM.mia]: farmsOutsideV2,
  };

  const farmsToFind = farmsChosen[vault.platform];
  const stakingTokenAddress = vault?.stakingToken.address[chainId];

  const farm =
    farmsToFind &&
    farmsToFind?.find((farm) => {
      const lpAddress = typeof farm?.lpAddress === 'string' ? farm?.lpAddress : farm?.lpAddress[chainId];

      return lpAddress.toLowerCase() === stakingTokenAddress.toLowerCase();
    });

  const apy = getVaultApy(farm?.apr?.yearlyAPR, vault.fees, chainId);

  const stakingTokenPrice = getParameterCaseInsensitive(
    prices,
      stakingTokenAddress,
  );

  const usdTotalStaked =
    stakingTokenPrice && vault.totalStaked.gt(0)
      ? new BigNumber(getFullDisplayBalance(vault.totalStaked, vault.stakingToken.decimals))
          .times(stakingTokenPrice || 0)
          .toNumber()
      : 0;

  return {
    ...vault,
    apy: apy,
    apr: farm?.apr,
    farmStaked: farm,
    stakedTvl: usdTotalStaked,
  };
};

export const formatVaultV2 = (prices, farms, farmsOutside, vault, chainId) => {
  const farmsChosen = {
    [PLATFORM.eCake]: farms,
    [PLATFORM.beco]: farmsOutside.filter((farm) => farm.platform === PLATFORM.beco),
    [PLATFORM.bds]: farmsOutside.filter((farm) => farm.platform === PLATFORM.bds),
  };

  const vaultStaked = getVaultStaked(vault, chainId);

  const farmsToFind = farmsChosen[vault.platform];
  const farmsOfVaultStakedToFind = farmsChosen[vaultStaked.platform];
  const stakingTokenAddress = vault?.stakingToken.address[chainId];
  const vaultStakedStakingTokenAddress = vaultStaked?.stakingToken.address[chainId];

  const farm =
    farmsToFind &&
    farmsToFind?.find((farm) => {
      const lpAddress = typeof farm?.lpAddress === 'string' ? farm?.lpAddress : farm?.lpAddress[chainId];

      return lpAddress?.toLowerCase() === stakingTokenAddress.toLowerCase();
    });

  const farmOfVaultStaked =
    farmsOfVaultStakedToFind &&
    farmsOfVaultStakedToFind?.find((farm) => {
      const lpAddress = typeof farm?.lpAddress === 'string' ? farm?.lpAddress : farm?.lpAddress[chainId];

      return lpAddress?.toLowerCase() === vaultStakedStakingTokenAddress.toLowerCase();
    });

  const stakingTokenPrice =
    getParameterCaseInsensitive(
      prices,
        stakingTokenAddress,
    ) || 0;
  const stakingTokenPriceInVaultStaked =
    getParameterCaseInsensitive(
      prices,
        vaultStakedStakingTokenAddress,
    ) || 0;

  const usdTotalStakedInVault = stakingTokenPrice
    ? new BigNumber(getFullDisplayBalance(vault.totalStaked, vault.stakingToken.decimals))
        .times(stakingTokenPrice)
        .toNumber()
    : 0;

  const usdTotalStakedStrategyInVaultStaked = stakingTokenPriceInVaultStaked
    ? new BigNumber(getFullDisplayBalance(vault.totalStakedOfStrategy, vaultStaked.stakingToken.decimals))
        .times(stakingTokenPriceInVaultStaked)
        .toNumber()
    : 0;

  const stakedTvl = usdTotalStakedInVault + usdTotalStakedStrategyInVaultStaked;

  const apy = getVaultV2Apy(
    farm?.apr?.weeklyAPR,
    farmOfVaultStaked?.apr?.weeklyAPR,
    vault.fees,
    vaultStaked.fees,
    vault.typeStrategy,
  );

  return {
    ...vault,
    stakedTvl,
    farmStaked: farm,
    apy: apy,
    apr: farm?.apr,
  };
};

export const calculatorStakedBalanceOfVaultV2 = (
  vault,
  userData,
  priceStakingTokenInVaultStaked,
  priceStakingToken,
  decimalsStakingToken,
  decimalsStrategyStakingToken,
) => {
  const stakedBalanceInVaultStaked = new BigNumber(
    userData.sharesBalance.div(vault.totalShare).times(vault.totalStakedOfStrategy),
  );

  const stakedBalanceInVaultStakedFollowStakingToken = stakedBalanceInVaultStaked
    .times(priceStakingTokenInVaultStaked || 0)
    .div(priceStakingToken || 0)
    .div(BIG_TEN.pow(decimalsStrategyStakingToken - decimalsStakingToken));

  const totalStakedBalance = stakedBalanceInVaultStakedFollowStakingToken.plus(userData.stakedBalance);

  return {
    stakedBalanceInVaultStaked:
      stakedBalanceInVaultStaked.isNaN() || !stakedBalanceInVaultStaked.isFinite()
        ? BIG_ZERO
        : stakedBalanceInVaultStaked,
    stakedBalanceOfVault: userData.stakedBalance,
    totalStakedBalance: totalStakedBalance.isNaN() || !totalStakedBalance.isFinite() ? BIG_ZERO : totalStakedBalance,
  };
};
