import axiosClient from './axiosClient';

const lootDragonApi = {
  getDragon: (id) => {
    const url = `/dragon/${id}`;
    return axiosClient.get(url);
  },
  getDragons: (params) => {
    const url = '/dragon';
    return axiosClient.get(url, { params });
  },
  getAIGangs: (id) => {
    const url = `/art/${id}`;
    return axiosClient.get(url, { customUrl: 'https://dpaint.eyeq.tech' });
  },
  getAIGangsBsc: (id) => {
    const url = `/art/bsc/${id}`;
    return axiosClient.get(url, { customUrl: 'https://dpaint.eyeq.tech' });
  },
  getLpdiNFTKai: (id) => {
    const url = `/lpdi/kai/${id}`;
    return axiosClient.get(url);
  },
  getLpdiNFTBsc: (id) => {
    const url = `/lpdi/bsc/${id}`;
    return axiosClient.get(url);
  },
};

export default lootDragonApi;
