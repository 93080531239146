import BigNumber from 'bignumber.js';
import Dots from 'components/Loader/Dots';
import Value from 'components/Value/Value';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance';

const EarnedCell = ({ farm, userDataLoaded }) => {
  const { account } = useActiveWeb3React();
  const { earnings } = farm.userData;

  const usdTokenEarning = earnings
    ? new BigNumber(getFullDisplayBalance(earnings)).times(farm.earningTokenPrice || 0).toNumber()
    : 0;

  return (
    <div>
      <p>Earned</p>
      {account ? (
        userDataLoaded ? (
          <>
            <Value className="font-bold" value={account ? getBalanceNumber(earnings) : 0} />
            <Value prefix="~" className="text-sm" value={account ? usdTokenEarning : 0} decimals={2} unit="USD" />
          </>
        ) : (
          <Dots className="font-bold" />
        )
      ) : (
        <>
          <p className="font-bold">???</p>
          <Value prefix="~" className="text-sm" value={0} decimals={0} unit="USD" />
        </>
      )}
    </div>
  );
};

export default EarnedCell;
