import VaultCard from 'views/Vaults/components/VaultsCard/VaultCard';

const VaultsCard = ({ userDataLoaded, userDataLoadedV2, vaults, vaultsV2 }) => {
  return (
    <div className="grid gap-4 xl:gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {vaultsV2.map((vault, index) => {
        return <VaultCard key={index} vault={vault} userDataLoaded={userDataLoadedV2} />;
      })}
      {vaults.map((vault, index) => {
        return <VaultCard key={index} vault={vault} userDataLoaded={userDataLoaded} />;
      })}
    </div>
  );
};

export default VaultsCard;
