import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import ModalTitle from 'components/Modal/ModalTitle';
import { getExplorerLink } from 'utils/explorer';
import { FiAlertCircle, FiArrowUpCircle } from 'react-icons/fi';
import Tooltip from 'components/Tooltip/Tooltip';
import useCopyClipboard from 'hooks/useCopyClipboard';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import {useChainId} from "wagmi";

function ConfirmationPendingContent({ pendingText }) {
  return (
    <div className="text-center">
      <div>
        <Loader className="border-t-4 h-20 w-20 mx-auto" />
      </div>
      <div className="mt-4">
        <p className="text-lg">Waiting For Confirmation</p>
        <div>
          <p className="font-bold">{pendingText}</p>
        </div>
        <p>Confirm this transaction in your wallet</p>
      </div>
    </div>
  );
}

function TransactionSubmittedContent({ onDismiss, hash, chainId }) {
  return (
    <div>
      <div>
        <div>
          <FiArrowUpCircle className="mx-auto text-green-600" size={90} />
        </div>
        <div className="text-center">
          <p>Transaction Submitted</p>
          {hash && (
            <a
              rel="noreferrer"
              className="text-primary"
              target="_blank"
              href={getExplorerLink(chainId, hash, 'transaction')}
            >
              View transaction
            </a>
          )}
          <ButtonCustom color="secondary" onClick={onDismiss} className="mx-auto mt-4">
            Close
          </ButtonCustom>
        </div>
      </div>
    </div>
  );
}

export function ConfirmationModalContent({ bottomContent, topContent }) {
  return (
    <div>
      <div>{topContent()}</div>
      <div>{bottomContent()}</div>
    </div>
  );
}

export function TransactionErrorContent({ message, onDismiss }) {
  const [isCopied, setCopied] = useCopyClipboard();

  return (
    <div>
      <div className="text-center">
        <FiAlertCircle className="mx-auto text-red-500" size={64} />
        <div className="w-full break-words mt-4">
          {isCopied ? (
            <Tooltip tooltip="Copied!">
              <span className=" cursor-pointer">
                There was some error. Please try changing the parameters or{' '}
                <span className="underline">copy the error</span>
              </span>
            </Tooltip>
          ) : (
            <span className=" cursor-pointer" onClick={() => setCopied(message)}>
              There was some error. Please try changing the parameters or{' '}
              <span className="underline">copy the error</span>
            </span>
          )}
        </div>
      </div>
      <ButtonCustom color="secondary" className="mx-auto mt-4" onClick={onDismiss}>
        Dismiss
      </ButtonCustom>
    </div>
  );
}

const TransactionConfirmationModal = ({ open, title, onDismiss, attemptingTxn, hash, pendingText, content }) => {
    const chainId = useChainId()

  if (!chainId) return null;

  return (
    <Modal open={open} onClose={onDismiss}>
      <ModalTitle onClose={onDismiss}>{title}</ModalTitle>
         <div className="p-4">
             {attemptingTxn ? (
                 <ConfirmationPendingContent pendingText={pendingText} />
             ) : hash ? (
                 <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
             ) : (
                 content()
             )}
         </div>
    </Modal>
  );
};

export default TransactionConfirmationModal;
