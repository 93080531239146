import Tooltip from 'components/Tooltip/Tooltip';
import { AVERAGE_BLOCK_TIME } from 'config/index';
import { useMemo } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { FiClock } from 'react-icons/fi';
import { formatNumber } from 'utils/formatBalance';
import { getPoolBlockInfoStake } from 'views/Castles/helpers';
import PropTypes from 'prop-types';
import { useCurrentBlock } from 'store/block/hooks';
import {useChainId} from "wagmi";

const BlockCountdownStake = ({ pool, isIfo }) => {
  const chainId = useChainId();
  const currentBlock = useCurrentBlock();
  const {
    shouldShowBlockCountdown,
    blocksUntilStake,
    blocksRemaining,
    hasPoolStaked,
    blocksToDisplay,
    shouldShowBlockCountdownUnStaking,
    blocksUntilUnStaking,
  } = getPoolBlockInfoStake(pool, currentBlock);

  const timer = useMemo(() => {
    return blocksToDisplay * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksToDisplay]);

  // const timerStakingStart = useMemo(() => {
  //   return blocksUntilStake * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now()
  // }, [blocksUntilStake])
  //
  // const timerStakingEnd = useMemo(() => {
  //   return blocksRemaining * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now()
  // }, [blocksRemaining])

  const timerBlockUnstaking = useMemo(() => {
    return blocksUntilUnStaking * AVERAGE_BLOCK_TIME[chainId] * 1000 + Date.now();
  }, [blocksUntilUnStaking]);

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return null;

    return (
      <>
        {days > 0 ? `${zeroPad(days)}d-` : ''}
        {zeroPad(hours)}h-{zeroPad(minutes)}m-{zeroPad(seconds)}s
      </>
    );
  };

  return (
    <>
      {shouldShowBlockCountdown && !isIfo && (
        <div className="flex justify-between items-center mb-1 text-white">
          <p>
            {hasPoolStaked
              ? `${isIfo ? 'Buy-in open until' : 'Staking is open until'}`
              : `${isIfo ? 'Buy-in starts in' : 'Staking starts in'}`}
          </p>
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>~{formatNumber(blocksToDisplay, 0, 0)} Blocks</p>
              </div>
            }
          >
            <div className="flex items-center">
              {(blocksRemaining || blocksUntilStake) && currentBlock ? (
                <Countdown zeroPadTime={2} date={timer} renderer={renderCountdown} />
              ) : (
                '...'
              )}
              <FiClock className="ml-1" size={18} />
            </div>
          </Tooltip>
        </div>
      )}
      {/*{shouldShowBlockCountdown && (*/}
      {/*  <div className="flex justify-between items-center mb-1 text-white">*/}
      {/*    <p>Buy-in ends in</p>*/}
      {/*    <div className="flex items-center">*/}
      {/*      {(blocksRemaining || blocksUntilStake) && currentBlock ? (*/}
      {/*        <Countdown zeroPadTime={2} date={timerStakingEnd} renderer={renderCountdown} />*/}
      {/*      ) : (*/}
      {/*        '...'*/}
      {/*      )}*/}
      {/*      <Clock className="ml-1" size={18} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
      {shouldShowBlockCountdownUnStaking && !isIfo && (
        <div className="flex justify-between items-center mb-1 text-white">
          <p>Unstake open in</p>
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>~{formatNumber(blocksToDisplay, 0, 0)} Blocks</p>
              </div>
            }
          >
            <div className="flex items-center">
              {blocksUntilUnStaking && currentBlock ? (
                <Countdown zeroPadTime={2} date={timerBlockUnstaking} renderer={renderCountdown} />
              ) : (
                '...'
              )}
              <FiClock className="ml-1" size={18} />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

BlockCountdownStake.propTypes = {
  pool: PropTypes.object.isRequired,
};

export default BlockCountdownStake;
