import PropTypes from 'prop-types';

const Container = ({ children, contentStyle }) => {
  return (
    <div className="relative overflow-hidden bg-container-main h-full">
      <div className="flex flex-col min-h-screen" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
