import DoubleLogo from 'components/Logo/DoubleLogo';
import Tooltip from 'components/Tooltip/Tooltip';
import { TYPE_STRATEGY } from 'constants/vaults';
import { ChainId } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useEffect, useMemo } from 'react';
import ApyCell from 'views/Vaults/components/VaultsTable/Table/Cells/ApyCell';
import AssetsCell from 'views/Vaults/components/VaultsTable/Table/Cells/AssetsCell';
import FeesCell from 'views/Vaults/components/VaultsTable/Table/Cells/FeesCell';
import HoldingsCell from 'views/Vaults/components/VaultsTable/Table/Cells/HoldingsCell';
import HoldingsV2Cell from 'views/Vaults/components/VaultsTable/Table/Cells/HoldingsV2Cell';
import ToggleShowCell from 'views/Vaults/components/VaultsTable/Table/Cells/ToggleShowCell';
import VaultAssetsCell from 'views/Vaults/components/VaultsTable/Table/Cells/VaultAssetsCell';
import Table from 'views/Vaults/components/VaultsTable/Table/Table';
import { FiHelpCircle } from 'react-icons/fi';
import { PLATFORM_LINK } from 'utils/explorer';

const VaultsTable = ({ vaults, vaultsV2, userDataLoaded, userDataLoadedV2 }) => {
  const { chainId } = useActiveWeb3React();

  const columns = useMemo(
    () => [
      {
        id: 'asset',
        Header: 'Asset',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <AssetsCell vault={row.original} />,
      },
      {
        id: 'strategy',
        Header: () => (
          <a
            className="flex items-center"
            onClick={(e) => e.stopPropagation()}
            href="https://docs.defily.io/financial/dungeons-and-strategies"
            rel="noreferrer"
            target="_blank"
          >
            Strategy <FiHelpCircle className="ml-1" size={14} />
          </a>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <a
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            href="https://docs.defily.io/financial/dungeons-and-strategies"
            target="_blank"
          >
            {row.original.token1 ? TYPE_STRATEGY.stratX2 : TYPE_STRATEGY.strat100Back}
          </a>
        ),
      },
      {
        id: 'fees',
        Header: 'Fees',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <FeesCell vault={row.original} index={row.index} />,
      },
      {
        id: 'stakedBalance',
        Header: () => (
          <Tooltip tooltip="Your vault holdings are shown below. This is denominated in the underlying token and includes your initial deposit and earnings">
            <div className="flex items-center">
              Holdings <FiHelpCircle className="ml-1" size={14} />
            </div>
          </Tooltip>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <HoldingsCell vault={row.original} />,
      },
      {
        id: 'apy',
        Header: 'Net APY',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <ApyCell vault={row.original} />,
      },
      {
        id: 'tvl',
        Header: () => (
          <Tooltip tooltip="Total assets held in the vault and strategy">
            <div className="flex items-center">
              Vault Assets <FiHelpCircle className="ml-1" size={14} />
            </div>
          </Tooltip>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <VaultAssetsCell row={row} />,
      },
      // {
      //   id: 'stakingTokenBalance',
      //   Header: 'Available to deposit',
      //   // eslint-disable-next-line react/prop-types
      //   Cell: ({ row }) => <AvailableDepositCell vault={row.original} />,
      // },
      {
        id: 'farm',
        Header: 'Farm',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>{row.original.platform}</p>
              </div>
            }
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                window.open(PLATFORM_LINK[row.original.platform], '_blank');
              }}
            >
              <DoubleLogo
                size={40}
                src0={`/platform/${row.original.platform.toLowerCase()}.png`}
                alt0={row.original.platform}
              />
            </div>
          </Tooltip>
        ),
      },
      {
        id: 'expander',
        Header: '',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <ToggleShowCell row={row} />,
      },
    ],
    [],
  );

  const columnsV2 = useMemo(
    () => [
      {
        id: 'asset',
        Header: 'Asset',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <AssetsCell vault={row.original} />,
      },
      {
        id: 'strategy',
        Header: () => (
          <a
            rel="noreferrer"
            className="flex items-center"
            onClick={(e) => e.stopPropagation()}
            href="https://docs.defily.io/financial/dungeons-and-strategies"
            target="_blank"
          >
            Strategy <FiHelpCircle className="ml-1" size={14} />
          </a>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <a
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
            href="https://docs.defily.io/financial/dungeons-and-strategies"
            target="_blank"
          >
            {row.original.typeStrategy ?? TYPE_STRATEGY.strat100Back}
          </a>
        ),
      },
      {
        id: 'fees',
        Header: 'Fees',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          return <FeesCell vault={row.original} index={row.index} />;
        },
      },
      {
        id: 'stakedBalance',
        Header: () => (
          <Tooltip tooltip="Your vault holdings are shown below. This is denominated in the underlying token and includes your initial deposit and earnings">
            <div className="flex items-center">
              Holdings <FiHelpCircle className="ml-1" size={14} />
            </div>
          </Tooltip>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) =>
          row.original?.contractVaultStakedAddress ? (
            <HoldingsV2Cell vault={row.original} />
          ) : (
            <HoldingsCell vault={row.original} />
          ),
      },
      {
        id: 'apy',
        Header: 'Net APY',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <ApyCell vault={row.original} />,
      },
      {
        id: 'tvl',
        Header: () => (
          <Tooltip tooltip="Total assets held in the vault and strategy">
            <div className="flex items-center">
              Vault Assets <FiHelpCircle className="ml-1" size={14} />
            </div>
          </Tooltip>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <VaultAssetsCell row={row} />,
      },
      // {
      //   id: 'stakingTokenBalance',
      //   Header: 'Available to deposit',
      //   // eslint-disable-next-line react/prop-types
      //   Cell: ({ row }) => <AvailableDepositCell vault={row.original} />,
      // },
      {
        id: 'farm',
        Header: 'Farm',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <Tooltip
            tooltip={
              <div className="whitespace-nowrap">
                <p>{row.original.platform}</p>
              </div>
            }
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                window.open(PLATFORM_LINK[row.original.platform], '_blank');
              }}
            >
              <DoubleLogo
                size={40}
                src0={`/platform/${row.original.platform.toLowerCase()}.png`}
                alt0={row.original.platform}
              />
            </div>
          </Tooltip>
        ),
      },
      {
        id: 'expander',
        Header: '',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => <ToggleShowCell row={row} />,
      },
    ],
    [],
  );

  const handleResizeFirstColumn = () => {
    const firstHeaderCells = document.getElementsByClassName('header-cell-table-0');
    if (firstHeaderCells?.length >= 2 && window?.innerWidth >= 500) {
      if (firstHeaderCells[0].offsetWidth > firstHeaderCells[1].offsetWidth) {
        firstHeaderCells[1].style.width = firstHeaderCells[0].offsetWidth + 'px';
      } else {
        firstHeaderCells[0].style.width = firstHeaderCells[1].offsetWidth + 'px';
      }
    }
  };

  useEffect(() => {
    handleResizeFirstColumn();
    window.addEventListener('resize', handleResizeFirstColumn);
    return () => {
      window.removeEventListener('resize', handleResizeFirstColumn);
    };
  }, []);

  return (
    <>
      {vaultsV2.length > 0 && (
        <Table
          columns={columnsV2}
          data={vaultsV2}
          userDataLoaded={chainId === ChainId.KAI ? userDataLoadedV2 : userDataLoaded}
        />
      )}
      {(vaults.length > 0 || !vaultsV2.length) && (
        <Table columns={columns} data={vaults} userDataLoaded={userDataLoaded} />
      )}
    </>
  );
};

export default VaultsTable;
