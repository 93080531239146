import { NativeCurrency, TokenAmount } from 'ezcake-v2-sdk';
import { useCallback, useMemo, useState } from 'react';
import { ROUTER_ADDRESS } from 'constants/swap';
import { approveWrap } from 'utils/calls/convert';
import useTokenAllowance from './useTokenAllowance';
import { Field } from 'store/swap/actions';
import { useHasPendingApproval, useTransactionAdder } from 'store/transactions/hooks';
import { computeSlippageAdjustedAmounts } from 'utils/prices';
import { useTokenContract } from 'hooks/useContract';
import { showToastError } from "components/CustomToast/CustomToast";
import {useAccount, useChainId, useConfig} from "wagmi";
import {waitForTransactionReceipt, writeContract} from "@wagmi/core";
import {UINT256_MAX} from "../config";

export const ApprovalState = {
  UNKNOWN: 0,
  NOT_APPROVED: 1,
  PENDING: 2,
  APPROVED: 3,
};

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(amountToApprove, spender) {
  const {address} = useAccount()
  const chainId = useChainId()
  const token = amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined;
  const currentAllowance = useTokenAllowance(token, address ?? undefined, spender);
  const [pendingTx, setPendingTx] = useState(false);
  const pendingApproval = useHasPendingApproval(token?.address, spender);
  const config = useConfig()

  const tokenContract = useTokenContract(token?.address);

  // check the current approval status
  const approvalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    if (amountToApprove.currency === NativeCurrency[amountToApprove.currency.chainId]) return ApprovalState.APPROVED;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval || pendingTx
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [amountToApprove, currentAllowance, pendingApproval, spender, pendingTx]);

  const addTransaction = useTransactionAdder();

  const approve = useCallback(async () => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily');
      return;
    }

    if (!token) {
      console.error('no token');
      return;
    }

    if (!tokenContract) {
      console.error('tokenContract is null');
      return;
    }

    if (!amountToApprove) {
      console.error('missing amount to approve');
      return;
    }

    if (!spender) {
      console.error('no spender');
      return;
    }

    try {
      setPendingTx(true);
      const txReceipt = await writeContract(config, {
        chainId,
        abi: tokenContract.abi,
        address: tokenContract.address,
        functionName: 'approve',
        args: [spender, UINT256_MAX]
      });

      await waitForTransactionReceipt(config, {hash: txReceipt})

      setPendingTx(false);
      addTransaction(txReceipt, {
        summary: `Approve ${amountToApprove.currency.symbol}`,
        approval: { tokenAddress: token.address, spender },
      });
      // }
    } catch (error) {
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
      console.error('Failed to approve token', error);
      // throw error;
    }
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction, config, chainId]);

  return [approvalState, approve];
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(trade, allowedSlippage = 0) {
  const chainId = useChainId()

  const amountToApprove = useMemo(
    () => (trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT] : undefined),
    [allowedSlippage, trade],
  );

  return useApproveCallback(amountToApprove, ROUTER_ADDRESS[chainId]);
}
