import erc20ABI from 'config/abi/erc20.json';
import vaultABI from 'config/abi/vaultAbi.json';
import ido1Abi from 'config/abi/IDO1.json';
import ido2Abi from 'config/abi/IDO2.json';
import ido3Abi from 'config/abi/IDO3.json';
import idoLinear from 'config/abi/IDOLinear.json';
import BigNumber from 'bignumber.js';
import multicall from 'utils/multicall';
import dragonAbi from '../../config/abi/dragon.json';
import { BIG_TEN } from '../../utils/bigNumber';
import { ethers } from 'ethers';
import { RPC } from '../../connectors';
import tokens from '../../constants/tokens';
import { NativeCurrency } from 'ezcake-v2-sdk';
import MULTICAL_ABI from 'config/abi/multicall.json';
import { MULTICALL_ADDRESS } from 'constants/contracts';

const IDO_ABI = {
  ido1Abi: ido1Abi,
  ido2Abi: ido2Abi,
  ido3Abi: ido3Abi,
  linearAbi: idoLinear,
};
export const fetchLandUserAllowances = async (account, pool, chainId) => {
  const calls = pool.options.data.map((option) => {
    return {
      address: pool.stakingToken.address?.[chainId],
      name: 'allowance',
      params: [account, option.poolContract.address],
    };
  });

  const rawPoolAllowances = await multicall(erc20ABI, calls, chainId);

  const parsedPoolAllowances = rawPoolAllowances.map((poolBalance) => {
    return new BigNumber(poolBalance).toJSON();
  });

  return parsedPoolAllowances;
};

export const fetchLandUserAllowancesBuyToken = async (account, pool, chainId) => {
  const calls = pool.options.data.map((option) => {
    return {
      address: pool.buyToken.address?.[chainId],
      name: 'allowance',
      params: [account, option.idoContract.address],
    };
  });

  const rawPoolAllowances = await multicall(erc20ABI, calls, chainId);

  const parsedPoolAllowances = rawPoolAllowances.map((poolBalance) => {
    return new BigNumber(poolBalance).toJSON();
  });

  return parsedPoolAllowances;
};

export const fetchInvestmentBalances = async (account, pool, chainId) => {
  if (!pool.linearClaim) {
    const promise = pool.options.data.map(async (option) => {
      const calls = [
        {
          address: option.idoContract.address,
          name: 'investments',
          params: [account],
        },

        {
          address: option.idoContract.address,
          name: 'claimed',
          params: [account],
        },
        {
          address: option.idoContract.address,
          name: 'claimAllowed',
        },
        {
          address: option.idoContract.address,
          name: 'refundAllowed',
        },
        {
          address: option.idoContract.address,
          name: 'totalCollectedWei',
        },
      ];
      if (option?.idoContract?.multipleClaim) {
        calls.push({
          address: option.idoContract.address,
          name: 'getCurrentPeriod',
        });
      }
      const [investments, claimed, [claimAllowed], [refundAllowed], totalCollectedWei, getCurrentPeriod] =
        await multicall(IDO_ABI[option?.idoContract?.abiKey], calls, chainId);
      const data = {
        investments: new BigNumber(investments).toJSON(),
        claimed: typeof claimed?.[0] === 'boolean' ? claimed?.[0] : new BigNumber(claimed).toNumber(),
        claimAllowed,
        refundAllowed,
        totalCollected: new BigNumber(totalCollectedWei).dividedBy(BIG_TEN.pow(pool?.buyToken?.decimals)).toNumber(),
      };
      if (option?.idoContract?.multipleClaim) {
        data.currentPeriod = new BigNumber(getCurrentPeriod).toNumber();
      }
      return data;
    });
    const response = await Promise.all(promise);
    return response;
  }
  const promise = pool.options.data.map(async (option) => {
    const calls = [
      {
        address: option.idoContract.address,
        name: 'investments',
        params: [account],
      },
      {
        address: option.idoContract.address,
        name: 'claimAllowed',
      },
      {
        address: option.idoContract.address,
        name: 'refundAllowed',
      },
      {
        address: option.idoContract.address,
        name: 'totalCollectedWei',
      },
      {
        address: option.idoContract.address,
        name: 'totalInvestorsCount',
      },
    ];
    if (pool.linearClaim) {
      calls.push({
        address: option.idoContract.address,
        name: 'getPendingTokens',
        params: [account],
      });
    }
    const [investments, [claimAllowed], [refundAllowed], totalCollectedWei, totalInvestorsCount, pendingTokens] =
      await multicall(IDO_ABI[option?.idoContract?.abiKey], calls, chainId);
    const data = {
      investments: new BigNumber(investments).toJSON(),
      claimAllowed,
      refundAllowed,
      totalInvestorsCount: new BigNumber(totalInvestorsCount).toNumber(),
      totalCollected: new BigNumber(totalCollectedWei).dividedBy(BIG_TEN.pow(pool?.buyToken?.decimals)).toNumber(),
    };
    if (pool.linearClaim) {
      data.pendingTokens = new BigNumber(pendingTokens?.pending?._hex).toString();
    }
    return data;
  });
  const response = await Promise.all(promise);
  return response;
};

export const fetchTotalCollectedIdo = async (pool, chainId) => {
  const promise = pool.options.data.map(async (option) => {
    const calls = [
      {
        address: option.idoContract.address,
        name: 'totalCollectedWei',
      },
    ];
    const [totalCollectedWei] = await multicall(IDO_ABI[option?.idoContract?.abiKey], calls, chainId);

    return new BigNumber(totalCollectedWei).dividedBy(BIG_TEN.pow(pool?.buyToken?.decimals)).toNumber();
  });
  const response = await Promise.all(promise);
  return response;
};

export const fetchTotalInvestorsCount = async (pool, chainId) => {
  const promise = pool.options.data.map(async (option) => {
    const calls = [
      {
        address: option.idoContract.address,
        name: 'totalInvestorsCount',
      },
    ];
    const [totalInvestorsCount] = await multicall(IDO_ABI[option?.idoContract?.abiKey], calls, chainId);
    return new BigNumber(totalInvestorsCount).toNumber();
  });
  const response = await Promise.all(promise);
  return response;
};

export const fetchBalanceHolders = async (pool, chainId) => {
  const calls = pool.options.data.map((option) => {
    return {
      address: pool.stakingToken.address?.[chainId],
      name: 'balanceOf',
      params: [option.poolContract.address],
    };
  });
  const res = await multicall(dragonAbi, calls, chainId);

  const balanceHolders = res.map((poolBalance) => {
    return new BigNumber(poolBalance).toJSON();
  });

  return balanceHolders;
};

export const fetchBalanceIdo = async (pool, chainId) => {
  const calls = pool.options.data.map((option) => {
    return {
      address: pool?.token?.address,
      name: 'balanceOf',
      params: [option.idoContract.address],
    };
  });
  const res = await multicall(erc20ABI, calls, chainId);

  const idoBalances = res.map((poolBalance) => {
    return new BigNumber(poolBalance).toJSON();
  });

  return idoBalances;
};

export const fetchLandUserStakingTokenBalance = async (account, pool, chainId) => {
  const calls = [
    {
      address: pool.stakingToken.address?.[chainId],
      name: 'balanceOf',
      params: [account],
    },
  ];
  const [balance] = await multicall(erc20ABI, calls, chainId);
  return new BigNumber(balance).toJSON();
};

export const fetchLandUserBuyTokenBalance = async (account, pool, chainId) => {
  const calls = [
    {
      address: pool.buyToken.address?.[chainId],
      name: 'balanceOf',
      params: [account],
    },
  ];
  const [balance] = await multicall(erc20ABI, calls, chainId);
  return new BigNumber(balance).toJSON();
};

export const fetchLandUserTokenBalance = async (account, chainId) => {
  const provider = new ethers.providers.JsonRpcProvider(RPC[chainId]);
  const balance = await provider.getBalance(account);
  return new BigNumber(balance?._hex).toJSON();
};

export const fetchLandUserStakedBalances = async (account, pool, chainId) => {
  const calls = pool.options.data.map((option) => {
    return {
      address: option.poolContract.address,
      name: 'userInfo',
      params: [account],
    };
  });

  const rawStakedBalances = await multicall(vaultABI, calls, chainId);
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance).toJSON();
  });

  return parsedStakedBalances;
};

export const fetchDragonUserBalance = async (account, chainId) => {
  const calls = [
    {
      address: tokens.dragon?.address[chainId],
      name: 'balanceOf',
      params: [account],
    },
  ];
  const [balance] = await multicall(erc20ABI, calls, chainId);
  return new BigNumber(balance).toJSON();
};

export const fetchLandV2UserTokenPaymentBalances = async (account, lands) => {
  const calls = lands.map((land) => {
    if (land.tokenPayment.symbol === NativeCurrency[lands[0].chainId].symbol) {
      return {
        address: MULTICALL_ADDRESS[lands[0].chainId],
        name: 'getEthBalance',
        params: [account],
      };
    }

    return {
      address: land.tokenPayment.address[lands[0].chainId],
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(MULTICAL_ABI, calls, lands[0].chainId);

  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });

  return parsedTokenBalances;
};
export const fetchLandV2UserTokenBalances = async (account, lands) => {
  const calls = lands.map((land) => {
    return {
      address: land.token.address,
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls, lands[0].chainId);

  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });

  return parsedTokenBalances;
};
