import React, { useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useMintDragon } from 'hooks/useMint';
import { showToastError, showToastSuccess } from 'components/CustomToast/CustomToast';
import { fetchNFTData, fetchUserDataAsync } from 'store/NFTs';
import { useUserDataNFT } from 'store/NFTs/hook';
import BigNumber from 'bignumber.js';
import { BIG_ZERO } from 'utils/bigNumber';
import { useApprove } from 'hooks/useApprove';
import { classNames } from 'utils/styling';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

const MintButton = ({ nft, openSell }) => {
  const { minted, maxMint } = nft;
  const { chainId, account } = useActiveWeb3React();
  const dispatch = useDispatch();
  const userData = useUserDataNFT();
  const { onApprove } = useApprove(nft?.buyToken?.address?.[nft?.chainId], nft?.poolAddress);
  const { onMint } = useMintDragon(nft?.poolAddress);
  const [amount, setAmount] = useState(1);
  const [pendingTx, setPendingTx] = useState(false);
  const [pendingApprove, setPendingApprove] = useState(false);
  const allowance = new BigNumber(userData?.allowance) || BIG_ZERO;
  const userDataLoaded = userData?.userDataLoaded;
  const isApproved = account && allowance && allowance?.isGreaterThan(0);

  const handleMint = async () => {
    try {
      setPendingTx(true);
      await onMint(amount, nft?.price?.value, nft?.buyToken?.decimals, !nft?.useOtherCoin);
      await dispatch(fetchNFTData(nft, chainId));
      showToastSuccess('Success', 'Claim successfully!');
      setPendingTx(false);
    } catch (error) {
      console.log(error);
      setPendingTx(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
    }
  };

  const handleApprove = async () => {
    try {
      setPendingApprove(true);
      await onApprove();
      setPendingApprove(false);
      await dispatch(fetchUserDataAsync(nft, account));
      showToastSuccess('Contract Enabled', `You can now buy NFT!`);
    } catch (error) {
      setPendingApprove(false);
      showToastError('Canceled', 'Please try again. Confirm the transaction and make sure you are paying enough gas!');
    }
  };

  const changeAmount = (value) => {
    const newValue = amount + value;
    if (newValue > 0 && (!nft?.limitPerTime || newValue <= nft?.limitPerTime)) {
      setAmount(newValue);
    }
  };

  return (
    <div className="flex w-full justify-center sm:justify-start">
      <div className="flex items-center text-white border-2 border-white border-solid rounded-lg">
        <button className="px-3 h-full  bg-transparent active:bg-primary" onClick={() => changeAmount(-1)}>
          <FiMinus />
        </button>
        <div className="px-2 font-bold text-xl">{amount}</div>
        <button
          className={classNames('px-3 h-full bg-transparent', {
            'active:bg-primary': !nft?.limitPerTime || amount < nft?.limitPerTime,
          })}
          disabled={amount >= nft?.limitPerTime}
          onClick={() => changeAmount(1)}
        >
          <FiPlus />
        </button>
      </div>
      {nft?.useOtherCoin ? (
        <>
          {isApproved ? (
            <ButtonCustom
              className=" ml-3 px-sm-14 px-7"
              disabled={!openSell || minted >= maxMint || pendingTx || !nft?.canClaim}
              isLoading={pendingTx}
              onClick={handleMint}
            >
              Mint
            </ButtonCustom>
          ) : (
            <ButtonCustom
              className=" ml-3 px-sm-14 px-2 sm:px-7"
              disabled={pendingApprove || !userDataLoaded}
              isLoading={pendingApprove || !userDataLoaded}
              onClick={handleApprove}
            >
              Approve contract
            </ButtonCustom>
          )}
        </>
      ) : (
        <ButtonCustom
          className=" ml-3 px-sm-14 px-7"
          disabled={!openSell || minted >= maxMint || pendingTx || !nft?.canClaim}
          isLoading={pendingTx}
          onClick={handleMint}
        >
          Mint
        </ButtonCustom>
      )}
    </div>
  );
};

export default MintButton;
