import fromPairs from 'lodash/fromPairs';
import { ONE_DAY_UNIX, PCS_V2_START } from 'constants/analytics';
import { TransactionType } from 'store/analytics/types';
import dayjs from 'dayjs';
import { getLpFeesAndApr } from 'utils/getLpFeesAndApr';

export const mapMints = (mint) => {
  return {
    _id: mint.id,
    type: TransactionType.MINT,
    hash: mint.transaction,
    timestamp: mint.timestamp,
    sender: mint.to,
    token0Symbol: mint.pair.token0.symbol,
    token1Symbol: mint.pair.token1.symbol,
    token0Address: mint.pair.token0._id,
    token1Address: mint.pair.token1._id,
    amountUSD: parseFloat(mint.amountUSD),
    amountToken0: parseFloat(mint.amount0),
    amountToken1: parseFloat(mint.amount1),
  };
};

export const mapBurns = (burn) => {
  return {
    _id: burn.id,
    type: TransactionType.BURN,
    hash: burn.transaction,
    timestamp: burn.timestamp,
    sender: burn.sender,
    token0Symbol: burn.pair.token0.symbol,
    token1Symbol: burn.pair.token1.symbol,
    token0Address: burn.pair.token0._id,
    token1Address: burn.pair.token1._id,
    amountUSD: parseFloat(burn.amountUSD),
    amountToken0: parseFloat(burn.amount0),
    amountToken1: parseFloat(burn.amount1),
  };
};

export const mapSwaps = (swap) => {
  return {
    _id: swap.id,
    type: TransactionType.SWAP,
    hash: swap.transaction,
    timestamp: swap.timestamp,
    sender: swap.from,
    token0Symbol: swap.pair.token0.symbol,
    token1Symbol: swap.pair.token1.symbol,
    token0Address: swap.pair.token0._id,
    token1Address: swap.pair.token1._id,
    amountUSD: parseFloat(swap.amountUSD),
    amountToken0: parseFloat(swap.amount0In) - parseFloat(swap.amount0Out),
    amountToken1: parseFloat(swap.amount1In) - parseFloat(swap.amount1Out),
  };
};

export const parsePoolData = (pool) => {
  const volumeUSD = parseFloat(pool.volume24h);
  const volumeUSDWeek = parseFloat(pool.volume7d);
  const liquidityUSD = parseFloat(pool.reserveUSD);
  const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } = getLpFeesAndApr(
    volumeUSD,
    volumeUSDWeek,
    liquidityUSD,
  );

  return {
    ...pool,
    volumeUSD,
    volumeUSDWeek,
    liquidityUSD,
    totalFees24h,
    totalFees7d,
    lpFees24h,
    lpFees7d,
    lpApr7d,
    token0: {
      ...pool.token0,
      address: pool.token0._id,
    },
    token1: {
      ...pool.token1,
      address: pool.token1._id,
    },
    address: pool._id,
    reserveUSD: parseFloat(pool.reserveUSD),
    reserve0: parseFloat(pool.reserve0),
    reserve1: parseFloat(pool.reserve1),
    token0Price: parseFloat(pool.token0Price),
    token1Price: parseFloat(pool.token1Price),
    volumeUSDChange: parseFloat(pool.volume24hChange),
    volumeUSDChangeWeek: parseFloat(pool.volume7dChange),
    liquidityUSDChange: parseFloat(pool.liquidityChange),
    liquidityToken0: parseFloat(pool.reserve0),
    liquidityToken1: parseFloat(pool.reserve1),
  };
};

export const parseTokenData = (token) => {
  return {
    ...token,
    exists: true,
    address: token._id,
    derivedONUS: parseFloat(token.derivedONUS),
    derivedUSD: parseFloat(token.derivedUSD),
    priceUSD: parseFloat(token.derivedUSD),
    tradeVolumeUSD: parseFloat(token.tradeVolumeUSD),
    totalTransactions: parseFloat(token.totalTransactions),
    totalLiquidity: parseFloat(token.totalLiquidity),
    volumeUSD: parseFloat(token.volume24h),
    volumeUSDChange: parseFloat(token.volumeChange),
    volumeUSDWeek: parseFloat(token.volume7d),
    txCount: parseInt(token.transaction24h),
    liquidityUSD: parseFloat(token.liquidity),
    liquidityUSDChange: parseFloat(token.liquidityChange),
    liquidityToken: parseFloat(token.liquidity),
    priceUSDChange: parseFloat(token.priceChange),
  };
};

export const mapDayData = (tokenDayData) => ({
  date: tokenDayData.date,
  volumeUSD: parseFloat(tokenDayData.dailyVolumeUSD),
  liquidityUSD: parseFloat(tokenDayData.totalLiquidityUSD),
});

export const mapTokenDayData = (tokenDayData) => ({
  date: tokenDayData.date,
  volumeUSD: parseFloat(tokenDayData.dailyVolumeUSD),
  liquidityUSD: parseFloat(tokenDayData.totalLiquidityUSD),
  priceUSD: parseFloat(tokenDayData.priceUSD),
});

export const mapTokenPriceData = (tokenPriceData) => ({
  time: tokenPriceData.hourStartUnix,
  open: parseFloat(tokenPriceData.openingPrice),
  close: parseFloat(tokenPriceData?.closingPrice),
  high: parseFloat(tokenPriceData?.highPrice),
  low: parseFloat(tokenPriceData.lowPrice),
});

export const mapPairDayData = (pairDayData) => ({
  date: pairDayData.date,
  volumeUSD: parseFloat(pairDayData.dailyVolumeUSD),
  liquidityUSD: parseFloat(pairDayData.reserveUSD),
});

export const mapPairPriceData = (pairPrice, activeToken, pairData) => ({
  time: dayjs.unix(pairPrice.timestamp).toDate(),
  value:
    activeToken === pairData?.token0?._id
      ? pairPrice.reserve1 / pairPrice.reserve0
      : pairPrice.reserve0 / pairPrice.reserve1,
});

export const formatProtocolData = (protocolData) => ({
  ...protocolData,
  volumeUSD: parseFloat(protocolData.volume24h),
  liquidityUSD: parseFloat(protocolData.totalLiquidityUSD),
});

// Common helper function to retrieve chart data
// Used for both Pool and Token charts
export const fetchChartData = async (getEntityDayDatas, address) => {
  let chartEntries = [];
  let error = false;
  let skip = 0;
  let allFound = false;

  while (!allFound) {
    // eslint-disable-next-line no-await-in-loop
    const { data, error: fetchError } = await getEntityDayDatas(skip, address);
    skip += 1000;
    allFound = data?.length < 1000;
    error = fetchError;
    if (data) {
      chartEntries = chartEntries.concat(data);
    }
  }

  if (error || chartEntries.length === 0) {
    return {
      error: true,
    };
  }

  const formattedDayDatas = fromPairs(
    chartEntries.map((dayData) => {
      // At this stage we track unix day ordinal for each data point to check for empty days later
      const dayOrdinal = parseInt((dayData.date / ONE_DAY_UNIX).toFixed(0));
      return [dayOrdinal, dayData];
    }),
  );

  const availableDays = Object.keys(formattedDayDatas).map((dayOrdinal) => parseInt(dayOrdinal, 10));

  const firstAvailableDayData = formattedDayDatas[availableDays[0]];
  // fill in empty days ( there will be no day datas if no trades made that day )
  let timestamp = firstAvailableDayData?.date ?? PCS_V2_START;
  let latestLiquidityUSD = firstAvailableDayData?.liquidityUSD ?? 0;
  const endTimestamp = dayjs(new Date()).unix();
  while (timestamp < endTimestamp - ONE_DAY_UNIX) {
    timestamp += ONE_DAY_UNIX;
    const dayOrdinal = parseInt((timestamp / ONE_DAY_UNIX).toFixed(0), 10);
    if (!Object.keys(formattedDayDatas).includes(dayOrdinal.toString())) {
      formattedDayDatas[dayOrdinal] = {
        date: timestamp,
        volumeUSD: 0,
        liquidityUSD: latestLiquidityUSD,
      };
    } else {
      latestLiquidityUSD = formattedDayDatas[dayOrdinal].liquidityUSD;
    }
  }

  return {
    data: Object.values(formattedDayDatas),
    error: false,
  };
};
