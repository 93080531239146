export const MINIMUM_SEARCH_CHARACTERS = 2;

export const WEEKS_IN_YEAR = 52.1429;

export const TOTAL_FEE = 0.003;
export const LP_HOLDERS_FEE = 0.0025;

export const PCS_V2_START = 1619136000; // April 23, 2021, 12:00:00 AM
export const ONE_DAY_UNIX = 86400; // 24h * 60m * 60s

export const ITEMS_PER_INFO_TABLE_PAGE = 10;
