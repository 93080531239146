import React from 'react';
import { classNames } from 'utils/styling';
import Value from 'components/Value/Value';

const BoxCounter = ({ nft }) => {
  return (
    <div
      className={classNames(
        'bg-blue1 text-center p-6 px-4 rounded border-2 border-primary mb-2 w-full sm:px-10 md:px-20',
      )}
      style={{
        maxWidth: 400,
      }}
    >
      <p className="text-white">Price</p>
      <div className="text-xl font-bold text-primary">
        {typeof nft?.price?.value === 'number' ? (
          <Value
            className="text-xl font-bold text-primary"
            value={nft?.price?.value}
            decimals={nft?.price?.decimals}
            unit={` ${nft?.buyToken?.symbol}`}
          />
        ) : (
          <span>TBA</span>
        )}
      </div>
      {nft?.tokenBurn ? (
        <>
          <hr className="my-3" />
          <p className="text-white">NFT Burned</p>
          <div className="text-xl font-bold text-primary">
            {typeof nft?.NFTBurned === 'number' ? (
              <Value className="text-xl font-bold text-primary" value={nft?.NFTBurned} decimals={0} />
            ) : (
              <span>--</span>
            )}
          </div>
        </>
      ) : null}
      {nft?.saleInBatch ? (
        <>
          <hr className="my-3" />
          <p className={classNames('text-white')}>NFT remaining in batch</p>
          <div className="text-xl font-bold text-primary">
            {typeof nft?.minted === 'number' && typeof nft?.nextStopId ? (
              <Value
                className="text-xl font-bold text-primary"
                value={nft?.nextStopId + 1 - nft?.minted}
                decimals={0}
              />
            ) : (
              <span>--</span>
            )}
          </div>
        </>
      ) : null}
      <hr className="my-3" />
      <p className={classNames('text-white')}>Minted</p>
      <div className="text-xl font-bold text-primary">
        {typeof nft?.minted === 'number' ? (
          <Value
            className="text-xl font-bold text-primary"
            value={nft?.minted}
            decimals={0}
            unit={` / ${nft?.maxMint}`}
          />
        ) : (
          <span>--</span>
        )}
      </div>
    </div>
  );
};

export default BoxCounter;
