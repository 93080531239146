import React from 'react';
import ListProjects from './components/ListProjects';
import { useDataLoaded, useFetchNFTsPublicData, useNFTs } from 'store/NFTs/hook';
import Back from 'components/Back/Back';
import Banner from 'components/Layout/Banner';

const NFTsPage = () => {
  useFetchNFTsPublicData();
  const NFTs = useNFTs();
  const dataLoaded = useDataLoaded();
  return (
    <div>
      <Back />
      <Banner bg="url(/images/banner-NFT.png)">
        {/*<div className="hidden sm:flex items-end">*/}
        {/*  <div className="w-full px-6">*/}
        {/*    <div className="container max-w-screen-xl mx-auto grid sm:grid-cols-2">*/}
        {/*      <div className="text-white text-center py-1 sm:py-6">*/}
        {/*        <p className="text-sm-md">Total Funds Raised</p>*/}
        {/*        <p className="text-3xl font-bold">TBA</p>*/}
        {/*        /!*<Value className="text-3xl font-bold" prefix={'$'} value={totalFund} decimals={0} />*!/*/}
        {/*      </div>*/}
        {/*      <div className="text-white text-center py-1 sm:py-6">*/}
        {/*        <p className="text-sm-md">NFTs Launched</p>*/}
        {/*        <p className="text-3xl font-bold">1</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Banner>
      {/*<OverviewNFTs />*/}
      <div className="px-6 pb-40">
        <div className="container max-w-screen-xl mx-auto">
          <ListProjects title="NFT Collections" data={NFTs} dataLoaded={dataLoaded} />
        </div>
      </div>
    </div>
  );
};

export default NFTsPage;
