import React from 'react';
import ImportToken from 'components/SearchModal/ImportToken';
import ModalTitle from 'components/Modal/ModalTitle';
import Modal from 'components/Modal/Modal';

const ModalTokenWarning = ({ isOpen, onDismiss, tokens }) => {
  return (
    <Modal open={isOpen} onClose={onDismiss} className="w-full sm:w-unset">
      <ModalTitle onClose={onDismiss}>Import Token</ModalTitle>
      <ImportToken tokens={tokens} handleCurrencySelect={onDismiss} />
    </Modal>
  );
};

export default ModalTokenWarning;
