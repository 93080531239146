import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { NativeCurrency, WETH } from 'ezcake-v2-sdk';

const BnbWbnbNotice = () => {
  const { chainId } = useActiveWeb3React();

  return (
    <div className="p-5 text-white min-h-[451px]">
      <div className="text-center">
        <p className="mb-2">
          You can swap {WETH[chainId].symbol} for {NativeCurrency[chainId].symbol} (and vice versa) with no trading
          fees.
        </p>
        <p>Exchange rate is always 1 to 1.</p>
      </div>
    </div>
  );
};

export default BnbWbnbNotice;
