import tokens from 'constants/tokens';
import { ChainId } from 'ezcake-v2-sdk';

const stakeConfig = {
  [ChainId.HARMONY]: {
    stakingToken: tokens.mCake,
    token0: tokens.mCake,
    earningToken: tokens.mCake,
    startBlock: 19250589,
    contractAddress: '0x291e13426C8427628e18bB278B2B3ab13c722396',
    isFinished: false,
  },
  [ChainId.KAI]: {},
  [ChainId.BSC]: {},
};

export default stakeConfig;
