import { ChainId, JSBI, NativeCurrency, Price, Token, WETH } from 'ezcake-v2-sdk';
import { useMemo } from 'react';
import { PairState, usePairs } from './usePairs';
import { multiplyPriceByAmount } from 'utils/prices';
import {useChainId} from "wagmi";

const DEFAULT_STABLE = {
  [ChainId.KAI]: new Token(ChainId.KAI, '0x92364Ec610eFa050D296f1EEB131f2139FB8810e', 6, 'KUSD-T', 'KUSD-T'),
  [ChainId.ONUS]: new Token(ChainId.ONUS, '0xff276c6bca1F66Fd54a8915e830735D6ab0C7B09', 18, 'USDT', 'USDT'),
  [ChainId.BSC]: new Token(ChainId.BSC, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'USDT'),
};

/**
 * Returns the price in BUSD of the input currency
 * @param currency currency to compute the BUSD price of
 */
export default function useUSDTPrice(currency) {
  const chainId = useChainId()
  const stable = DEFAULT_STABLE[chainId];

  const tokenPairs = useMemo(
    () => [
      [
        chainId && (currency === NativeCurrency[chainId] || currency === WETH[chainId]) ? undefined : currency,
        chainId ? WETH[chainId] : undefined,
      ],
      [stable && currency === stable ? undefined : currency, stable],
      [chainId ? WETH[chainId] : undefined, stable],
    ],
    [stable, chainId, currency],
  );
  const [[bnbPairState, bnbPair], [busdPairState, busdPair], [busdBnbPairState, busdBnbPair]] = usePairs(tokenPairs);

  return useMemo(() => {
    if (!currency || !chainId) {
      return undefined;
    }

    const isBUSDPairExist =
      busdPair &&
      busdPairState === PairState.EXISTS &&
      busdPair.reserve0.greaterThan('0') &&
      busdPair.reserve1.greaterThan('0');

    // handle wbnb/bnb
    if (currency === NativeCurrency[chainId] || currency === WETH[chainId]) {
      if (isBUSDPairExist) {
        const price = busdPair.priceOf(WETH[chainId]);
        return new Price(currency, stable, price.denominator, price.numerator);
      }
      return undefined;
    }
    // handle busd
    if (currency === stable) {
      return new Price(stable, stable, '1', '1');
    }

    const isBnbPairExist =
      bnbPair &&
      bnbPairState === PairState.EXISTS &&
      bnbPair.reserve0.greaterThan('0') &&
      bnbPair.reserve1.greaterThan('0');
    const isBusdBnbPairExist =
      busdBnbPair &&
      busdBnbPairState === PairState.EXISTS &&
      busdBnbPair.reserve0.greaterThan('0') &&
      busdBnbPair.reserve1.greaterThan('0');

    const bnbPairBNBAmount = isBnbPairExist && bnbPair?.reserveOf(WETH[chainId]);
    const bnbPairBNBBUSDValue =
      bnbPairBNBAmount && isBUSDPairExist && isBusdBnbPairExist
        ? busdBnbPair.priceOf(WETH[chainId]).quote(bnbPairBNBAmount).quotient
        : JSBI.BigInt(0);

    // all other tokens
    // first try the busd pair
    if (isBUSDPairExist && busdPair.reserveOf(stable).greaterThan(bnbPairBNBBUSDValue)) {
      const price = busdPair.priceOf(currency);
      return new Price(currency, stable, price.denominator, price.numerator);
    }
    if (isBnbPairExist && isBusdBnbPairExist) {
      if (busdBnbPair.reserveOf(stable).greaterThan('0') && bnbPair.reserveOf(WETH[chainId]).greaterThan('0')) {
        const bnbBusdPrice = busdBnbPair.priceOf(stable);
        const currencyBnbPrice = bnbPair.priceOf(WETH[chainId]);
        const busdPrice = bnbBusdPrice.multiply(currencyBnbPrice).invert();
        return new Price(currency, stable, busdPrice.denominator, busdPrice.numerator);
      }
    }

    return undefined;
  }, [currency, chainId, stable, bnbPair, busdBnbPair, busdPairState, busdPair, bnbPairState, busdBnbPairState]);
}
export const useUSDTCurrencyAmount = (currency, amount) => {
  const usdtPrice = useUSDTPrice(currency);
  if (!amount) {
    return undefined;
  }
  if (usdtPrice) {
    return multiplyPriceByAmount(usdtPrice, amount);
  }
  return undefined;
};
