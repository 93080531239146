import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { updateVersion } from './global/actions';
import { save, load } from 'redux-localstorage-simple';

import farmsReducer from './farms';
import stakeReducer from './stake';
import castlesReducer from './castles';
import pricesReducer from './prices';
import modalReducer from './modal';
import landsReducer from './lands';
import vaultsReducer from './vaults';
import statisticReducer from './statistic';

import user from './user/reducer';
import multicall from './multicall/reducer';
import transactions from './transactions/reducer';
import swap from './swap/reducer';
import burn from './burn/reducer';
import mint from './mint/reducer';

import NFTsReducer from './NFTs';
import farmsOutsideReducer from './farmsOutside';

const PERSISTED_KEYS = ['user', 'transactions'];

const store = configureStore({
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    farms: farmsReducer,
    stake: stakeReducer,
    castles: castlesReducer,
    prices: pricesReducer,
    modal: modalReducer,
    lands: landsReducer,
    vaults: vaultsReducer,
    farmsOutside: farmsOutsideReducer,
    NFTs: NFTsReducer,
    statistic: statisticReducer,

    swap,
    transactions,
    multicall,
    user,
    burn,
    mint,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
});

store.dispatch(updateVersion());

export default store;
