import CurrencyLogo from 'components/LogoSwap/CurrencyLogo';
import QuestionHelper from 'components/QuestionHelper/index';
import { currencyEquals, NativeCurrency, Token } from 'ezcake-v2-sdk';

import { SUGGESTED_BASES } from 'constants/swap';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';

export default function CommonBases({ chainId, onSelect, selectedCurrency }) {
  return (
    <div>
      <div className="flex items-center my-2 ">
        <p className="text-lg text-white font-bold">Common bases</p>
        <QuestionHelper text="These tokens are commonly paired with other tokens." iconClassName="text-white" />
      </div>
      <div className="flex flex-wrap gap-2">
        <ButtonCustom
          className=" !rounded !px-2.5"
          size="sm"
          onClick={() => {
            if (!selectedCurrency || !currencyEquals(selectedCurrency, NativeCurrency[chainId])) {
              onSelect(NativeCurrency[chainId]);
            }
          }}
          disabled={selectedCurrency === NativeCurrency?.[chainId]}
        >
          <CurrencyLogo currency={NativeCurrency?.[chainId]} className="mr-1 min-w-[22px]" />
          <p className="font-semibold text-sm">{NativeCurrency?.[chainId].symbol}</p>
        </ButtonCustom>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token) => {
          const selected = selectedCurrency instanceof Token && selectedCurrency.address === token.address;
          return (
            <ButtonCustom
              className=" !rounded !px-2.5 font-semibold"
              size="sm"
              onClick={() => !selected && onSelect(token)}
              disabled={selected}
              key={token.address}
            >
              <CurrencyLogo currency={token} className="mr-1" />
              <p>{token.symbol}</p>
            </ButtonCustom>
          );
        })}
      </div>
    </div>
  );
}
