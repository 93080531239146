export const PairDataTimeWindowEnum = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
};

export const INTERVAL = {
  0: 'DAY',
  1: 'WEEK',
  2: 'MONTH',
  3: 'YEAR',
};
