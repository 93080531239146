import CurrencyLogo from 'components/LogoSwap/CurrencyLogo';
import DoubleCurrencyLogo from 'components/LogoSwap/DoubleLogo';
import { useState } from 'react';
import { useCurrencyBalance } from 'hooks/wallet';
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal';

import { Input as NumericalInput } from 'components/CurrencyInputPanel/NumericalInput';
import { useUSDTCurrencyAmount } from 'hooks/useUSDTPrice';
import { formatNumber } from 'utils/formatBalance';
import { BiWallet } from 'react-icons/bi';
import { MdArrowDropDown } from 'react-icons/md';
import PercentPicker from 'components/PercentPicker/PercentPicker';
import {useAccount} from "wagmi";

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  disableInput = false,
  listHideTokens,
  showUSDT = false,
  onDismissTokenWarning,
  onChangePercentInput,
    showPercentPicker = false
}) {
  const [presentCurrencyModal, setPresentCurrencyModal] = useState(false);
  const {address} = useAccount()
  const selectedCurrencyBalance = useCurrencyBalance(address ?? undefined, currency ?? undefined);
  const translatedLabel = label;
  const toggleCurrencyModal = () => {
    // fix for token warning modal only showing once
    onDismissTokenWarning && onDismissTokenWarning();
    setPresentCurrencyModal((prevState) => !prevState);
  };

  const amountInDollar = useUSDTCurrencyAmount(
    showUSDT ? currency : undefined,
    Number.isFinite(+value) ? +value : undefined,
  );

  return (
    <div className="mb-10">
      <CurrencySearchModal
        open={presentCurrencyModal}
        onDismiss={toggleCurrencyModal}
        onCurrencySelect={onCurrencySelect}
        selectedCurrency={currency}
        otherSelectedCurrency={otherCurrency}
        showCommonBases={showCommonBases}
        listHideTokens={listHideTokens}
      />
      <div className='flex justify-between mb-2'>
        <button
          className="text-white font-bold whitespace-nowrap h-6"
          onClick={() => {
            if (!disableCurrencySelect) {
              toggleCurrencyModal();
            }
          }}
        >
          <div className="flex items-center justify-between">
            {pair ? (
              <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
            ) : currency ? (
              <CurrencyLogo currency={currency} />
            ) : null}
            {pair ? (
              <p id="pair" className="ml-1 text-md">
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </p>
            ) : (
              <p id="pair" className="ml-1 text-md">
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                      currency.symbol.length - 5,
                      currency.symbol.length,
                    )}`
                  : currency?.symbol)}
              </p>
            )}
            {!disableCurrencySelect && <MdArrowDropDown size={20} />}
          </div>
        </button>
      </div>
      <div className="flex flex-nowrap flex-col bg-black-1 p-2 rounded-2xl min-h-[93px]" id={id}>
        <div className="rounded-2xl">
          {!hideInput && (
            <div className="flex flex-nowrap items-center justify-between text-white mb-2">
              <p>{translatedLabel}</p>
              <div className="flex items-center cursor-pointer">
                {address && (
                  <div className="flex items-center" onClick={onMax}>
                    {!hideBalance && !!currency && selectedCurrencyBalance ? (
                      <>
                        <BiWallet size={18} className="mr-1 text-primary" /> {selectedCurrencyBalance?.toSignificant(6)}
                      </>
                    ) : (
                      ' -'
                    )}
                  </div>
                )}
                {address && currency && showMaxButton && label !== 'To' && (
                  <button className="text-sm ml-1 bg-primary rounded px-1.5 py-px text-black" onClick={onMax}>
                    MAX
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="flex  justify-between flex-1" style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}>
            {!hideInput && (
              <div className="flex-1 overflow-auto">
                <div className="overflow-auto flex-1 text-right">
                  <NumericalInput
                    className="!text-right w-full bg-transparent"
                    disabled={disableInput}
                    value={value}
                    onUserInput={(val) => {
                      onUserInput(val);
                    }}
                  />
                  {/* {!!currency && showUSDT && Number.isFinite(amountInDollar) && ( */}
                    <div className="overflow-hidden">
                      <p className="text-sm-md text-white ">~${formatNumber(amountInDollar)}</p>
                    </div>
                  {/* )} */}
                </div>
              </div>
            )}
          </div>
        </div>
        {showPercentPicker && (
            <PercentPicker onChangePercentInput={onChangePercentInput} isDisabled={!selectedCurrencyBalance} />
        )}
      </div>
    </div>
  );
}
