import { createAction } from '@reduxjs/toolkit';
import { TYPE_VIEW } from 'constants/index';

export const VAULT_VIEW_DEFAULT = TYPE_VIEW.grid;

export const updateUserExpertMode = createAction('user/updateUserExpertMode');
export const updateUserSingleHopOnly = createAction('user/updateUserSingleHopOnly');
export const updateUserSlippageTolerance = createAction('user/updateUserSlippageTolerance');
export const updateUserDeadline = createAction('user/updateUserDeadline');
export const updateUserNetwork = createAction('user/updateUserNetwork');
export const addSerializedPair = createAction('user/addSerializedPair');
export const removeSerializedPair = createAction('user/removeSerializedPair');

export const toggleTheme = createAction('user/toggleTheme');
export const updateUserStakedOnly = createAction('user/updateUserStakedOnly');
export const updateUserViewMode = createAction('user/updateUserViewMode');

export const addWatchlistToken = createAction('user/addWatchlistToken');
export const addWatchlistPool = createAction('user/addWatchlistPool');

export const addSerializedToken = createAction('user/addSerializedToken');
export const removeSerializedToken = createAction('user/removeSerializedToken');

export const setIsExchangeChartDisplayed = createAction('user/toggleIsExchangeChartDisplayed');
