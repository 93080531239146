import React, { useState } from 'react';
import Card from 'components/Card/Card';
import Countdown, { zeroPad } from 'react-countdown';
import Badge from '../../Lands/components/Badge';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import BoxCounter from './BoxCounter';
import { NavItem, TabContent, TabPane, Tabs } from 'components/Tabs/Tabs';
import DetailRarity from './DetailRarity';
import MintButton from './MintButton';
import { classNames } from 'utils/styling';
import useInterval from 'hooks/useInterval';
import UnlockButton from 'components/UnlockButton/UnlockButton';

const Detail = ({ nft }) => {
  const { account } = useActiveWeb3React();
  const [tab, setTab] = useState(1);
  const [openSell, setOpenSell] = useState(false);

  const onChangeTab = (value) => {
    if (value !== tab) {
      setTab(value);
    }
  };

  useInterval(() => {
    if (!openSell && nft?.openTime && Date.now() >= nft?.openTime) {
      setOpenSell(true);
    }
  }, [1000]);

  const rendererStartIn = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className="flex mt-2 justify-center sm:justify-start">
          <Badge className="flex justify-start items-center bg-primary">
            {typeof nft?.minted === 'number' && nft?.maxMint && nft?.minted >= nft?.maxMint ? 'Sold Out' : 'Opening'}
          </Badge>
        </div>
      );
    }
    return (
      <div className="flex mt-2">
        <Badge className="flex justify-start items-center">
          <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
          Open in {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </Badge>
      </div>
    );
  };
  return (
    <Card className="my-20">
      <div
        className={classNames('px-4 sm:px-8 pt-8 mt-8 bg-cover sm:bg-contain sm:bg-right', {
          'pb-16': !nft?.data,
        })}
        style={{
          backgroundImage: 'url(/images/Lightning-Dragons.png)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="grid sm:grid-cols-2">
          <div>
            <div className="flex flex-col items-center sm:items-start ">
              <div className="block md:flex items-center mb-7">
                <div className="rounded-50 overflow-hidden bg-black1 flex justify-center items-center w-20 h-20  m-auto md:mx-0">
                  <img alt="logo" src={nft?.img} className="w-auto h-full" />
                </div>
                <div>
                  <h3 className="text-center md:text-left text-2xl ml-0 md:ml-3 font-bold text-primary mt-3 md:mt-0 background-opacity-base-xs sm:bg-transparent p-2 sm:p-0 rounded-lg">
                    {nft?.name}
                  </h3>
                </div>
              </div>

              {nft?.openTime ? (
                <Countdown zeroPadTime={2} date={nft?.openTime} renderer={rendererStartIn} />
              ) : (
                <div className="flex mt-2 justify-center sm:justify-start">
                  <Badge className="flex justify-start items-center">
                    <div className="rounded-50 p-1 mr-1 bg-primary mr-2" />
                    Opens in TBA
                  </Badge>
                </div>
              )}
              <div className="mt-1">
                <Badge>{nft?.buyToken?.symbol}</Badge>
              </div>
            </div>
            <p className="text-white mt-5 text-md background-opacity-base-xs sm:bg-transparent p-2 sm:p-0 rounded">
              {nft?.content}
            </p>
            <div className="flex my-5">
              {!account ? (
                <UnlockButton fullWidth={false} />
              ) : (
                <>
                  <MintButton nft={nft} openSell={openSell} />
                </>
              )}
            </div>
            {openSell && nft?.canClaim === false ? (
              <p className="text-warning mb-4 text-center sm:text-left">
                NFT in this batch is sold out. Please wait for the next batch
              </p>
            ) : null}
          </div>
          <div className="flex justify-center sm:justify-end items-end">
            <BoxCounter nft={nft} />
          </div>
        </div>
        {nft?.data ? (
          <Tabs>
            <NavItem active={tab === 1} onClick={() => onChangeTab(1)}>
              Detail NFT
            </NavItem>
          </Tabs>
        ) : null}
      </div>
      {nft?.data ? (
        <TabContent className="p-4 sm:px-8 pb-10" activeTab={tab}>
          <TabPane tabId={1}>
            <DetailRarity nft={nft} />
          </TabPane>
        </TabContent>
      ) : null}
    </Card>
  );
};

export default Detail;
