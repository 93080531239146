import Dots from 'components/Loader/Dots';
import Tooltip from 'components/Tooltip/Tooltip';
import Value from 'components/Value/Value';
import { formatNumber, getBalanceNumber } from 'utils/formatBalance';

const TvlCell = ({ pool }) => {
  const stakedTvl = pool?.stakedTvl || 0;

  return (
    <div>
      <p>TVL</p>
      <Tooltip
        tooltip={
          <div className="whitespace-nowrap">
            <p>Total Deposits</p>
            <p>
              {formatNumber(getBalanceNumber(+pool?.totalStaked, pool.stakingToken.decimals))}{' '}
              {pool.stakingToken.symbol}
            </p>
          </div>
        }
      >
        {+stakedTvl > 0 ? (
          <Value className="font-bold" prefix="$" value={stakedTvl} decimals={0} />
        ) : (
          <Dots className="font-bold" />
        )}
      </Tooltip>
    </div>
  );
};

export default TvlCell;
