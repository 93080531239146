import { createSlice } from '@reduxjs/toolkit';
import vaultsConfig, { vaultsV2Config } from 'constants/vaults';
import { ChainId } from 'ezcake-v2-sdk';
import { setPricesData } from 'store/prices/index';
import {
  fetchVaultsPendingRewardBSC,
  fetchVaultsPendingRewardKAI,
  fetchVaultsPendingRewardONUS,
  fetchVaultsTotalStaked,
  fetchVaultsTotalSupply,
  fetchVaultsV2TotalShares,
  fetchVaultsV2TotalStakedOfStrategy,
} from 'store/vaults/fetchVaults';
import {
  fetchVaultUserAllowances,
  fetchVaultUserStakedBalances,
  fetchVaultUserTokenBalances,
  fetchVaultV2UserShares,
} from 'store/vaults/fetchVaultUser';
import { BIG_ZERO } from 'utils/bigNumber';
import { getPrices } from 'utils/priceHelpers';

const initialState = {
  data: vaultsConfig,
  dataV2: vaultsV2Config,
  userDataLoaded: false,
  userDataLoadedV2: false,
};

export const vaultsSlice = createSlice({
  name: 'vaults',
  initialState,
  reducers: {
    setVaultsPublicData: (state, action) => {
      const { liveVaultsData, chainId } = action.payload;
      state.data[chainId] = state.data[chainId].map((vault) => {
        const liveVaultData = liveVaultsData.find((entry) => entry.vid === vault.vid);
        return { ...vault, ...liveVaultData };
      });
    },
    setVaultsUserData: (state, action) => {
      const { userData, chainId } = action.payload;
      state.data[chainId] = state.data[chainId].map((vault) => {
        const userVaultData = userData.find((entry) => entry.vid === vault.vid);
        return { ...vault, userData: userVaultData };
      });
      state.userDataLoaded = true;
    },
    updateVaultsUserData: (state, action) => {
      const { field, value, vid, chainId } = action.payload;
      const index = state.data[chainId].findIndex((p) => p.vid === vid);

      if (index >= 0) {
        state.data[chainId][index] = {
          ...state.data[chainId][index],
          userData: { ...state.data[chainId][index].userData, [field]: value },
        };
      }
    },
    setVaultsV2PublicData: (state, action) => {
      const { liveVaultsData, chainId } = action.payload;
      state.dataV2[chainId] = state.dataV2[chainId].map((vault) => {
        const liveVaultData = liveVaultsData.find((entry) => entry.vid === vault.vid);
        return { ...vault, ...liveVaultData };
      });
    },
    setVaultsV2UserData: (state, action) => {
      const { userData, chainId } = action.payload;
      state.dataV2[chainId] = state.dataV2[chainId].map((vault) => {
        const userVaultData = userData.find((entry) => entry.vid === vault.vid);
        return { ...vault, userData: userVaultData };
      });
      state.userDataLoadedV2 = true;
    },
  },
});

// async action

const fcTotalPendingRewards = {
  [ChainId.BSC]: fetchVaultsPendingRewardBSC,
  [ChainId.KAI]: fetchVaultsPendingRewardKAI,
  [ChainId.ONUS]: fetchVaultsPendingRewardONUS,
};

export const fetchVaultsTotalStakedDataAsync = (chainId) => async (dispatch) => {
  try {
    const totalStakeds = await fetchVaultsTotalStaked(vaultsConfig[chainId], chainId);
    const totalPendingRewards = await fcTotalPendingRewards[chainId](vaultsConfig[chainId], chainId);

    const totalStakedsData = vaultsConfig[chainId].map((vault, index) => {
      const totalStaked = totalStakeds[index] || BIG_ZERO;
      const totalPendingReward = totalPendingRewards.find((v) => v.vid === vault.vid);

      return {
        vid: vault.vid,
        totalStaked: totalStaked.toJSON(),
        pendingReward: totalPendingReward?.earnings,
      };
    });

    dispatch(setVaultsPublicData({ liveVaultsData: totalStakedsData, chainId }));
  } catch (error) {
    console.log(error);
  }
};

export const fetchVaultUserDataAsync = (account, chainId) => async (dispatch) => {
  const promise = [
    fetchVaultUserAllowances(account, vaultsConfig[chainId], chainId),
    fetchVaultUserTokenBalances(account, vaultsConfig[chainId], chainId),
    fetchVaultUserStakedBalances(account, vaultsConfig[chainId], chainId),
  ];
  const response = await Promise.all(promise);

  const allowances = response?.[0];
  const stakingTokenBalances = response?.[1];
  const stakedBalances = response?.[2];

  const userData = vaultsConfig[chainId].map((vault, index) => ({
    vid: vault.vid,
    allowance: allowances[index],
    stakingTokenBalance: stakingTokenBalances[index],
    stakedBalance: stakedBalances[index],
  }));

  dispatch(setVaultsUserData({ userData, chainId }));
};

export const fetchVaultsV2PublicDataAsync = (chainId) => async (dispatch, getState) => {
  const promise = [
    fetchVaultsTotalStaked(vaultsV2Config[chainId], chainId),
    fetchVaultsV2TotalStakedOfStrategy(chainId),
    fetchVaultsV2TotalShares(chainId),
    fetchVaultsTotalSupply(vaultsV2Config[chainId], chainId),
  ];

  const response = await Promise.all(promise);

  const totalStakings = response?.[0];
  const totalStakingOfStrategy = response?.[1];
  const totalShares = response?.[2];
  const totalSupplys = response?.[3];

  const prices = getState()?.prices?.data[chainId] || (await getPrices(chainId));
  const newPrices = { ...prices };

  const liveData = vaultsV2Config[chainId].map((vault, index) => {
    const totalStaked = totalStakings[index] || BIG_ZERO;
    const totalStakedOfStrategy = totalStakingOfStrategy[index] || BIG_ZERO;
    const totalShare = totalShares[index] || BIG_ZERO;
    const totalSupply = totalSupplys.find((entry) => entry.vid === vault.vid).totalSupply;

    return {
      ...vault,
      totalSupply,
      totalStaked: totalStaked.toJSON(),
      totalStakedOfStrategy: totalStakedOfStrategy.toJSON(),
      totalShare: totalShare.toJSON(),
    };
  });
  dispatch(setVaultsV2PublicData({ liveVaultsData: liveData, chainId }));
  dispatch(
    setPricesData({
      chainId,
      prices: newPrices,
    }),
  );
};

export const fetchVaultV2UserDataAsync = (account, chainId) => async (dispatch) => {
  const promise = [
    fetchVaultUserAllowances(account, vaultsV2Config[chainId], chainId),
    fetchVaultUserTokenBalances(account, vaultsV2Config[chainId], chainId),
    fetchVaultUserStakedBalances(account, vaultsV2Config[chainId], chainId),
    fetchVaultV2UserShares(account, vaultsV2Config[chainId], chainId),
  ];
  const response = await Promise.all(promise);

  const allowances = response?.[0];
  const stakingTokenBalances = response?.[1];
  const stakedBalances = response?.[2];
  const sharesBalances = response?.[3];

  const userData = vaultsV2Config[chainId].map((vault, index) => ({
    vid: vault.vid,
    allowance: allowances[index],
    stakingTokenBalance: stakingTokenBalances[index],
    stakedBalance: stakedBalances[index],
    sharesBalance: sharesBalances[index],
  }));

  dispatch(setVaultsV2UserData({ userData, chainId }));
};

// Actions
export const { setVaultsPublicData, setVaultsUserData, setVaultsV2PublicData, setVaultsV2UserData } =
  vaultsSlice.actions;
export default vaultsSlice.reducer;
