import { useState } from 'react';
import TransactionsModal from 'views/Swap/components/Transactions/TransactionsModal';
import { FiClock } from 'react-icons/fi';

const Transactions = () => {
  const [presentTransactionsModal, setPresentTransactionsModal] = useState(false);

  const toggleTransactionModal = () => setPresentTransactionsModal((prevState) => !prevState);

  return (
    <>
      <TransactionsModal open={presentTransactionsModal} onDismiss={toggleTransactionModal} />
      <FiClock onClick={toggleTransactionModal} className="cursor-pointer" size={24} />
    </>
  );
};

export default Transactions;
