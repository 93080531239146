import CastleCard from 'views/Castles/components/CastlesCard/CastleCard';

const CastlesCard = ({ pools, userDataLoaded }) => {
  return (
    <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      {pools.map((pool, index) => {
        return <CastleCard key={index} pool={pool} index={index} userDataLoaded={userDataLoaded} />;
      })}
    </div>
  );
};

export default CastlesCard;
