import DoubleLogo from 'components/Logo/DoubleLogo';
import Tooltip from 'components/Tooltip/Tooltip';
import { CASTLE_TAGS, NAME_PLATFORM_LP } from 'constants/index';
import { formatLogo } from 'utils/formatLogo';
import { getTokenName } from 'utils/tokenHelpers';

const AssetsCell = ({ pool }) => {
  const lpTokenName = getTokenName(pool.token0, pool.token1, pool.stakingToken, true);
  const logo = formatLogo(pool.token0, pool.token1);

  return (
    <div className="flex items-center">
      <DoubleLogo right="-right-6" src0={logo.src0} src1={logo.src1} alt0={logo.alt0} alt1={logo.alt1} size={40} />
      <div className="flex flex-col ml-8 items-start">
        <p className="text-center text-primary text-lg font-bold">{lpTokenName}</p>
        <div className="flex items-center text-black">
          {pool.tags.map((tag, i) => (
            <p key={i} className="bg-primary px-2 rounded-lg min-w-min mr-1 capitalize">
              {tag}
            </p>
          ))}
          {pool.isFinished && (
            <p className="bg-primary px-2 rounded-lg min-w-min mr-1 capitalize">{CASTLE_TAGS.ended}</p>
          )}
          {pool?.token1 && (
            <Tooltip
              tooltip={
                <div className="whitespace-nowrap">
                  <p>{NAME_PLATFORM_LP[pool.stakingToken.symbol]}</p>
                </div>
              }
            >
              <div>
                <img
                  className="ml-1"
                  width="20"
                  src={`/platform/${NAME_PLATFORM_LP[pool.stakingToken.symbol].toLowerCase()}.png`}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetsCell;
