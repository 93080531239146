import { useDispatch, useSelector } from 'react-redux';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { useEffect } from 'react';
import useRefresh from '../../hooks/useRefresh';
import {
  fetchDetailNFTAsync,
  fetchMyNFTsAsync,
  fetchNFTData,
  fetchNFTsPublicDataAsync,
  fetchUserDataAsync,
  setMyNFTs,
  setUserData,
} from './index';
import { useParams } from 'react-router-dom';

export const useNFTs = () => useSelector((state) => state.NFTs.data);

export const useMyNFTs = () => useSelector((state) => state.NFTs.myNFTs);

export const useDragon = () => useSelector((state) => state.NFTs.dragon);

export const useDataLoaded = () => useSelector((state) => state.NFTs.dataLoaded);

export const useUserDataNFT = () => useSelector((state) => state.NFTs.userData);

export const useNFT = (id, chainId) => {
  const nft = useSelector((state) =>
    state.NFTs.data?.find(
      (p) =>
        (p.id === Number(id) || (id && id?.toString()?.toLowerCase() === p.slug?.toLowerCase())) &&
        (p.chainId === chainId || p.singleChain),
    ),
  );
  return nft || undefined;
};

export const useFetchNFTsPublicData = () => {
  const { chainId } = useActiveWeb3React();
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  useEffect(() => {
    if (chainId) {
      dispatch(fetchNFTsPublicDataAsync(chainId));
    }
  }, [dispatch, fastRefresh, chainId]);
};

export const useFetchNFTData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  const { chainId } = useActiveWeb3React();
  const nft = useNFT(id, chainId);
  useEffect(() => {
    if (chainId) {
      dispatch(fetchNFTData(nft));
    }
  }, [id, dispatch, fastRefresh, chainId]);
};

export const useFetchMyNFTs = () => {
  const { account, chainId } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  useEffect(() => {
    if (chainId && account) {
      dispatch(fetchMyNFTsAsync(account, chainId));
    }
  }, [account, chainId, dispatch, fastRefresh]);
  useEffect(() => {
    return () => {
      dispatch(setMyNFTs([]));
    };
  }, []);
};

export const useFetchDetailNFT = () => {
  const { account, chainId } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  const { id, slug } = useParams();
  const nft = useNFT(slug, chainId);

  useEffect(() => {
    if (chainId && nft) {
      dispatch(fetchDetailNFTAsync(nft, id));
    }
  }, [account, fastRefresh, chainId, dispatch, nft]);
};

export const useFetchUserDataNFT = () => {
  const { account, chainId } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();
  const dispatch = useDispatch();
  const { id } = useParams();
  const nft = useNFT(id, chainId);
  useEffect(() => {
    if (chainId && nft && account) {
      dispatch(fetchUserDataAsync(nft, account));
    }
  }, [account, fastRefresh, chainId, dispatch, nft]);

  useEffect(() => {
    return () => {
      dispatch(setUserData(undefined));
    };
  }, []);
};
