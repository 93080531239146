import { createSlice } from '@reduxjs/toolkit';
import { ChainId } from 'ezcake-v2-sdk';
import fetchFarms from 'store/farmsOutside/fetchFarms';
import { setPricesData } from 'store/prices/index';

const initialState = {
  farms: {
    [ChainId.BSC]: [],
    [ChainId.ONUS]: [],
  },
};

export const farmsOutsideSlice = createSlice({
  name: 'farmsOutside',
  initialState,
  reducers: {
    setFarmsPublicData: (state, action) => {
      state.farms[action.payload.chainId] = action.payload.farms;
    },
  },
});
export const fetchFarmsOutsideDataAsync = (prices, chainId) => async (dispatch) => {
  try {
    const res = await fetchFarms(prices, chainId);

    const farms = [...res[0].farms];
    const newPrices = {
      ...res[0].prices,
    };

    dispatch(
      setFarmsPublicData({
        farms,
        chainId,
      }),
    );
    dispatch(
      setPricesData({
        prices: newPrices,
        chainId,
      }),
    );
  } catch (error) {
    console.log(error);
  }
};

// Actions
export const { setFarmsPublicData } = farmsOutsideSlice.actions;
export default farmsOutsideSlice.reducer;
