import BigNumber from 'bignumber.js';
import erc20ABI from 'config/abi/erc20.json';
import vaultABI from 'config/abi/vaultAbi.json';
import multicall from 'utils/multicall';

export const fetchVaultUserAllowances = async (account, vaults, chainId) => {
  const calls = vaults.map((vault) => {
    return {
      address: vault.stakingToken.address[chainId],
      name: 'allowance',
      params: [account, vault.contractAddress],
    };
  });

  const rawLpAllowances = await multicall(erc20ABI, calls, chainId);
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON();
  });

  return parsedLpAllowances;
};

export const fetchVaultUserTokenBalances = async (account, vaults, chainId) => {
  const calls = vaults.map((vault) => {
    return {
      address: vault.stakingToken.address[chainId],
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls, chainId);

  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });

  return parsedTokenBalances;
};

export const fetchVaultUserStakedBalances = async (account, vaults, chainId) => {
  const calls = vaults.map((vault) => {
    return {
      address: vault.contractAddress,
      name: 'stakedWantTokens',
      params: [account],
    };
  });

  const rawStakedBalances = await multicall(vaultABI, calls, chainId);
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON();
  });

  return parsedStakedBalances;
};

export const fetchVaultV2UserShares = async (account, vaults, chainId) => {
  const calls = vaults.map((vault) => {
    return {
      address: vault.contractAddress,
      name: 'userInfo',
      params: [account],
    };
  });

  const rawShares = await multicall(vaultABI, calls, chainId);
  const parsedShares = rawShares.map((shares) => {
    return new BigNumber(shares[0]._hex).toJSON();
  });

  return parsedShares;
};
