import React from 'react';
import Back from '../../components/Back/Back';
import NFTCard from './components/NFTCard';
import { useFetchMyNFTs, useMyNFTs } from '../../store/NFTs/hook';
import Loader from '../../components/Loader/Loader';

const MyNFTs = () => {
  useFetchMyNFTs();
  const nfts = useMyNFTs();

  return (
    <div>
      <Back />
      <div className="mt-10" style={{ minHeight: '70vh' }}>
        <h2 className="text-white text-2xl text-center my-5 font-bold">My NFT</h2>
        <div className="px-6 pb-40">
          {Array.isArray(nfts) ? (
            <div className="container max-w-screen-xl mx-auto">
              {nfts?.length ? (
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 -mx-4">
                  {nfts?.map((item, index) => (
                    <NFTCard key={index} nft={item} />
                  ))}
                </div>
              ) : (
                <p className="text-center text-white py-5">No NFT</p>
              )}
            </div>
          ) : (
            <div className="mt-10">
              <Loader className="border-t-4 h-20 w-20 mx-auto" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyNFTs;
