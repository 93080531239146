import { useCallback, useState } from 'react';
import BnbWbnbNotice from './BnbWbnbNotice';
import PriceChart from './PriceChart';
import { getTokenAddress } from './utils';
import { WETH } from 'ezcake-v2-sdk';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { Field } from 'store/swap/actions';
import { useSwapState } from 'store/swap/hooks';

const PriceChartContainer = ({ inputCurrency, outputCurrency }) => {
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();

  const { chainId } = useActiveWeb3React();
  const token0Address = getTokenAddress(inputCurrencyId, chainId);
  const token1Address = getTokenAddress(outputCurrencyId, chainId);
  const [isPairReversed, setIsPairReversed] = useState(false);
  const togglePairReversed = useCallback(() => setIsPairReversed((prePairReversed) => !prePairReversed), []);

  const isBnbWbnb =
    token0Address?.toLowerCase() === WETH[chainId].address.toLowerCase() &&
    token1Address?.toLowerCase() === WETH[chainId].address.toLowerCase();

  if (isBnbWbnb) {
    return <BnbWbnbNotice />;
  }

  return (
    <PriceChart
      token0Address={isPairReversed ? token1Address : token0Address}
      token1Address={isPairReversed ? token0Address : token1Address}
      inputCurrency={isPairReversed ? outputCurrency : inputCurrency}
      outputCurrency={isPairReversed ? inputCurrency : outputCurrency}
      onSwitchTokens={togglePairReversed}
    />
  );
};

export default PriceChartContainer;
