import { useState, useMemo, memo, useEffect } from 'react';
import { formatAmount } from 'utils/formatInfoNumbers';
import Skeleton from 'components/Skeleton/Skeleton';
import dayjs from 'dayjs';

const HoverableChart = ({ chartData, protocolData, currentDate, valueProperty, title, ChartComponent }) => {
  const [hover, setHover] = useState();
  const [dateHover, setDateHover] = useState();

  // Getting latest data to display on top of chart when not hovered
  useEffect(() => {
    if (hover == null && protocolData) {
      setHover(parseFloat(protocolData[valueProperty]));
    }
  }, [protocolData, hover, valueProperty]);

  const formattedData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: dayjs.unix(day.date).toDate(),
          value: day[valueProperty],
        };
      });
    }
    return [];
  }, [chartData, valueProperty]);

  return (
    <div className="p-5">
      <p className="text-primary">{title}</p>
      {hover !== undefined ? (
        <p className="font-bold text-2xl">${formatAmount(hover)}</p>
      ) : (
        <Skeleton height={36} width={150} className="!rounded-lg" />
      )}
      <p>{dateHover ?? currentDate}</p>
      <div className="h-[250px]">
        <ChartComponent data={formattedData} setHoverValue={setHover} setHoverDate={setDateHover} />
      </div>
    </div>
  );
};

export default memo(HoverableChart);
