import { ChainId } from 'ezcake-v2-sdk';
import { Navigate, Route } from 'react-router-dom';
import Farms from '../views/Farms/Farms';
import Castles from '../views/Castles/Castles';
import DragonLands from '../views/Lands/DragonLands';
import Land from '../views/Land/Land';
import Vaults from '../views/Vaults/Vaults';
import NFTsPage from '../views/NFTs/NFTs';
import NFTPage from '../views/NFT/NFT';
import MyNFTs from '../views/MyNFTs/MyNFTs';
import DetailNFT from '../views/DetailNFT/DetailNFT';
import Swap from '../views/Swap/Swap';
import { RedirectToSwap } from '../views/Swap/redirects';
import Liquidity from '../views/Liquidity/Liquidity';
import AddLiquidity from '../views/AddLiquidity/AddLiquidity';
import RemoveLiquidity from '../views/RemoveLiquidity/RemoveLiquidity';
import PoolFinder from '../views/PoolFinder';
import { Fragment } from 'react';
import Overview from 'views/Analytics/Overview/Overview';
import PoolsOverview from 'views/Analytics/Pools/PoolsOverview';
import PoolPage from 'views/Analytics/Pools/PoolPage';
import TokensOverview from 'views/Analytics/Tokens/TokensOverview';
import TokenPage from 'views/Analytics/Tokens/TokenPage';
import { AnalyticsPageLayout } from 'views/Analytics/AnalyticsPageLayout';
import ComingSoon from "../views/ComingSoon/ComingSoon";

export const AVAILABLE_ROUTE = {
  [ChainId.BSC]: [
      '',
  ],
  [ChainId.KAI]: [],
  [ChainId.HARMONY]: [],
  [ChainId.ONUS]: [
      'swap',
      'liquidity',
      'add',
      'find',
      'remove',
      'analytics',
  ],
  [ChainId.ONUS_TESTNET]: [],
    [ChainId.BSC_TESTNET]: [
        'farms',
        'pools',
        'swap',
        'liquidity',
        'add',
        'find',
        'remove',
        'info',
        'analytics',
        'ido',
        'ido-detail',
        'ido-detail-v2',
        'nfts',
        'nft',
        'my-nfts',
        'nft-collection',
    ]
};

export const PATH_SWAP = ['/swap', '/liquidity', '/add', '/find', '/remove'];

export const CHAIN_ROUTE_SWAP = [ChainId.BSC_TESTNET, ChainId.ONUS];

export const ROUTE_CONFIG = {
    comingSoon:   <Fragment key="pools">
        <Route  path="/"  element={<Castles />} />
        <Route  path="/staking"  element={<ComingSoon />} />
        <Route  path="/launchpool"  element={<ComingSoon />} />
        <Route  path="/launchpad"  element={<ComingSoon />} />
    </Fragment>,
  farms: <Route key="farms" path="/farms" element={<Farms />} />,
  // pools: <Route key="pools" path="/pools" element={<Pools />} />,
  pools: (
    <Fragment key="pools">
      <Route path="/pools" element={<Castles />} />
    </Fragment>
  ),
  ido: (
    <Fragment key="ido">
      <Route path="/ido" element={<DragonLands />} />
      <Route path="/ido-detail/:id" element={<Land />} />
    </Fragment>
  ),
  nfts: (
    <Fragment key="nfts">
      <Route path="/nfts" element={<NFTsPage />} />
      <Route path="/nft/:id" element={<NFTPage />} />
      <Route path="/my-nfts" element={<MyNFTs />} />
      <Route path="/nft-collection/:slug/:id" element={<DetailNFT />} />
    </Fragment>
  ),
  vaults: <Route key="vaults" path="/vaults" element={<Vaults />} />,
  swap: (
    <Fragment key="swap">
        <Route path="/swap" element={<Swap />}>
           <Route exact strict path=":outputCurrency" element={RedirectToSwap} />
        </Route>

      <Route exact strict path="/liquidity" element={<Liquidity />} />

      <Route path="/add" element={<AddLiquidity />} />
      <Route exact strict path="/add/:currencyIdA" element={<AddLiquidity />} />
      <Route exact strict path="/add/:currencyIdA/:currencyIdB" element={<AddLiquidity />} />

      <Route path="/remove" element={<RemoveLiquidity />} />
      <Route exact strict path="/remove/:currencyIdA" element={<RemoveLiquidity />} />
      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" element={<RemoveLiquidity />} />

      <Route exact strict path="/find" element={<PoolFinder />} />
    </Fragment>
  ),
  info: (
    <Fragment key="info">
      <Route exact path="/info" element={<Navigate to="/analytics" replace />} />

      <Route
        exact
        path="/analytics"
        element={
          <AnalyticsPageLayout>
            <Overview />
          </AnalyticsPageLayout>
        }
      />

      <Route path="/info/pairs" element={<Navigate to="/analytics/pairs" replace />} />

      <Route
        exact
        path="/analytics/pairs"
        element={
          <AnalyticsPageLayout>
            <PoolsOverview />
          </AnalyticsPageLayout>
        }
      />

      <Route exact strict path="/info/pairs/:address" element={<Navigate to="/analytics/pairs/:address" replace />} />

      <Route
        exact
        strict
        path="/analytics/pairs/:address"
        element={
          <AnalyticsPageLayout>
            <PoolPage />
          </AnalyticsPageLayout>
        }
      />

      <Route path="/info/tokens" element={<Navigate to="/analytics/tokens" replace />} />

      <Route
        exact
        path="/analytics/tokens"
        element={
          <AnalyticsPageLayout>
            <TokensOverview />
          </AnalyticsPageLayout>
        }
      />

      <Route exact strict path="/info/tokens/:address" element={<Navigate to="/analytics/tokens/:address" replace />} />

      <Route
        exact
        strict
        path="/analytics/tokens/:address"
        element={
          <AnalyticsPageLayout>
            <TokenPage />
          </AnalyticsPageLayout>
        }
      />
    </Fragment>
  ),
};

export const ROUTE_BSC = [
  ROUTE_CONFIG.comingSoon,
];

export const ROUTE_KAI = [
  ROUTE_CONFIG.farms,
  ROUTE_CONFIG.pools,
  ROUTE_CONFIG.ido,
  ROUTE_CONFIG.nfts,
  ROUTE_CONFIG.vaults,
  ROUTE_CONFIG.swap,
];

export const ROUTE_HARMONY = [
  ROUTE_CONFIG.farms,
];

export const ROUTE_ONUS = [
    <Route key="coming-soon" path="/" element={<ComingSoon />}>
    </Route>,
  ROUTE_CONFIG.farms,
  ROUTE_CONFIG.ido,
  ROUTE_CONFIG.swap,
  ROUTE_CONFIG.info,
  ROUTE_CONFIG.vaults,
];

export const ROUTE_ONUS_TESTNET = [
  <Route key="home" path="/" exact element={<Navigate to="/swap" replace />} />,
  ROUTE_CONFIG.ido,
  ROUTE_CONFIG.swap,
];

export const ROUTE_BSC_TESTNET = [
    <Route key="home" path="/" exact element={<Navigate to="/swap" replace />} />,
    ROUTE_CONFIG.swap,
    ROUTE_CONFIG.farms,
    ROUTE_CONFIG.info,
    ROUTE_CONFIG.pools,
    ROUTE_CONFIG.ido,
    ROUTE_CONFIG.nfts,
];
