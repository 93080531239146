import React from 'react';
import Table from 'components/Table/Table';
import Value from 'components/Value/Value';
import { classNames } from 'utils/styling';

const DetailRarity = ({ nft }) => {
  const { data } = nft;
  return (
    <div>
      <div className={`grid grid-cols-1 md:grid-cols-10`}>
        <div className={classNames(`col-span-5 overflow-auto`)}>
          <Table containerClass="mt-3 custom-scrollbar-scroll">
            <thead>
              <tr>
                <th className="font-bold" colSpan={9999}>
                  Rarity
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data?.rarity) &&
                data?.rarity?.map((row, iRow) => (
                  <tr key={iRow}>
                    <td>{row.label}</td>
                    <td>
                      <Value value={row.value} decimals={row.decimals} unit="%" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DetailRarity;
